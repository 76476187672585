import { usePage } from '@inertiajs/react';

import { T_Access, T_Book, T_ClassGroup } from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';

export const useClassroomsShowPage = usePage<T_ClassroomsShowProps>;

export interface T_ClassroomsShowProps extends T_PageProps {
  access: T_Access;
  book: T_Book;
  classGroup: T_ClassGroup;
}
