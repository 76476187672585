import { Switch } from '~/components/ui/switch';

type Props = {
  remakable: boolean;
  remakeMode: boolean;
  setRemakeMode: (value: boolean) => void;
};

export function RemakeButton(props: Props) {
  const { remakable, remakeMode, setRemakeMode } = props;

  if (!remakable) return null;

  return (
    <div className="flex items-center">
      <h5 className="text-xs text-gray-500 font-medium mr-1.5">Vul In</h5>

      <Switch checked={remakeMode} onCheckedChange={setRemakeMode} data-testid="remake-switch" />

      <h5 className="text-xs text-gray-500 font-medium ml-1.5 mr-2">Hermaak</h5>
    </div>
  );
}
