import { usePage } from '@inertiajs/react';
import { cva } from 'class-variance-authority';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XMark } from 'styled-icons/heroicons-solid';

import { T_PageProps } from '~/types/T_PageProps';

import { Button } from '../ui/button';

export function Flash() {
  const { flash } = usePage<T_PageProps>().props;

  const [flashContent, setFlashContent] = useState(null);

  useEffect(() => {
    setFlashContent(flash);
  }, [flash]);

  if (flashContent?.alert)
    return (
      <FlashPrimitive
        clearContent={() => setFlashContent(null)}
        message={flashContent.alert}
        variant="alert"
      />
    );

  if (flashContent?.notice) {
    return (
      <FlashPrimitive
        clearContent={() => setFlashContent(null)}
        message={flashContent.notice}
        variant="notice"
      />
    );
  }
  return null;
}

type Props = {
  clearContent: () => void;
  message: string;
  variant: 'notice' | 'alert';
};

function FlashPrimitive({ clearContent, message, variant }: Props) {
  const { t } = useTranslation();

  const styles = cva(
    'flex justify-between items-center w-[300px] border border-t-4 outline-none p-3 shadow z-[500] bg-white rounded absolute z-50 p-4 top-4 right-4 rounded-md',
    {
      variants: {
        variant: {
          notice: 'border-t-green-500',
          alert: 'border-t-yellow-600',
        },
      },
    },
  );

  return (
    <div data-testid={`flash-${variant}`} className={styles({ variant })}>
      <div className="mr-4">
        <p className="text-sm font-light">{t(message)}</p>
      </div>

      <div className="self-start">
        <Button
          onClick={clearContent}
          data-testid="close_flash_button"
          variant="outline"
          size="icon">
          <XMark className="h-4 w-4" aria-hidden="true" />
        </Button>
      </div>
    </div>
  );
}
