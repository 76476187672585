import React from 'react';

import { T_AnswerableFillList } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillList';

import { ItemList } from './ItemList';

type Props = {
  answerable: T_AnswerableFillList['ViewNode'];
};

export function FillList(props: Props) {
  const { answerable } = props;

  return <ItemList answerable={answerable} />;
}
