// JsFromRoutes CacheKey 5c3f2b9be721c28a0c5e1a57ed5337ed
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  review: /* #__PURE__ */ definePathHelper('post', '/b/:book_slug/flashcards/:id/review'),
  updateFlashcardChapters: /* #__PURE__ */ definePathHelper('post', '/b/:book_slug/flashcards/update_flashcard_chapters'),
  index: /* #__PURE__ */ definePathHelper('get', '/b/:book_slug/flashcards'),
}
