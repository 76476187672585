import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  sourceList: any[];
};

export function SourceList(props: Props) {
  const { t } = useTranslation();

  const { sourceList } = props;

  return (
    <div className="source-page">
      <h1 className="font-bold text-2xl border-b pb-1">
        {t('pages.printTasksGenerate.text.sourceList')}
      </h1>

      <ul className="list-disc list-inside mt-6">
        {(sourceList ?? []).map((source, index) => (
          <li key={index}>{source}</li>
        ))}
      </ul>
    </div>
  );
}
