import React from 'react';
import { useTranslation } from 'react-i18next';
import { LightBulb } from 'styled-icons/heroicons-outline';

import { Button } from '~/components/ui/button';
import { nodeIsEmpty } from '~/lib/node-renderer/nodeHelpers';
import { cn } from '~/utils';

import { useExerciseProps } from '../context/ExercisePropsContext';

export function HintButton() {
  const { t } = useTranslation();

  const { node, showHint, setShowHint } = useExerciseProps();

  if (nodeIsEmpty(node.hint)) return null;

  return (
    <Button
      onClick={() => setShowHint(!showHint)}
      className={cn(showHint && 'bg-stone-100')}
      variant="outline"
      data-testid="toggle-hint-button"
      size="sm">
      <LightBulb className="mr-1 h-4 w-4" />
      {showHint
        ? t('pages.courseContentsShow.buttons.hideHint')
        : t('pages.courseContentsShow.buttons.showHint')}
    </Button>
  );
}
