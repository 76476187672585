import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components/ui/button';

type Props = {
  flashcardCount: number;
  handleReview: (remembered: boolean) => void;
};

export function Navigation(props: Props) {
  const { t } = useTranslation();

  const { handleReview } = props;

  const forgotButtonRef = useRef<HTMLButtonElement>(null);
  const rememberedButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const key = event.key.toLowerCase();

      if (rememberedButtonRef && rememberedButtonRef.current && key === 'c') {
        rememberedButtonRef.current.click();
      }

      if (forgotButtonRef && forgotButtonRef.current && key === 'f') {
        forgotButtonRef.current.click();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="rounded-md absolute bottom-10 left-1/2 -translate-x-1/2 p-3 text-sm font-medium flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <Button
          size="lg"
          ref={forgotButtonRef}
          variant="secondary"
          onClick={() => handleReview(false)}
          className="group border border-transparent space-x-1 hover:border-rose-300 hover:bg-rose-100 hover:text-red-900">
          <Cross2Icon className="w-4 h-4" />
          <span>{t('pages.flashcardsIndex.buttons.forgot')}</span>

          <label className="group-hover:cursor-pointer border bg-white rounded px-1.5 py-0.5 text-stone-600 text-xs font-medium">
            F
          </label>
        </Button>

        <Button
          size="lg"
          ref={rememberedButtonRef}
          variant="secondary"
          onClick={() => handleReview(true)}
          className="group border space-x-1 border-transparent hover:border-lime-300 hover:bg-lime-100 hover:text-lime-900">
          <CheckIcon className="w-4 h-4 mr-1" />
          <span>{t('pages.flashcardsIndex.buttons.remembered')}</span>

          <label className="group-hover:cursor-pointer border bg-white rounded px-1.5 py-0.5 text-stone-600 text-xs font-medium">
            C
          </label>
        </Button>
      </div>
    </div>
  );
}
