import { DialogTrigger } from '@radix-ui/react-dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '~/components/ui/dialog';

import { NewCustomSectionForm } from './new-custom-section-form';

type Props = {
  partSlug: string;
};

export function NewCustomSectionDialog(props: Props) {
  const { t } = useTranslation();

  const { partSlug } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button className="ml-2" data-testid="new_custom_section_button" size="sm" variant="ghost">
          {t('pages.booksShow.buttons.addPersonalDocument')}
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('pages.booksShow.dialogs.addCustomSection.title')}</DialogTitle>
        </DialogHeader>

        <NewCustomSectionForm closeDialog={() => setOpen(false)} partSlug={partSlug} />
      </DialogContent>
    </Dialog>
  );
}
