import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import React from 'react';

import { T_BaseText, T_BaseViewNode } from '~/node-builder/types';

type GeneratorProps = {
  nodeComponents: Record<
    string,
    Record<string, (props: { node: T_BaseViewNode | T_BaseText }) => JSX.Element>
  >;
};

type Props = {
  nodes: Array<T_BaseText | T_BaseViewNode>;
  Wrapper?: (props: {
    node: T_BaseViewNode | T_BaseText;
    children: React.ReactElement;
  }) => JSX.Element;
};

export function NodeMapGenerator(props: GeneratorProps) {
  const { nodeComponents } = props;

  return function NodeMap(props: Props) {
    const { nodes, Wrapper } = props;

    return (
      <>
        {nodes.map((node, index) => {
          const type = node['type'] === 'p' ? 'single-line-node' : node['type'] ?? 'text';

          const typeFormatted = type
            .split('-')
            .filter((v) => !!v)
            .join('-');

          const ComponentName = upperFirst(camelCase(type));

          try {
            const Component =
              nodeComponents[`./nodes/${typeFormatted}/${ComponentName}.tsx`][ComponentName];

            if (!Component) throw new Error();

            if (!Wrapper)
              return <Component key={node['nodeKey'] ?? `${index}_${type}`} node={node} />;

            return (
              <Wrapper key={node['nodeKey'] ?? `${index}_${type}`} node={node}>
                <Component node={node} />
              </Wrapper>
            );
          } catch (err) {
            return <UnrecognizedElement node={node} />;
          }
        })}
      </>
    );
  };
}

type UnrecognizedElementProps = {
  node: any;
};

function UnrecognizedElement(props: UnrecognizedElementProps) {
  const { node } = props;

  return (
    <div key={node.nodeKey}>
      <p>UNRECOGNIZED ELEMENT: {node.type}</p>
      <p>Component should be: {`./nodes/${node.type}/${upperFirst(camelCase(node.type))}.tsx`}</p>
      <p>
        The component should not be default exported but named export function{' '}
        {upperFirst(camelCase(node.type))}
      </p>
    </div>
  );
}
