import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChatBubbleLeftEllipsis } from 'styled-icons/heroicons-outline';

import { Button } from '~/components/ui/button';
import { DialogTrigger } from '~/components/ui/dialog';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';

export function AddEnrichmentButton() {
  const { t } = useTranslation();

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <DialogTrigger asChild>
            <Button data-testid="create_node_enrichment_button" size="sm" variant="ghost">
              <ChatBubbleLeftEllipsis className="h-4 w-4" />
            </Button>
          </DialogTrigger>
        </TooltipTrigger>
        <TooltipContent>{t('pages.courseContentsShow.text.addEnrichmentTooltip')}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
