import React from 'react';

import { DataTable } from '~/components/ui/data-table';
import { DataTableContainer } from '~/components/ui-extended/data-table-container';

import { columns } from '../columns';
import { useLicenseGroupsIndexPage } from '../T_LicenseGroupsIndex';
import { NewLicenseGroupButton } from './new-license-group-button';

export function PageContent() {
  const { licenseGroups, pagyMetadata } = useLicenseGroupsIndexPage().props;

  return (
    <DataTableContainer
      title="models.licenseGroup.many"
      translateTitle
      rightSideContent={<NewLicenseGroupButton />}>
      <DataTable
        columns={columns}
        data={licenseGroups}
        pagyMetadata={pagyMetadata}
        testidConfig={{ name: 'license_group', id: 'id' }}
      />
    </DataTableContainer>
  );
}
