import React from 'react';
import { useTranslation } from 'react-i18next';

import { T_AnswerableMarkOption } from '~/types/node/exercise/answerables/mark-option/T_AnswerableMarkOption';
import { T_AnswerableMarkOptionGap } from '~/types/node/exercise/answerables/mark-option/T_AnswerableMarkOptionGap';

import { SentInAnswersWrapper } from '../../shared/SentInAnswersWrapper';
import { PercentageView } from './PercentageView';

type Props = {
  activeGap: T_AnswerableMarkOptionGap['ViewNode'] | null;
  answerable: T_AnswerableMarkOption['ViewNode'];
};

export function SentInAnswers(props: Props) {
  const { t } = useTranslation();

  const { activeGap } = props;

  return (
    <SentInAnswersWrapper>
      <div className="flex flex-col">
        <div className="mt-1">
          {activeGap ? (
            <PercentageView activeGap={activeGap} />
          ) : (
            <div className="mt-1 text-gray-500">
              {t('pages.courseContentsShow.nodes.exercise.selectItemToSeeSentInAnswers')}:
            </div>
          )}
        </div>
      </div>
    </SentInAnswersWrapper>
  );
}
