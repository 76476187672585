// JsFromRoutes CacheKey 2a247ea100cc4a4b2cf0803cc83bfdab
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/users/sign_in'),
  create: /* #__PURE__ */ definePathHelper('post', '/users/sign_in'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/users/sign_out'),
}
