// JsFromRoutes CacheKey d48fb9fe656ab24dd3faf6c1a5c0a0da
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  editor: /* #__PURE__ */ definePathHelper('get', '/c/:slug/editor'),
  saveEditor: /* #__PURE__ */ definePathHelper('put', '/c/:slug/save_editor'),
  setPublished: /* #__PURE__ */ definePathHelper('post', '/c/:slug/set_published'),
  show: /* #__PURE__ */ definePathHelper('get', '/c/:slug'),
}
