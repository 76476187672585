import React, { useEffect } from 'react';

import { T_Flashcard } from '~/types/serializers';
import { MarkableImageable } from '~/views/book/components-new/nodes-shared/markable-imageable';

type Props = {
  flashcard: T_Flashcard;
};

export function MarkedImageFront(props: Props) {
  const {
    flashcard: { front, back },
  } = props;

  useEffect(() => {
    (back as any).number = ' ';
  });

  return (
    <div data-testid="flashcard_front" className="text-xl text-gray-600">
      <MarkableImageable imageable={front} markers={[back as any]} />
    </div>
  );
}

export function MarkedImageBack(props: Props) {
  const {
    flashcard: { back },
  } = props;

  return (
    <div
      data-testid="flashcard_back"
      className="text-gray-600 p-2 text-xl flex items-center justify-center">
      {(back as any).text}
    </div>
  );
}
