import React from 'react';

import { DataTable } from '~/components/ui/data-table';
import { DataTableContainer } from '~/components/ui-extended/data-table-container';

import { columns } from '../columns';
import { useClassGroupsIndexPage } from '../T_ClassGroupMembersIndex';
import { NewGroupDialog } from './new-group-dialog';

export default function PageContent() {
  const { book, classGroups, pagyMetadata } = useClassGroupsIndexPage().props;

  return (
    <DataTableContainer
      title="models.group.many"
      translateTitle
      rightSideContent={<NewGroupDialog book={book} />}>
      <DataTable
        columns={columns}
        data={classGroups}
        meta={{ book }}
        pagyMetadata={pagyMetadata}
        testidConfig={{ name: 'class_group', id: 'id' }}
      />
    </DataTableContainer>
  );
}
