import { useForm, usePage } from '@inertiajs/react';
import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DiscussionsApi from '~/api/DiscussionsApi';
import { Button } from '~/components/ui/button';
import { findNodeForNodeKey } from '~/lib/node-renderer/findNodeForNodekey';

import { NodePreview } from '../../components/node-preview';

export default function Form() {
  const { t } = useTranslation();

  const { bookContent, courseContent, teacherClassGroups, access } = usePage<any>().props;

  const nodeKey = queryString.parse(location.search)['nodeKey'] as string;

  const { data, setData, post } = useForm({
    title: '',
    body: '',
    class_group_id: 'default',
    node_key: nodeKey || null,
  });

  const classGroupOptions = useMemo(() => {
    return (teacherClassGroups || []).map(({ name, id }) => ({ value: id, label: name }));
  }, [teacherClassGroups]);

  const node = useMemo(() => {
    return findNodeForNodeKey(bookContent.children, nodeKey);
  }, [nodeKey]);

  return (
    <>
      {node && <NodePreview access={access} node={node} />}

      <form onSubmit={submit}>
        <div className="sm:col-span-6 mb-6">
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">
            {t('models.discussion.title')}
          </label>
          <div className="mt-1">
            <input
              value={data.title}
              onChange={(event) => setData('title', event.target.value)}
              placeholder={t('pages.discussionsNew.forms.newDiscussion.placeholders.title')}
              type="text"
              name="title"
              id="title"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        {!access.student && (
          <div className="sm:col-span-6 mb-6">
            <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
              {t('models.group.one')}
            </label>

            <select
              data-testid="discussion_class_group_select"
              value={data.class_group_id}
              id="class_group"
              name="class_group"
              onChange={(event) => setData('class_group_id', event.target.value)}
              className="mr-1 disabled:text-gray-300 bg-gray-50 block w-40 pl-3 pr-10 py-0.5 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
              <option disabled hidden value="default">
                {t('models.group.one')}
              </option>

              {classGroupOptions.map(({ label, value }, index) => {
                return (
                  <option value={value} key={index}>
                    {label}
                  </option>
                );
              })}
            </select>
          </div>
        )}

        <div className="sm:col-span-6">
          <label htmlFor="body" className="block text-sm font-medium text-gray-700">
            {t('models.discussion.body')}
          </label>

          <div className="mt-1">
            <textarea
              value={data.body}
              onChange={(event) => setData('body', event.target.value)}
              id="body"
              name="body"
              rows={4}
              className="shadow-sm resize-none focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"></textarea>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <Button disabled={!data.title} type="submit">
              {t('common.buttons.add')}
            </Button>
          </div>
        </div>
      </form>
    </>
  );

  function submit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    post(
      DiscussionsApi.create.path({
        course_content_slug: courseContent.slug,
      }),
      {
        preserveState: false,
      },
    );
  }
}
