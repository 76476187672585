// JsFromRoutes CacheKey b9341b2d4ec3309377d6170d74a07c2b
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/c/:course_content_slug/discussions'),
  create: /* #__PURE__ */ definePathHelper('post', '/c/:course_content_slug/discussions'),
  new: /* #__PURE__ */ definePathHelper('get', '/c/:course_content_slug/discussions/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/c/:course_content_slug/discussions/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/c/:course_content_slug/discussions/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/c/:course_content_slug/discussions/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/c/:course_content_slug/discussions/:id'),
}
