import React, { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AudioFragmentsApi from '~/api/AudioFragmentsApi';
import BooksApi from '~/api/BooksApi';
import CourseContentsApi from '~/api/CourseContentsApi';
import FilesApi from '~/api/FilesApi';
import ImagesApi from '~/api/ImagesApi';
import { Layout } from '~/components/ui-extended/layout';
import { SuspenseLoader } from '~/components/ui-extended/suspense-loader';
import { T_Ancestry } from '~/types/serializers';
import T_AncestryPart from '~/types/T_AncestryPart';
import {
  T_Breadcrumb,
  T_BreadcrumbDropdownItem,
  T_BreadcrumbDropdownItemLink,
  T_BreadcrumbDropdownItemNestedMenu,
} from '~/types/T_Breadcrumb';

import { SidebarEditorMenu } from './components/sidebar-editor-menu';
import { useCourseContentEditorPage } from './T_CourseContentEditorProps';

const ViewEditor = React.lazy(() => import('~/environment-editor/Editor'));

export default function Editor() {
  const { t } = useTranslation();

  const { courseContent, parts, editorBlob, book, containerTemplates } =
    useCourseContentEditorPage().props;

  const currentChapter = useMemo(() => findChapter(), [courseContent, parts]);

  return (
    <Layout
      htmlHeadTitle="Editor"
      sidebarChildren={
        <SidebarEditorMenu book={book} chapter={currentChapter} courseContent={courseContent} />
      }
      sidebarTitle={`${currentChapter.number}. ${currentChapter.title}`}
      breadcrumbs={[
        {
          title: book.title,
          type: 'link',
          href: BooksApi.editStructure.path({ slug: book.slug }),
        },
        {
          type: 'dropdown-collapsed',
          items: formatPartsAsBreadcrumbs(parts),
        },
        formatSectionsAsBreadcrumbItems(currentChapter),
      ]}>
      <div className="flex flex-col flex-1 w-full md:hidden mt-16 items-center">
        {t('pages.courseContentsEditor.text.unsupportedOnSmallDevices')}
      </div>

      <div className="h-full -m-2">
        <div className="hidden md:flex h-full flex-col">
          <Suspense fallback={<SuspenseLoader />}>
            <ViewEditor
              previewUrl={CourseContentsApi.show.path({ slug: courseContent.slug })}
              blob={editorBlob}
              book={book}
              containerTemplates={containerTemplates}
              savePath={CourseContentsApi.saveEditor.path({ slug: courseContent.slug })}
              storeProps={{
                audioUploadUrl: AudioFragmentsApi.create.path({
                  course_content_slug: courseContent.slug,
                }),
                imageUploadUrl: ImagesApi.create.path({
                  course_content_slug: courseContent.slug,
                }),
                fileUploadUrl: FilesApi.create.path({ course_content_slug: courseContent.slug }),
              }}
            />
          </Suspense>
        </div>
      </div>
    </Layout>
  );

  function findChapter() {
    const chapters = parts.map((part) => part.children).flat();

    return chapters.find((chapter) => chapter.id === courseContent.courseContentable.partId);
  }

  function formatPartsAsBreadcrumbs(parts: T_Ancestry[]): T_BreadcrumbDropdownItem[] {
    return parts.map<T_BreadcrumbDropdownItemNestedMenu>((part) => ({
      title: part.title,
      type: 'dropdown-item-nested-menu',
      items: formatChaptersAsBreadcrumbs(part.children),
    }));
  }

  function formatChaptersAsBreadcrumbs(chapters: T_AncestryPart[]): T_BreadcrumbDropdownItem[] {
    return chapters.map<T_BreadcrumbDropdownItemLink>((child) => ({
      title: `${child.number} ${child.title}`,
      type: 'dropdown-item-link',
      disabled: !child.sections?.[0],
      href: CourseContentsApi.editor.path({ slug: child.sections?.[0]?.slug }),
    }));
  }

  function formatSectionsAsBreadcrumbItems(chapter: T_AncestryPart): T_Breadcrumb {
    return {
      title: courseContent.courseContentable.numberedTitle,
      type: 'dropdown',
      items: chapter.sections.map<T_BreadcrumbDropdownItemLink>((section) => ({
        title: section.numberedTitle,
        type: 'dropdown-item-link',
        href: CourseContentsApi.editor.path({ slug: section.courseContent.slug }),
      })),
    };
  }
}

export type OnSaveHandlerProps = {
  value: any[];
  onSuccess: any;
  onError: any;
};
