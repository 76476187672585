import React from 'react';

import { T_MarkedImageMarker } from '~/types/node/T_MarkedImageMarker';

type Props = {
  node: T_MarkedImageMarker['ViewNode'];
};

export function Marker(props: Props) {
  const { node } = props;

  return (
    <div className="flex flex-row w-1/2 mb-[2.5mm] items-end">
      <span className="pr-[2mm] text-[#374151] text-[10pt]">{node.number}.</span>
      <span className="text-[10pt] text-[#374151]">{node.text}</span>
    </div>
  );
}
