import React from 'react';

import { T_AnswerableMultipleChoice } from '~/types/node/exercise/answerables/multiple-choice/T_AnswerableMultipleChoice';
import { useViewPrintContext } from '~/views/print/useViewPrintContext';

import { Option } from './Option';

type Props = {
  answerable: T_AnswerableMultipleChoice['ViewNode'];
};

export function OptionList(props: Props) {
  const { answerable } = props;

  const { exercise_layout, user_answers } = useViewPrintContext();

  return <div className="break-inside-avoid">{renderAnswerNodes()}</div>;

  function renderAnswerNodes() {
    return answerable.options.map((option, index) => {
      let checked: boolean;

      switch (exercise_layout) {
        case 'empty': {
          checked = false;
          break;
        }
        case 'standard_answers': {
          checked = option.correct;
          break;
        }
        case 'user_answers': {
          const answer = user_answers.answers.find(
            (answer) => answer.answerable.node_key === answerable.nodeKey,
          );

          checked = answer?.value.checkedAnswers.includes(option.nodeKey);
          break;
        }
      }

      return <Option checked={checked} option={option} key={index} />;
    });
  }
}
