import React, { Fragment } from 'react';

import { E_BookStructure } from '~/types/T_BookStructure';

import { useViewPrintContext } from '../useViewPrintContext';
import { BrandingPage } from './BrandingPage';
import { Cover } from './Cover';
import { Part } from './PartRoot';
import { PartRootTitlePage } from './PartRootTitlePage';
import { SourceList } from './SourceList';
import { TableOfContents } from './TableOfContents';

type Props = {
  book: any;
};

export function Book(props: Props) {
  const { book } = props;

  const { structure } = useViewPrintContext();

  const { children, ...metadata } = book;

  const partNodes = children.filter(({ type }) => type === 'part');

  return (
    <>
      <Cover book={book} metadata={metadata} />
      <BrandingPage metadata={metadata} />
      <TableOfContents partNodes={partNodes} />

      {partNodes.map((part, index) => (
        <Fragment key={index}>
          {structure === E_BookStructure.part_chapter_section && (
            <PartRootTitlePage title={`${part.number} ${part.title}`} metadata={metadata} />
          )}

          <Part part={part} />
        </Fragment>
      ))}

      {!!metadata.source_list?.length && <SourceList sourceList={metadata.source_list} />}
    </>
  );
}
