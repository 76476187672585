import React from 'react';
import { useTranslation } from 'react-i18next';

import { Footer } from '~/pages/website/components/footer';
import { HeaderEducation } from '~/pages/website/components/header-education';

import { useTrainingsShowPage } from './T_TutorialsShow';

export default function Show() {
  const { t } = useTranslation();

  const { training } = useTrainingsShowPage().props;

  return (
    <>
      <HeaderEducation />

      <div className="bg-white">
        <div className="mx-auto max-w-3xl px-6 py-10 lg:px-8">
          <div className="mx-auto max-w-4xl divide-y divide-gray-900/10 mb-6">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
              {training.title}
            </h2>
          </div>

          <div className="text-justify text-stone-600">{training.description}</div>

          <div className="text-stone-700 mt-4">
            <span className="font-semibold">{t('website.trainings.trainer')}: </span>{' '}
            {training.trainer}
          </div>

          <div className="text-stone-700 mt-1">
            <span className="font-semibold">{t('website.trainings.duration')}: </span>{' '}
            {training.duration}
          </div>

          <div className="text-stone-700 mt-1">
            <span className="font-semibold">{t('website.trainings.online')}: </span>{' '}
            {training.online ? t('website.trainings.yes') : t('website.trainings.no')}
          </div>

          <div className="w-full">
            <p className="mt-4 font-semibold text-green-500">{t('website.trainings.form')}:</p>

            <iframe
              height="800"
              className="w-full"
              allow="clipboard-write;camera;geolocation;fullscreen"
              src={training.formLink}></iframe>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

Show.layout = (page) => page;
