import { usePage } from '@inertiajs/react';
import React from 'react';

import { LayoutBook } from '~/components/ui-extended/layout-book';

import { PageContent } from './components/page-content';

export default function Show() {
  const { access, book } = usePage<any>().props;

  return (
    <LayoutBook
      breadcrumbs={[
        {
          title: 'common.breadcrumbs.booksShowPage',
          translateTitle: true,
          type: 'static',
        },
      ]}
      tour={{
        steps,
      }}
      htmlHeadTitle={book.title}
      access={access}
      book={book}
      sidebarTitle={book.title}>
      <PageContent />
    </LayoutBook>
  );
}

const steps = [
  {
    selector: 'welcome',
    contentI18nKey: 'pages.booksShow.tour.firstStep',
  },
  {
    selector: '.groups',
    contentI18nKey: 'pages.booksShow.tour.secondStep',
  },
  {
    selector: '.print',
    contentI18nKey: 'pages.booksShow.tour.thirdStep',
  },
  {
    selector: '.edit-structure',
    contentI18nKey: 'pages.booksShow.tour.fourthStep',
  },
  {
    selector: '.licenses',
    contentI18nKey: 'pages.booksShow.tour.fifthStep',
  },
  {
    selector: '.authors',
    contentI18nKey: 'pages.booksShow.tour.sixthStep',
  },
  {
    selector: '.configuration',
    contentI18nKey: 'pages.booksShow.tour.seventhStep',
  },
];
