import React from 'react';

import { DataTable } from '~/components/ui/data-table';
import { DataTableContainer } from '~/components/ui-extended/data-table-container';

import { columns } from '../columns';
import { useAuthorsIndexPage } from '../T_AuthorsIndex';
import { NewCollaborationDialog } from './new-collaboration-dialog';

export function PageContent() {
  const { book, collaborations, pagyMetadata } = useAuthorsIndexPage().props;

  return (
    <DataTableContainer
      title="pages.collaborationsIndex.title"
      translateTitle
      rightSideContent={<NewCollaborationDialog />}>
      <DataTable
        columns={columns}
        data={collaborations}
        meta={{ book }}
        pagyMetadata={pagyMetadata}
        testidConfig={{ name: 'collaboration', id: 'user.email' }}
      />
    </DataTableContainer>
  );
}
