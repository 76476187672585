import React, { useContext } from 'react';

import { useTheme } from '~/components/theme-provider';

import { Gutter } from '../components/Gutter';
import { NodeMap } from '../NodeMap';

type Props = {
  section: any;
};

export function Section(props: Props) {
  const { section } = props;

  const { colors } = useTheme();

  const sectionLink = `brbo.io/c/${section.slug}`;

  return (
    <SectionContext.Provider value={section}>
      <section className="break-after-always">
        <h2 style={{ color: colors.section }} className="font-bold text-lg">
          <Gutter content={section.number} />
          {section.title}
        </h2>

        <div className="-mt-2 mb-2">
          <a className="text-gray-500 text-xs" href={'https://' + sectionLink}>
            {sectionLink}
          </a>
        </div>

        <NodeMap nodes={section.children} />
      </section>
    </SectionContext.Provider>
  );
}

const SectionContext = React.createContext(null);

export const useSection = () => useContext(SectionContext);
