import { Link } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CourseContentsApi from '~/api/CourseContentsApi';
import { DropdownMenuLabel, DropdownMenuSeparator } from '~/components/ui/dropdown-menu';

import { useCourseContentShowPage } from '../T_CourseContentShowProps';
import { ClassGroupSelect } from './class-group-select';
import { DynamicQuestionButton } from './dynamic-question-button';
import { FocusModeSwitch } from './focus-mode-toggle';
import { MoreOptionsDropdown } from './more-options-dropdown';
import { ProvideClassGroupAnswersToggle } from './provide-class-group-answers-toggle';
import { TopmenuDesktop } from './top-menu-desktop';
import { TopMenuMobile } from './top-menu-mobile';

export function TopMenu() {
  const { t } = useTranslation();
  const { teachingClassGroup, courseContent } = useCourseContentShowPage().props;

  return (
    <div className="flex items-center space-x-2">
      <DynamicQuestionButton />

      <TopmenuDesktop>
        <ClassGroupSelect />

        <MoreOptionsDropdown>
          <Link href={CourseContentsApi.editor.path({ slug: courseContent.slug })}>
            {t('common.buttons.edit')}
          </Link>

          {teachingClassGroup && (
            <>
              <DropdownMenuSeparator />

              <DropdownMenuLabel>
                {t('pages.courseContentsShow.text.groupOptions')}: {teachingClassGroup?.name}
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <ProvideClassGroupAnswersToggle />
            </>
          )}
        </MoreOptionsDropdown>

        <FocusModeSwitch />
      </TopmenuDesktop>

      <TopMenuMobile>
        <ClassGroupSelect />
        <ProvideClassGroupAnswersToggle />
      </TopMenuMobile>
    </div>
  );
}
