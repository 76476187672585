import { Link } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { buttonVariants } from '~/components/ui/button';
import { cn } from '~/utils';

type Props = {
  navLinks: { i18nKey: string; href: string; external?: boolean }[];
};

export function NavLinks({ navLinks }: Props) {
  const { t } = useTranslation();

  return (
    <div className="hidden lg:flex lg:gap-4">
      {navLinks.map(({ external, i18nKey, href, ...props }) => {
        if (external) {
          return (
            <a
              key={i18nKey}
              href={href}
              {...props}
              className={cn(buttonVariants({ variant: 'ghost' }))}>
              <span className="relative z-10">{t(`website.navbar.${i18nKey}`)}</span>
            </a>
          );
        }

        return (
          <Link
            key={i18nKey}
            href={href}
            {...props}
            className={cn(buttonVariants({ variant: 'ghost' }))}>
            <span className="relative z-10">{t(`website.navbar.${i18nKey}`)}</span>
          </Link>
        );
      })}
    </div>
  );
}
