import React from 'react';

import { T_Link } from '~/types/node/T_Link';
import { NodeMap } from '~/views/print/NodeMap';

type Props = {
  node: T_Link['ViewNode'];
};

export function Link(props: Props) {
  const { node } = props;

  return (
    <a className="text-blue-500 underline" target="_blank" rel="noreferrer" href={node.url}>
      <NodeMap nodes={node.children} />
    </a>
  );
}
