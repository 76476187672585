// JsFromRoutes CacheKey f8515a699e18de1eb45a2ac9533f448b
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  duplicate: /* #__PURE__ */ definePathHelper('post', '/c/:course_content_slug/enrichments/:id/duplicate'),
  create: /* #__PURE__ */ definePathHelper('post', '/c/:course_content_slug/enrichments'),
  update: /* #__PURE__ */ definePathHelper('patch', '/c/:course_content_slug/enrichments/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/c/:course_content_slug/enrichments/:id'),
}
