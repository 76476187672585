import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components/ui/button';
import { Dialog, DialogContent, DialogTrigger } from '~/components/ui/dialog';
import { useSubscription } from '~/hooks/useSubscription';

import { useCourseContentShowPage } from '../T_CourseContentShowProps';
import { DynamicQuestionAskForm } from './dynamic-question-ask-form';

export function DynamicQuestionAskButton() {
  const { t } = useTranslation();

  const { teachingClassGroup } = useCourseContentShowPage().props;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          data-testid="ask-question-button"
          disabled={!teachingClassGroup}
          variant="secondary">
          {t('pages.courseContentsShow.buttons.askDynamicQuestion')}
        </Button>
      </DialogTrigger>

      <AskQuestionDialog />
    </Dialog>
  );
}

export function AskQuestionDialog() {
  const { teachingClassGroup, customQuestion } = useCourseContentShowPage().props;

  const isBeamingCustomQuestion = !!customQuestion;

  const [customQuestionAnswers, setCustomQuestionAnswers] = useState(
    customQuestion ? customQuestion.customQuestionAnswers : [],
  );

  useEffect(() => {
    setCustomQuestionAnswers(customQuestion ? customQuestion.customQuestionAnswers : []);
  }, [customQuestion]);

  useSubscription((response) => setCustomQuestionAnswers(response.data), {
    channel: 'ClassroomChannel',
    room: teachingClassGroup?.broadcastRoom,
    stream: 'custom_question_answers',
    trigger: isBeamingCustomQuestion,
  });

  return (
    <DialogContent>
      <DynamicQuestionAskForm
        isBeamingCustomQuestion={isBeamingCustomQuestion}
        customQuestion={customQuestion}
        customQuestionAnswers={customQuestionAnswers}
        setCustomQuestionAnswers={setCustomQuestionAnswers}
      />
    </DialogContent>
  );
}
