import {
  DndContext as ReactDndContext,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { router } from '@inertiajs/react';

import PartsApi from '~/api/PartsApi';
import SectionsApi from '~/api/SectionsApi';

type Props = {
  children: React.ReactNode;
  parts: any[];
  setDraggedItem: (item: any) => void;
  setParts: (parts: any[]) => void;
  setIsInDragAction: (isInDragAction: boolean) => void;
};

export function DndContext(props: Props) {
  const { children, parts, setDraggedItem, setParts, setIsInDragAction } = props;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <ReactDndContext
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
      sensors={sensors}>
      {children}
    </ReactDndContext>
  );

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;

    setIsInDragAction(true);

    switch (active.data.current.type) {
      case 'part': {
        const part = parts.find(({ slug }) => slug === active.id);

        if (!part) return;

        setDraggedItem({ ...part, type: 'part' });
        break;
      }
      case 'nestedPart': {
        const { partSlug } = active.data.current;

        const part = parts.find(({ slug }) => slug === partSlug);
        const nestedPart = part.children.find(({ slug }) => slug === active.id);

        if (!nestedPart) return;

        setDraggedItem({ ...nestedPart, type: 'nestedPart' });
        break;
      }
      case 'section': {
        const { partSlug, nestedPartSlug } = active.data.current;

        const part = parts.find(({ slug }) => slug === partSlug);
        const nestedPart = part.children.find(({ slug }) => slug === nestedPartSlug);
        const section = nestedPart.sections.find(({ id }) => id === active.id);

        if (!section) return;

        setDraggedItem({ ...section, type: 'section' });
        break;
      }
    }
  }

  function handleDragOver(event: DragOverEvent) {
    const { active, over } = event;

    const overId = over?.id;

    if (overId == null || parts.map((p) => p.slug).includes(active.id)) {
      // We are dragging a part, no nested updates are needed.
      return;
    }

    if (active.data.current.type === 'nestedPart') {
      const partOver = findPart(overId);
      const partActive = findPart(active.id);

      if (partOver && partActive.slug !== partOver.slug) {
        setParts((parts) => {
          const nestedPartsActive = partActive.children;
          const nestedPartsOver = partOver.children;

          const indexActive = nestedPartsActive.findIndex((item) => item.slug === active.id);
          const indexOver = nestedPartsOver.findIndex((item) => item.slug === overId);

          let newIndex: number;

          if (isPart(overId)) {
            newIndex = nestedPartsOver.length + 1;
          } else {
            const isBelowOverItem =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.top > over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;

            newIndex = indexOver > 0 ? indexOver + modifier : nestedPartsOver.length + 1;
          }

          const newParts = parts.map((part) => {
            if (part.slug === partActive.slug) {
              return {
                ...part,
                children: part.children.filter((nestedPart) => nestedPart.slug !== active.id),
              };
            }

            if (part.slug === partOver.slug) {
              return {
                ...part,
                children: [
                  ...part.children.slice(0, newIndex),
                  nestedPartsActive[indexActive],
                  ...part.children.slice(newIndex, part.children.length),
                ],
              };
            }

            return part;
          });

          return newParts;
        });
      }
    }

    if (active.data.current.type === 'section') {
      const nestedPartActive = findNestedPart(active.data.current.partSlug, active.id);
      const nestedPartOver = findNestedPart(over.data.current.partSlug, overId);

      if (nestedPartOver && nestedPartActive.slug !== nestedPartOver.slug) {
        setParts((parts) => {
          const sectionsActive = nestedPartActive.sections;
          const sectionsOver = nestedPartOver.sections;

          const indexActive = sectionsActive.findIndex((item) => item.id === active.id);
          const indexOver = sectionsOver.findIndex((item) => item.id === overId);

          let newIndex: number;

          if (isNestedPart(overId)) {
            newIndex = sectionsOver.length + 1;
          } else {
            const isBelowOverItem =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.top > over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;
            newIndex = indexOver > 0 ? indexOver + modifier : sectionsOver.length + 1;
          }

          const newNestedPartActive = {
            ...nestedPartActive,
            sections: nestedPartActive.sections.filter((section) => section.id !== active.id),
          };

          const newNestedPartOver = {
            ...nestedPartOver,
            sections: [
              ...nestedPartOver.sections.slice(0, newIndex),
              sectionsActive[indexActive],
              ...nestedPartOver.sections.slice(newIndex, nestedPartOver.sections.length),
            ],
          };

          const newParts = parts.map((part) => {
            let newPart = part;

            if (part.slug === active.data.current.partSlug) {
              newPart = {
                ...newPart,
                children: newPart.children.map((nestedPart) => {
                  if (nestedPart.slug === nestedPartActive.slug) {
                    return newNestedPartActive;
                  } else {
                    return nestedPart;
                  }
                }),
              };
            }

            if (part.slug === over.data.current.partSlug) {
              newPart = {
                ...newPart,
                children: newPart.children.map((nestedPart) => {
                  if (nestedPart.slug === nestedPartOver.slug) {
                    return newNestedPartOver;
                  } else {
                    return nestedPart;
                  }
                }),
              };
            }

            return newPart;
          });

          return newParts;
        });
      }
    }
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    switch (active.data.current.type) {
      case 'part': {
        const indexActive = parts.findIndex((p) => p.slug === active.id);
        const indexOver = parts.findIndex((p) => p.slug === over?.id);

        if (indexOver >= 0 && indexActive >= 0 && indexOver !== indexActive) {
          setParts((parts) => arrayMove(parts, indexActive, indexOver));
        }

        router.patch(
          PartsApi.sort.path({ slug: active.id }),
          { position: indexOver > 0 ? indexOver : 0 },
          {
            preserveState: true,
            preserveScroll: true,
            onFinish: () => {
              setIsInDragAction(false);
            },
          },
        );

        break;
      }
      case 'nestedPart': {
        const partOver = findPart(over?.id);
        const partActive = findPart(active.id);

        const indexActive = partActive.children.findIndex((np) => np.slug === active.id);

        if (!partOver || !partActive) return;

        const indexOver = partOver.children.findIndex((p) => p.slug === over?.id);

        setParts((parts) => {
          return parts.map((p) => {
            if (p.slug !== partOver.slug) return p;

            return {
              ...p,
              children: arrayMove(p.children, indexActive, indexOver),
            };
          });
        });

        router.patch(
          PartsApi.sort.path({ slug: active.id }),
          { position: indexOver > 0 ? indexOver : 0, part_slug: partOver.slug },
          {
            preserveState: true,
            preserveScroll: true,
            onFinish: () => {
              setIsInDragAction(false);
            },
          },
        );

        break;
      }
      case 'section': {
        const nestedPartActive = findNestedPart(active.data.current.partSlug, active.id);
        const nestedPartOver = findNestedPart(over.data.current.partSlug, over.id);

        if (!nestedPartOver || !nestedPartActive) return;

        const indexActive = nestedPartActive.sections.findIndex((item) => item.id === active.id);
        const indexOver = nestedPartOver.sections.findIndex((item) => item.id === over?.id);

        const newNestedPart = {
          ...nestedPartOver,
          sections: arrayMove(nestedPartOver.sections, indexActive, indexOver),
        };

        setParts((parts) => {
          return parts.map((p) => {
            if (p.slug !== over.data.current.partSlug) return p;

            return {
              ...p,
              children: p.children.map((np) => {
                if (np.slug !== nestedPartOver.slug) return np;

                return newNestedPart;
              }),
            };
          });
        });

        router.patch(
          SectionsApi.sort.path({ part_slug: nestedPartOver.slug, id: active.id }),
          { position: indexOver > 0 ? indexOver : 0, part_slug: nestedPartOver.slug },
          {
            preserveState: true,
            preserveScroll: true,
            onFinish: () => {
              setIsInDragAction(false);
            },
          },
        );

        break;
      }
    }

    setDraggedItem(null);
  }

  function handleDragCancel() {
    setIsInDragAction(false);
    setDraggedItem(null);
  }

  function findPart(slug: UniqueIdentifier) {
    const part = parts.find((p) => p.slug === slug);

    if (part) return part;

    return parts.find((part) => part.children.map((child) => child.slug).includes(slug));
  }

  function findNestedPart(partSlug: UniqueIdentifier, id: UniqueIdentifier) {
    const part = parts.find((p) => p.slug === partSlug);

    if (!part) return null;

    const nestedPart = part.children.find((np) => np.slug === id);

    if (nestedPart) return nestedPart;

    return part.children.find((np) => np.sections.map((s) => s.id).includes(id));
  }

  function isPart(slug: UniqueIdentifier) {
    return parts.map((p) => p.slug).includes(slug);
  }

  function isNestedPart(slug: UniqueIdentifier) {
    return parts
      .map((p) => p.children.map((np) => np.slug))
      .flat()
      .includes(slug);
  }
}
