import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TypographyMuted } from '~/components/typography/muted';
import { Button } from '~/components/ui/button';
import { T_Flashcard } from '~/types/serializers';

import { Flashcard } from './flashcard-item';
import { Navigation } from './navigation';

type Props = {
  flashcards: T_Flashcard[];
};

export function FlashcardsAllList(props: Props) {
  const { t } = useTranslation();

  const { flashcards } = props;

  const flashcardCount = flashcards.length;

  const [reviewStack, setReviewStack] = useState(flashcards);

  if (!reviewStack.length) {
    return (
      <div className="mt-5">
        {flashcardCount === 0 ? (
          <TypographyMuted>{t('pages.flashcardsIndex.text.noFlashcardsYet')}</TypographyMuted>
        ) : (
          <div className="space-y-2">
            <TypographyMuted>{t('pages.flashcardsIndex.text.reviewedAll')}</TypographyMuted>
            <Button onClick={() => setReviewStack(flashcards)}>
              {t('pages.flashcardsIndex.buttons.reviewAgain')}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="h-full relative pt-16">
        <Flashcard flashcard={reviewStack[0]} />

        <Navigation handleReview={handleReview} flashcardCount={flashcardCount} />
      </div>
    </div>
  );

  function handleReview(remembered: boolean) {
    setReviewStack((prevStack) => {
      const updatedStack = [...prevStack];
      const index = updatedStack.findIndex((flashcard) => flashcard.id === reviewStack[0].id);
      const [flashcard] = updatedStack.splice(index, 1);

      if (!remembered) {
        updatedStack.push(flashcard);
      }

      return updatedStack;
    });
  }
}
