import { usePage } from '@inertiajs/react';
import React from 'react';

import { LayoutBook } from '~/components/ui-extended/layout-book';

import { PageContent } from './components/page-content';

export default function Show() {
  const { access, parts, book } = usePage<any>().props;

  const steps = [
    {
      selector: '.structure',
      contentI18nKey: 'pages.booksEditStructure.tour.firstStep',
    },
    {
      selector: '.structure',
      contentI18nKey: 'pages.booksEditStructure.tour.secondStep',
    },
    {
      selector: '.structure',
      contentI18nKey: 'pages.booksEditStructure.tour.thirdStep',
    },
    {
      selector: '.structure',
      contentI18nKey: 'pages.booksEditStructure.tour.fourthStep',
    },
    {
      selector: isSectionPresent() ? '.section-options' : '.structure',
      contentI18nKey: 'pages.booksEditStructure.tour.fifthStep',
    },
    {
      selector: isSectionPresent() ? '.section-editor' : '.structure',
      contentI18nKey: isSectionPresent()
        ? 'pages.booksEditStructure.tour.sixthStep'
        : 'pages.booksEditStructure.tour.sixthStepWithoutSection',
    },
  ];

  return (
    <LayoutBook
      tour={{ steps }}
      breadcrumbs={[
        { title: 'common.breadcrumbs.booksEditStructure', translateTitle: true, type: 'static' },
      ]}
      sidebarTitle={book.title}
      htmlHeadTitle={book.title}
      access={access}
      book={book}>
      <PageContent />
    </LayoutBook>
  );

  function isSectionPresent() {
    return parts.some(
      (part) => part.children.length > 0 && part.children.some((p) => p.sections.length > 0),
    );
  }
}
