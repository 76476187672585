import React from 'react';

import { T_ListItem } from '~/types/node/T_ListItem';
import { T_NodeType } from '~/types/T_NodeTypes';

import { OrderedList } from './OrderedList';
import { ParagraphWithGaps } from './ParagraphWithGaps';
import { UnorderedList } from './UnorderedList';

type Props = {
  node: T_ListItem['ViewNode'];
};

export function ListItem(props: Props) {
  const { node, ...otherProps } = props;

  return (
    <li className="text-gray-700 font-light">
      {node.children.map((contentNode, index) => {
        switch (contentNode.type) {
          case T_NodeType.LIST_ITEM_CONTENT: {
            return <ParagraphWithGaps {...otherProps} key={index} node={contentNode} />;
          }
          case T_NodeType.LIST_ORDERED: {
            return <OrderedList {...otherProps} key={index} node={contentNode} nested />;
          }
          case T_NodeType.LIST_UNORDERED: {
            return <UnorderedList {...otherProps} key={index} node={contentNode} nested />;
          }
        }
      })}
    </li>
  );
}
