import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronUp } from 'styled-icons/heroicons-outline';

import { Button } from '~/components/ui/button';

import { useCollapsableItem } from './collapsable-context';
import { StructureItemCreateButton } from './structure-item-create-button';
import { StructureItemDragHandle } from './structure-item-drag-handle';
import { StructureItemOptionsMenu } from './structure-item-options-menu';
import { StructureItemTitle } from './structure-item-title';

type Props = {
  children?: ReactNode;
  createItem: () => void;
  extraOptions?: any[];
  fromDragOverlay?: boolean;
  meta: {
    type: string;
    title: string;
    id: UniqueIdentifier;
    number: any;
    i18nKey: 'nestedPart' | 'section' | 'part';
  };
  paths: { update: string; delete: string };
  sortable: { id: UniqueIdentifier; data: any };
  rightSideContent?: ReactNode;
  menuClassName?: string;
};

export function StructureItem(props: Props) {
  const { t } = useTranslation();

  const {
    children,
    createItem,
    extraOptions,
    fromDragOverlay,
    meta,
    paths,
    rightSideContent,
    sortable,
    menuClassName,
  } = props;

  const { setNodeRef, attributes, listeners, transition, transform, isDragging } =
    useSortable(sortable);

  const { toggleContent, showContent } = useCollapsableItem(`${meta.type}_${meta.id}`);

  return (
    <div
      data-testid={`${meta.type}_${meta.id}`}
      ref={setNodeRef}
      style={{
        opacity: isDragging ? 0.5 : 1,
        transform: CSS.Translate.toString(transform),
        transition,
      }}>
      <div className="relative my-2">
        <div className="flex flex-1 items-center py-2.5 pr-2 rounded bg-stone-50 border">
          <StructureItemCreateButton
            createItem={createItem}
            fromDragOverlay={fromDragOverlay}
            isDragging={isDragging}
            meta={meta}
          />
          <StructureItemDragHandle
            attributes={attributes}
            fromDragOverlay={fromDragOverlay}
            isDragging={isDragging}
            listeners={listeners}
            meta={meta}
          />
          <div className="flex flex-1 justify-between items-center mr-2">
            <div className="flex items-center">
              <p className="text-xs text-black font-light pr-2 border-r mr-2">
                {t(`models.${meta.i18nKey}.one`)} {meta.number}
              </p>

              <StructureItemTitle
                fromDragOverlay={fromDragOverlay}
                isDragging={isDragging}
                meta={meta}
                paths={paths}
              />
            </div>

            {!!rightSideContent && rightSideContent}
          </div>

          <StructureItemOptionsMenu
            menuClassName={menuClassName}
            meta={meta}
            extraOptions={extraOptions}
            path={paths.delete}
          />

          {meta.type !== 'section' && (
            <Button onClick={toggleContent} className="h-5 w-5 ml-2" variant="ghost" size="icon">
              {showContent ? (
                <ChevronUp className="w-4 h-4" />
              ) : (
                <ChevronDown className="w-4 h-4" />
              )}
            </Button>
          )}
        </div>

        {showContent && <div className="ml-8">{children}</div>}
      </div>
    </div>
  );
}
