import { zodResolver } from '@hookform/resolvers/zod';
import { router } from '@inertiajs/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import LicenseGroupsApi from '~/api/LicenseGroupsApi';
import { Button } from '~/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import { RadioGroup, RadioGroupItem } from '~/components/ui/radio-group';
import { T_Book, T_InvoiceDetails } from '~/types/serializers';

type Props = {
  book: T_Book;
  closeDialog: () => void;
  testForm?: boolean;
  invoiceDetails?: T_InvoiceDetails;
};

export function NewLicenseGroupForm(props: Props) {
  const { t } = useTranslation();

  const { book, closeDialog, testForm, invoiceDetails } = props;

  const formSchema = z.object({
    quantity: z
      .string()
      .min(1)
      .max(testForm ? 30 : 1000),
    role: z.enum(['student', 'teacher']),
    notes: z.string().optional(),
    test_group: z.boolean().optional(),
    ...(!testForm
      ? {
          invoice_name: z.string(),
          invoice_email: z.string(),
          invoice_address: z.string(),
          invoice_vat_number: z.string(),
        }
      : {}),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      quantity: '1',
      role: 'student',
      notes: '',
      test_group: testForm,
      ...(!testForm
        ? {
            invoice_name: invoiceDetails?.name,
            invoice_email: invoiceDetails?.email,
            invoice_address: invoiceDetails?.address,
            invoice_vat_number: invoiceDetails?.vat,
          }
        : {}),
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
        <FormField
          control={form.control}
          name="quantity"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('pages.licenseGroupsIndex.forms.newLicenseGroup.labels.amount')}
              </FormLabel>
              <FormControl>
                <Input min={1} max={testForm ? 30 : 1000} type="number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem className="space-y-2">
              <div>
                <FormLabel>{t('models.licenseGroup.role')}</FormLabel>
              </div>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="flex items-center space-x-4">
                  <FormItem className="flex items-center space-x-2 space-y-0">
                    <FormControl>
                      <RadioGroupItem data-testid="role_student" value="student" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      {t('models.licenseGroup.role_student')}
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-2 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="teacher" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      {t('models.licenseGroup.role_teacher')}
                    </FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('models.licenseGroup.notes')}{' '}
                <span className="text-gray-400 font-light">({t('common.nouns.optional')})</span>
              </FormLabel>
              <FormControl>
                <Input type="text" placeholder="1A" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {!testForm && (
          <div className="text-sm space-y-1 pr-4">
            <p>{t('pages.licenseGroupsIndex.forms.newLicenseGroup.text.teacherLicenseFree')}</p>
            <p>{t('pages.licenseGroupsIndex.forms.newLicenseGroup.text.studentLicenseCost')}</p>
            <p>{t('pages.licenseGroupsIndex.forms.newLicenseGroup.text.bulkLicenseCost')}</p>
          </div>
        )}

        {!testForm && (
          <div className="space-y-1">
            <h3 className="font-semibold text-stone-700 border-b pb-2 mb-2">
              {t('pages.licenseGroupsIndex.forms.newLicenseGroup.labels.invoiceDetails')}
            </h3>

            <FormField
              control={form.control}
              name="invoice_name"
              render={({ field }) => (
                <FormItem className="flex items-center ">
                  <FormLabel className="w-1/4">
                    {t('pages.licenseGroupsIndex.forms.newLicenseGroup.labels.invoiceName')}
                  </FormLabel>
                  <FormControl>
                    <Input type="text" placeholder="" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="invoice_email"
              render={({ field }) => (
                <FormItem className="flex items-center ">
                  <FormLabel className="w-1/4">
                    {t('pages.licenseGroupsIndex.forms.newLicenseGroup.labels.invoiceEmail')}
                  </FormLabel>
                  <FormControl>
                    <Input type="email" placeholder="" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="invoice_address"
              render={({ field }) => (
                <FormItem className="flex items-center ">
                  <FormLabel className="w-1/4">
                    {t('pages.licenseGroupsIndex.forms.newLicenseGroup.labels.invoiceAddress')}
                  </FormLabel>
                  <FormControl>
                    <Input type="text" placeholder="" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="invoice_vat_number"
              render={({ field }) => (
                <FormItem className="flex items-center ">
                  <FormLabel className="w-1/4">
                    {t('pages.licenseGroupsIndex.forms.newLicenseGroup.labels.invoiceVATNumber')}
                  </FormLabel>
                  <FormControl>
                    <Input type="text" placeholder="" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        )}

        {testForm && (
          <p className="text-red-600 text-sm ">
            {t('pages.licenseGroupsIndex.forms.newLicenseGroup.text.testLicenseExplanation')}{' '}
          </p>
        )}
        <Button data-testid="create_license_group_button" type="submit">
          {t('common.buttons.add')}
        </Button>
      </form>
    </Form>
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.post(LicenseGroupsApi.create.path({ book_slug: book.slug }), values, {
      replace: true,
      onSuccess: () => {
        form.reset();
        closeDialog();
      },
    });
  }
}
