import { DialogTrigger } from '@radix-ui/react-dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircle } from 'styled-icons/bootstrap';

import { Button } from '~/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '~/components/ui/dialog';

import { useAuthorsIndexPage } from '../T_AuthorsIndex';
import { NewCollaborationForm } from './new-collaboration-form';

export function NewCollaborationDialog() {
  const { t } = useTranslation();

  const { access, book } = useAuthorsIndexPage().props;

  const [open, setOpen] = React.useState(false);

  if (!access.owner) return null;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button data-testid="new_collaboration_button" variant="outline">
          <PlusCircle className="w-4 h-4 mr-2" />
          {t('common.buttons.add')}
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('pages.collaborationsIndex.dialogs.newCollaboration.title')}</DialogTitle>
        </DialogHeader>

        <NewCollaborationForm closeDialog={() => setOpen(false)} book={book} />
      </DialogContent>
    </Dialog>
  );
}
