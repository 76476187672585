import React from 'react';

import { T_TableCell } from '~/types/node/T_TableCell';

import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_TableCell['ViewNode'];
};

export function Cell(props: Props) {
  const { node } = props;

  return (
    <td className="p-2 text-sm text-gray-700">
      <NodeMap nodes={node.children} />
    </td>
  );
}
