import { usePage } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TypographyH3 } from '~/components/typography/heading-three';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '~/components/ui/card';
import { Progress } from '~/components/ui/progress';
import { T_AncestrySection } from '~/types/T_AncestryPart';

import SectionItem from './view-student-section-item';

export function ViewStudent() {
  const { t } = useTranslation();

  const { exercises, answeredExercises, chapter, selfAssessments } = usePage<any>().props;

  return (
    <div className="grid grid-cols-3 gap-2">
      <ExerciseCard
        exerciseCount={exercises.length}
        answeredExerciseCount={answeredExercises.length}
      />

      <Card className="col-span-3 bg-white max-w-3xl">
        <CardHeader className="pb-4">
          <CardTitle>
            <TypographyH3>{t('models.section.many')}</TypographyH3>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="">
            {chapter.sections.map((section) => {
              return (
                <SectionItem
                  answeredExerciseCount={answeredExerciseCount(section)}
                  assessment={findStudentAssessment(section)}
                  exerciseCount={exerciseCount(section)}
                  key={section.id}
                  section={section}
                />
              );
            })}
          </div>
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
    </div>
  );

  function findStudentAssessment(section: T_AncestrySection) {
    return selfAssessments.find(
      (assessment) => assessment?.courseContent?.courseContentable?.id === section?.id,
    );
  }

  function exerciseCount(section: T_AncestrySection) {
    return (exercises ?? []).filter(
      (exercise) => exercise?.courseContent?.courseContentable?.id === section?.id,
    ).length;
  }

  function answeredExerciseCount(section) {
    let count = 0;

    const answerNodeKeys = (answeredExercises ?? []).map(
      (answeredExercise) => answeredExercise.nodeKey,
    );

    (exercises ?? [])
      .filter((exercise) => exercise?.courseContent?.courseContentable?.id === section?.id)
      .forEach((exercise) => {
        if (
          exercise.node.answerable_type !== 'sub-exercise-list' &&
          answerNodeKeys.includes(exercise.node.answerable_value.nodeKey)
        ) {
          count++;
        }

        if (exercise.node.answerable_type === 'sub-exercise-list') {
          const isAnswered = exercise.node.answerable_value.items.some((subExercise) => {
            return answerNodeKeys.includes(subExercise.answerable_value.nodeKey);
          });

          if (isAnswered) {
            count++;
          }
        }
      });

    return count;
  }
}

function ExerciseCard({
  exerciseCount,
  answeredExerciseCount,
}: {
  exerciseCount: number;
  answeredExerciseCount: number;
}) {
  const { t } = useTranslation();

  const progress = (answeredExerciseCount / exerciseCount) * 100;

  return (
    <Card>
      <CardHeader className="pb-2">
        <CardTitle className="text-2xl">{t('models.exercise.many')}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="text-sm text-muted-foreground">
          {answeredExerciseCount} {t('common.text.ofThe')} {exerciseCount}{' '}
          {t('pages.partsShow.text.exercisesAnswered')}
        </div>
      </CardContent>
      <CardFooter>
        <Progress value={progress} aria-label="25% increase" />
      </CardFooter>
    </Card>
  );
}
