import React from 'react';

import { useSection } from '../../structure/Section';

export function Source() {
  const section = useSection();

  const sectionLink = `brbo.io/s/${section.slug}`;

  return (
    <a className="block text-gray-500 text-xs" href={'https://' + sectionLink}>
      {sectionLink}
    </a>
  );
}
