import { usePage } from '@inertiajs/react';
import React from 'react';
import styled from 'styled-components';

import { Enrichment } from '~/views/book/components/enrichments';
import { useViewBookContext } from '~/views/book/hooks/useViewBookContext';
import { NodeMap } from '~/views/book/NodeMap';

/**
 * Belangrijk:
 *  ScalingDiv MOET de eerste child zijn in de container die overflow beheert.
 *  En die mogen ook niet genest zijn!
 *
 *  Bewijs:
 *
 *  In onderstaande is het niet mogelijk om de div met border de geschaalde div
 *  netjes te laten wrappen.
 *
 *  https://play.tailwindcss.com/7RdybvYeWM
 *
 *  Zou moeten netjes 100% zijn, maar is toch groter. Lukt nochtans hier:
 *  https://play.tailwindcss.com/Hlr26Vtffz
 *
 */

export function FocusedContent({ zoomFactor }) {
  const { focus } = usePage<any>().props;

  const S = {
    ScalingDiv: styled.div`
      @media only screen and (min-width: 1600px) {
        width: ${60 / zoomFactor}%;
      }

      width: ${80 / zoomFactor}%;
      min-height: ${100 / zoomFactor}%;

      // cfr tailwind transform
      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
      transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
        rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
        scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));

      // cfr tailwind scale
      --tw-scale-x: ${zoomFactor};
      --tw-scale-y: ${zoomFactor};

      // schalen vanaf de top
      transform-origin: top;
    `,

    ScaledContentContainer: styled.div`
      padding-top: ${2.5 / zoomFactor}rem;
      padding-bottom: ${2.5 / zoomFactor}rem;

      padding-left: ${5 / zoomFactor}rem;
      padding-right: ${5 / zoomFactor}rem;

      height: calc(100% - 2 * ${1 / zoomFactor}rem);
    `,
  };

  const Content = {
    node: NodeContent,
    enrichment: EnrichmentContent,
  }[focus.type];

  return (
    <S.ScalingDiv className="mx-auto __SCALING__" data-testid="focused-content">
      <S.ScaledContentContainer className="mt-0 __SCALED__">
        <Content />
      </S.ScaledContentContainer>
    </S.ScalingDiv>
  );

  function NodeContent() {
    const { bookContent } = useViewBookContext();

    const node = bookContent.children.find((n) => n.nodeKey === focus.anchor);

    return <NodeMap nodes={[node]} />;
  }

  function EnrichmentContent() {
    const { enrichments } = useViewBookContext();

    const id = focus.anchor.match(/enrichment_(\d*)/)[1];
    const enrichment = enrichments.find((e) => e.id == id);

    return <Enrichment enrichment={enrichment} />;
  }
}
