import React from 'react';

import EducationApi from '~/api/Website/EducationApi';
import OrganizationApi from '~/api/Website/OrganizationApi';

import { NavBar } from './nav-bar';

const navLinks = [
  { i18nKey: 'home', href: '/' },
  { i18nKey: 'pricing', href: EducationApi.pricing.path() },
  { i18nKey: 'contact', href: EducationApi.contact.path() },
  { i18nKey: 'trainings', href: EducationApi.trainings.path() },
  { i18nKey: 'tutorials', href: OrganizationApi.tutorials.path() },
  { i18nKey: 'jobs', href: EducationApi.jobs.path() },
];

export function HeaderEducation() {
  return <NavBar subtitle="" navLinks={navLinks} />;
}
