import { Link } from '@inertiajs/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph } from 'styled-icons/bootstrap';

import CourseContentsApi from '~/api/CourseContentsApi';
import { T_BaseElement } from '~/types/nodes';
import { T_ElemenNodeType } from '~/types/T_NodeTypes';

export default function OutlineContent(props) {
  const { courseContent, bookContent, focus } = props;

  const render = useCallback(renderChildren, []);

  return (
    <ul className="p-5 space-y-2 overflow-y-auto">
      <div className="space-y-2">{render(bookContent.children, courseContent.slug, focus)}</div>
    </ul>
  );
}

function renderChildren(nodes: T_BaseElement[], slug: string, focus: boolean) {
  const outlineElements = [];

  let skipParagraph = false;

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];

    let Component = null;

    const href = focus
      ? CourseContentsApi.show.path({ slug, query: { focus: node.nodeKey } })
      : `#${node.nodeKey}`;

    switch (node.type) {
      case T_ElemenNodeType.PARAGRAPH: {
        if (skipParagraph) break;

        Component = <OutlineParagraph />;
        skipParagraph = true;
        break;
      }
      case T_ElemenNodeType.HEADING_ONE: {
        Component = (
          <Link href={href} preserveState>
            <OutlineHeadingOne node={node} />
          </Link>
        );
        skipParagraph = false;
        break;
      }
      case T_ElemenNodeType.DEFINITION: {
        Component = (
          <Link href={href} preserveState>
            <OutlineDefinition node={node} />
          </Link>
        );
        skipParagraph = false;
        break;
      }
      case T_ElemenNodeType.CONTAINER: {
        Component = (
          <Link href={href} preserveState>
            <OutlineContainer node={node} />
          </Link>
        );
        skipParagraph = false;
        break;
      }
      case T_ElemenNodeType.EXERCISE: {
        Component = (
          <Link href={href} preserveState>
            <OutlineExercise node={node} />
          </Link>
        );
        skipParagraph = false;
        break;
      }
    }

    if (Component) {
      outlineElements.push(<li key={i}>{Component}</li>);
    }
  }

  return outlineElements;
}

function OutlineParagraph() {
  return (
    <div data-testid="outline_paragraph" className="space-y-1">
      <div className="flex">
        <Paragraph className="h-4 w-4 text-gray-200" />
        <div className="flex-1 space-y-1.5">
          <div className="h-1 bg-gray-200 rounded-xl" />
          <div className="h-1 bg-gray-200 rounded-xl" />
        </div>
      </div>

      <div className="h-1 bg-gray-200 rounded-xl" />
    </div>
  );
}

function OutlineHeadingOne({ node }) {
  return (
    <div className="font-bold pt-3 text-sm text-gray-600 truncate">
      {node.number} {node.children.map((c) => c.text).join('')}
    </div>
  );
}

function OutlineDefinition({ node }) {
  const { t } = useTranslation();

  const { concept } = node;

  const text = concept.children[0].children.map((child) => child.text).join(' ');

  return (
    <div
      data-testid={`outline_definition_${node.nodeKey}`}
      className="text-sm font-medium text-gray-600 border-yellow-500 border hover:bg-yellow-100 cursor-pointer px-2 py-0.5 rounded-md truncate flex">
      <span className="mr-1">{t('common.nodes.definition')}: </span> {text}
    </div>
  );
}

function OutlineContainer({ node }) {
  const { title } = node;

  const text = title.children[0].children.map((child) => child.text).join(' ');

  return (
    <div
      data-testid={`outline_container_${node.nodeKey}`}
      className="text-sm font-medium text-gray-600 border-blue-500 hover:bg-blue-100 cursor-pointer border px-2 py-0.5 rounded-md truncate">
      <span className="mr-1">
        {node.name}
        {text && ':'}
      </span>{' '}
      {text}
    </div>
  );
}

function OutlineExercise({ node }) {
  const { t } = useTranslation();

  return (
    <div
      data-testid={`outline_exercise_${node.nodeKey}`}
      className="text-sm font-medium text-gray-600 border-green-500 hover:bg-green-100 cursor-pointer border px-2 py-0.5 rounded-md truncate flex">
      {t('common.nodes.exercise')} {node.number}
    </div>
  );
}
