import { router } from '@inertiajs/react';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlass } from 'styled-icons/heroicons-outline';

import BooksApi from '~/api/BooksApi';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '~/components/ui/tabs';

import ArchivedBookList from '../ArchivedBookList';
import BookList from '../BookList';
import { useBooksIndexPage } from '../T_BooksIndex';
import { CreationOptions } from './create-options';

export function PageContent() {
  const { t } = useTranslation();

  const { user, activeBooks, archivedBooks } = useBooksIndexPage().props;

  const searchQuery = (queryString.parse(location.search)['query'] as string) || '';

  const [searchString, setSearchString] = useState(searchQuery);

  return (
    <div className="flex flex-col w-full">
      <div className="max-w-3xl w-full mx-auto mt-10">
        <div className="mb-6">
          <h2 className="font-bold text-3xl text-gray-700">{t('pages.booksIndex.title')}</h2>
        </div>
        <div className="flex items-end justify-between mb-3">
          <div>
            <div>
              <label htmlFor="search_books" className="block text-sm font-medium text-gray-700">
                {t('pages.booksIndex.forms.search.labels.searchInput')}
              </label>
              <form onSubmit={handleSearch}>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <input
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                      type="text"
                      name="search_books"
                      id="search_books"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                      placeholder={t('pages.booksIndex.forms.search.placeholders.searchInput')}
                    />
                  </div>
                  <button
                    type="submit"
                    className="-ml-px relative inline-flex items-center space-x-1 px-3 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                    <MagnifyingGlass className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    <span>{t('pages.booksIndex.forms.search.buttons.performBookSearch')}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <CreationOptions role={user.role} />
        </div>

        <Tabs className="books" defaultValue="active">
          <TabsList className="grid w-80 grid-cols-2 mb-2 tegreen">
            <TabsTrigger data-testid="active-books-list" value="active">
              {t('pages.booksIndex.bookTabs.active.title')}
            </TabsTrigger>
            <TabsTrigger data-testid="archived-books-list" value="archived">
              {t('pages.booksIndex.bookTabs.archived.title')}
            </TabsTrigger>
          </TabsList>
          <TabsContent className="w-full" value="active">
            <BookList books={activeBooks} searchString={searchQuery} />
          </TabsContent>
          <TabsContent className="w-full" value="archived">
            <ArchivedBookList books={archivedBooks} searchString={searchQuery} />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );

  function handleSearch(event) {
    event.preventDefault();

    router.get(BooksApi.index.path({ query: { query: searchString } }), { preserveState: true });
  }
}
