import React, { useState } from 'react';

import { T_AnswerableMarkImage } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImage';
import { T_AnswerableMarkImageMarker } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImageMarker';
import { MarkableImageable } from '~/views/book/components-new/nodes-shared/markable-imageable';

import { useRemake } from '../../hooks/useRemake';
import { AnswerableControls } from '../../shared/AnswerableControls';
import { MarkerList } from './MarkerList';
import { SentInAnswers } from './SentInAnswers';

type Props = {
  answerable: T_AnswerableMarkImage['ViewNode'];
  subExercise?: boolean;
};

export function MarkImage(props: Props) {
  const { answerable, subExercise } = props;

  const remakeOptions = useRemake(answerable);

  const [activeItem, setActiveItem] = useState<T_AnswerableMarkImageMarker['ViewNode']>(null);

  const { source, markers } = answerable;

  return (
    <>
      <div className="w-full flex flex-col">
        <MarkableImageable markers={markers} imageable={source} />

        <MarkerList
          {...remakeOptions}
          activeItem={activeItem}
          answerable={answerable}
          markers={markers}
          setActiveItem={setActiveItem}
        />
      </div>

      <AnswerableControls<T_AnswerableMarkImage['ViewNode']>
        {...remakeOptions}
        subExercise={!!subExercise}
        answerable={answerable}
        sentInAnswersRender={(props) => <SentInAnswers {...props} activeItem={activeItem} />}
      />
    </>
  );
}
