import React, { useContext, useEffect, useState } from 'react';

import { useSubscription } from '~/hooks/useSubscription';
import { T_Answer } from '~/types/serializers';

import { useViewBookContext } from '../hooks/useViewBookContext';

type Props = {
  children: React.ReactNode;
};

export function SentInAnswersContextWrapper(props) {
  const { children } = props;

  const { access } = useViewBookContext();

  if (access.student) return children;

  return <SentInAnswersListener>{children}</SentInAnswersListener>;
}

export function SentInAnswersListener(props: Props) {
  const { children } = props;

  const { teachingClassGroup, teachingClassGroupStudentAnswers, courseContent } =
    useViewBookContext();

  const [sentInAnswers, setSentInAnswers] = useState<T_Answer[]>(teachingClassGroupStudentAnswers);

  useSubscription(
    (response) => {
      setSentInAnswers(response.data);
    },
    {
      channel: 'ClassroomChannel',
      room: teachingClassGroup?.broadcastRoom,
      stream: `answers_for_${courseContent.courseContentable.id}`,
      trigger: !!teachingClassGroup?.broadcastRoom,
    },
  );

  useEffect(() => {
    if (!teachingClassGroupStudentAnswers) return;

    setSentInAnswers(teachingClassGroupStudentAnswers);
  }, [teachingClassGroupStudentAnswers]);

  return (
    <SentInAnswersContextProvider value={{ sentInAnswers }}>
      {children}
    </SentInAnswersContextProvider>
  );
}

const SentInAnswersContext = React.createContext({});

function SentInAnswersContextProvider(props: {
  value?: { sentInAnswers?: T_Answer[] };
  children: React.ReactNode;
}) {
  const { children, value } = props;

  return <SentInAnswersContext.Provider value={value}>{children}</SentInAnswersContext.Provider>;
}

export const useSentInAnswersListener = () =>
  useContext<{ sentInAnswers?: T_Answer[] }>(SentInAnswersContext);
