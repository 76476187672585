import React, { useMemo } from 'react';

import BooksApi from '~/api/BooksApi';
import CourseContentsApi from '~/api/CourseContentsApi';
import {
  T_Access,
  T_Ancestry,
  T_Book,
  T_ClassGroup,
  T_CourseContent,
  T_StudentSelfAssessment,
} from '~/types/serializers';
import T_AncestryPart from '~/types/T_AncestryPart';
import {
  T_Breadcrumb,
  T_BreadcrumbDropdownItem,
  T_BreadcrumbDropdownItemLink,
  T_BreadcrumbDropdownItemNestedMenu,
} from '~/types/T_Breadcrumb';
import { userHasAccessToSection } from '~/utils/userHasAccessToSection';

import { Layout } from './layout';
import { SidebarCourseContentMenu } from './sidebar-course-content-menu';

type Props = {
  access: T_Access;
  book: T_Book;
  breadcrumbs: T_Breadcrumb[];
  chapter?: T_AncestryPart;
  children: React.ReactNode;
  courseContent?: T_CourseContent;
  hideSidebar?: boolean;
  htmlHeadMeta?: Record<string, string>;
  htmlHeadTitle: string;
  parts: T_Ancestry[];
  rightSideContent?: React.ReactNode;
  sidebarTitle: string;
  studentClassGroup?: T_ClassGroup;
  studentSelfAssessments?: T_StudentSelfAssessment[];
};

export function LayoutCourseContent(props: Props) {
  const {
    access,
    book,
    breadcrumbs,
    chapter,
    children,
    courseContent,
    htmlHeadMeta,
    htmlHeadTitle,
    hideSidebar,
    parts,
    rightSideContent,
    studentClassGroup,
    studentSelfAssessments,
  } = props;

  const currentChapter = useMemo(() => findChapter(), [chapter, courseContent, parts]);

  return (
    <Layout
      language={book.language}
      htmlHeadMeta={htmlHeadMeta}
      htmlHeadTitle={htmlHeadTitle}
      rightSideContent={rightSideContent}
      hideSidebar={hideSidebar}
      sidebarChildren={
        <SidebarCourseContentMenu
          chapter={currentChapter}
          access={access}
          book={book}
          courseContent={courseContent}
          studentClassGroup={studentClassGroup}
          studentSelfAssessments={studentSelfAssessments}
        />
      }
      sidebarTitle={`${currentChapter.number}. ${currentChapter.title}`}
      breadcrumbs={[
        {
          title: book.title,
          type: 'link',
          href: BooksApi.show.path({ slug: book.slug }),
        },
        {
          type: 'dropdown-collapsed',
          dataTestId: 'breadcrumbs-parts-collapsed',
          items:
            book.structure === 'part_chapter_section'
              ? formatPartsAsBreadcrumbs(parts)
              : formatNestedPartsAsBreadcrumbs(parts[0].children),
        },
        formatSectionsAsBreadcrumbItems(currentChapter),
        ...breadcrumbs,
      ]}>
      {children}
    </Layout>
  );

  function findChapter() {
    if (chapter) return chapter;

    const chapters = parts.map((part) => part.children).flat();

    return chapters.find((chapter) => chapter.id === courseContent.courseContentable.partId);
  }

  function formatNestedPartsAsBreadcrumbs(chapters: T_AncestryPart[]) {
    return chapters.map<T_BreadcrumbDropdownItemLink>((chapter) => ({
      title: `${chapter.number} ${chapter.title}`,
      type: 'dropdown-item-link',
      href: CourseContentsApi.show.path({ slug: chapter.sections?.[0]?.slug }),
    }));
  }

  function formatPartsAsBreadcrumbs(parts: T_Ancestry[]): T_BreadcrumbDropdownItem[] {
    return parts.map<T_BreadcrumbDropdownItemNestedMenu>((part) => ({
      title: part.title,
      dataTestId: `breadcrumb-part-dropdown-item-${part.slug}`,
      type: 'dropdown-item-nested-menu',
      items: formatChaptersAsBreadcrumbs(part.children),
    }));
  }

  function formatChaptersAsBreadcrumbs(chapters: T_AncestryPart[]): T_BreadcrumbDropdownItem[] {
    return chapters.map<T_BreadcrumbDropdownItemLink>((child) => ({
      title: `${child.number} ${child.title}`,
      dataTestId: `breadcrumb-chapter-dropdown-item-${child.slug}`,
      type: 'dropdown-item-link',
      disabled: !child.sections?.[0],
      href: CourseContentsApi.show.path({ slug: child.sections?.[0]?.slug }),
    }));
  }

  function formatSectionsAsBreadcrumbItems(chapter: T_AncestryPart): T_Breadcrumb {
    return {
      title: courseContent.courseContentable.numberedTitle,
      dataTestId: 'breadcrumb-section-dropdown-menu',
      type: 'dropdown',
      items: chapter.sections.map<T_BreadcrumbDropdownItemLink>((section) => ({
        title: section.numberedTitle,
        type: 'dropdown-item-link',
        dataTestId: `breadcrumb-section-dropdown-item-${section.slug}`,
        href: CourseContentsApi.show.path({ slug: section.courseContent.slug }),
        disabled: !userHasAccessToSection(chapter, section, access, studentSelfAssessments),
      })),
    };
  }
}
