import React from 'react';

import { T_MarkedImage } from '~/types/node/T_MarkedImage';

import { MarkableImageable } from '../../components-new/nodes-shared/markable-imageable';
import { MarkerList } from './MarkerList';

type Props = {
  node: T_MarkedImage['ViewNode'];
};

export function MarkedImage(props: Props) {
  const { node } = props;

  return (
    <div data-testid={`${node.type} ${node.nodeKey}`} className="w-full flex flex-col">
      <MarkableImageable imageable={node.source} markers={node.markers} />
      <MarkerList markers={node.markers} />
    </div>
  );
}
