import React from 'react';

export default function TermsOfService() {
  return (
    <section className="relative bg-gray-50">
      <div className="mx-auto max-w-2xl pb-20 text-justify">
        <div className="px-4 sm:px-8 pt-20">
          <a className="mb-5 block text-green-500" href="javascript:history.back()">
            Terug
          </a>
          <h1 className="text-left text-xl tracking-tight font-semibold text-gray-700 sm:text-4xl">
            <span className="block">
              Algemeen beleid op het vlak van gegevensverwerking en -bescherming
            </span>
          </h1>
        </div>
        <div className="px-4 sm:px-8 pt-10 text-gray-800">
          <p className="font-serif mb-5"></p>
          <p className="font-serif mb-5">Versie 1 • 1 september 2021</p>
          <p className="font-serif mb-5">
            Brightbook respecteert uw rechten bij de verwerking van uw persoonsgegevens. In deze
            verklaring vindt u hoe we uw persoonsgegevens verzamelen, verwerken en gebruiken. Deze
            privacyverklaring geeft het algemene beleid aan op het vlak van gegevensverwerking en
            -bescherming van:
          </p>
          <p className="font-serif">Brightbook, onderdeel van Lichtvis bv</p>
          <p className="font-serif">Baron Ruzettelaan 7</p>
          <p className="font-serif">8310 Brugge</p>
          <p className="font-serif">Contacteer ons via e-mail via info@brightbook.io</p>
          <p className="font-serif mb-5">RPR Brugge • BE0635559440</p>

          <h1 className="text-xl tracking-tight font-semibold text-gray-700 sm:text-2xl mb-5">
            Wie verwerkt uw persoonsgegevens?
          </h1>
          <p className="font-serif mb-5">
            Brightbook verwerkt uw persoonsgegevens. In deze privacyverklaring gebruiken we het
            persoonlijk voornaamwoord we om te verwijzen naar Brightbook. Brightbook is
            verantwoordelijk voor de verwerking van uw persoonsgegevens die in deze verklaring
            worden omschreven en toegelicht. We verwerken alleen persoonsgegevens en we laten alleen
            persoonsgegevens verwerken als dat noodzakelijk is om de taken die ons zijn toebedeeld,
            te kunnen verrichten. We verwerken de gegevens altijd in overeenstemming met de
            bepalingen van de algemene verordening gegevensbescherming (AVG), en met de bepalingen
            van de federale en Vlaamse regelgeving over de bescherming van natuurlijke personen bij
            de verwerking van persoonsgegevens.
          </p>
          <p className="font-serif mb-5">
            Als u algemene vragen hebt over de manier waarop we uw persoonsgegevens verwerken, kunt
            u contact opnemen met de functionaris voor gegevensbescherming (FG) van Brightbook via
            bovenstaande contactgegevens. In het Engels is dat de data protection officer (DPO). U
            kunt ook terecht bij de FG met opmerkingen en suggesties en om uw rechten uit te
            oefenen.
          </p>

          <h1 className="text-left text-xl tracking-tight font-semibold text-gray-700 sm:text-2xl mb-5">
            Wanneer verzamelen en verwerken we uw persoonsgegevens?
          </h1>
          <p className="font-serif mb-5">
            We verzamelen en verwerken uw persoonsgegevens als u een van onze diensten aanvraagt of
            contact met ons opneemt in het kader van de diensten die we verlenen. We kunnen uw
            persoonsgegevens ook verwerken als u een algemene vraag aan ons stelt.
          </p>

          <h1 className="text-xl tracking-tight font-semibold text-gray-700 sm:text-2xl mb-5">
            Welke gegevens verwerken we over u?
          </h1>
          <p className="font-serif mb-5">
            We verwerken uw persoonsgegevens. Dat zijn de gegevens die u identificeren of die een
            band leggen met u als natuurlijke persoon. De gegevens die we verwerken zijn voornaam,
            achternaam, e-mail en de rol (vb: leerling, leerkracht, auteur). Deze
            identificatiegegevens verwerken we om onze dienst aan u te kunnen verlenen.
          </p>

          <h1 className="text-xl tracking-tight font-semibold text-gray-700 sm:text-2xl mb-5">
            Hoe verzamelen en verwerken we uw persoonsgegevens?
          </h1>
          <p className="font-serif mb-5">
            We vragen uw gegevens op tijdens het aanmaken van een account in Brightbook.
          </p>

          <h1 className="text-xl tracking-tight font-semibold text-gray-700 sm:text-2xl mb-5">
            Waarvoor verwerken we uw persoonsgegevens?
          </h1>
          <p className="font-serif mb-5">
            We verwerken uw persoonsgegevens om onze dienst naar behoren te kunnen laten
            functioneren. Hierbij bedoelen we het gebruik van de applicatie en het informeren van uw
            als gebruiker i.v.m. updates van de applicatie.
          </p>

          <h1 className="text-xl tracking-tight font-semibold text-gray-700 sm:text-2xl mb-5">
            Hoelang bewaren we uw persoonsgegevens?
          </h1>
          <p className="font-serif mb-5">
            Volgens de algemene regel mogen we uw persoonsgegevens alleen bewaren tijdens de periode
            waarin ze noodzakelijk zijn om bepaalde diensten te verlenen. We bewaren uw gegevens dus
            zolang u gebruikmaakt van onze diensten. Daarna bewaren we uw gegevens nog gedurende de
            termijn waarin we ons moeten kunnen verantwoorden voor de verleende diensten. Die
            periode bedraagt 10 jaar. Na verloop van die verjaringstermijn worden de gegevens
            verwijderd.
          </p>

          <h1 className="text-xl tracking-tight font-semibold text-gray-700 sm:text-2xl mb-5">
            Delen we uw persoonsgegevens mee aan anderen?
          </h1>
          <p className="font-serif mb-5">
            Uw gegevens worden hoofdzakelijk intern verwerkt door onze medewerkers.
          </p>
          <p className="font-serif mb-5">
            Het is mogelijk dat we voor bepaalde specifieke diensten een beroep doen op derden, die
            uw gegevens verwerken. We bezorgen uw gegevens aan die verwerkers, zodat ze de dienst in
            kwestie kunnen verlenen. We oefenen daar op elk moment controle uit. De verwerkingen die
            door verwerkers gebeuren dienen voor volgende doeleinden: ICT-beheer, financieel beheer
            zoals facturatie, support, analyse van gebruik van de website en applicatie,
            klantenbeheer en direct mailing. U kunt een overzicht van de verwerkers ook aanvragen.
          </p>
          <p className="font-serif mb-5">
            Soms zijn we wettelijk of decretaal verplicht om uw persoonsgegeven door te geven of
            hebben overheidsdiensten het recht om bij ons gegevens op te vragen. Ze moeten daarvoor
            over een machtiging beschikken.{' '}
          </p>

          <h1 className="text-xl tracking-tight font-semibold text-gray-700 sm:text-2xl mb-5">
            Worden uw persoonsgegevens doorgegeven buiten de Europese Unie?
          </h1>
          <p className="font-serif mb-5">
            We geven uw gegevens niet door aan landen buiten de Europese Unie of internationale
            organisaties. Dat zijn landen of organisaties die gevestigd zijn buiten het grondgebied
            van de Europese Economische Ruimte (Europese Unie + IJsland, Noorwegen en
            Liechtenstein). We doen dat mogelijk wel als we gebruikmaken van een
            cloudserviceprovider. In dat geval nemen we alle mogelijke maatregelen om ervoor te
            zorgen dat uw gegevens worden beschermd zoals bepaald in de Europese, federale en
            Vlaamse regelgeving.
          </p>

          <h1 className="text-xl tracking-tight font-semibold text-gray-700 sm:text-2xl mb-5">
            Wat zijn uw rechten?
          </h1>
          <p className="font-serif mb-5">
            Als we uw persoonsgegevens verwerken in het kader van het algemeen belang, kunt u zich
            op elk moment verzetten tegen de verwerking van uw gegevens. We wegen dan af of uw
            individuele belangen zwaarder doorwegen dan het algemeen belang dat we nastreven met de
            verwerking. Als we uw gegevens niet langer mogen of kunnen verwerken, is het ook niet
            mogelijk om u de dienst of het voordeel waar u aanspraak op maakt, te verlenen.
          </p>
          <p className="font-serif mb-5">
            U kunt de gegevens die we over u verwerken, altijd inkijken en, indien nodig, laten
            verbeteren. U kunt daarvoor contact opnemen via bovenstaande contactgegevens. We vragen
            dan een bewijs van uw identiteit zodat uw gegevens niet worden meegedeeld aan iemand die
            er geen recht op heeft.
          </p>
          <p className="font-serif mb-5">
            U kunt ook terecht bij ons als u vindt dat uw gegevens niet langer relevant zijn en dus
            verwijderd moeten worden.{' '}
          </p>
          <p className="font-serif mb-5">
            Als u het niet eens bent met de manier waarop we uw gegevens verwerken, kunt u contact
            opnemen met onze functionaris voor gegevensbescherming (FG) via bovenstaande
            contactgegevens. Daarnaast kunt u een klacht indienen bij de bevoegde toezichthoudende
            autoriteit.
          </p>

          <h1 className="text-xl tracking-tight font-semibold text-gray-700 sm:text-2xl mb-5">
            Algemene informatie
          </h1>
          <p className="font-serif mb-5">
            We hebben het recht om het beleid te wijzigen en aan te passen. Wijzigingen en
            aanpassingen melden we altijd via de website. In voorkomend geval – een andere manier om
            wijzigingen en aanpassingen aan de betrokkenen mee te delen
          </p>

          <a className="mb-5 block text-green-500" href="javascript:history.back()">
            Terug
          </a>
        </div>
      </div>
    </section>
  );
}

TermsOfService.layout = (page) => page;
