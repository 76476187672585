import { zodResolver } from '@hookform/resolvers/zod';
import { router } from '@inertiajs/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import AuthorsApi from '~/api/AuthorsApi';
import { Button } from '~/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import { T_Book } from '~/types/serializers';

const formSchema = z.object({
  email: z.string().email(),
});

type Props = {
  book: T_Book;
  closeDialog: () => void;
};

export function NewCollaborationForm(props: Props) {
  const { t } = useTranslation();

  const { book, closeDialog } = props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  });

  return (
    <Form {...form}>
      <form
        data-testid="new_collaboration_form"
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-8">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('pages.collaborationsIndex.forms.newCollaboration.labels.email')}
              </FormLabel>
              <FormControl>
                <Input type="text" placeholder="john@company.com" {...field} />
              </FormControl>
              <FormDescription>
                {t('pages.collaborationsIndex.forms.newCollaboration.descriptions.newAuthor')}
                <p className="text-red-400">
                  {t(
                    'pages.collaborationsIndex.forms.newCollaboration.descriptions.warningAuthorLimitation',
                  )}
                </p>
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button data-testid="create_collaboration_button" type="submit">
          {t('common.buttons.add')}
        </Button>
      </form>
    </Form>
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.post(AuthorsApi.create.path({ book_slug: book.slug }), values, {
      replace: true,
      onError: (err) => {
        form.setError('email', {
          type: 'custom',
          message: `Gebruiker ${err['user'][0]}`,
        });
      },
      onSuccess: () => {
        form.reset();
        closeDialog();
      },
    });
  }
}
