import { RocketIcon } from '@radix-ui/react-icons';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components/ui/button';

import { useSentInAnswerProps } from '../context/AnswerablePropsContext';

type Props = {
  access: any;
};

export function SentInAnswersButton(props: Props) {
  const { t } = useTranslation();

  const { access } = props;

  const { showSentInAnswers, setShowSentInAnswers, sentInAnswers } = useSentInAnswerProps();

  if (access.student || !sentInAnswers?.length) return null;

  return (
    <Button
      disabled={!sentInAnswers}
      variant="outline"
      data-testid="toggle-student-answers-button"
      onClick={() => setShowSentInAnswers(!showSentInAnswers)}
      size="sm">
      <RocketIcon className="mr-1 h-4 w-4" />
      {showSentInAnswers
        ? t('pages.courseContentsShow.buttons.hideSentInAnswers')
        : t('pages.courseContentsShow.buttons.showSentInAnswers')}
    </Button>
  );
}
