// EN
import ZOD_EN from 'zod-i18n-map/locales/en/zod.json';
import ZOD_FR from 'zod-i18n-map/locales/fr/zod.json';
import ZOD_NL from 'zod-i18n-map/locales/nl/zod.json';

import COMMON_EN from './../../locale/en/common.json';
import CUSTOM_ZOD_ERRORS_EN from './../../locale/en/custom_zod_errors.json';
import MODELS_EN from './../../locale/en/models.json';
import PAGES_EN from './../../locale/en/pages.json';
import WEBSITE_EN from './../../locale/en/website.json';
// // FR
import COMMON_FR from './../../locale/fr/common.json';
import CUSTOM_ZOD_ERRORS_FR from './../../locale/fr/custom_zod_errors.json';
import MODELS_FR from './../../locale/fr/models.json';
import PAGES_FR from './../../locale/fr/pages.json';
import WEBSITE_FR from './../../locale/fr/website.json';
// // NL
import COMMON_NL from './../../locale/nl/common.json';
import CUSTOM_ZOD_ERRORS_NL from './../../locale/nl/custom_zod_errors.json';
import MODELS_NL from './../../locale/nl/models.json';
import PAGES_NL from './../../locale/nl/pages.json';
import WEBSITE_NL from './../../locale/nl/website.json';

export const resources = {
  en: {
    zod: ZOD_EN,
    translation: {
      common: COMMON_EN,
      customZodErrors: CUSTOM_ZOD_ERRORS_EN,
      models: MODELS_EN,
      pages: PAGES_EN,
      website: WEBSITE_EN,
    },
  },
  fr: {
    zod: ZOD_FR,
    translation: {
      common: COMMON_FR,
      customZodErrors: CUSTOM_ZOD_ERRORS_FR,
      models: MODELS_FR,
      pages: PAGES_FR,
      website: WEBSITE_FR,
    },
  },
  nl: {
    zod: ZOD_NL,
    translation: {
      common: COMMON_NL,
      customZodErrors: CUSTOM_ZOD_ERRORS_NL,
      models: MODELS_NL,
      pages: PAGES_NL,
      website: WEBSITE_NL,
    },
  },
};
