import shuffle from 'lodash/shuffle';
import React from 'react';

import { T_AnswerableFillGapsGap } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGapsGap';

type Props = {
  gaps: T_AnswerableFillGapsGap['ViewNode'][];
};

export function PossibleAnswers(props: Props) {
  const { gaps } = props;

  return (
    <div className="break-after-avoid flex flex-row flex-wrap italic">
      {renderPossibleAnswers()}
    </div>
  );

  function renderPossibleAnswers() {
    const answers = shuffle(gaps).map((node) => {
      if (!node.children[0].type) {
        return node.children[0].text;
      } else {
        return '';
      }
    });

    return <span className="text-[10pt] text-[#9ca3af]">{answers.join(' - ')}</span>;
  }
}
