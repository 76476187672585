import { ArrowDownIcon } from '@radix-ui/react-icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '~/components/ui/card';
import { T_Flashcard } from '~/types/serializers';
import { T_NodeType } from '~/types/T_NodeTypes';

import { DefinitionBack, DefinitionFront } from './flashcard-item-definition';
import { MarkedImageBack, MarkedImageFront } from './flashcard-item-marked-image';
import { WordItemBack, WordItemFront } from './flashcard-item-word-item';

type Props = {
  flashcard: T_Flashcard;
};

export function Flashcard(props: Props) {
  const { t } = useTranslation();

  const { flashcard } = props;

  const [showBack, setShowBack] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === 'Space') {
        setShowBack(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setShowBack(false);
  }, [flashcard]);

  return (
    <div className="min-w-[200px] w-[450px] mx-auto">
      <Card className="w-full  overflow-hidden">
        <div>
          <div
            data-testid="flashcard-front"
            className="min-h-[100px] p-2 flex items-center justify-center">
            {renderFrontSide()}
          </div>

          {showBack && (
            <div data-testid="flashcard-back" className="border-t bg-stone-50 border-dashed p-2">
              {renderBackSide()}
            </div>
          )}
        </div>

        {!showBack && (
          <div
            data-testid="show-answer-button"
            onClick={() => setShowBack(true)}
            className="group bg-stone-50 hover:bg-stone-100 transition-all duration-300 p-3 overflow-hidden rounded-b-lg flex justify-center items-center hover:cursor-pointer">
            <div className="flex items-center">
              <ArrowDownIcon className="w-4 h-4 mr-1" />
              <p className="text-stone-600 text-sm font-medium mr-2">
                {t('pages.flashcardsIndex.buttons.showAnswer')}
              </p>

              <label className="group-hover:cursor-pointer border bg-white rounded px-1.5 py-0.5 text-stone-600 text-xs font-medium">
                SPACE
              </label>
            </div>
          </div>
        )}
      </Card>
    </div>
  );

  function renderFrontSide() {
    switch (flashcard.nodeType) {
      case T_NodeType.DEFINITION:
        return <DefinitionFront flashcard={flashcard} />;
      case T_NodeType.WORD_ITEM:
        return <WordItemFront flashcard={flashcard} />;
      case 'marked-image-marker':
        return <MarkedImageFront flashcard={flashcard} />;
    }
  }

  function renderBackSide() {
    switch (flashcard.nodeType) {
      case T_NodeType.DEFINITION:
        return <DefinitionBack flashcard={flashcard} />;
      case T_NodeType.WORD_ITEM:
        return <WordItemBack flashcard={flashcard} />;
      case 'marked-image-marker':
        return <MarkedImageBack flashcard={flashcard} />;
    }
  }
}
