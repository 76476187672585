import React from 'react';

import { T_AnswerableMarkOption } from '~/types/node/exercise/answerables/mark-option/T_AnswerableMarkOption';
import { T_NodeType } from '~/types/T_NodeTypes';
import {
  findNodeByProperty,
  forEachNode,
} from '~/views/book/nodes/exercise/answerables/fill-gaps/helpers/gapIterators';
import { useViewPrintContext } from '~/views/print/useViewPrintContext';

import { AnswerInputFields } from './AnswerInputFields';
import { ParagraphList } from './ParagraphList';
import { PossibleAnswers } from './PossibleAnswers';

type Props = {
  answerable: T_AnswerableMarkOption['ViewNode'];
};

export function MarkOption(props: Props) {
  const { answerable } = props;

  const { exercise_layout, user_answers } = useViewPrintContext();

  const gaps = formatGaps();

  return (
    <div className="break-inside-avoid">
      <ParagraphList answerable={answerable} />
      <PossibleAnswers gaps={gaps} />
      <AnswerInputFields gaps={gaps} />
    </div>
  );

  function formatGaps() {
    const gaps: Record<string, any>[] = [];
    let gapCount = 0;

    forEachNode(answerable, T_NodeType.MARK_OPTION_GAP, (gap) => {
      gap.number = ++gapCount;

      let answer;

      switch (exercise_layout) {
        case 'empty': {
          answer = null;
          break;
        }
        case 'standard_answers': {
          answer = gap.children[0].text;
          break;
        }
        case 'user_answers': {
          const userAnswer = user_answers.answers.find(
            (answer) => answer.answerable.node_key === answerable.nodeKey,
          );

          if (!userAnswer) break;

          const selectedNodeKey = userAnswer?.value.markOption[gap.nodeKey];

          const selectedNode = findNodeByProperty(answerable, 'nodeKey', selectedNodeKey);

          answer = selectedNode?.cphildren?.[0]?.text ?? '';

          break;
        }
      }

      gap.answer = answer;

      gaps.push(gap);
    });

    return gaps;
  }
}
