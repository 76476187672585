import React from 'react';
import { useTranslation } from 'react-i18next';
import { Copy } from 'styled-icons/fluentui-system-regular';

import { Button } from '~/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';
import { useToast } from '~/components/ui/use-toast';
import { copyToClipboard } from '~/utils/copyToClipboard';

type Props = {
  code: string;
};

export function CopyLicenseCodeButton(props: Props) {
  const { t } = useTranslation();
  const { code } = props;

  const { toast } = useToast();

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            data-testid="copy-license-code-button"
            onClick={handleClick}
            variant="ghost"
            size="icon"
            className="ml-1 w-5 h-5">
            <Copy className="h-4 w-4 text-stone-500" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t('pages.licenseGroupsIndex.text.copyLicenseCode')}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );

  async function handleClick() {
    const result = await copyToClipboard(code);

    if (result) {
      toast({
        description: (
          <p className="font-medium">
            Code <span className="text-cyan-600">{code}</span> was copied to clipboard.
          </p>
        ),
        duration: 1500,
      });
    }
  }
}
