import { zodResolver } from '@hookform/resolvers/zod';
import { router } from '@inertiajs/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import PrintTasksApi from '~/api/PrintTasksApi';
import { Button } from '~/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { RadioGroup, RadioGroupItem } from '~/components/ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/components/ui/select';
import { E_BookStructure } from '~/types/T_BookStructure';

const formSchema = z.object({
  exercise_layout: z.enum(['empty', 'standard_answers', 'user_answers']),
  content: z.enum(['complete', 'exercises', 'container', 'definitions']),
  segments: z.enum(['book', 'part']),
  part_id: z.coerce.string().optional(),
  container_template_id: z.coerce.string().optional(),
  answers_user_book_id: z.coerce.string().optional(),
});

export function NewPrintTaskForm(props) {
  const { t } = useTranslation();

  const { book, parts, containerTemplates, answersUserBooks } = props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      exercise_layout: 'empty',
      content: 'complete',
      segments: 'book',
      part_id: undefined,
      container_template_id: undefined,
      answers_user_book_id: undefined,
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-2/3 space-y-6">
        {book.structure === E_BookStructure.part_chapter_section && (
          <FormField
            control={form.control}
            name="segments"
            render={({ field }) => (
              <FormItem className="space-y-2">
                <div>
                  <FormLabel>{t('models.part.many')}</FormLabel>
                </div>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    value={form.watch('segments')}
                    className="flex flex-col space-y-1">
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="book" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        {t('models.printTask.config.completeBook')}
                      </FormLabel>
                    </FormItem>

                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="part" />
                      </FormControl>
                      <FormLabel className="font-normal">{t('models.part.one')}: </FormLabel>

                      <FormField
                        control={form.control}
                        name="part_id"
                        render={({ field }) => (
                          <FormItem>
                            <Select onValueChange={handlePartSelection} defaultValue={field.value}>
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder={t('common.nouns.select')} />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {parts.map((part) => (
                                  <SelectItem key={part.id} value={part.id.toString()}>
                                    {part.title}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </FormItem>
                        )}
                      />
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        <FormField
          control={form.control}
          name="exercise_layout"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <div>
                <FormLabel>{t('models.exercise.many')}</FormLabel>
              </div>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  value={form.watch('exercise_layout')}
                  className="flex flex-col space-y-1">
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="empty" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      {t('models.printTask.config.toFillIn')}
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="standard_answers" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      {t('models.printTask.config.standardAnswers')}
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="user_answers" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      {t('models.printTask.config.filledInByUser')}{' '}
                    </FormLabel>

                    <FormField
                      control={form.control}
                      name="answers_user_book_id"
                      render={({ field }) => (
                        <FormItem>
                          <Select
                            defaultValue={field.value}
                            onValueChange={handleFilledInByUserSelection}>
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder={t('common.nouns.select')} />
                              </SelectTrigger>
                            </FormControl>

                            <SelectContent>
                              {answersUserBooks.map((userBook, index) => (
                                <SelectItem key={index} value={userBook.id?.toString()}>
                                  {userBook.user.fullName}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormItem>
                      )}
                    />
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="content"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <div>
                <FormLabel>{t('common.nouns.content')}</FormLabel>
              </div>
              <FormControl>
                <RadioGroup
                  onValueChange={field.onChange}
                  value={form.watch('content')}
                  className="flex flex-col space-y-1">
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="complete" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      {t('models.printTask.config.allContent')}
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="definitions" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      {t('models.printTask.config.onlyDefinitions')}
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="exercises" />
                    </FormControl>
                    <FormLabel className="font-normal">
                      {t('models.printTask.config.onlyExercises')}
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem disabled={!containerTemplates.length} value="container" />
                    </FormControl>
                    <FormLabel className="font-normal">{t('models.frame.many')}: </FormLabel>

                    <FormField
                      control={form.control}
                      name="container_template_id"
                      render={({ field }) => (
                        <FormItem>
                          <Select
                            disabled={!containerTemplates.length}
                            onValueChange={handleContentContainerSelection}
                            defaultValue={field.value}>
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder={t('common.nouns.select')} />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {containerTemplates.map((template) => (
                                <SelectItem key={template.id} value={template.id.toString()}>
                                  {template.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormItem>
                      )}
                    />
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button data-testid="create_print_task_button" type="submit">
          {t('common.buttons.add')}
        </Button>
      </form>
    </Form>
  );

  async function handlePartSelection(value) {
    form.setValue('segments', 'part', {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    form.setValue('part_id', value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  }

  async function handleFilledInByUserSelection(value) {
    form.setValue('exercise_layout', 'user_answers', {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    form.setValue('answers_user_book_id', value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  }

  async function handleContentContainerSelection(value) {
    form.setValue('content', 'container', {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    form.setValue('container_template_id', value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  }

  function onSubmit(values: z.infer<typeof formSchema>) {
    const data = {
      ...values,
      part_id: values.segments === 'part' ? values.part_id : null,
      answers_user_book_id:
        values.exercise_layout === 'user_answers' ? values.answers_user_book_id : null,
    };
    router.post(PrintTasksApi.create.path({ book_slug: book.slug }), data);
  }
}
