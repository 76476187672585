import React from 'react';

import { nodeIsEmpty } from '~/lib/node-renderer/nodeHelpers';
import { T_Access } from '~/types/views';
import { useViewBookContext } from '~/views/book/hooks/useViewBookContext';

import { useStandardAnswerProps } from '../context/AnswerablePropsContext';
import { useExerciseProps } from '../context/ExercisePropsContext';
import { ToggleAnswer } from '../shared-new/all-answers-toggle';
import { ShowOneAnswerButton } from '../shared-new/show-one-answer-button';

type Props = {
  access: T_Access;
};

export function StandardAnswerButtons(props: Props) {
  const { access } = props;

  const { studentClassGroup, classGroupCourseContent } = useViewBookContext();

  const { node } = useExerciseProps();
  const { showAnswer, answerCount, toggleFullAnswer, showOneAnswer, answerPresent } =
    useStandardAnswerProps();

  if (!answerPresent && nodeIsEmpty(node.feedback)) {
    return null;
  }

  return (
    <>
      <ToggleAnswer
        active={showAnswer !== 'none'}
        multipleAnswers={answerCount > 1}
        onPressedChange={toggleFullAnswer}
        disabled={studentAnswersAreDisabled()}
      />

      {answerPresent && answerCount > 1 && (
        <ShowOneAnswerButton
          onClick={showOneAnswer}
          disabled={studentAnswersAreDisabled() || showAnswer === 'all'}
        />
      )}
    </>
  );

  function studentAnswersAreDisabled() {
    return access.student && studentClassGroup && !classGroupCourseContent?.provideAnswers;
  }
}
