import { usePage } from '@inertiajs/react';

import {
  T_Access,
  T_Ancestry,
  T_Blob,
  T_Book,
  T_ContainerTemplate,
  T_CourseContent,
} from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';

export const useCourseContentEditorPage = usePage<T_CourseContentEditorProps>;

export interface T_CourseContentEditorProps extends T_PageProps {
  access: T_Access;
  book: T_Book;
  containerTemplates: T_ContainerTemplate[];
  courseContent: T_CourseContent;
  editorBlob: T_Blob;
  parts: T_Ancestry[];
}
