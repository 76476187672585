import React from 'react';

import { T_AnswerableMultipleChoice } from '~/types/node/exercise/answerables/multiple-choice/T_AnswerableMultipleChoice';

import { useRemake } from '../../hooks/useRemake';
import { AnswerableControls } from '../../shared/AnswerableControls';
import { OptionsList } from './OptionsList';
import { SentInAnswers } from './SentInAnswers';

type Props = {
  answerable: T_AnswerableMultipleChoice['ViewNode'];
  subExercise?: boolean;
};

export function MultipleChoice(props: Props) {
  const { answerable, subExercise } = props;

  const remakeOptions = useRemake(answerable);

  return (
    <>
      <OptionsList answerable={answerable} {...remakeOptions} />

      <AnswerableControls<T_AnswerableMultipleChoice['ViewNode']>
        {...remakeOptions}
        subExercise={!!subExercise}
        answerable={answerable}
        sentInAnswersRender={SentInAnswers}
      />
    </>
  );
}
