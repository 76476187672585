import React from 'react';

import { T_Flashcard } from '~/types/serializers';

type Props = {
  flashcard: T_Flashcard;
};

export function WordItemFront(props: Props) {
  const {
    flashcard: { front },
  } = props;

  return (
    <div data-testid="flashcard_front" className="text-xl text-gray-600">
      {front as string}
    </div>
  );
}

export function WordItemBack(props: Props) {
  const {
    flashcard: { back },
  } = props;

  return (
    <div
      data-testid="flashcard_back"
      className="text-gray-600 p-2 text-xl flex items-center justify-center">
      {back as string}
    </div>
  );
}
