import { Link } from '@inertiajs/react';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DiscussionsApi from '~/api/DiscussionsApi';
import { buttonVariants } from '~/components/ui/button';
import { DataTableContainer } from '~/components/ui-extended/data-table-container';
import { findNodeForNodeKey } from '~/lib/node-renderer/findNodeForNodekey';

import { NodePreview } from '../../components/node-preview';
import { useDiscussionsShowPage } from '../T_DiscussionsNewProps';
import { Author } from './Author';
import { CommentForm } from './CommentForm';
import { CommentList } from './CommentList';

export function PageContent() {
  const { t } = useTranslation();

  const { access, discussion, courseContent, bookContent } = useDiscussionsShowPage().props;

  const node = useMemo(() => {
    if (!discussion.nodeKey) return null;

    return findNodeForNodeKey(bookContent.children, discussion.nodeKey);
  }, []);

  return (
    <DataTableContainer
      title={discussion.title}
      rightSideContent={
        <Link
          className={buttonVariants({ variant: 'outline' })}
          href={DiscussionsApi.index.path({
            course_content_slug: courseContent.slug,
          })}>
          {t('common.buttons.back')}
        </Link>
      }>
      {node && <NodePreview access={access} node={node} />}

      <div className="pt-3 mb-6 text-gray-700">
        {discussion.body ? discussion.body : t('pages.discussionsShow.text.noDiscussionBody')}
      </div>

      <div className="text-sm text-gray-500 mb-2 flex items-center">
        <Author userBook={discussion.userBook} user={discussion.user} /> •{' '}
        {discussion.classGroup.name} • {dayjs(discussion.createdAt).fromNow()}
      </div>

      <CommentList discussion={discussion} access={access} courseContent={courseContent} />

      <CommentForm discussion={discussion} courseContent={courseContent} />
    </DataTableContainer>
  );
}
