import React, { useState } from 'react';

import { T_AnswerableFillList } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillList';

import { useRemake } from '../../hooks/useRemake';
import { AnswerableControls } from '../../shared/AnswerableControls';
import { ItemList } from './ItemList';
import { SentInAnswers } from './SentInAnswers';

type Props = {
  answerable: T_AnswerableFillList['ViewNode'];
  subExercise?: boolean;
};

export function FillList(props: Props) {
  const { answerable, subExercise } = props;

  const remakeOptions = useRemake(answerable);

  const [activeItem, setActiveItem] = useState(null);

  return (
    <>
      <ItemList
        {...remakeOptions}
        activeItem={activeItem}
        answerable={answerable}
        setActiveItem={setActiveItem}
      />

      <AnswerableControls<T_AnswerableFillList['ViewNode']>
        {...remakeOptions}
        subExercise={!!subExercise}
        answerable={answerable}
        sentInAnswersRender={(props) => <SentInAnswers {...props} activeItem={activeItem} />}
      />
    </>
  );
}
