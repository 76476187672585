import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataTableContainer } from '~/components/ui-extended/data-table-container';

import { useBooksEditPage } from '../T_BooksEdit';
import { PageContainers } from './page-containers';
import { PageGeneral } from './page-general';
import { PageLayout } from './page-layout';
import { PagePrint } from './page-print';
import { SideBar } from './sidebar';

export function PageContent() {
  const { t } = useTranslation();

  const { book, containerTemplates, rootPartCount } = useBooksEditPage().props;

  const [activePage, setActivePage] = useState('general');

  const pages = {
    general: { component: PageGeneral, title: t('pages.booksEdit.buttons.generalOptions') },
    containers: { component: PageContainers, title: t('pages.booksEdit.buttons.containerOptions') },
    print: { component: PagePrint, title: t('pages.booksEdit.buttons.printOptions') },
    layout: { component: PageLayout, title: t('pages.booksEdit.buttons.layoutOptions') },
  };

  return (
    <DataTableContainer title="pages.booksEdit.title" translateTitle>
      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="lg:w-1/5">
          <SideBar pages={pages} activePage={activePage} setActivePage={setActivePage} />
        </aside>

        <div className="flex-1 lg:max-w-2xl">{renderPage()}</div>
      </div>
    </DataTableContainer>
  );

  function renderPage() {
    const Component = pages[activePage].component;

    return (
      <Component
        book={book}
        containerTemplates={containerTemplates}
        rootPartCount={rootPartCount}
      />
    );
  }
}
