import React from 'react';

import { CustomSection } from './custom-section';

type Props = {
  access: any;
  customSections: any[];
  partSlug: string;
};

export function CustomSections(props: Props) {
  const { access, partSlug, customSections } = props;

  return (
    <div className="pt-2 pl-4 space-y-1">
      {customSections.map((customSection, index) => (
        <CustomSection
          number={index + 1}
          access={access}
          partSlug={partSlug}
          customSection={customSection}
          key={index}
        />
      ))}
    </div>
  );
}
