import React from 'react';

import { useTheme } from '~/components/theme-provider';
import { T_BaseText, T_BaseViewNode } from '~/node-builder/types';

import { GutterContentContainer, GutterTitle } from '../../components/Gutter';
import { NodeMap } from '../../NodeMap';

type Props = {
  node: any;
  Wrapper?: (props: {
    node: T_BaseViewNode | T_BaseText;
    children: React.ReactElement;
  }) => JSX.Element;
};

export function Sect1(props: Props) {
  const { node, Wrapper } = props;

  const { number, title } = node;

  const { colors } = useTheme();

  return (
    <>
      <GutterContentContainer>
        <h2
          style={{ color: colors.section }}
          date-testid="section_title"
          className="text-2xl font-bold mb-2 text-gray-700">
          <GutterTitle>{number}</GutterTitle>
          {title}
        </h2>
      </GutterContentContainer>

      <NodeMap Wrapper={Wrapper} nodes={node.children} />
    </>
  );
}
