import { usePage } from '@inertiajs/react';

import { T_Access, T_Book, T_ContainerTemplate } from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';

export const useBooksEditPage = usePage<T_BooksEditProps>;

export interface T_BooksEditProps extends T_PageProps {
  access: T_Access;
  book: T_Book;
  containerTemplates: T_ContainerTemplate[];
  partCount: number;
}
