import React, { useCallback } from 'react';

import { T_ListItem } from '~/types/node/T_ListItem';
import { T_NodeType } from '~/types/T_NodeTypes';
import { NodeMap } from '~/views/print/NodeMap';

import { OrderedList } from './OrderedList';
import { ParagraphWithGaps } from './ParagraphWithGaps';
import { UnorderedList } from './UnorderedList';

type Props = {
  node: T_ListItem['ViewNode'];
};

export function ListItem(props: Props) {
  const { node } = props;

  const render = useCallback(renderChildren, []);

  return render(node.children);
}

function renderChildren(children) {
  return children.map((child, index) => {
    switch (child.type) {
      case T_NodeType.LIST_ITEM_CONTENT: {
        return <ParagraphWithGaps key={index} node={child} />;
      }
      case T_NodeType.LIST_UNORDERED: {
        return <UnorderedList key={index} node={child} nested />;
      }
      case T_NodeType.LIST_ORDERED: {
        return <OrderedList key={index} node={child} nested />;
      }
      default: {
        return <NodeMap key={index} nodes={[child]} />;
      }
    }
  });
}
