import React from 'react';

import { T_AnswerableMultipleChoiceItem } from '~/types/node/exercise/answerables/multiple-choice/T_AnswerableMultipleChoiceItem';

type Props = {
  option: T_AnswerableMultipleChoiceItem['ViewNode'];
  checked: boolean;
};

export function Option(props: Props) {
  const { checked, option } = props;

  if (checked) {
    return (
      <div className="flex flex-row items-center">
        <svg
          className="w-[12pt] mr-[2.5mm]"
          fill="#0066CC"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24">
          <path d="M24,26H6c-1.105,0-2-0.895-2-2V6c0-1.105,0.895-2,2-2h18c1.105,0,2,0.895,2,2v18C26,25.105,25.105,26,24,26z" />
        </svg>

        <p className="m-0 my-[1mm] text-[#4b5563] text-[11pt]">{option.text}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-row items-center">
      <svg className="w-[12pt] mr-[2.5mm]" viewBox="0 0 24 24">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill="#212121" fillRule="nonzero">
            <path d="M5.75,3 L18.25,3 C19.7687831,3 21,4.23121694 21,5.75 L21,18.25 C21,19.7687831 19.7687831,21 18.25,21 L5.75,21 C4.23121694,21 3,19.7687831 3,18.25 L3,5.75 C3,4.23121694 4.23121694,3 5.75,3 Z M5.75,4.5 C5.05964406,4.5 4.5,5.05964406 4.5,5.75 L4.5,18.25 C4.5,18.9403559 5.05964406,19.5 5.75,19.5 L18.25,19.5 C18.9403559,19.5 19.5,18.9403559 19.5,18.25 L19.5,5.75 C19.5,5.05964406 18.9403559,4.5 18.25,4.5 L5.75,4.5 Z"></path>
          </g>
        </g>
      </svg>

      <p className="m-0 my-[1mm] text-[#4b5563] text-[11pt]">{option.text}</p>
    </div>
  );
}
