import { usePage } from '@inertiajs/react';
import React from 'react';

import { LayoutBook } from '~/components/ui-extended/layout-book';

import { PageContent } from './components/page-content';

export default function Discussions() {
  const { access, book } = usePage<any>().props;

  return (
    <LayoutBook
      breadcrumbs={[{ title: 'models.printTask.many', translateTitle: true, type: 'static' }]}
      htmlHeadTitle={book.title}
      access={access}
      book={book}
      sidebarTitle={book.title}>
      <PageContent />
    </LayoutBook>
  );
}
