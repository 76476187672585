import React, { createContext, useContext, useEffect, useState } from 'react';

type CollapsableContext = {
  items: Record<string, boolean>;
  setItems: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
};

const CollapsableContext = createContext<CollapsableContext>({
  items: {},
  setItems: () => {},
});

type Props = {
  children: React.ReactNode;
};

export function CollapsableContextProvider(props: Props) {
  const { children } = props;

  const [items, setItems] = useState<Record<string, boolean>>({});

  return (
    <CollapsableContext.Provider value={{ items, setItems }}>
      {children}
    </CollapsableContext.Provider>
  );
}

export function useCollapseAll() {
  const { items, setItems } = useContext(CollapsableContext);

  return {
    allContentIsClosed: Object.entries(items).every(([, open]) => !open),
    toggleAllContent,
  };

  function toggleAllContent() {
    if (Object.entries(items).some(([, open]) => open)) {
      const newItems = Object.entries(items).reduce((accumulator, [id]) => {
        accumulator[id] = false;

        return accumulator;
      }, {});

      setItems(newItems);
    } else {
      const newItems = Object.entries(items).reduce((accumulator, [id]) => {
        accumulator[id] = true;

        return accumulator;
      }, {});

      setItems(newItems);
    }
  }
}

export function useCollapsableItem(id: string) {
  const { items, setItems } = useContext(CollapsableContext);

  useEffect(() => {
    setItems((prev) => {
      if (Object.prototype.hasOwnProperty.call(prev, id)) {
        return prev;
      }

      return {
        ...prev,
        [id]: true,
      };
    });
  }, []);

  return {
    showContent: items[id],
    toggleContent,
  };

  function toggleContent() {
    if (items[id]) {
      setItems((prev) => {
        return {
          ...prev,
          [id]: false,
        };
      });
    } else {
      setItems((prev) => {
        return {
          ...prev,
          [id]: true,
        };
      });
    }
  }
}
