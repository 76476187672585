import React from 'react';

import { T_TextSingleLine } from '~/types/node/T_TextSingleLine';
import { NodeMap } from '~/views/print/NodeMap';

type Props = {
  node: T_TextSingleLine['ViewNode'];
};

export function Title(props: Props) {
  const { node } = props;

  if (!node.children || !node.children[0]) return null;

  return (
    <p className="text-[#4b5563] text-[11pt]">
      <NodeMap nodes={node.children} />
    </p>
  );
}
