import { CellContext, ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Translation } from 'react-i18next';

import { DataTableColumnHeader } from '~/components/ui/data-table-column-header';

import { DiscussionOptions } from './components/discussion-options';

export type Collaboration = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
};

export const columns: ColumnDef<Collaboration>[] = [
  {
    accessorKey: 'title',
    header: ({ column }) => (
      <Translation>
        {(t) => (
          <DataTableColumnHeader
            className="pl-4"
            column={column}
            title={t('pages.booksDiscussions.table.columns.title')}
          />
        )}
      </Translation>
    ),
    cell: ({ cell }: CellContext<Collaboration, string>) => (
      <div className="w-[300px] pl-4 font-medium">{cell.getValue()}</div>
    ),
  },
  {
    accessorKey: 'createdAt',
    id: 'created_at',
    header: ({ column }) => (
      <Translation>
        {(t) => (
          <DataTableColumnHeader className="pl-4" column={column} title={t('common.nouns.date')} />
        )}
      </Translation>
    ),
    cell: ({ cell }: CellContext<any, string>) => (
      <div className="w-[150px] pl-4 font-medium">
        {dayjs(cell.getValue()).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    accessorKey: 'user.fullName',
    header: ({ column }) => (
      <Translation>
        {(t) => (
          <DataTableColumnHeader
            column={column}
            title={t('pages.booksDiscussions.table.columns.author')}
          />
        )}
      </Translation>
    ),
    cell: ({ cell }: CellContext<Collaboration, string>) => (
      <div className="w-[200px]">{cell.getValue()}</div>
    ),
    enableSorting: false,
  },
  {
    accessorKey: 'classGroup.name',
    id: 'class_group.name',
    header: ({ column }) => (
      <Translation>
        {(t) => (
          <DataTableColumnHeader
            column={column}
            title={t('pages.booksDiscussions.table.columns.group')}
          />
        )}
      </Translation>
    ),
    cell: ({ cell }: CellContext<Collaboration, string>) => (
      <div className="w-[100px]">{cell.getValue()}</div>
    ),
    enableSorting: false,
  },
  {
    accessorKey: 'commentCount',
    header: ({ column }) => (
      <Translation>
        {(t) => (
          <DataTableColumnHeader
            column={column}
            title={t('pages.booksDiscussions.table.columns.comments')}
          />
        )}
      </Translation>
    ),
    cell: ({ cell }: CellContext<Collaboration, string>) => (
      <div className="w-[100px]">{cell.getValue()}</div>
    ),
    enableSorting: false,
  },
  {
    id: 'actions',
    cell: ({ row, table }) => {
      return <DiscussionOptions book={table.options.meta.book} discussion={row.original} />;
    },
  },
];
