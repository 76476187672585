import { T_UserAnswer } from '~/types/views';

import { useExerciseProps } from '../context/ExercisePropsContext';

export function useFindUserAnswers<T = T_UserAnswer>(answerableNodeKey: string): T | null {
  const { userAnswers } = useExerciseProps();

  if (!userAnswers) return null;

  return userAnswers.find((a) => a.nodeKey === answerableNodeKey) as T;
}
