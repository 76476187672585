import React from 'react';

type Props = {
  text: string;
};

export function Text(props: Props) {
  const { text } = props;

  return <span className="text-base font-light text-gray-600">{text}</span>;
}
