import { router } from '@inertiajs/react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Photo, VideoCamera } from 'styled-icons/heroicons-outline';

import EnrichmentsApi from '~/api/EnrichmentsApi';
import { AspectRatioIframe } from '~/components/ui-extended/aspect-ratio-iframe';

import { useViewBookContext } from '../../hooks/useViewBookContext';

type FormValues = {
  title: string;
  image: string | File;
  url: string;
  kind: 'text' | 'youtube' | 'image';
  pushed_to_students: boolean;
  shared_with_teachers: boolean;
};

type Props = {
  nodeKey: string;
};

export function AddEnrichmentForm(props: Props) {
  const { t } = useTranslation();

  const { courseContent, access } = useViewBookContext();

  const { nodeKey } = props;

  const imageInputRef = useRef(null);

  const [imagePreview, setImagePreview] = useState('');

  const [youtubeLink, setYoutubeLink] = useState('');
  const [showYoutubeInput, setShowYoutubeInput] = useState(false);
  const [urlError, seturlError] = useState(false);

  const [values, setValues] = useState<FormValues>({
    title: '',
    image: '',
    url: '',
    kind: 'text',
    pushed_to_students: false,
    shared_with_teachers: false,
  });

  return (
    <form className="flex flex-col flex-1" onSubmit={handleSubmit}>
      <textarea
        autoFocus
        className="w-full p-0 m-0 h-32 mb-1 bg-transparent border-none outline-none resize-none focus:outline-none focus:ring-transparent"
        placeholder={t('pages.courseContentsShow.forms.enrichment.placeholders.addText')}
        id="title"
        value={values.title}
        onChange={handleChange}></textarea>

      <input
        ref={imageInputRef}
        onChange={handleImagePreview}
        id="image_input"
        className="hidden"
        type="file"
      />

      <div className="flex items-center py-3">
        {imagePreview || values.url ? (
          <div className="relative justify-center flex flex-1">
            {imagePreview && (
              <div className="relative">
                <div className="relative rounded-md shadow-sm overflow-hidden">
                  <img className="max-h-64" src={imagePreview} alt="dummy" />
                </div>
                <button
                  onClick={resetMedia}
                  className="absolute text-white bg-red-400 rounded-full z-50 -top-2 -right-2">
                  <svg
                    className="w-6 h-6 p-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
            )}

            {values.url && (
              <div className="relative justify-center flex flex-1">
                <AspectRatioIframe url={`//www.youtube.com/embed/${values.url}`} />
                <button
                  onClick={resetMedia}
                  className="absolute text-white bg-red-400 rounded-full z-50 -top-2 -right-2">
                  <svg
                    className="w-6 h-6 p-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col w-full">
            <div className="flex items-center p-3 border rounded mb-2">
              <h4 className="mb-1 font-medium text-gray-700 mr-4">
                {t('pages.courseContentsShow.forms.enrichment.labels.addMedia')}:
              </h4>

              <label data-tip="Voeg afbeelding toe" htmlFor="image_input">
                <div className="bg-gray-700 p-1 rounded cursor-pointer hover:bg-gray-500 ">
                  <Photo className="w-6 h-6 text-white" />
                </div>
              </label>

              <button className="ml-2" data-tip="Voeg video toe" onClick={toggleYoutubeInput}>
                <div
                  className={`${
                    showYoutubeInput ? 'bg-pink-600' : 'bg-gray-700 hover:bg-gray-500'
                  } p-1 rounded cursor-pointer`}>
                  <VideoCamera className="w-6 h-6 text-white" />
                </div>
              </button>
            </div>

            {showYoutubeInput && (
              <div>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <input
                      value={youtubeLink}
                      autoFocus
                      onChange={(event) => setYoutubeLink(event.target.value)}
                      type="text"
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                      placeholder="Paste your Youtube-link"
                    />
                  </div>

                  <button
                    disabled={!youtubeLink}
                    onClick={handleYoutubePreview}
                    type="button"
                    className="-ml-px disabled:text-gray-400 relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                    <span> {t('common.buttons.add')}</span>
                  </button>
                </div>

                {urlError && (
                  <h4 className="text-red-400 text-xs mt-1 ml-1">
                    {t('pages.courseContentsShow.forms.enrichment.text.invalidYoutubeLink')}
                  </h4>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col">
        {!access.student && (
          <div className="mb-6">
            <p className="text-base text-gray-700 font-bold">
              {t('pages.courseContentsShow.forms.enrichment.labels.sharingOptions')}
            </p>
            <div className="mt-2">
              <div className="flex items-center">
                <input
                  id="pushed_to_students"
                  checked={values.pushed_to_students}
                  name="pushed_to_students"
                  type="checkbox"
                  onChange={() =>
                    setValues({ ...values, pushed_to_students: !values.pushed_to_students })
                  }
                  className="focus:ring-green-400 h-4 w-4 text-green-400 border-gray-300 rounded"
                />
                <label
                  htmlFor="pushed_to_students"
                  className="ml-1.5 font-light block text-sm text-gray-700">
                  {t('pages.courseContentsShow.forms.enrichment.labels.shareWithStudents')}
                </label>
              </div>

              <div className="flex items-center mt-3">
                <input
                  id="shared_with_teachers"
                  checked={values.shared_with_teachers}
                  name="shared_with_teachers"
                  type="checkbox"
                  onChange={() =>
                    setValues({ ...values, shared_with_teachers: !values.shared_with_teachers })
                  }
                  className="focus:ring-green-400 h-4 w-4 text-green-400 border-gray-300 rounded"
                />
                <label
                  htmlFor="shared_with_teachers"
                  className="ml-1.5 font-light block text-sm text-gray-700">
                  {t('pages.courseContentsShow.forms.enrichment.labels.shareWithTeachers')}
                </label>
              </div>
            </div>
          </div>
        )}

        <button
          disabled={!values.title}
          className="px-3 py-1.5 text-white bg-green-500 disabled:bg-gray-300 rounded-md"
          type="submit">
          {t('common.buttons.save')}
        </button>
      </div>
    </form>
  );

  function resetMedia(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    setYoutubeLink('');
    setShowYoutubeInput(false);
    setImagePreview('');

    if (imageInputRef && imageInputRef.current) {
      imageInputRef.current.value = null;
    }

    setValues({
      ...values,
      image: '',
      kind: 'text',
      url: '',
    });
  }

  function toggleYoutubeInput(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    setShowYoutubeInput(!showYoutubeInput);
  }

  function handleYoutubePreview(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    let embeddedVideoLink = '';

    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = youtubeLink.match(regExp);

    if (match && match[2].length == 11) {
      embeddedVideoLink = match[2];

      setValues({
        ...values,
        image: '',
        kind: 'youtube',
        url: embeddedVideoLink,
      });
    } else {
      seturlError(true);
    }
  }

  function handleImagePreview(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files.length) {
      setImagePreview(URL.createObjectURL(event.target.files[0]));

      setValues({
        ...values,
        kind: 'image',
        image: event.target.files[0],
      });
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const key = event.target.id;
    const value = event.target.value;

    setValues((values) => ({
      ...values,
      [key]: value,
    }));
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const data = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      if (value === '') return;

      if (typeof value === 'boolean') {
        data.append(`enrichment[${key}]`, value.toString());
      }

      data.append(`enrichment[${key}]`, value);
    });

    data.append('enrichment[node_key]', nodeKey);

    router.post(EnrichmentsApi.create.path({ course_content_slug: courseContent.slug }), data, {
      preserveState: false,
    });
  }
}
