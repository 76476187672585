import { router, usePage } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PrintTasksApi from '~/api/PrintTasksApi';
import { Button } from '~/components/ui/button';
import { DataTableContainer } from '~/components/ui-extended/data-table-container';

import { NewPrintTaskForm } from './new-print-task-form';

export default function Print() {
  const { t } = useTranslation();

  const { book, license, parts, answersUserBooks, containerTemplates } = usePage<any>().props;

  return (
    <DataTableContainer
      title="pages.printTasksNew.title"
      translateTitle
      rightSideContent={
        <Button
          onClick={() => router.get(PrintTasksApi.index.path({ book_slug: book.slug }))}
          variant="outline">
          {t('common.buttons.back')}
        </Button>
      }>
      <NewPrintTaskForm
        answersUserBooks={answersUserBooks}
        containerTemplates={containerTemplates}
        book={book}
        license={license}
        parts={parts}
      />
    </DataTableContainer>
  );
}
