import clsx from 'clsx';
import shuffle from 'lodash/shuffle';
import React, { useEffect, useMemo, useState } from 'react';

import { T_AnswerableMultipleChoice } from '~/types/node/exercise/answerables/multiple-choice/T_AnswerableMultipleChoice';

import { useExerciseProps } from '../../context/ExercisePropsContext';
import { useFindUserAnswers } from '../../hooks/useFindUserAnswers';
import { Option } from './Option';

type Props = {
  answerable: T_AnswerableMultipleChoice['ViewNode'];
  remakable: boolean;
  remakeMode: boolean;
};

export function OptionsList(props: Props) {
  const { answerable, remakable, remakeMode } = props;

  const { submitExercise } = useExerciseProps();

  const optionsRandomized = useMemo(() => shuffle(answerable.options), [answerable.options]);

  const userAnswer = useFindUserAnswers(answerable.nodeKey);

  useEffect(() => {
    if (remakeMode) {
      setAnswer([]);
    } else if (userAnswer?.value) {
      setAnswer(userAnswer?.value?.checkedAnswers || []);
    }
  }, [remakeMode]);

  const [answer, setAnswer] = useState(
    userAnswer?.value && !remakeMode ? userAnswer.value.checkedAnswers : [],
  );

  return (
    <div className={clsx('mb-3 pl-1', remakable && remakeMode && 'bg-green-50')}>
      {optionsRandomized.map((option, index) => (
        <Option
          answerable={answerable}
          checked={answer.includes(option.nodeKey)}
          index={index}
          key={option.nodeKey}
          onAnswerClick={() => toggleAnswer(option.nodeKey)}
          option={option}
        />
      ))}
    </div>
  );

  function toggleAnswer(optionNodeKey: string) {
    let newAnswer = [];

    if (answerable.single_answer) {
      newAnswer = [optionNodeKey];
    } else {
      if (answer.includes(optionNodeKey)) {
        newAnswer = answer.filter((checkedOptionKey) => checkedOptionKey !== optionNodeKey);
      } else {
        newAnswer = [...answer, optionNodeKey];
      }
    }

    submitExercise({
      answer: { value: { checkedAnswers: newAnswer } },
      answerableNodeKey: answerable.nodeKey,
    });

    setAnswer(newAnswer);
  }
}
