import { Link } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DiscussionsApi from '~/api/DiscussionsApi';
import { buttonVariants } from '~/components/ui/button';
import { DataTableContainer } from '~/components/ui-extended/data-table-container';

import { useDiscussionsIndexPage } from '../T_DiscussionsIndexProps';
import { DiscussionList } from './DiscussionList';

export function PageContent() {
  const { t } = useTranslation();

  const { access, discussions, courseContent } = useDiscussionsIndexPage().props;

  return (
    <DataTableContainer
      title="models.discussion.many"
      translateTitle
      rightSideContent={
        <Link
          type="button"
          href={DiscussionsApi.new.path({ course_content_slug: courseContent.slug })}
          className={buttonVariants({ variant: 'outline' })}>
          {t('common.buttons.add')}
        </Link>
      }>
      {discussions.length ? (
        <DiscussionList access={access} discussions={discussions} courseContent={courseContent} />
      ) : (
        <div className="text-gray-600 text-sm">
          {t('pages.discussionsIndex.text.noDiscussions')}
        </div>
      )}
    </DataTableContainer>
  );
}
