import { router, usePage } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircle } from 'styled-icons/bootstrap';

import PrintTasksApi from '~/api/PrintTasksApi';
import { Button } from '~/components/ui/button';
import { DataTable } from '~/components/ui/data-table';
import { DataTableContainer } from '~/components/ui-extended/data-table-container';

import { columns } from '../columns';

export function PageContent() {
  const { t } = useTranslation();

  const { book, printTasks, pagyMetadata } = usePage<any>().props;

  return (
    <DataTableContainer
      title="models.printTask.many"
      translateTitle
      rightSideContent={
        <Button
          data-testid="new_print_task_button"
          onClick={() => router.visit(PrintTasksApi.new.path({ book_slug: book.slug }))}
          variant="outline">
          <PlusCircle className="w-4 h-4 mr-2" />
          {t('common.buttons.add')}
        </Button>
      }>
      <DataTable columns={columns} data={printTasks} meta={{ book }} pagyMetadata={pagyMetadata} />
    </DataTableContainer>
  );
}
