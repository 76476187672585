import React from 'react';
import { useTranslation } from 'react-i18next';

import CourseContentsApi from '~/api/CourseContentsApi';

import { MarkableImageable } from '../nodes-shared/markable-imageable';

type Props = {
  keyword: string;
  extract: any;
};

export function ContentMarkedImage(props: Props) {
  const { t } = useTranslation();

  const { keyword, extract } = props;

  const node = extract.node as any;

  const marker = findMarker();

  if (!marker) return null;

  return (
    <div>
      <MarkableImageable imageable={node.source} markers={[marker]} />
      <div className="pt-1">
        <a
          className="text-sm font-light text-gray-600 hover:underline hover:text-gray-800"
          target="_blank"
          rel="noreferrer"
          href={`${CourseContentsApi.show.path({
            slug: extract.courseContent.slug,
          })}#${extract.nodeKey}`}>
          {t('pages.courseContentsShow.buttons.keywordViewMarkedImage')}
        </a>
      </div>
    </div>
  );

  function findMarker() {
    const marker = node.markers.find((item) => item.text.toLowerCase() === keyword);

    if (!marker) return null;

    marker.number = '';

    return marker;
  }
}
