import { usePage } from '@inertiajs/react';

import {
  T_Access,
  T_Ancestry,
  T_Blob,
  T_Book,
  T_ClassGroup,
  T_Flashcard,
} from '~/types/serializers';
import T_AncestryPart from '~/types/T_AncestryPart';
import { T_PageProps } from '~/types/T_PageProps';

export const usePartFlashcardsPage = usePage<T_PartFlashcardsProps>;

export interface T_PartFlashcardsProps extends T_PageProps {
  access: T_Access;
  book: T_Book;
  chapter: T_AncestryPart;
  editorBlob: T_Blob;
  flashcards: T_Flashcard[];
  parts: T_Ancestry[];
  studentClassGroup?: T_ClassGroup;
}
