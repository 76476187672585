import React from 'react';

import { HightlightContextProvider, useHighlightContext } from '~/hooks/useHighlightContext';
import { T_Paragraph } from '~/types/node/T_Paragraph';
import { cn } from '~/utils';

import { useViewBookContext } from '../../hooks/useViewBookContext';
import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_Paragraph['ViewNode'];
};

export function Paragraph(props: Props) {
  const { node } = props;

  const { book } = useViewBookContext();

  //  The default value (even without a context provider present on a higher level component) is true.
  // Other components in which the paragraph is nested can override this value by providing their own provider
  // and passing false as the option. For example, definition concept and explanation or exercise questions.

  const { showHighlight } = useHighlightContext();

  return (
    <HightlightContextProvider showHighlight={showHighlight}>
      <p
        data-testid={`${node.type} ${node.nodeKey}`}
        className={cn('text-gray-700 font-light my-3', book?.justifyText && 'text-justify')}>
        <NodeMap nodes={node.children} />
      </p>
    </HightlightContextProvider>
  );
}
