import React from 'react';

import { Column, Columns } from '~/components/ui/column-layout';
import { T_MarkedImageMarker } from '~/types/node/T_MarkedImageMarker';
import { splitCollection } from '~/utils/splitCollection';

import { Marker } from './Marker';

type Props = {
  markers: T_MarkedImageMarker['ViewNode'][];
};

export function MarkerList(props: Props) {
  const { markers } = props;

  const [column1, column2] = splitCollection(markers);

  return (
    <Columns className="mt-[3mm] border-b-[0.5px] border-b-[#d1d5db]">
      <Column>
        {column1.map((marker, index) => (
          <Marker key={index} node={marker} />
        ))}
      </Column>
      <Column>
        {column2.map((marker, index) => (
          <Marker key={index} node={marker} />
        ))}
      </Column>
    </Columns>
  );
}
