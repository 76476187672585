import { router } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ClassGroupsApi from '~/api/ClassGroupsApi';
import { Button } from '~/components/ui/button';
import { DataTable } from '~/components/ui/data-table';
import { DataTableContainer } from '~/components/ui-extended/data-table-container';

import { columns } from '../columns';
import { useClassGroupMembersIndexPage } from '../T_ClassGroupMembersIndex';

export default function PageContent() {
  const { t } = useTranslation();

  const { book, classGroup, users, pagyMetadata } = useClassGroupMembersIndexPage().props;

  return (
    <DataTableContainer
      title={classGroup.name}
      rightSideContent={
        <Button
          onClick={() => router.get(ClassGroupsApi.index.path({ book_slug: book.slug }))}
          variant="outline">
          {t('common.buttons.back')}
        </Button>
      }>
      <DataTable
        columns={columns}
        data={users}
        meta={{ book, classGroup }}
        pagyMetadata={pagyMetadata}
        testidConfig={{ name: 'class_group_user', id: 'id' }}
      />
    </DataTableContainer>
  );
}
