import { zodResolver } from '@hookform/resolvers/zod';
import { router, usePage } from '@inertiajs/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import PasswordsApi from '~/api/Users/PasswordsApi';
import { Button } from '~/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import { T_PageProps } from '~/types/T_PageProps';

const formSchema = z.object({
  email: z.string().email(),
});

export function ResetPasswordForm() {
  const { t } = useTranslation();

  const {
    organization: { template },
  } = usePage<T_PageProps>().props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('pages.passwordNew.forms.resetPassword.labels.email')}</FormLabel>
              <FormControl>
                <Input type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div>
          <Button
            style={{ backgroundColor: template.color1 }}
            type="submit"
            className="w-full font-bold">
            {t('pages.passwordNew.forms.resetPassword.buttons.resetPassword')}
          </Button>
        </div>
      </form>
    </Form>
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.post(
      PasswordsApi.create.path(),
      { user: values },
      {
        onError: (err) => {
          console.log(err);
        },
      },
    );
  }
}
