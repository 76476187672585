import React from 'react';

import { T_TextMultiLine } from '~/types/node/T_TextMultiLine';

type Props = {
  node: T_TextMultiLine['ViewNode'];
  children: React.ReactNode;
};

export function Hint() {
  return null;
}
