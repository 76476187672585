import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '~/components/ui/accordion';
import { T_Manual } from '~/types/node/T_Manual';

import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_Manual['ViewNode'];
};

export function Manual(props: Props) {
  const { t } = useTranslation();

  const { node } = props;

  return (
    <Accordion
      data-testid={`${node.type} ${node.nodeKey}`}
      type="multiple"
      className="mb-4 relative">
      <AccordionItem value="node" className="border-none">
        <AccordionTrigger className="flex justify-between w-full px-4 z-40 py-2 text-sm font-medium text-left text-gray-900 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
          <span>{t('common.nodes.manual')}</span>
        </AccordionTrigger>
        <AccordionContent className="px-4 pt-6 z-10 rounded-lg pb-2 bg-gray-50 text-sm text-gray-500">
          <NodeMap nodes={node.content.children} />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
