import { UniqueIdentifier } from '@dnd-kit/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DragIndicator } from 'styled-icons/material';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';
import { cn } from '~/utils';

type Props = {
  attributes: any;
  fromDragOverlay?: boolean;
  isDragging: boolean;
  listeners: any;
  meta: {
    type: string;
    title: string;
    id: UniqueIdentifier;
    i18nKey: 'nestedPart' | 'section' | 'part';
  };
};

export function StructureItemDragHandle(props: Props) {
  const { t } = useTranslation();

  const { attributes, fromDragOverlay, isDragging, listeners, meta } = props;

  return (
    <TooltipProvider delayDuration={500}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            {...attributes}
            {...listeners}
            data-testid={`drag_${meta.type}_${meta.id}`}
            className="w-4 h-4 mr-1 flex items-center justify-center hover:bg-gray-200 rounded">
            <DragIndicator className="w-4 h-4 text-gray-700" />
          </div>
        </TooltipTrigger>

        <TooltipContent className={cn((isDragging || fromDragOverlay) && 'hidden')}>
          <p>{t('pages.booksEditStructure.text.dragToReorder')}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
