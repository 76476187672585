import React from 'react';

import { DataTableContainer } from '~/components/ui-extended/data-table-container';

import { useDiscussionsNewPage } from '../T_DiscussionsNewProps';
import Form from './Form';

export function PageContent() {
  const { access } = useDiscussionsNewPage().props;

  return (
    <DataTableContainer translateTitle title="pages.discussionsNew.title">
      <Form access={access} />
    </DataTableContainer>
  );
}
