import React from 'react';
import styled from 'styled-components';

import { T_TextMultiLine } from '~/types/node/T_TextMultiLine';
import { cn } from '~/utils';

import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_TextMultiLine['ViewNode'];
  subExercise?: boolean;
};

export function Question(props: Props) {
  const { node, subExercise } = props;

  return (
    <StyledDiv
      className={cn(
        '[&_p]:font-normal [&_p]:text-gray-800 [&_p]:text-base',
        subExercise ? 'my-2' : 'mb-6',
      )}>
      <NodeMap nodes={node.children} />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  > * {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
