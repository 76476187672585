import clsx from 'clsx';
import React from 'react';

import { T_ListUnordered } from '~/types/node/T_ListUnordered';

import { ListItem } from './ListItem';

type Props = {
  node: T_ListUnordered['ViewNode'];
  nested?: boolean;
};

export function UnorderedList(props: Props) {
  const { node, nested, ...otherProps } = props;

  return (
    <ul
      data-testid={`${node.type} ${node.nodeKey}`}
      className={clsx('list-disc pl-5', nested && 'mb-5 text-green-500 mt-3')}>
      {node.children.map((itemNode) => (
        <ListItem {...otherProps} key={itemNode.nodeKey} node={itemNode} />
      ))}
    </ul>
  );
}
