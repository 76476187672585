import { Link } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxBlankCircle as CircleFull } from 'styled-icons/remix-fill';
import { CheckboxBlankCircle as CircleEmpty, RecordCircle } from 'styled-icons/remix-line';

import CourseContentsApi from '~/api/CourseContentsApi';
import T_AncestryPart, { T_AncestrySection } from '~/types/T_AncestryPart';
import { cn } from '~/utils';

import { useBooksShowPage } from '../T_BooksShow';

type Props = {
  nestedPart: T_AncestryPart;
  section: T_AncestrySection;
};

export function Section(props: Props) {
  const { t } = useTranslation();

  const { nestedPart, section } = props;

  const { access, studentSelfAssessments } = useBooksShowPage().props;

  const hasAccess = userHasAccess();

  return (
    <Link
      href={CourseContentsApi.show.path({ slug: section.courseContent.slug })}
      as="button"
      disabled={!hasAccess}
      data-testid={`section_${section.slug}`}
      className="flex items-center text-stone-800 disabled:text-stone-500 py-1 px-2 disabled:hover:bg-white rounded hover:bg-stone-100 cursor-pointer disabled:cursor-default">
      {access.student && renderProgressionIcon()}

      <div
        className={cn(
          'text-sm truncate cursor-pointer flex',
          section.published ? 'text-stone-800' : 'text-stone-400',
        )}>
        <div className="w-8">{section.number}</div>
        <span>
          {section.title} {!section.published && <span> ({t('models.section.unpublished')})</span>}
        </span>
      </div>
    </Link>
  );

  function renderProgressionIcon() {
    const assessment = findSectionAssessment(section.courseContent.id);

    switch (true) {
      case !hasAccess: {
        return <CircleEmpty className="w-4 h-4  mr-3.5 text-stone-500" />;
      }
      case hasAccess && !assessment: {
        return <RecordCircle className="w-4 h-4 mr-3.5 text-stone-700" />;
      }
      case hasAccess && !!assessment: {
        return (
          <div data-testid={`assessment-indicator-${assessment.status}`}>
            <CircleFull
              style={{ color: assessmentColorMap[assessment.understanding] }}
              className="w-4 h-4 mr-3.5 text-stone-500"
            />
          </div>
        );
      }
    }
  }

  function userHasAccess() {
    if (nestedPart.learningPath === 'open') return true;
    if (section.position === 0) return true;
    if (!access.student) return true;

    const previousSection = nestedPart.sections[section.position - 1];

    return hasCompletedContent(previousSection?.courseContent?.id);
  }

  function hasCompletedContent(courseContentId: number) {
    return !!findSectionAssessment(courseContentId);
  }

  function findSectionAssessment(courseContentId) {
    return (studentSelfAssessments || []).find(
      (assessment) => assessment.courseContent.id === courseContentId,
    );
  }
}

const assessmentColorMap = {
  good: 'rgb(16 185 129 / var(--tw-text-opacity))',
  bad: 'rgb(239 68 68 / var(--tw-text-opacity))',
  medium: 'rgb(245 158 11 / var(--tw-text-opacity))',
};
