import React from 'react';
import { useTranslation } from 'react-i18next';

import LicenseGroupsApi from '~/api/LicenseGroupsApi';
import { LayoutBook } from '~/components/ui-extended/layout-book';

import { PageContent } from './components/page-content';
import { useLicenseGroupsNewPage } from './T_LicenseGroupsNew';

export default function New() {
  const { book, access } = useLicenseGroupsNewPage().props;
  const { t } = useTranslation();

  return (
    <LayoutBook
      breadcrumbs={[
        {
          title: t('models.licenseGroup.many'),
          type: 'link',
          href: LicenseGroupsApi.index.path({ book_slug: book.slug }),
        },
        { title: t('common.breadcrumbs.licenseNew'), type: 'static' },
      ]}
      access={access}
      book={book}
      sidebarTitle={book.title}
      htmlHeadTitle={book.title}>
      <PageContent />
    </LayoutBook>
  );
}
