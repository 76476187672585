import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommentItem } from './CommentItem';

export function CommentList(props) {
  const { t } = useTranslation();

  const { discussion, access, courseContent } = props;

  return (
    <div className="mb-2 text-sm text-gray-500 __comment_list__">
      {!discussion.discussionComments.length ? (
        <p className="p-2"> {t('pages.discussionsShow.text.noComments')}</p>
      ) : (
        <div>
          {discussion.discussionComments.map((comment) => (
            <CommentItem
              key={comment.id}
              comment={comment}
              discussion={discussion}
              courseContent={courseContent}
              access={access}
            />
          ))}
        </div>
      )}
    </div>
  );
}
