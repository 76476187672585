import React from 'react';
import { useTranslation } from 'react-i18next';

import { HightlightContextProvider } from '~/hooks/useHighlightContext';
import { T_Definition } from '~/types/node/T_Definition';

import { Element } from '../../components/Element';
import { Concept } from './Concept';
import { Explanation } from './Explanation';

type Props = {
  node: T_Definition['ViewNode'];
};

export function Definition(props: Props) {
  const { t } = useTranslation();

  const { node } = props;

  return (
    <Element node={node} className="bg-yellow-50 p-4" title={t('common.nodes.definition')}>
      <HightlightContextProvider showHighlight={false}>
        <Concept node={node.concept} />
        <Explanation node={node.explanation} />
      </HightlightContextProvider>
    </Element>
  );
}
