import React from 'react';

import { LayoutCourseContent } from '~/components/ui-extended/layout-course-content';

import { PageContent } from './components/page-content';
import { useDiscussionsNewPage } from './T_DiscussionsNewProps';

export default function New() {
  const { access, book, courseContent, parts, studentClassGroup } = useDiscussionsNewPage().props;

  return (
    <LayoutCourseContent
      access={access}
      book={book}
      breadcrumbs={[
        {
          type: 'static',
          title: 'common.breadcrumbs.discussionsNew',
          translateTitle: true,
        },
      ]}
      courseContent={courseContent}
      htmlHeadTitle={courseContent.courseContentable.title}
      parts={parts}
      sidebarTitle={courseContent.courseContentable.title}
      studentClassGroup={studentClassGroup}>
      <PageContent />
    </LayoutCourseContent>
  );
}
