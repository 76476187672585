import { router } from '@inertiajs/react';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EllipsisHorizontal } from 'styled-icons/heroicons-outline';

import DiscussionCommentsApi from '~/api/DiscussionCommentsApi';
import { Button } from '~/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';

import { Author } from './Author';

export function CommentItem(props) {
  const { t } = useTranslation();

  const { discussion, comment, courseContent, access } = props;

  return (
    <div
      data-testid="discussion_comment_item"
      className="border-t pb-2 pt-4 last:border-b border-gray-200"
      key={comment.id}>
      <div className="text-gray-900 mt-4 mb-6">{comment.body}</div>

      <div className="flex justify-between items-center">
        <div className="flex">
          <Author access={access} user={comment.user} userBook={comment.userBook} />
          <div className="text-sm text-gray-500 ml-1">• {dayjs(comment.createdAt).fromNow()}</div>
        </div>

        {access.teacher && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                data-testid={`discussion_comment_menu_trigger ${comment.id}`}
                size="icon"
                className="w-7 h-7"
                variant="ghost">
                <EllipsisHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuGroup>
                <DropdownMenuItem
                  data-testid="remove-comment-button"
                  onClick={() => removeComment(comment)}>
                  {t('common.buttons.remove')}
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
    </div>
  );

  function removeComment(comment) {
    if (confirm('Ben je zeker dat je deze reactie wil verwijderen?')) {
      router.delete(
        DiscussionCommentsApi.destroy.path({
          course_content_slug: courseContent.slug,
          discussion_id: discussion.id,
          id: comment.id,
        }),
        {
          preserveScroll: true,
          preserveState: true,
        },
      );
    }
  }
}
