import * as React from 'react';

import { cn } from '~/utils';

type ColumnsProps = {
  children: React.ReactNode;
  className?: string;
};

function Columns(props: ColumnsProps) {
  return <div className={cn('flex flex-row', props.className)}>{props.children}</div>;
}

type ColumnProps = {
  children: React.ReactNode;
  className?: string;
};

function Column(props: ColumnProps) {
  return <div className={cn('w-1/2', props.className)}>{props.children}</div>;
}

export { Column, Columns };
