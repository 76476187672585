import { useEffect, useState } from 'react';

import { Button } from '~/components/ui/button';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '~/components/ui/carousel';
import { T_AnswerableSubExerciseList } from '~/types/node/exercise/answerables/sub-exercise-list/T_AnswerableSubExerciseList';
import { cn } from '~/utils';

import { Hint } from '../../Hint';
import { HintButton } from '../../shared/HintButton';
import { SubExerciseItem } from './SubExerciseItem';

type Props = {
  answerable: T_AnswerableSubExerciseList['ViewNode'];
};

export function SubExerciseList(props: Props) {
  const { answerable } = props;

  return (
    <>
      <HintButton />
      <Hint />

      {answerable.online_layout === 'carousel' ? (
        <CarouselLayout items={answerable.items} />
      ) : (
        <ListLayout items={answerable.items} />
      )}
    </>
  );
}

function ListLayout({ items }: { items: T_AnswerableSubExerciseList['ViewNode']['items'] }) {
  return (
    <>
      {items.map((subExerciseNode) => (
        <SubExerciseItem layout="list" key={subExerciseNode.nodeKey} node={subExerciseNode} />
      ))}
    </>
  );
}

function CarouselLayout({ items }: { items: T_AnswerableSubExerciseList['ViewNode']['items'] }) {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCurrent(api.selectedScrollSnap());

    console.log('api.selectedScrollSnap()', api.selectedScrollSnap());

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap());
    });
  }, [api]);

  return (
    <div className="mb-10">
      <Carousel className="border-b pb-2 mb-1" setApi={setApi}>
        <CarouselContent>
          {items.map((subExerciseNode) => (
            <CarouselItem key={subExerciseNode.nodeKey}>
              <SubExerciseItem layout="carousel" node={subExerciseNode} />
            </CarouselItem>
          ))}
        </CarouselContent>

        <CarouselPrevious />
        <CarouselNext />
      </Carousel>

      <div className="flex space-x-1 justify-end">
        {api?.scrollSnapList().map((i, index) => (
          <Button
            onClick={() => scrollTo(index)}
            key={index}
            className={cn(current === index && 'bg-stone-100 font-semibold')}
            size="sm"
            variant="outline">
            {items[index]?.number}
          </Button>
        ))}
      </div>
    </div>
  );

  function scrollTo(index: number) {
    api?.scrollTo(index);
  }
}
