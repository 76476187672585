import React from 'react';

import { T_AnswerableFillListItemKeyImage } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillListItemKeyImage';
import { Imageable } from '~/views/book/components-new/nodes-shared/imageable';

type Props = {
  node: T_AnswerableFillListItemKeyImage['ViewNode'];
};

export function KeyImage(props: Props) {
  const { node } = props;

  if (!node.medium_src) {
    return (
      <div className="max-w-full h-56 mb-2 bg-white flex items-center justify-center cursor-pointer border rounded"></div>
    );
  }

  return (
    <div className="mb-2 px-4">
      <Imageable node={node} className="block w-full h-56" src={node.medium_src} />
    </div>
  );
}
