import { usePage } from '@inertiajs/react';
import { Previewer } from 'pagedjs';
import { useLayoutEffect } from 'react';

import { PrintView } from '~/views/print/PrintView';

export default function Print() {
  const { json } = usePage<any>().props;

  console.log('json', json);

  useLayoutEffect(() => {
    const paged = new Previewer();

    const root = document.getElementById('app');

    paged.preview(root, [], document.body).then((flow) => {
      console.log('Rendered', flow.total, 'pages.');
      root.remove();
    });
  }, []);

  return <PrintView json={json} />;
}
