import React from 'react';
import { useTranslation } from 'react-i18next';

import CourseContentsApi from '~/api/CourseContentsApi';
import { T_WordList } from '~/node-builder/nodes/word-list/types';
import { T_Extract } from '~/types/serializers';

type Props = {
  keyword: string;
  extract: T_Extract;
};

export function ContentTranslation(props: Props) {
  const { t } = useTranslation();

  const { keyword, extract } = props;

  const node = extract.node as T_WordList;

  const wordItem = findWordItem();

  if (!wordItem) return null;

  return (
    <div className="text-gray-700 font-light">
      <div className="py-2">{wordItem.translation}</div>

      <div className="pt-1 border-t">
        <a
          className="text-sm text-gray-600 hover:underline hover:text-gray-800"
          target="_blank"
          rel="noreferrer"
          href={`${CourseContentsApi.show.path({
            slug: extract.courseContent.slug,
          })}#${extract.nodeKey}`}>
          {t('pages.courseContentsShow.buttons.keyWordViewWordList')}
        </a>
      </div>
    </div>
  );

  function findWordItem() {
    return node.items.find((item) => item.term.toLowerCase() === keyword);
  }
}
