export function findNodeForNodeKey(childNodes: Record<string, any>[], nodeKey: string) {
  if (!childNodes) return null;

  for (const childNode of childNodes) {
    if (childNode.nodeKey && childNode.nodeKey === nodeKey) {
      return childNode;
    }

    if (childNode.children) {
      const node = findNodeForNodeKey(childNode.children, nodeKey);

      if (node) {
        return node;
      }
    }
  }
}
