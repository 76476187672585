import { Link, usePage } from '@inertiajs/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import CourseContentsApi from '~/api/CourseContentsApi';
import { Popover, PopoverContent, PopoverTrigger } from '~/components/ui/popover';
import { cn } from '~/utils';

type Props = {
  partId: string;
  contentCount: number;
};

export function AssessmentLevel(props: Props) {
  const { t } = useTranslation();

  const { studentSelfAssessments } = usePage<any>().props;

  const { partId, contentCount } = props;

  const levels = useMemo(() => {
    const grouped = { bad: [], medium: [], good: [], goodWeak: [], goodWeaker: [] };

    (studentSelfAssessments || [])
      .filter((ssa) => ssa.part.id === partId)
      .forEach((ssa) => {
        if (ssa.status === 'good_decrement_1') {
          grouped.goodWeak.push(ssa);
        } else if (ssa.status === 'good_decrement_2') {
          grouped.goodWeaker.push(ssa);
        } else {
          grouped[ssa.status].push(ssa);
        }
      });
    return grouped;
  }, [studentSelfAssessments]);

  return (
    <div data-testid="student-self-assessment-indicators">
      <p className="text-xs font-light mb-0.5">
        {t('pages.booksShow.text.studentAssessment.title')}
      </p>

      <div className="w-48 flex h-1 bg-gray-200 overflow-hidden">
        <AssessmentPopover
          assessmentColor="bg-green-400"
          dataTestId="assessment-indicator-good-button"
          level={levels.good}
          title={t('pages.booksShow.text.studentAssessment.wellKnown')}
          width={levels.good.length / contentCount}
        />
        <AssessmentPopover
          assessmentColor="bg-green-300"
          dataTestId="assessment-indicator-good-weak-button"
          level={levels.goodWeak}
          title={t('pages.booksShow.text.studentAssessment.toFreshenUp')}
          width={levels.goodWeak.length / contentCount}
        />
        <AssessmentPopover
          assessmentColor="bg-green-200"
          dataTestId="assessment-indicator-good-weaker-button"
          level={levels.goodWeaker}
          title={t('pages.booksShow.text.studentAssessment.toReview')}
          width={levels.goodWeaker.length / contentCount}
        />
        <AssessmentPopover
          assessmentColor="bg-yellow-200"
          dataTestId="assessment-indicator-medium-button"
          level={levels.medium}
          title={t('pages.booksShow.text.studentAssessment.notFullyUnderstood')}
          width={levels.medium.length / contentCount}
        />
        <AssessmentPopover
          assessmentColor="bg-red-300"
          dataTestId="assessment-indicator-bad-button"
          level={levels.bad}
          title={t('pages.booksShow.text.studentAssessment.notUnderstood')}
          width={levels.bad.length / contentCount}
        />
      </div>
    </div>
  );
}

function AssessmentPopover({ width, level, title, assessmentColor, dataTestId }) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <div
          data-testid={dataTestId}
          className={cn('h-full cursor-pointer', assessmentColor)}
          style={{
            width: `${width * 100}%`,
          }}
        />
      </PopoverTrigger>
      <PopoverContent>
        <h5 className="text-sm font-semibold text-gray-700">{title}</h5>

        <ul className="mt-1">
          {level.map((ssa, index) => {
            const { slug, courseContentable } = ssa.courseContent;

            return (
              <Link key={index} href={CourseContentsApi.show.path({ slug })}>
                <StyledLi
                  number={courseContentable.number}
                  className={cn(
                    'text-gray-700 py-1 text-sm hover:underline before:inline-block before:text-gray-500',
                    courseContentable.number ? 'before:w-8' : 'before:w-0',
                  )}>
                  {courseContentable.title}
                </StyledLi>
              </Link>
            );
          })}
        </ul>
      </PopoverContent>
    </Popover>
  );
}

const StyledLi = styled.li<{ number: string }>((props) => {
  return `
    &::before {
      content: "${props.number}";
    }
    `;
});
