import { usePage } from '@inertiajs/react';
import React from 'react';

import { DataTableContainer } from '~/components/ui-extended/data-table-container';

import { ViewStudent } from './view-student';
import { ViewTeacher } from './view-teacher';

export function PageContent() {
  const { access, chapter } = usePage<any>().props;

  return (
    <DataTableContainer title={`${chapter.number}. ${chapter.title}`}>
      {access.student ? <ViewStudent /> : <ViewTeacher />}
    </DataTableContainer>
  );
}
