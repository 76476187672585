import React from 'react';

import { Dialog, DialogContent, DialogTrigger } from '~/components/ui/dialog';
import { T_Enrichment } from '~/types/serializers';

import { useViewBookContext } from '../../hooks/useViewBookContext';
import { Enrichment } from './Enrichment';
import { SharedEnrichmentsList } from './SharedEnrichmentsList';

type Props = {
  nodeKey: string;
};

export function EnrichmentsList(props: Props) {
  const { currentUserBook, enrichments, sharedEnrichments } = useViewBookContext();

  const { nodeKey } = props;

  const viewableEnrichmentsForNode = findEnrichmentsByNodeKey(enrichments, nodeKey);
  const sharedEnrichmentsForNode = findEnrichmentsByNodeKey(sharedEnrichments, nodeKey);

  return (
    <>
      {!!sharedEnrichmentsForNode.length && (
        <Dialog>
          <DialogTrigger asChild>
            <button
              data-testid="shared_enrichments_button"
              className="float-right cursor-pointer clear-right md:-mr-28 flex mb-2">
              <div className="border w-6 h-6 flex items-center justify-center rounded-full border-blue-500">
                <p className="text-gray-500 font-semibold text-sm">
                  {sharedEnrichmentsForNode.length}
                </p>
              </div>
            </button>
          </DialogTrigger>
          <DialogContent>
            <SharedEnrichmentsList nodeKey={nodeKey} />
          </DialogContent>
        </Dialog>
      )}

      {!!viewableEnrichmentsForNode.length && (
        <div className="mt-3 mb-3 flex flex-col gap-2">
          {viewableEnrichmentsForNode.map((enrichment) => {
            return (
              <Enrichment
                currentUserIsOwner={currentUserBook.id === enrichment.userBookId}
                key={enrichment.id}
                enrichment={enrichment}
              />
            );
          })}
        </div>
      )}
    </>
  );
}

function findEnrichmentsByNodeKey(enrichments: T_Enrichment[], nodeKey: string) {
  if (!enrichments) return [];

  return enrichments.filter((enrichment) => {
    return enrichment.nodeKey == nodeKey;
  });
}
