import React, { useEffect, useState } from 'react';

import { T_AnswerableOpenQuestion } from '~/types/node/exercise/answerables/open-question/T_AnswerableOpenQuestion';

import { useExerciseProps } from '../../context/ExercisePropsContext';
import { useFindUserAnswers } from '../../hooks/useFindUserAnswers';

type Props = {
  answerable: T_AnswerableOpenQuestion['ViewNode'];
  remakable: boolean;
  remakeMode: boolean;
};

export function AnswerInput(props: Props) {
  const { answerable, remakeMode } = props;

  const { submitExercise } = useExerciseProps();
  const userAnswer = useFindUserAnswers(answerable.nodeKey);

  const [answer, setAnswer] = useState(remakeMode ? '' : userAnswer?.value?.openAnswer || '');

  useEffect(() => {
    setAnswer(remakeMode ? '' : userAnswer?.value?.openAnswer || '');
  }, [remakeMode]);

  return (
    <div className="mb-2">
      <textarea
        name="open-question-answer-input"
        style={{ resize: 'none' }}
        id="htmlId"
        rows={3}
        value={answer}
        onChange={onChange}
        data-testid="open-question-answer-input"
        className="shadow-sm text-blue-800 font-medium focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
      />
    </div>
  );

  function onChange(event) {
    const { value } = event.target;

    submitExercise({
      answerableNodeKey: answerable.nodeKey,
      answer: { value: { openAnswer: value } },
    });

    setAnswer(value);
  }
}
