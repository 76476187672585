import clsx from 'clsx';
import React from 'react';
import { HandRaised as HandOutline } from 'styled-icons/heroicons-outline';
import { HandRaised as HandSolid } from 'styled-icons/heroicons-solid';

import { idFromNodeKey } from '~/lib/node-renderer/nodeHelpers';
import { T_AnswerableFillGapsGap } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGapsGap';
import { useViewBookContext } from '~/views/book/hooks/useViewBookContext';
import { NodeMap } from '~/views/book/NodeMap';

type Props = {
  node: any;
  showAnswer: 'all' | 'none' | number;
  value: string;
  handleChange: (gapKey: string, answerKey: string) => void;
  activeGap: T_AnswerableFillGapsGap['ViewNode'] | null;
  setActiveGap: (gap: T_AnswerableFillGapsGap['ViewNode'] | null) => void;
  showSentInAnswers: boolean;
};

export function GapText(props: Props) {
  const { access } = useViewBookContext();

  const { node, activeGap, showSentInAnswers, setActiveGap, handleChange, value, showAnswer } =
    props;

  const answerIsCorrect = value === node.nodeKey;

  const htmlId = idFromNodeKey(node.nodeKey);

  const showAnswerForCurrentGap = showAnswer === 'all' || showAnswer >= node.number;

  const active = node.nodeKey === activeGap?.nodeKey;

  return (
    <div className="inline-block mb-2">
      <div className="flex items-center">
        {showAnswerForCurrentGap && !answerIsCorrect && (
          <div
            data-testid="fill-gaps-correct-answer"
            className="bg-green-300 text-sm mx-1 rounded px-1">
            <NodeMap nodes={node.children} />
          </div>
        )}

        <div className="inline-flex rounded-md shadow-sm">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <input
              className={inputStyle({
                withButton: showSentInAnswers && !access.student,
              })}
              data-testid="fill-gaps-gap-input"
              name={`fill-gaps-input-${node.number}`}
              value={value}
              onChange={selectAnswer}
              id={htmlId}
            />
          </div>

          {showSentInAnswers && !access.student && (
            <button
              className="-ml-px py-[0.04rem] relative inline-flex items-center space-x-2 px-1 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              data-testid={`select-gap-button-${node.number}`}
              onClick={() => setActiveGap(node)}>
              {active ? (
                <HandSolid className="w-4 h-4 text-gray-600" />
              ) : (
                <HandOutline className="w-4 h-4 text-gray-400" />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  function selectAnswer(event) {
    const { value } = event.target;

    handleChange(node.nodeKey, value);
  }
}

const inputStyle = ({ withButton }) =>
  clsx(
    'relative py-[0.04rem] flex items-stretch flex-grow focus-within:z-10 ',
    !!withButton && 'rounded-r-none',
  );
