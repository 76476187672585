import React from 'react';

import { T_BaseText, T_BaseViewNode } from '~/node-builder/types';

import { NodeMapGenerator } from '../NodeMapGenerator';

const nodeComponents = import.meta.glob<
  Record<string, (props: { node: T_BaseViewNode }) => JSX.Element>
>('./nodes/*/*.tsx', {
  eager: true,
});

type Props = {
  nodes: Array<T_BaseViewNode | T_BaseText>;
  Wrapper?: (props: {
    node: T_BaseViewNode | T_BaseText;
    children: React.ReactElement;
  }) => JSX.Element;
};

export function NodeMap(props: Props) {
  const { nodes, Wrapper } = props;

  const Map = NodeMapGenerator({ nodeComponents });

  return <Map Wrapper={Wrapper} nodes={nodes} />;
}
