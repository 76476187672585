import { usePage } from '@inertiajs/react';
import { Cross2Icon } from '@radix-ui/react-icons';
import { PopoverTrigger } from '@radix-ui/react-popover';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import ExtractsApi from '~/api/ExtractsApi';
import { Button } from '~/components/ui/button';
import { Popover, PopoverContent } from '~/components/ui/popover';
import { T_Book, T_Extract } from '~/types/serializers';

import { KeywordMarkerContent } from './keyword-marker-content';

type Props = {
  keyword: string;
};

export function KeywordMarker(props: Props) {
  const { book } = usePage<{ book: T_Book }>().props;

  const { keyword } = props;

  const [open, setOpen] = useState(false);

  const [keywordData, setKeywordData] = useState<T_Extract[]>(null);

  useEffect(() => {
    if (open && !keywordData) fetchKeywordData();
  }, [open]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <mark data-testid="keyword-highlight">
          <span className="border rounded border-yellow-200 p-0.5 bg-yellow-50">{keyword}</span>
        </mark>
      </PopoverTrigger>

      <PopoverContent data-testid="keyword-marker-content" className="p-2">
        <div className="flex items-center justify-between border-b pb-1">
          <h5 className="font-medium text-stone-900 ">{keyword}</h5>
          <Button size="icon" variant="ghost" className="w-6 h-6" onClick={() => setOpen(false)}>
            <Cross2Icon className="h-4 w-4" />
          </Button>
        </div>

        {keywordData?.[0] && (
          <KeywordMarkerContent keyword={keyword} keywordData={keywordData[0]} />
        )}
      </PopoverContent>
    </Popover>
  );

  async function fetchKeywordData() {
    const extracts = await fetchExtractsByKeyword();

    setKeywordData(extracts);
  }

  async function fetchExtractsByKeyword() {
    const response = await axios.get(
      ExtractsApi.index.path({ book_slug: book.slug }) + `.json?keyword=${keyword}`,
    );

    return response.data;
  }
}
