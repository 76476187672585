import React from 'react';

import { T_AnswerableFillList } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillList';
import { T_AnswerableFillListItemAnswer } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillListItemAnswer';

import { useStandardAnswerProps } from '../../context/AnswerablePropsContext';
import { AnswerSelectInput } from './AnswerSelectInput';
import { AnswerTextInput } from './AnswerTextInput';

type Props = {
  active: boolean;
  answerable: T_AnswerableFillList['ViewNode'];
  handleChange: (key: string, value: string) => void;
  itemNodeKey: string;
  node: T_AnswerableFillListItemAnswer['ViewNode'];
  number: number;
  setActiveItem: () => void;
  userAnswer: string;
};

export function Answer(props: Props) {
  const { showAnswer } = useStandardAnswerProps();

  // Image Answers are not supported and not implemented yet
  if (!('text' in props.node)) return null;

  const showAnswerForCurrentItem =
    showAnswer === 'all' || (typeof showAnswer === 'number' && showAnswer >= props.number);

  return (
    <div className="relative flex flex-1">
      {props.answerable.answer_by === 'text' ? (
        <AnswerTextInput {...props} node={props.node} showAnswer={showAnswerForCurrentItem} />
      ) : (
        <AnswerSelectInput {...props} node={props.node} showAnswer={showAnswerForCurrentItem} />
      )}
    </div>
  );
}
