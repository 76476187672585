import { router } from '@inertiajs/react';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '~/components/ui/alert-dialog';
import { Button } from '~/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import { Input } from '~/components/ui/input';
import { Label } from '~/components/ui/label';

type Props = {
  path: string;
  extraOptions?: any[];
  meta: any;
  menuClassName?: string;
};

export function StructureItemOptionsMenu(props: Props) {
  const { t } = useTranslation();

  const { path, extraOptions, meta, menuClassName } = props;

  const [title, setTitle] = useState('');

  return (
    <AlertDialog onOpenChange={() => setTitle('')}>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger className={menuClassName} data-testid={`${meta.type}_options_menu`}>
          <Button className="w-5 h-5" variant="ghost" size="icon">
            <DotsHorizontalIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" side="bottom" align="end">
          <DropdownMenuLabel>{t('common.nouns.actions')}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {!!extraOptions && (
            <>
              <DropdownMenuGroup>{extraOptions}</DropdownMenuGroup>
              <DropdownMenuSeparator />
            </>
          )}
          <DropdownMenuGroup>
            <AlertDialogTrigger asChild>
              <DropdownMenuItem data-testid={`delete_${meta.type}_button`}>
                {t('common.buttons.remove')}
              </DropdownMenuItem>
            </AlertDialogTrigger>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('common.dialogs.confirmAction.title')}</AlertDialogTitle>
          <AlertDialogDescription className="text-black">
            {t('pages.booksEditStructure.dialogs.confirmRemoval.description')}

            <p className="my-2">
              {t('pages.booksEditStructure.dialogs.confirmRemoval.confirmTitleInput1')}{' '}
              {t(`models.${meta.i18nKey}.one`)}{' '}
              {t('pages.booksEditStructure.dialogs.confirmRemoval.confirmTitleInput2')}
            </p>

            <Label>{meta.title}</Label>
            <Input
              data-testid="confirm-title-input"
              type="text"
              autoFocus
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              name="confirm-title-input"
              className="mt-2"
            />
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel>{t('common.buttons.cancel')}</AlertDialogCancel>
          <AlertDialogAction
            className="bg-red-400 hover:bg-red-600"
            disabled={title !== meta.title}
            onClick={deleteItem}>
            {t('common.buttons.continue')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );

  function deleteItem() {
    router.delete(path);
  }
}
