import React, { ReactNode } from 'react';

import { cn } from '~/utils';

import { Container } from './container';

type Props = {
  children: ReactNode;
  className?: string;
};

export function Hero({ children, className }: Props) {
  return (
    <div className={cn('py-20 sm:py-24 lg:pb-32 xl:pb-36 relative', className)}>
      <Container className="max-w-[56rem]">{children}</Container>
    </div>
  );
}
