import React from 'react';
import { useTranslation } from 'react-i18next';

export function Author(props) {
  const { t } = useTranslation();

  const { user, userBook } = props;

  return (
    <div className="flex items-center">
      <div className="text-sm text-gray-500 mr-1">{user.fullName}</div>
      {renderLabel()}
    </div>
  );

  function renderLabel() {
    if (userBook.author || userBook.owner) {
      return <div className="text-sm text-cyan-500">({t('models.discussion.author')})</div>;
    }

    if (userBook.teacher) {
      return <div className="text-sm text-cyan-500">({t('common.nouns.teacher')})</div>;
    }
  }
}
