import React from 'react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '~/components/ui/accordion';
import { T_BaseNode } from '~/types/nodes';
import { ViewBookContextProvider } from '~/views/book/hooks/useViewBookContext';
import { NodeMap } from '~/views/book/NodeMap';

type Props = {
  node: T_BaseNode;
  access: any;
};

export function NodePreview(props: Props) {
  const { access, node } = props;

  return (
    <Accordion defaultValue={['node']} type="multiple" className="mb-4">
      <AccordionItem value="node" className="border-none">
        <AccordionTrigger className="flex justify-between w-full px-4 py-2 font-medium text-left text-gray-700 bg-blue-100 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
          <span>Onderdeel</span>
        </AccordionTrigger>
        <AccordionContent className="p-4 border-none">
          <ViewBookContextProvider value={{ access }}>
            <NodeMap nodes={[node]} />
          </ViewBookContextProvider>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
