import { useMemo } from 'react';

import { T_Ancestry, T_CourseContent } from '~/types/serializers';

type Props = {
  parts: T_Ancestry[];
  courseContent: T_CourseContent;
};

export function useBreadcrumbContent({ courseContent, parts }: Props) {
  const { courseContentable } = courseContent;

  const parentPart = useMemo(() => findParentPart(courseContentable.partId), []);
  const rootPart = useMemo(() => findRootPart(parentPart.slug), []);

  const partOptions = useMemo(() => getPartOptions(), []);
  const nestedPartOptions = useMemo(() => getNestedPartOptions(), []);
  const courseContentOptions = useMemo(() => getCourseContentOptions(), []);

  function findParentPart(id: number) {
    const nestedParts = parts.map((p) => p.children).flat();

    return nestedParts.find((p) => p.id === id);
  }

  function findRootPart(slug: string) {
    return parts.find((part) => part.children.some((p) => p.slug === slug));
  }

  function getPartOptions() {
    const rootParts = parts.filter((p) => {
      return p.children?.length >= 1 && p.children.some((child) => child.sections?.length >= 1);
    });

    return rootParts.map((part) => ({ ...part, title: part.title }));
  }

  function getNestedPartOptions() {
    if (!rootPart) return [];

    const nestedParts = rootPart.children.filter((child) => child.sections?.length >= 1);

    return nestedParts.map((part) => ({
      ...part,
      title: `${part.number} ${part.title}`,
    }));
  }

  function getCourseContentOptions() {
    if (!rootPart || !parentPart) return [];

    return parentPart.sections.map((section) => ({
      ...section,
      title: section.numberedTitle,
    }));
  }

  return {
    rootPart,
    parentPart,
    partOptions,
    nestedPartOptions,
    courseContentOptions,
  };
}
