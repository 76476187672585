// JsFromRoutes CacheKey 4e63001616551ca324deb5ecd3ebe9aa
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  home: /* #__PURE__ */ definePathHelper('get', '/home'),
  support: /* #__PURE__ */ definePathHelper('get', '/support'),
  pricing: /* #__PURE__ */ definePathHelper('get', '/pricing'),
  contact: /* #__PURE__ */ definePathHelper('get', '/contact'),
  tutorials: /* #__PURE__ */ definePathHelper('get', '/tutorials'),
  trainings: /* #__PURE__ */ definePathHelper('get', '/trainings'),
  training: /* #__PURE__ */ definePathHelper('get', '/training/:id'),
}
