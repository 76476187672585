import { DoubleArrowLeftIcon, HamburgerMenuIcon } from '@radix-ui/react-icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tour from 'reactour';
import { QuestionMark } from 'styled-icons/boxicons-regular';

import { T_Breadcrumb } from '~/types/T_Breadcrumb';

import { Button } from '../ui/button';
import { Breadcrumbs } from './breadcrumbs';
import { ErrorBoundary } from './error-boundary';
import { Flash } from './flash';
import { HtmlHead } from './html-head';
import { SidebarDesktop } from './sidebar-desktop';
import { SidebarMobile } from './sidebar-mobile';

type Props = {
  breadcrumbs: T_Breadcrumb[];
  children: React.ReactNode;
  htmlHeadMeta?: Record<string, string>;
  htmlHeadTitle: string;
  hideSidebar?: boolean;
  sidebarChildren: React.ReactNode;
  sidebarTitle: string;
  rightSideContent?: React.ReactNode;
  language?: 'en' | 'nl' | 'fr';
  tour?: {
    steps: any[];
  };
};

export function Layout(props: Props) {
  const { t } = useTranslation();

  const {
    breadcrumbs,
    children,
    htmlHeadMeta,
    htmlHeadTitle,
    hideSidebar,
    rightSideContent,
    sidebarChildren,
    sidebarTitle,
    tour,
  } = props;

  const [showSidebar, setShowSidebar] = useState(true);
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    setShowSidebar(!hideSidebar);
  }, [hideSidebar]);

  return (
    <ErrorBoundary>
      <HtmlHead htmlHeadMeta={htmlHeadMeta} htmlHeadTitle={htmlHeadTitle} />
      <Flash />

      <div className="flex h-full">
        <SidebarDesktop title={sidebarTitle} open={showSidebar}>
          {sidebarChildren}
        </SidebarDesktop>

        <div className="w-full">
          <div className="h-12 w-full p-2 flex items-center justify-between">
            <div className="flex items-center">
              <SidebarMobile title={sidebarTitle}>{sidebarChildren}</SidebarMobile>

              <Button
                className="hidden lg:block mr-3"
                onClick={() => setShowSidebar(!showSidebar)}
                data-testid="toggle-sidebar-button"
                variant="secondary"
                size="sm">
                {showSidebar ? <DoubleArrowLeftIcon /> : <HamburgerMenuIcon />}
              </Button>

              <Breadcrumbs breadcrumbs={breadcrumbs} />

              {tour && (
                <Button
                  onClick={() => setIsTourOpen(true)}
                  size="icon"
                  className="rounded-full bg-green-500 h-5 w-5 ml-2 hover:bg-green-600  opacity-75 hidden lg:flex">
                  <QuestionMark className="h-3 w-3 text-white" />
                </Button>
              )}
            </div>

            {rightSideContent}
          </div>

          <main className="h-[calc(100%-3rem)] p-2 overflow-y-auto">{children}</main>
        </div>
      </div>

      {tour && (
        <Tour
          steps={formattedSteps(tour.steps)}
          isOpen={isTourOpen}
          onRequestClose={() => setIsTourOpen(false)}
          className="text-sm rounded-md"
          accentColor="#10b981"
          rounded={5}
        />
      )}
    </ErrorBoundary>
  );

  function formattedSteps(steps: { selector: string; contentI18nKey: any }[]) {
    return steps.map(({ selector, contentI18nKey }) => ({
      selector,
      content: t(contentI18nKey),
    }));
  }
}
