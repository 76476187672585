import { usePage } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TypographyH4 } from '~/components/typography/heading-four';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '~/components/ui/accordion';
import { Card, CardContent, CardHeader, CardTitle } from '~/components/ui/card';
import { ClassGroupSelect } from '~/pages/course-contents/show/components/class-group-select';
import { T_AncestrySection } from '~/types/T_AncestryPart';

import { AssessmentBadge } from './assessment-badge';

export function ViewTeacher() {
  const { t } = useTranslation();

  const {
    exercises,
    chapter,
    teachingClassGroupAssessments,
    teachingClassGroup,
    teachingClassGroupStudents,
    teachingClassGroupStudentAnswers,
  } = usePage<any>().props;

  return (
    <div>
      <ClassGroupSelect />

      {teachingClassGroup ? (
        <Card className="w-full mt-4">
          <CardHeader>
            <CardTitle>
              <TypographyH4>{t('common.nouns.students')}</TypographyH4>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <Accordion type="multiple" className="w-full">
              {(teachingClassGroupStudents ?? []).map((student) => {
                return (
                  <AccordionItem key={student.id} value={student.id}>
                    <AccordionTrigger>{student.fullName}</AccordionTrigger>
                    <AccordionContent>
                      <div className="pl-6 divide-y">
                        {chapter.sections.map((section) => {
                          return (
                            <div
                              key={section.id}
                              className="flex justify-between items-center py-2">
                              <h5 className="flex-1 max-w-[16rem] truncate font-bold">
                                {section.numberedTitle}
                              </h5>

                              <p className="flex-1 max-w-[16rem]">
                                <span className="font-semibold">
                                  {answeredExerciseCountForStudent(student, section)}
                                </span>{' '}
                                {t('common.text.ofThe')}{' '}
                                <span className="font-semibold">{exerciseCount(section)}</span>{' '}
                                {t('pages.partsShow.text.exercisesAnswered')}
                              </p>

                              <AssessmentBadge assessment={findAssessment(student, section)} />
                            </div>
                          );
                        })}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </CardContent>
        </Card>
      ) : (
        <div className="p-2">{t('pages.partsShow.text.selectGroup')}</div>
      )}
    </div>
  );

  function findAssessment(student, section) {
    return (teachingClassGroupAssessments ?? []).find(
      (assessment) =>
        assessment.userId === student.id &&
        assessment?.courseContent?.courseContentable?.id === section?.id,
    );
  }

  function answeredExerciseCountForStudent(student, section) {
    let count = 0;

    const answerNodeKeys = (teachingClassGroupStudentAnswers ?? [])
      .filter((answeredExercise) => {
        return answeredExercise.user.id === student.id;
      })
      .map((answeredExercise) => answeredExercise.nodeKey);

    (exercises ?? [])
      .filter((exercise) => exercise?.courseContent?.courseContentable?.id === section?.id)
      .forEach((exercise) => {
        if (
          exercise.node.answerable_type !== 'sub-exercise-list' &&
          answerNodeKeys.includes(exercise.node.answerable_value.nodeKey)
        ) {
          count++;
        }

        if (exercise.node.answerable_type === 'sub-exercise-list') {
          const isAnswered = exercise.node.answerable_value.items.some((subExercise) => {
            return answerNodeKeys.includes(subExercise.answerable_value.nodeKey);
          });

          if (isAnswered) {
            count++;
          }
        }
      });

    return count;
  }

  function exerciseCount(section: T_AncestrySection) {
    return (exercises ?? []).filter(
      (exercise) => exercise?.courseContent?.courseContentable?.id === section?.id,
    ).length;
  }
}
