import clsx from 'clsx';
import React from 'react';

import { T_AnswerableFillList } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillList';
import { T_AnswerableFillListItem } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillListItem';

import { KeyImage } from './KeyImage';
import { KeyText } from './KeyText';

type Props = {
  item: T_AnswerableFillListItem['ViewNode'];
  answer?: string;
  answerable: T_AnswerableFillList['ViewNode'];
};

export function Item(props: Props) {
  const { item, answer, answerable } = props;

  return (
    <div
      className={clsx(
        'flex mb-[2mm]',
        answerable.columns === 1 ? 'w-full' : 'w-1/2',
        answerable.key_type === 'image' ? 'flex-col' : 'flex-row',
        answerable.key_type == 'image' && answerable.columns == 1 ? 'ml-[5mm]' : 0,
        answerable.key_type == 'image' && answerable.columns == 1 ? 'mr-[5mm]' : 0,
      )}>
      <div
        className={clsx('flex-1 w-full', answerable.key_type === 'image' && 'flex justify-center')}>
        {answerable.key_type === 'image' ? (
          <KeyImage itemKey={item.key} />
        ) : (
          <KeyText itemKey={item.key} />
        )}
      </div>

      <div
        className={clsx(
          'relative px-2 ml-[1.5mm] mr-[5mm]',
          answerable.key_type === 'image' ? 'w-full' : 'flex-1',
        )}>
        {answer ? <p className="text-[#0066cc] text-[10pt] m-0">{answer}</p> : null}

        <div className="w-full border-b border-dotted border-gray-400"></div>
      </div>
    </div>
  );
}
