import React from 'react';

import { Navigation } from './components/Navigation';
import { StudentSelfAssessment } from './components/StudentSelfAssessment';
import { useViewBookContext } from './hooks/useViewBookContext';
import { Sect1 } from './nodes/sect1/Sect1';
import { Wrapper } from './Wrapper';

export function BookView() {
  const { access, bookContent } = useViewBookContext();

  return (
    <div className="relative z-[1] bg-white mt-8">
      <div className="z-[1] max-w-4xl w-full m-auto p-4 md:pr-8 mb-32">
        <Sect1 node={bookContent} Wrapper={(props) => <Wrapper {...props} />} />

        <Navigation />

        {access.student && <StudentSelfAssessment />}
      </div>
    </div>
  );
}
