import React, { useEffect } from 'react';

import { usePrevious } from '~/hooks/usePrevious';
import { T_AnswerableMarkOption } from '~/types/node/exercise/answerables/mark-option/T_AnswerableMarkOption';
import { T_AnswerableMarkOptionGap } from '~/types/node/exercise/answerables/mark-option/T_AnswerableMarkOptionGap';
import { T_AnswerableMarkOptionParagraph } from '~/types/node/exercise/answerables/mark-option/T_AnswerableMarkOptionParagraph';

import { useSentInAnswerProps, useStandardAnswerProps } from '../../context/AnswerablePropsContext';
import { GapSelect } from './GapSelect';
import { Text } from './Text';

type Props = {
  activeGap: T_AnswerableMarkOptionGap['ViewNode'] | null;
  userAnswer: Record<string, string> | null;
  answerable: T_AnswerableMarkOption['ViewNode'];
  handleChange: (gapKey: string, answerKey: string) => void;
  node: T_AnswerableMarkOptionParagraph['ViewNode'];
  setActiveGap: (gap: any | null) => void;
};

export function ParagraphWithGaps(props: Props) {
  const { activeGap, userAnswer, handleChange, node, setActiveGap } = props;

  const { showSentInAnswers } = useSentInAnswerProps();
  const { showAnswer } = useStandardAnswerProps();

  const previousShowSentInAnswers = usePrevious(showSentInAnswers);

  useEffect(() => {
    if (showSentInAnswers && !previousShowSentInAnswers) {
      const firstGap = node.children.find(
        (child) => 'type' in child,
      ) as T_AnswerableMarkOptionGap['ViewNode'];

      setActiveGap(firstGap);
    }
  }, [previousShowSentInAnswers, showSentInAnswers]);

  return (
    <div className="text-gray-600 mb-4">
      {node.children.map((child) => {
        if (!('type' in child)) return <Text text={child.text} />;

        // TODO OVERAL NODE GEBRUIKEN
        // TODO OVERAL ANSWER GEBRUIKEN IPV VALUE

        return (
          <GapSelect
            activeGap={activeGap}
            handleChange={handleChange}
            key={child.nodeKey}
            node={child}
            setActiveGap={setActiveGap}
            showAnswer={showAnswer}
            showSentInAnswers={showSentInAnswers}
            userAnswer={userAnswer[child.nodeKey]}
          />
        );
      })}
    </div>
  );
}
