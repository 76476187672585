import React from 'react';

type Props = {
  children: React.ReactNode;
};

export function TopmenuDesktop(props: Props) {
  const { children } = props;

  return <div className="hidden sm:flex items-center space-x-2">{children}</div>;
}
