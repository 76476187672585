import React from 'react';

import { T_AnswerableMarkImage } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImage';
import { T_AnswerableMarkImageMarker } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImageMarker';

import { useStandardAnswerProps } from '../../context/AnswerablePropsContext';
import { MarkerSelect } from './MarkerSelect';
import { MarkerText } from './MarkerText';

type Props = {
  node: T_AnswerableMarkImageMarker['ViewNode'];
  userAnswer: string;
  handleChange: (key: string, value: string) => void;
  number: number;
  activeItem: any | null;
  setActiveItem: () => void;
  showSentInAnswers: boolean;
  answerable: T_AnswerableMarkImage['ViewNode'];
};

export default function Marker(props: Props) {
  const { showAnswer } = useStandardAnswerProps();

  const { answerable, node, userAnswer } = props;

  const showAnswerForCurrentMarker =
    showAnswer === 'all' || (typeof showAnswer === 'number' && showAnswer >= node.number);

  const answerIsCorrect = props.node?.nodeKey === userAnswer;

  return (
    <div className="mb-2 mt-1">
      {answerable.answer_by == 'text' ? (
        <MarkerText {...props} />
      ) : (
        <MarkerSelect {...props} showStandardAnswer={showAnswerForCurrentMarker} />
      )}

      {showAnswerForCurrentMarker && !answerIsCorrect && (
        <div className="flex">
          <div
            data-testid="marked-image-correct-answer"
            className="bg-green-300 text-sm mx-1 rounded px-1 mt-1">
            {node.text}
          </div>
        </div>
      )}
    </div>
  );
}
