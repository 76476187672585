import React from 'react';

import { T_MarkedImageMarker } from '~/types/node/T_MarkedImageMarker';

type Props = {
  node: T_MarkedImageMarker['ViewNode'];
};

export function Marker(props: Props) {
  const { node } = props;

  return (
    <div className="flex w-1/2 mb-2">
      <div className="mr-1 font-bold text-gray-600">{node.number}.</div>
      {node.text}
    </div>
  );
}
