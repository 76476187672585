import clsx from 'clsx';
import React from 'react';

import { T_AnswerableFillList } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillList';
import { T_AnswerableFillListItem } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillListItem';

import { Answer } from './Answer';
import { KeyImage } from './KeyImage';
import { KeyText } from './KeyText';

type Props = {
  activeItem: T_AnswerableFillListItem['ViewNode'] | null;
  answerable: T_AnswerableFillList['ViewNode'];
  handleChange: (key: string, value: string) => void;
  node: T_AnswerableFillListItem['ViewNode'];
  setActiveItem: (item: T_AnswerableFillListItem['ViewNode'] | null) => void;
  userAnswer: string;
};

export function Item(props: Props) {
  const { activeItem, answerable, handleChange, node, setActiveItem, userAnswer } = props;

  return (
    <div
      className={clsx(
        'flex items-center mb-2 relative pr-2 odd:pr-4',
        answerable.columns === 1 ? 'w-full' : 'w-1/2',
        answerable.key_type === 'image' && 'flex-col items-center',
        answerable.key_type === 'image' && answerable.columns === 1 && 'mx-20',
      )}>
      {answerable.key_type === 'image' ? <KeyImage node={node.key} /> : <KeyText node={node.key} />}

      <Answer
        active={activeItem?.nodeKey === node.nodeKey}
        answerable={answerable}
        handleChange={handleChange}
        itemNodeKey={node.nodeKey}
        node={node.answer}
        number={node.number}
        setActiveItem={() => setActiveItem(node)}
        userAnswer={userAnswer}
      />
    </div>
  );
}
