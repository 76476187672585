import React from 'react';
import { useTranslation } from 'react-i18next';

import { T_AudioFragment } from '~/types/node/T_AudioFragment';
import { Element } from '~/views/book/components/Element';

import { Source } from './Source';
import { Title } from './Title';

type Props = {
  node: T_AudioFragment['ViewNode'];
};

export function AudioFragment(props: Props) {
  const { t } = useTranslation();

  const { node } = props;

  return (
    <Element node={node} title={t('common.nodes.audioFragment')}>
      <Source node={node.source} />
      <Title node={node.title} source={node.source?.source} />
    </Element>
  );
}
