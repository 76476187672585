import React from 'react';

import { T_Table } from '~/types/node/T_Table';

import { Row } from './Row';

type Props = {
  node: T_Table['ViewNode'];
};

export function Table(props: Props) {
  const { node } = props;

  return (
    <div className="flex" data-testid={`${node.type} ${node.nodeKey}`}>
      <div className="flex flex-col w-full">
        <div className="rounded w-full border overflow-hidden border-gray-300">
          <table className="text-gray-700 bg-white w-full table table-fixed divide-y divide-gray-300">
            <tbody className="divide-y divide-gray-200">
              {node.children.map((node, index) => {
                return <Row key={index} node={node} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
