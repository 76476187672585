// JsFromRoutes CacheKey 2a5051f2c754c916dd117d47a1e64fd6
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/b/:book_slug/print_tasks'),
  create: /* #__PURE__ */ definePathHelper('post', '/b/:book_slug/print_tasks'),
  new: /* #__PURE__ */ definePathHelper('get', '/b/:book_slug/print_tasks/new'),
  test: /* #__PURE__ */ definePathHelper('get', '/b/:slug/print_test'),
}
