import { usePage } from '@inertiajs/react';

import { T_Training } from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';

export const useTrainingsShowPage = usePage<T_TrainingsShowProps>;

export interface T_TrainingsShowProps extends T_PageProps {
  training: T_Training;
}
