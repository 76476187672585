import React, { useEffect, useState } from 'react';
import { Megaphone } from 'styled-icons/heroicons-solid';

import { Button } from '~/components/ui/button';
import { Dialog, DialogContent, DialogTrigger } from '~/components/ui/dialog';
import { useSubscription } from '~/hooks/useSubscription';

import { useCourseContentShowPage } from '../T_CourseContentShowProps';
import { DynamicQuestionAnswerForm } from './dynamic-question-answer-form';

export function DynamicQuestionAnswerButton() {
  const {
    auth: { user },
    customQuestion,
    studentClassGroup,
  } = useCourseContentShowPage().props;

  const [question, setQuestion] = useState(customQuestion || null);

  const [answers, setAnswers] = useState(
    (customQuestion && customQuestion.customQuestionAnswers) || [],
  );

  useEffect(() => {
    if (!customQuestion || !customQuestion.customQuestionAnswers) return;

    setAnswers(customQuestion.customQuestionAnswers);
  }, [customQuestion]);

  console.log('studentClassGroup?.broadcastRoom', studentClassGroup?.broadcastRoom);

  useSubscription(
    (response) => {
      console.log('hallo?');
      setQuestion(response.data.customQuestion);

      if (!response.data.customQuestion) {
        setAnswers([]);
      }
    },
    {
      channel: 'ClassroomChannel',
      room: studentClassGroup?.broadcastRoom,
      stream: 'custom_question',
    },
  );

  if (!question) {
    return null;
  }

  const userHasAnswered = answers.some((answer) => answer.userId === user.id);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          className="bg-red-500 hover:bg-red-300 border-red-500"
          data-testid="custom_question_alert"
          size="icon"
          variant="outline">
          <Megaphone className="w-4 h-4 text-white" />
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DynamicQuestionAnswerForm question={question} userHasAnswered={userHasAnswered} />
      </DialogContent>
    </Dialog>
  );
}
