import { zodResolver } from '@hookform/resolvers/zod';
import { router, usePage } from '@inertiajs/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import BooksApi from '~/api/BooksApi';
import { Button } from '~/components/ui/button';
import { DialogFooter } from '~/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import { T_PageProps } from '~/types/T_PageProps';

const formSchema = z.object({
  code: z.string().refine((data) => data !== '', {
    params: { i18n: 'customZodErrors.required' },
  }),
});

type Props = {
  closeDialog: () => void;
};

export function RegisterLicenseForm(props: Props) {
  const { t } = useTranslation();

  const { closeDialog } = props;

  const {
    organization: { template },
  } = usePage<T_PageProps>().props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: '',
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="code"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('pages.booksIndex.forms.registerLicense.labels.code')}</FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder={t('pages.booksIndex.forms.registerLicense.placeholders.code')}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter>
          <Button
            style={{ backgroundColor: template.color1 }}
            data-testid="create_class_group_button"
            type="submit">
            {t('pages.booksIndex.forms.registerLicense.buttons.registerLicense')}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.post(
      BooksApi.claimLicense.path(),
      { claim_license: values },
      {
        replace: true,
        onError: () => {
          form.setError('code', {
            type: 'custom',
          });
        },
        onSuccess: () => {
          form.reset();
          closeDialog();
        },
      },
    );
  }
}
