import { Link } from '@inertiajs/react';
import { PlusIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BooksApi from '~/api/BooksApi';
import { Button } from '~/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '~/components/ui/dialog';

import { RegisterLicenseForm } from './register-license-form';

export function CreationOptions({ role }) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button className="first-step" variant="outline" data-testid="register_book_button">
            <PlusIcon className="h-4 w-4 mr-2" />
            {t('pages.booksIndex.buttons.registerLicense')}
          </Button>
        </DialogTrigger>

        <DialogContent>
          <DialogHeader>
            <DialogTitle> {t('pages.booksIndex.dialogs.registerLicense.title')}</DialogTitle>
          </DialogHeader>

          <RegisterLicenseForm closeDialog={() => setOpen(false)} />
        </DialogContent>
      </Dialog>

      {role !== 'student' && (
        <Button className="second-step ml-2" asChild variant="outline">
          <Link href={BooksApi.new.path()}>
            <PlusIcon className="h-4 w-4 mr-2" />
            {t('pages.booksIndex.buttons.createNewBook')}
          </Link>
        </Button>
      )}
    </div>
  );
}
