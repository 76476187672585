import { Link } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AcademicCap } from 'styled-icons/heroicons-outline';

import RegistrationsApi from '~/api/Users/RegistrationsApi';

import { Footer } from '../../components/footer';
import { HeaderBusiness } from '../../components/header-business';

export default function Pricing() {
  const { t } = useTranslation();

  return (
    <>
      <HeaderBusiness />

      <div className="py-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-xl">
            <div className="flex flex-col">
              <dl className="space-y-8">
                <div>
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-stone-700">
                    <AcademicCap aria-hidden="true" className="h-6 w-6 flex-none text-green-600" />
                    {t('website.pricing.business.trainerTitle')}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-stone-700">
                    <p className="flex-auto">
                      {t('website.pricing.business.trainerDescription1')}
                      <Link
                        className="text-green-500 hover:underline"
                        href={RegistrationsApi.new.path()}>
                        {t('website.pricing.business.trainerCallToAction')}
                      </Link>{' '}
                      {t('website.pricing.business.trainerDescription2')}
                    </p>
                    <p className="flex-auto">{t('website.pricing.business.trainerDescription3')}</p>
                  </dd>
                </div>
                <div>
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-stone-700">
                    <AcademicCap aria-hidden="true" className="h-6 w-6 flex-none text-green-600" />
                    {t('website.pricing.business.organizationTitle')}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-stone-700">
                    <p className="text-stone-700">
                      {t('website.pricing.business.organizationDescription1')}
                      <a className="text-green-500 underline" href="mailto:info@brightbook.io">
                        {t('website.pricing.business.organizationCallToAction')}
                      </a>{' '}
                      {t('website.pricing.business.organizationDescription2')}
                    </p>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

Pricing.layout = (page) => page;
