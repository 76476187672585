import { usePage } from '@inertiajs/react';
import React from 'react';

import { LayoutChapter } from '~/components/ui-extended/layout-chapter';

import { PageContent } from './components/page-content';

export default function Show() {
  const { access, book, chapter, parts, studentClassGroup } = usePage<any>().props;

  return (
    <LayoutChapter
      access={access}
      book={book}
      chapter={chapter}
      studentClassGroup={studentClassGroup}
      parts={parts}
      breadcrumbs={[
        {
          title: 'common.breadcrumbs.partsShowPage',
          translateTitle: true,
          type: 'static',
        },
      ]}>
      <PageContent />
    </LayoutChapter>
  );
}
