import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { T_AnswerableFillGaps } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGaps';
import { T_AnswerableFillGapsGap } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGapsGap';

import { SentInAnswersWrapper } from '../../shared/SentInAnswersWrapper';
import { getGapNodes } from './helpers/gapIterators';
import { OpenTextView } from './OpenTextView';
import { PercentageView } from './PercentageView';

type Props = {
  activeGap: T_AnswerableFillGapsGap['ViewNode'] | null;
  answerable: T_AnswerableFillGaps['ViewNode'];
};

export function SentInAnswers(props: Props) {
  const { t } = useTranslation();

  const { activeGap, answerable } = props;

  const gapNodes = useMemo(() => getGapNodes(answerable), []);

  return (
    <SentInAnswersWrapper>
      <div className="flex flex-col">
        <div className="mt-1">
          {activeGap ? (
            !answerable.answer_by || answerable.answer_by === 'select' ? (
              <PercentageView activeGap={activeGap} gapNodes={gapNodes} />
            ) : (
              <OpenTextView activeGap={activeGap} />
            )
          ) : (
            <div className="mt-1 text-gray-500">
              {t('pages.courseContentsShow.nodes.exercise.selectItemToSeeSentInAnswers')}
            </div>
          )}
        </div>
      </div>
    </SentInAnswersWrapper>
  );
}
