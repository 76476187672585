import React from 'react';

import { T_Image } from '~/types/node/T_Image';

type Props = {
  node: T_Image['ViewNode'];
};

export function Image(props: Props) {
  const { node } = props;

  if (!node.print_src) return null;

  return (
    <div className="relative w-[35%]">
      <img className="w-full h-auto" src={node.print_src} />
    </div>
  );
}
