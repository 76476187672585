import { usePage } from '@inertiajs/react';
import React from 'react';

import { PrintView } from '~/views/print/PrintView';

export default function Print() {
  const { json } = usePage<any>().props;

  return <PrintView json={JSON.parse(json)} />;
}

Print.layout = (page) => page;
