import { usePage } from '@inertiajs/react';
import React from 'react';

import { LayoutBook } from '~/components/ui-extended/layout-book';

import { PageContent } from './components/page-content';

export default function Search() {
  const { access, book } = usePage<any>().props;

  return (
    <LayoutBook
      breadcrumbs={[
        { title: 'common.breadcrumbs.booksSearch', translateTitle: true, type: 'static' },
      ]}
      htmlHeadTitle={book.title}
      access={access}
      book={book}
      sidebarTitle={book.title}>
      <PageContent />
    </LayoutBook>
  );
}
