import React from 'react';

import { T_BlockQuote } from '~/types/node/T_BlockQuote';
import { NodeMap } from '~/views/print/NodeMap';

type Props = {
  node: T_BlockQuote['ViewNode'];
};

export function BlockQuote(props: Props) {
  const { node } = props;

  return (
    <div className="mt-2.5 mb-4 border-l-2 pl-2 border-blue-700 py-1">
      <p className="font-serif italic text-gray-600">
        <NodeMap nodes={node.children} />
      </p>
    </div>
  );
}
