import { usePage } from '@inertiajs/react';
import React from 'react';

export default function SelectableLinks() {
  const { book, parts, partJwts, returnUrl } = usePage<any>().props;

  return (
    <div>
      <div className="p-4">
        <h3 className="mb-3 text-lg">
          Leerinhoud voor <span className="font-bold text-gray-700 font-nunito">{book.title}</span>:
        </h3>

        <ul>
          {parts.map((part, index) => {
            const partJwt = findJwt(part.id);

            return (
              <li className="font-semibold text-gray-700" key={index}>
                <form
                  action={returnUrl}
                  method="POST"
                  className="flex items-center justify-between pb-2 mb-4 border-b">
                  <div>{part.title}</div>
                  <input type="hidden" name="JWT" defaultValue={partJwt} />
                  <button className="ml-3 font-bold text-blue-400 rounded" type="submit">
                    Selecteer
                  </button>
                </form>

                <ul className="pb-2 pl-6 mb-4 font-normal border-b space-y-1">
                  {part.children.map((chapter, index) => {
                    const nestedPartJwt = findJwt(chapter.id);

                    if (!nestedPartJwt) return null;

                    return (
                      <li key={index} className="flex items-center justify-between">
                        <div className="text-gray-700">
                          {chapter.number} {chapter.title}{' '}
                        </div>
                        <div>
                          <form action={returnUrl} method="POST">
                            <input type="hidden" name="JWT" defaultValue={nestedPartJwt} />
                            <button className="ml-3 font-bold rounded text-accentt" type="submit">
                              Selecteer
                            </button>
                          </form>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
          {/* {sectionsWithJwt.map(({ section, jwt }, index) => {
            console.log('SECTION', section);
            return (
              <li key={index}>
                {section.numberedTitle}
                <div>
                  <form action={returnUrl} method="POST">
                    <input type="hidden" name="JWT" defaultValue={jwt} />
                    <button
                      className="ml-3 px-1 py-0.5 hover:bg-opacity-60  rounded bg-accentt text-white"
                      type="submit">
                      Selecteer
                    </button>
                  </form>
                </div>
              </li>
            );
          })} */}
        </ul>
      </div>
    </div>
  );

  function findJwt(chapterId) {
    const chapterJwt = partJwts.find((chapterJwt) => {
      return chapterJwt.id === chapterId;
    });

    return chapterJwt ? chapterJwt.jwt : null;
  }
}
