import React from 'react';

import { useTheme } from '~/components/theme-provider';
import { HightlightContextProvider } from '~/hooks/useHighlightContext';
import { T_HeadingFour } from '~/types/node/T_HeadingFour';

import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_HeadingFour['ViewNode'];
};

export function HeadingFour(props: Props) {
  const { node } = props;

  const { colors } = useTheme();

  return (
    <h4
      data-testid={`${node.type} ${node.nodeKey}`}
      style={{ color: colors.headingFour }}
      className="font-bold leading-relaxed text-cyan-600">
      <HightlightContextProvider showHighlight={false}>
        <NodeMap nodes={node.children} />
      </HightlightContextProvider>
    </h4>
  );
}
