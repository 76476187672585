import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';

import { T_AnswerableMarkOption } from '~/types/node/exercise/answerables/mark-option/T_AnswerableMarkOption';
import { T_AnswerableMarkOptionGap } from '~/types/node/exercise/answerables/mark-option/T_AnswerableMarkOptionGap';
import { T_NodeType } from '~/types/T_NodeTypes';

import { useExerciseProps } from '../../context/ExercisePropsContext';
import { useFindUserAnswers } from '../../hooks/useFindUserAnswers';
import { forEachNode } from '../fill-gaps/helpers/gapIterators';
import { OrderedList } from './OrderedList';
import { ParagraphWithGaps } from './ParagraphWithGaps';
import { UnorderedList } from './UnorderedList';

type Props = {
  activeGap: T_AnswerableMarkOptionGap['ViewNode'] | null;
  answerable: T_AnswerableMarkOption['ViewNode'];
  itemCount: number;
  remakable: boolean;
  remakeMode: boolean;
  setActiveGap: (gap: T_AnswerableMarkOptionGap['ViewNode'] | null) => void;
};

export function ParagraphList(props: Props) {
  const { activeGap, answerable, remakable, remakeMode, setActiveGap } = props;

  const { submitExercise } = useExerciseProps();

  const userAnswer = useFindUserAnswers(answerable.nodeKey);

  const [answer, setAnswer] = useState(
    userAnswer?.value && userAnswer?.value?.markOption && !remakeMode
      ? userAnswer.value.markOption
      : {},
  );

  useEffect(() => {
    if (remakeMode) {
      setAnswer({});
    } else if (userAnswer?.value) {
      setAnswer(userAnswer?.value?.markOption);
    }
  }, [remakeMode]);

  useMemo(() => addNumberToGaps(), []);

  return (
    <div className={clsx('mb-4', remakable && remakeMode && 'bg-green-50 pl-1')}>
      {answerable.children.map((node) => {
        switch (node.type) {
          case T_NodeType.PARAGRAPH: {
            return (
              <ParagraphWithGaps
                activeGap={activeGap}
                answerable={answerable}
                handleChange={handleChange}
                key={node.nodeKey}
                node={node}
                setActiveGap={setActiveGap}
                userAnswer={answer}
              />
            );
          }
          case T_NodeType.LIST_ORDERED: {
            return (
              <OrderedList
                activeGap={activeGap}
                answerable={answerable}
                handleChange={handleChange}
                key={node.nodeKey}
                node={node}
                setActiveGap={setActiveGap}
                userAnswer={answer}
              />
            );
          }
          case T_NodeType.LIST_UNORDERED: {
            return (
              <UnorderedList
                activeGap={activeGap}
                answerable={answerable}
                handleChange={handleChange}
                key={node.nodeKey}
                node={node}
                setActiveGap={setActiveGap}
                userAnswer={answer}
              />
            );
          }
        }
      })}
    </div>
  );

  function handleChange(gapKey: string, answerKey: string) {
    const newAnswer = { ...answer, [gapKey]: answerKey };

    submitExercise({
      answerableNodeKey: answerable.nodeKey,
      answer: { value: { markOption: newAnswer } },
    });

    setAnswer(newAnswer);
  }

  function addNumberToGaps() {
    let gapNumber = 0;

    forEachNode(answerable, T_NodeType.MARK_OPTION_GAP, (gap) => (gap.number = ++gapNumber));
  }
}
