import { usePage } from '@inertiajs/react';

import { T_Tutorial } from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';

export const useTutorialsIndexPage = usePage<T_TutorialsIndexProps>;

export interface T_TutorialsIndexProps extends T_PageProps {
  tutorials: T_Tutorial[];
}
