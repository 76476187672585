import { UniqueIdentifier } from '@dnd-kit/core';
import { PopoverContent, PopoverTrigger } from '@radix-ui/react-popover';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PencilSquare } from 'styled-icons/heroicons-outline';

import { Popover } from '~/components/ui/popover';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';
import { cn } from '~/utils';

import { StructureItemTitleEditForm } from './structure-item-title-edit-form';

type Props = {
  isDragging: boolean;
  fromDragOverlay?: boolean;
  paths: { update: string; delete: string };
  meta: {
    type: string;
    title: string;
    id: UniqueIdentifier;
    i18nKey: 'nestedPart' | 'section' | 'part';
  };
};

export function StructureItemTitle(props: Props) {
  const { t } = useTranslation();

  const { fromDragOverlay, isDragging, meta, paths } = props;

  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <TooltipProvider delayDuration={500}>
      <Tooltip>
        <TooltipTrigger>
          <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
            <PopoverTrigger className="flex items-center">
              <h4
                className="flex items-center font-medium text-sm text-gray-700"
                data-testid={`edit_${meta.type}_title_button`}>
                {meta.title}
              </h4>
              <PencilSquare className="w-4 h-4 text-gray-500 ml-1" />
            </PopoverTrigger>

            <PopoverContent side="bottom" className="bg-white border p-4 w-96 z-[100]">
              <StructureItemTitleEditForm
                closePopover={() => setPopoverOpen(false)}
                path={paths.update}
                meta={meta}
              />
            </PopoverContent>
          </Popover>
        </TooltipTrigger>

        <TooltipContent className={cn((isDragging || fromDragOverlay) && 'hidden')}>
          <p>{t('pages.booksEditStructure.text.clickToEditTitle')}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
