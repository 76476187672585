import React from 'react';

import { T_Flashcard } from '~/types/serializers';
import { NodeMap } from '~/views/book/NodeMap';

type Props = {
  flashcard: T_Flashcard;
};

export function DefinitionFront(props: Props) {
  const {
    flashcard: { front },
  } = props;

  return (
    <div data-testid="flashcard_front" className="text-xl text-gray-600">
      <NodeMap nodes={(front as any).children} />
    </div>
  );
}

export function DefinitionBack(props: Props) {
  const {
    flashcard: { back },
  } = props;

  return (
    <div data-testid="flashcard_back" className="text-gray-600">
      <NodeMap nodes={(back as any).children} />
    </div>
  );
}
