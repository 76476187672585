import { router } from '@inertiajs/react';
import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'react-i18next';
import { MoreHorizontal } from 'styled-icons/fluentui-system-regular';

import DiscussionsApi from '~/api/DiscussionsApi';
import { Button } from '~/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '~/components/ui/dropdown-menu';

export type Props = {
  discussion: any;
  book: any;
};

export function DiscussionOptions(props: Props) {
  const { t } = useTranslation();

  const { discussion } = props;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">{t('common.screenReader.openMenu')}</span>
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t('common.nouns.actions')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={navigateToDiscussionPage}>
          {t('common.buttons.view')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );

  function navigateToDiscussionPage() {
    router.visit(
      DiscussionsApi.show.path({
        course_content_slug: discussion.courseContent.slug,
        id: discussion.id,
      }),
    );
  }
}
