import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';

import { T_AnswerableFillGaps } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGaps';
import { T_AnswerableFillGapsGap } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGapsGap';
import { T_NodeType } from '~/types/T_NodeTypes';

import { useExerciseProps } from '../../context/ExercisePropsContext';
import { useFindUserAnswers } from '../../hooks/useFindUserAnswers';
import { collectNodes, forEachNode } from './helpers/gapIterators';
import { OrderedList } from './OrderedList';
import { ParagraphWithGaps } from './ParagraphWithGaps';
import { PossibleAnswersList } from './PossibleAnswerList';
import { UnorderedList } from './UnorderedList';

type Props = {
  activeGap: T_AnswerableFillGapsGap['ViewNode'] | null;
  answerable: T_AnswerableFillGaps['ViewNode'];
  itemCount: number;
  remakable: boolean;
  remakeMode: boolean;
  setActiveGap: (gap: T_AnswerableFillGapsGap['ViewNode'] | null) => void;
};

export function ParagraphList(props: Props) {
  const { activeGap, answerable, remakable, remakeMode, setActiveGap } = props;

  const { submitExercise } = useExerciseProps();

  const userAnswer = useFindUserAnswers(answerable.nodeKey);

  const possibleAnswers = useMemo(() => formatPossibleAnswers(), []);

  const [answer, setAnswer] = useState(
    userAnswer?.value && userAnswer?.value?.fillGaps && !remakeMode
      ? userAnswer.value.fillGaps
      : {},
  );

  useEffect(() => {
    if (remakeMode) {
      setAnswer({});
    } else if (userAnswer?.value) {
      setAnswer(userAnswer?.value?.fillGaps);
    }
  }, [remakeMode]);

  useMemo(() => addNumberToGaps(), []);

  return (
    <div className={clsx('mb-4', remakable && remakeMode && 'bg-green-50 pl-1')}>
      {answerable.children.map((node) => {
        switch (node.type) {
          case T_NodeType.PARAGRAPH: {
            return (
              <ParagraphWithGaps
                activeGap={activeGap}
                answer={answer}
                answerable={answerable}
                handleChange={handleChange}
                key={node.nodeKey}
                node={node}
                possibleAnswers={possibleAnswers}
                setActiveGap={setActiveGap}
              />
            );
          }
          case T_NodeType.LIST_ORDERED: {
            return (
              <OrderedList
                activeGap={activeGap}
                answer={answer}
                answerable={answerable}
                handleChange={handleChange}
                key={node.nodeKey}
                node={node}
                possibleAnswers={possibleAnswers}
                setActiveGap={setActiveGap}
              />
            );
          }
          case T_NodeType.LIST_UNORDERED: {
            return (
              <UnorderedList
                activeGap={activeGap}
                answer={answer}
                answerable={answerable}
                handleChange={handleChange}
                key={node.nodeKey}
                node={node}
                possibleAnswers={possibleAnswers}
                setActiveGap={setActiveGap}
              />
            );
          }
        }
      })}

      {answerable.show_possible_answers && (
        <PossibleAnswersList possibleAnswers={possibleAnswers} />
      )}
    </div>
  );

  function handleChange(gapKey: string, answerKey: string) {
    const newAnswer = { ...answer, [gapKey]: answerKey };

    submitExercise({
      answer: { value: { fillGaps: newAnswer } },
      answerableNodeKey: answerable.nodeKey,
    });

    setAnswer(newAnswer);
  }

  function addNumberToGaps() {
    let gapNumber = 0;

    forEachNode(answerable, T_NodeType.FILL_GAPS_GAP, (gap) => (gap.number = ++gapNumber));
  }

  function formatPossibleAnswers(): {
    amount: number;
    nodeKey: string;
    number: number;
    text: string;
  }[] {
    const gapNodes = collectNodes(answerable, T_NodeType.FILL_GAPS_GAP);

    const formattedGapNodes = gapNodes.reduce(function (previousValue, currentNode, index) {
      const childTextNode = currentNode.children[0];
      const textContent = childTextNode.text;

      const nodeWithSameTextIndex = previousValue.findIndex(
        (formattedNode) => formattedNode.text === textContent,
      );

      if (nodeWithSameTextIndex >= 0) {
        previousValue[nodeWithSameTextIndex].amount += 1;
      } else {
        previousValue.push({
          amount: 1,
          nodeKey: currentNode.nodeKey,
          number: index + 1,
          text: textContent,
        });
      }

      return previousValue;
    }, []);

    return formattedGapNodes;
  }
}
