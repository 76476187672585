import React, { createContext, useContext } from 'react';

import { T_Sect1Element } from '~/types/nodes';
import {
  T_Access,
  T_Answer,
  T_Book,
  T_ClassGroup,
  T_ClassGroupCourseContent,
  T_ContainerTemplate,
  T_CourseContent,
  T_CurrentUserBook,
  T_Enrichment,
  T_StudentSelfAssessment,
} from '~/types/serializers';
import { T_Auth } from '~/types/T_Auth';

type ViewBookConfig = {
  auth: T_Auth;
  access: T_Access;
  answers: T_Answer[];
  book: T_Book;
  bookContent: T_Sect1Element;
  currentUserBook: T_CurrentUserBook;
  classGroupCourseContent?: T_ClassGroupCourseContent;
  courseContent: T_CourseContent;
  enrichments: T_Enrichment[];
  sharedEnrichments?: T_Enrichment[];
  studentClassGroup?: T_ClassGroup;
  studentSelfAssessment: T_StudentSelfAssessment;
  studentSelfAssessments: T_StudentSelfAssessment[];
  teachingClassGroup: T_ClassGroup;
  teachingClassGroupStudentAnswers: T_Answer[];
  templateConfig: T_ContainerTemplate[];
  userAnswers?: T_Answer[];
};

const ViewBookContext = createContext({} as ViewBookConfig);

interface ProviderProps {
  children: React.ReactNode;
  value: ViewBookConfig;
}

export function ViewBookContextProvider(props: ProviderProps) {
  const { children, value } = props;

  return (
    <ViewBookContext.Provider value={{ ...value, userAnswers: value.answers }}>
      {children}
    </ViewBookContext.Provider>
  );
}

export function useViewBookContext() {
  return useContext(ViewBookContext);
}
