import React from 'react';

import { T_AnswerableMarkOption } from '~/types/node/exercise/answerables/mark-option/T_AnswerableMarkOption';
import { T_NodeType } from '~/types/T_NodeTypes';

import { OrderedList } from './OrderedList';
import { ParagraphWithGaps } from './ParagraphWithGaps';
import { UnorderedList } from './UnorderedList';

type Props = {
  answerable: T_AnswerableMarkOption['ViewNode'];
};

export function ParagraphList(props: Props) {
  const { answerable } = props;

  return (
    <div className="break-inside-avoid">
      {answerable.children.map((node) => {
        switch (node.type) {
          case T_NodeType.PARAGRAPH: {
            return <ParagraphWithGaps key={node.nodeKey} node={node} />;
          }
          case T_NodeType.LIST_ORDERED: {
            return <OrderedList key={node.nodeKey} node={node} />;
          }
          case T_NodeType.LIST_UNORDERED: {
            return <UnorderedList key={node.nodeKey} node={node} />;
          }
        }
      })}
    </div>
  );
}
