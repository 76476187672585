// JsFromRoutes CacheKey 559bfe449df61c79c070ebd7b4f041cd
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  cancel: /* #__PURE__ */ definePathHelper('get', '/users/cancel'),
  new: /* #__PURE__ */ definePathHelper('get', '/users/sign_up'),
  edit: /* #__PURE__ */ definePathHelper('get', '/users/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/users'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/users'),
  create: /* #__PURE__ */ definePathHelper('post', '/users'),
}
