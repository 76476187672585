import { router } from '@inertiajs/react';
import { usePage } from '@inertiajs/react';
import axios from 'axios';
import dayjs from 'dayjs';
import React from 'react';
import { Translation } from 'react-i18next';

import NotificationsApi from '~/api/NotificationsApi';
import { DataTableContainer } from '~/components/ui-extended/data-table-container';
import { LayoutBook } from '~/components/ui-extended/layout-book';

export default function Index() {
  const { access, book, unreadNotifications, readNotifications } = usePage<any>().props;

  return (
    <LayoutBook
      breadcrumbs={[
        {
          title: 'models.notification.many',
          translateTitle: true,
          type: 'static',
        },
      ]}
      htmlHeadTitle={book.title}
      access={access}
      book={book}
      sidebarTitle={book.title}>
      <DataTableContainer translateTitle title="models.notification.many">
        <Translation>
          {(t) => (
            <>
              <h4 className="mt-4 font-bold text-gray-700">
                {t('pages.notificationsIndex.text.unread')}
              </h4>

              {!unreadNotifications.length ? (
                <p className="text-sm text-gray-500">
                  {t('pages.notificationsIndex.text.noUnread')}
                </p>
              ) : (
                <ul role="list" className="mt-2 space-y-3">
                  {unreadNotifications.map((notification) => (
                    <a
                      className="cursor-pointer"
                      onClick={() => readAndNavigate(notification)}
                      key={notification.id}>
                      <li className="py-4 border-b">
                        <div className="flex space-x-3">
                          <div className="flex-1 space-y-1">
                            <div className="flex items-center justify-between">
                              <h3 className="text-gray-700">
                                <span className="font-bold">{notification.creatorName}</span>{' '}
                                {notification.message}
                              </h3>
                              <p className="text-sm text-gray-500">
                                {dayjs(notification.createdAt).fromNow()}
                              </p>
                            </div>
                            <p className="text-xs font-medium text-accentt">
                              {notification.partTitle} {'>'} {notification.courseContentableTitle}
                            </p>
                          </div>
                        </div>
                      </li>
                    </a>
                  ))}
                </ul>
              )}

              <h4 className="mt-4 font-bold text-gray-700">
                {t('pages.notificationsIndex.text.read')}
              </h4>
              {!readNotifications.length ? (
                <p className="text-sm text-gray-500">{t('pages.notificationsIndex.text.noRead')}</p>
              ) : (
                <ul role="list" className="mt-2 space-y-3">
                  {readNotifications.map((notification) => (
                    <a
                      className="block cursor-pointer"
                      onClick={() => readAndNavigate(notification)}
                      key={notification.id}>
                      <li className="pb-2 border-b">
                        <div className="flex space-x-3">
                          <div className="flex-1 space-y-1">
                            <div className="flex items-center justify-between">
                              <h3 className="text-gray-700">
                                <span className="font-bold">{notification.creatorName}</span>{' '}
                                {notification.message}
                              </h3>
                              <p className="text-sm text-gray-500">
                                {dayjs(notification.createdAt).fromNow()}
                              </p>
                            </div>
                            <p className="text-xs font-medium text-accentt">
                              {notification.chapterTitle} {'>'}{' '}
                              {notification.courseContentableTitle}
                            </p>
                          </div>
                        </div>
                      </li>
                    </a>
                  ))}
                </ul>
              )}
            </>
          )}
        </Translation>
      </DataTableContainer>
    </LayoutBook>
  );

  function readAndNavigate(notification) {
    axios.post(
      NotificationsApi.read.path({
        book_slug: book.slug,
        id: notification.id,
      }),
    );

    router.visit(notification.url);
  }
}
