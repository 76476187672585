import { PostProcessorModule } from 'i18next';

export function createContextPlugin(context): PostProcessorModule {
  return {
    type: 'postProcessor',
    name: 'contextProcessor',
    process: function (value, key, options, translator) {
      if (context && translator.exists(`${key}_${context}`)) {
        return translator.translate(`${key}_${context}`, options);
      }

      return value;
    },
  };
}
