import React from 'react';

import { T_TableCell } from '~/types/node/T_TableCell';
import { NodeMap } from '~/views/print/NodeMap';

type Props = {
  node: T_TableCell['ViewNode'];
};

export function Cell(props: Props) {
  const { node } = props;

  return (
    <td className="text-[9pt] text-[#4b5563] p-[2mm] [&_p]:mt-0">
      <span>
        <NodeMap nodes={node.children} />
      </span>
    </td>
  );
}
