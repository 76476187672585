import { usePage } from '@inertiajs/react';
import React from 'react';

import { DataTableContainer } from '~/components/ui-extended/data-table-container';

import { NestedParts } from './nested-parts';
import { Parts } from './parts';

export function PageContent() {
  const { access, parts, book } = usePage<any>().props;

  return (
    <DataTableContainer title="pages.booksShow.title" translateTitle>
      {book.structure === 'part_chapter_section' ? (
        <Parts access={access} parts={parts} />
      ) : (
        <NestedParts access={access} nestedParts={parts[0].children} />
      )}
    </DataTableContainer>
  );
}
