import { createContext, useContext } from 'react';

import { T_ContainerTemplate } from '~/types/serializers';
import T_BookStructure from '~/types/T_BookStructure';

export const ViewPrintContext = createContext<{
  exercise_layout: 'empty' | 'standard_answers' | 'user_answers';
  user_answers: { answers: any[]; user: any };
  justify_text: boolean;
  template_config: T_ContainerTemplate[];
  structure: T_BookStructure;
}>({
  exercise_layout: 'empty',
  user_answers: { answers: [], user: null },
  justify_text: false,
  template_config: [],
  structure: null,
});

export const useViewPrintContext = () => useContext(ViewPrintContext);
