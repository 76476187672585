import React, { useCallback } from 'react';

import { Column, Columns } from '~/components/ui/column-layout';
import { T_MarkedImageMarker } from '~/types/node/T_MarkedImageMarker';
import { splitCollection } from '~/utils/splitCollection';

import { Marker } from './Marker';

type Props = {
  markers: T_MarkedImageMarker['ViewNode'][];
};

export function MarkerList(props: Props) {
  const { markers } = props;

  const [column1, column2] = splitCollection(markers);

  const renderMarkers = useCallback(
    (marker: T_MarkedImageMarker['ViewNode']) => <Marker key={marker.number} node={marker} />,
    [markers],
  );

  return (
    <Columns className="mt-4">
      <Column>{column1.map(renderMarkers)}</Column>
      <Column>{column2.map(renderMarkers)}</Column>
    </Columns>
  );
}
