import { Link, usePage } from '@inertiajs/react';
import { Pencil1Icon } from '@radix-ui/react-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChartBarSquare,
  ChatBubbleLeftRight,
  Pencil,
  PencilSquare,
  RectangleStack,
} from 'styled-icons/heroicons-outline';
import { Edit2 } from 'styled-icons/remix-line';

import BooksApi from '~/api/BooksApi';
import CourseContentsApi from '~/api/CourseContentsApi';
import DiscussionsApi from '~/api/DiscussionsApi';
import PartsApi from '~/api/PartsApi';
import Edit from '~/pages/books/edit';
import {
  T_Access,
  T_Book,
  T_ClassGroup,
  T_CourseContent,
  T_StudentSelfAssessment,
} from '~/types/serializers';
import T_AncestryPart from '~/types/T_AncestryPart';
import { userHasAccessToSection } from '~/utils/userHasAccessToSection';

import { TypographyMuted } from '../typography/muted';
import { SidebarButton } from './sidebar-button';
import { SidebarMenuWrapper } from './sidebar-menu-wrapper';

type Props = {
  access: T_Access;
  book: T_Book;
  chapter?: T_AncestryPart;
  courseContent?: T_CourseContent;
  studentClassGroup?: T_ClassGroup;
  studentSelfAssessments?: T_StudentSelfAssessment[];
};

export function SidebarCourseContentMenu(props: Props) {
  const { t } = useTranslation();

  const { access, book, chapter, courseContent, studentClassGroup, studentSelfAssessments } = props;

  const { component } = usePage();

  return (
    <>
      <SidebarMenuWrapper className="mb-6">
        {/* <SidebarButton
          data-testid="chapter-flashcards-link"
          active={component === 'parts/flashcards'}
          href={PartsApi.flashcards.path({ slug: chapter?.slug })}
          icon={RectangleStack}
          title={t('common.sidebar.courseContentOptions.flashcards')}
        /> */}

        {(!access.student || (access.student && studentClassGroup)) && (
          <SidebarButton
            disabled={!courseContent}
            data-testid="chapter-discussions-link"
            active={
              component === 'discussions/show' ||
              component === 'discussions/index' ||
              component === 'discussions/new'
            }
            href={DiscussionsApi.index.path({ course_content_slug: courseContent?.slug })}
            icon={ChatBubbleLeftRight}
            title={t('models.discussion.many')}
          />
        )}

        <SidebarButton
          data-testid="chapter-discussions-link"
          active={component === 'parts/show'}
          href={PartsApi.show.path({ slug: chapter?.slug })}
          icon={ChartBarSquare}
          title={
            access.student
              ? t('common.sidebar.courseContentOptions.studyProgress')
              : t('common.sidebar.courseContentOptions.groupAnalytics')
          }
        />
      </SidebarMenuWrapper>

      <div className="">
        <Link
          className="hover:underline text-xs font-medium"
          href={BooksApi.show.path({ slug: book.slug })}>
          {t('common.sidebar.courseContentOptions.backToBook')}
        </Link>

        <TypographyMuted>
          {t('models.nestedPart.one')} {t('common.nouns.content')}
        </TypographyMuted>
      </div>

      <SidebarMenuWrapper className="border-t mt-0.5 pt-1 mb-2 overflow-y-auto">
        {(chapter.sections ?? []).map((section) => {
          const active =
            component === 'course-contents/show' &&
            section.id === courseContent?.courseContentable.id;

          return (
            <SidebarButton
              key={section.id}
              data-testid={`section-link-${section.slug}`}
              active={active}
              disabled={!userHasAccessToSection(chapter, section, access, studentSelfAssessments)}
              href={CourseContentsApi.show.path({ slug: section.slug })}
              linkToEditor={!access.student}
              editorHref={CourseContentsApi.editor.path({ slug: section.slug })}
              editorIcon={PencilSquare}
              title={section.numberedTitle}
            />
          );
        })}
      </SidebarMenuWrapper>
    </>
  );
}
