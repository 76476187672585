import { router } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FlashcardsApi from '~/api/FlashcardsApi';
import { TypographyMuted } from '~/components/typography/muted';
import { T_Book, T_Flashcard } from '~/types/serializers';

import { Flashcard } from './flashcard-item';
import { Navigation } from './navigation';

type Props = {
  flashcards: T_Flashcard[];
  book: T_Book;
};

export function FlashcardsDueList(props: Props) {
  const { t } = useTranslation();

  const { book, flashcards } = props;

  const flashcardCount = flashcards.length;

  if (!flashcards.length) {
    return (
      <div className="mt-5">
        <TypographyMuted>{t('pages.flashcardsIndex.text.reviewedAll')}</TypographyMuted>
      </div>
    );
  }

  return (
    <div className="h-full relative pt-16">
      <Flashcard flashcard={flashcards[0]} />

      <Navigation handleReview={handleReview} flashcardCount={flashcardCount} />
    </div>
  );

  function handleReview(remembered: boolean) {
    router.post(FlashcardsApi.review.path({ book_slug: book.slug, id: flashcards[0].id }), {
      review: { remembered },
    });
  }
}
