import { Link } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BusinessApi from '~/api/Website/BusinessApi';
import { Card, CardContent, CardHeader } from '~/components/ui/card';
import { Footer } from '~/pages/website/components/footer';
import { HeaderBusiness } from '~/pages/website/components/header-business';
import { NewsletterSubscription } from '~/pages/website/components/newsletter-subscription';

import { useTrainingsIndexPage } from './T_TutorialsIndex';

export default function Index() {
  const { t } = useTranslation();

  const { trainings } = useTrainingsIndexPage().props;

  return (
    <>
      <HeaderBusiness />

      <div className="bg-white">
        <div className="mx-auto max-w-3xl px-6 py-10 lg:px-8">
          <div className="mx-auto max-w-4xl mb-6">
            <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">
              {t('website.trainings.title')}
            </h2>

            <h3>
              ({t('website.trainings.description')}{' '}
              <a className="text-green-500 underline" href="mailto:info@brightbook.io">
                info@brightbook.io
              </a>
              )
            </h3>
          </div>

          <div className="space-y-4">
            {trainings.map((training) => (
              <Card key={training.id}>
                <CardHeader>
                  <h3 className="text-lg font-semibold leading-6 text-gray-900">
                    {training.title}
                  </h3>
                </CardHeader>
                <CardContent>
                  <div className="text-sm text-justify text-stone-600">{training.description}</div>

                  <div className="text-sm text-stone-700 mt-4">
                    <span className="font-semibold">{t('website.trainings.trainer')}: </span>{' '}
                    {training.trainer}
                  </div>

                  <div className="text-sm text-stone-700 mt-1">
                    <span className="font-semibold">{t('website.trainings.duration')}: </span>{' '}
                    {training.duration}
                  </div>

                  <div className="text-sm text-stone-700 mt-1">
                    <span className="font-semibold">{t('website.trainings.online')}: </span>{' '}
                    {training.online ? t('website.trainings.yes') : t('website.trainings.no')}
                  </div>

                  <div className="text-sm text-stone-700 mt-1">
                    <span className="font-semibold">{t('website.trainings.date')}: </span>{' '}
                    {t('website.trainings.date2')}
                  </div>

                  <div className="mt-2">
                    <Link
                      href={BusinessApi.training.path({ id: training.id })}
                      className="font-medium text-green-500 hover:underline">
                      {t('website.trainings.register')}
                    </Link>
                  </div>
                </CardContent>
              </Card>
            ))}

            <Card>
              <CardHeader>
                <h3 className="text-lg font-semibold leading-6 text-gray-900">
                  {t('website.trainings.tailoredTrainingTitle')}
                </h3>
              </CardHeader>
              <CardContent>
                <div className="text-sm text-stone-600">
                  {t('website.trainings.tailoredTrainingDescription1')}{' '}
                  <a href="mailto:info@brightbook.io" className="text-green-500 hover:underline">
                    {t('website.trainings.callToAction')}
                  </a>
                  {t('website.trainings.tailoredTrainingDescription2')}
                </div>

                <div className="mt-2"></div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>

      <NewsletterSubscription dark />

      <Footer />
    </>
  );
}

Index.layout = (page) => page;
