import { useTranslation } from 'react-i18next';

import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../ui/alert-dialog';

type Props = {
  destructiveAction: () => void;
  message: string;
};

export function DialogConfirmDestructiveAction(props: Props) {
  const { t } = useTranslation();

  const { destructiveAction, message } = props;

  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>{t('common.dialogs.confirmAction.title')}</AlertDialogTitle>
        <AlertDialogDescription>{message}</AlertDialogDescription>
      </AlertDialogHeader>

      <AlertDialogFooter>
        <AlertDialogCancel>{t('common.buttons.cancel')}</AlertDialogCancel>
        <AlertDialogAction
          data-testid="confirm-destructive-action-button"
          onClick={destructiveAction}>
          {t('common.buttons.continue')}
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
}
