import React from 'react';

type Props = {
  children: React.ReactNode;
  gifSrc: string;
};

export function GifSectionLight({ children, gifSrc }: Props) {
  return (
    <section>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-5xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-16 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div>{children}</div>

            <div className="lg:pr-4">
              <img className="h-full w-full border rounded-xl shadow-lg" src={gifSrc} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export const GifSectionLightTitle = ({ children }) => (
  <h1 className="mt-2 text-3xl font-bold tracking-tight text-neutral-900 sm:text-4xl">
    {children}{' '}
  </h1>
);

export const GifSectionLightContent = ({ children }) => (
  <div className="max-w-xl text-neutral-700">{children}</div>
);

export const GifSectionLightParagraph = ({ children }) => <p className="mt-4">{children}</p>;

export const GifSectionLightCallout = ({ children }) => (
  <p className="text-base font-semibold leading-7 text-green-400">{children}</p>
);
