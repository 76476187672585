import { router } from '@inertiajs/react';
import { DropdownMenu } from '@radix-ui/react-dropdown-menu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EllipsisHorizontal } from 'styled-icons/heroicons-outline';

import EnrichmentsApi from '~/api/EnrichmentsApi';
import { Button } from '~/components/ui/button';
import { Dialog, DialogContent, DialogTrigger } from '~/components/ui/dialog';
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import { T_Enrichment } from '~/types/serializers';

import { useViewBookContext } from '../hooks/useViewBookContext';
import { EditEnrichmentForm } from './edit-enrichment-form';

type Props = {
  enrichment: T_Enrichment;
};

export function EnrichmentOptionsButton(props: Props) {
  const { t } = useTranslation();

  const { courseContent } = useViewBookContext();

  const { enrichment } = props;

  return (
    <Dialog>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button data-testid="enrichment-options-button" size="sm" variant="ghost">
            <EllipsisHorizontal className="h-4 w-4 text-gray-600" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>{t('common.nouns.actions')}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DialogTrigger asChild>
            <DropdownMenuItem data-testid="update-enrichment-button">
              {t('common.buttons.edit')}
            </DropdownMenuItem>
          </DialogTrigger>
          <DropdownMenuItem onClick={deleteEnrichment} data-testid="delete-enrichment-button">
            {t('common.buttons.remove')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <DialogContent>
        <EditEnrichmentForm enrichment={enrichment} />
      </DialogContent>
    </Dialog>
  );

  function deleteEnrichment() {
    if (confirm(t('pages.courseContentsShow.text.confirmEnrichmentRemoval'))) {
      router.delete(
        EnrichmentsApi.destroy.path({ course_content_slug: courseContent.slug, id: enrichment.id }),
      );
    }
  }
}
