import React from 'react';

import { T_Table } from '~/types/node/T_Table';

import { Row } from './Row';

type Props = {
  node: T_Table['ViewNode'];
};

export function Table(props: Props) {
  const { node } = props;

  return (
    <table
      data-testid={node.type}
      className="text-sm table table-fixed break-inside-avoid bg-white w-full border border-gray-400 border-collapse [&_th]:border [&_th]:border-gray-400 [&_td]:border [&_td]:border-gray-400 my-2">
      <tbody>
        {node.children.map((node, index) => {
          return <Row key={index} node={node} />;
        })}
      </tbody>
    </table>
  );
}
