import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Translation } from 'react-i18next';

import { Badge } from '~/components/ui/badge';
import { DataTableColumnHeader } from '~/components/ui/data-table-column-header';
import { T_Collaboration } from '~/types/serializers';

import { CollaborationOptions } from './components/collaboration-options';

export const columns: ColumnDef<T_Collaboration>[] = [
  {
    accessorKey: 'user.firstName',
    header: ({ column }) => (
      <Translation>
        {(t) => (
          <DataTableColumnHeader
            className="pl-4"
            column={column}
            title={t('pages.collaborationsIndex.table.columns.firstName')}
          />
        )}
      </Translation>
    ),
    cell: ({ cell }: CellContext<T_Collaboration, string>) => (
      <div className="w-[100px] pl-4 font-medium">{cell.getValue()}</div>
    ),
  },
  {
    accessorKey: 'user.lastName',
    header: ({ column }) => (
      <Translation>
        {(t) => (
          <DataTableColumnHeader
            column={column}
            title={t('pages.collaborationsIndex.table.columns.lastName')}
          />
        )}
      </Translation>
    ),
    cell: ({ cell }: CellContext<T_Collaboration, string>) => (
      <div className="w-[100px] font-medium">{cell.getValue()}</div>
    ),
  },
  {
    accessorKey: 'kind',
    header: ({ column }) => (
      <Translation>
        {(t) => (
          <DataTableColumnHeader
            column={column}
            title={t('pages.collaborationsIndex.table.columns.type')}
          />
        )}
      </Translation>
    ),
    cell: ({ cell }: CellContext<T_Collaboration, string>) => (
      <Translation>
        {(t) => (
          <div className="text-right text-gray-600 max-w-[40px]">
            {
              <Badge variant="outline">
                {t(`pages.collaborationsIndex.table.cells.collaborationType_${cell.getValue()}`)}
              </Badge>
            }
          </div>
        )}
      </Translation>
    ),
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      return <CollaborationOptions collaboration={row.original} />;
    },
  },
];
