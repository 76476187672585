import React from 'react';

import { T_AnswerableOpenQuestion } from '~/types/node/exercise/answerables/open-question/T_AnswerableOpenQuestion';

import { useRemake } from '../../hooks/useRemake';
import { AnswerableControls } from '../../shared/AnswerableControls';
import { AnswerInput } from './AnswerInput';
import { SentInAnswers } from './SentInAnswers';
import { StandardAnswer } from './StandardAnswer';

type Props = {
  answerable: T_AnswerableOpenQuestion['ViewNode'];
  subExercise?: boolean;
};

export function OpenAnswer(props: Props) {
  const { answerable, subExercise } = props;

  const remakeOptions = useRemake(answerable);

  return (
    <>
      <AnswerInput {...remakeOptions} answerable={answerable} />

      <AnswerableControls<T_AnswerableOpenQuestion['ViewNode']>
        {...remakeOptions}
        subExercise={!!subExercise}
        answerable={answerable}
        sentInAnswersRender={SentInAnswers}
        standardAnswerRender={StandardAnswer}
      />
    </>
  );
}
