import React from 'react';

import { T_AudioFragmentSource } from '~/types/node/T_AudioFragmentSource';

type Props = {
  node: T_AudioFragmentSource['ViewNode'];
};

export function Source(props: Props) {
  const { node } = props;

  return (
    <audio className="w-full" controls src={node?.src}>
      Your browser does not support the
      <code>audio</code> element.
    </audio>
  );
}
