import React from 'react';
import { ChatBubbleBottomCenterText, Photo, VideoCamera } from 'styled-icons/heroicons-outline';

type Props = {
  kind: 'text' | 'youtube' | 'image';
};

export function TypeIcon(props: Props) {
  const { kind } = props;

  return <div>{renderIcon()}</div>;

  function renderIcon() {
    let Icon = null;

    switch (kind) {
      case 'text': {
        Icon = ChatBubbleBottomCenterText;
        break;
      }
      case 'youtube': {
        Icon = VideoCamera;
        break;
      }
      case 'image': {
        Icon = Photo;
        break;
      }
    }

    return <Icon className="h-5 text-pink-500 w-5 mr-2" />;
  }
}
