import { usePage } from '@inertiajs/react';

import { T_Sect1Element } from '~/types/nodes';
import {
  T_Access,
  T_Ancestry,
  T_Book,
  T_ClassGroup,
  T_CourseContent,
  T_CourseContentable,
} from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';

export const useDiscussionsNewPage = usePage<T_DiscussionsNewProps>;

export interface T_DiscussionsNewProps extends T_PageProps {
  access: T_Access;
  book: T_Book;
  bookContent: T_Sect1Element;
  courseContent: T_CourseContent;
  customSections: T_CourseContentable[];
  teacherClassGroups: T_ClassGroup[];
  parts: T_Ancestry[];
  studentClassGroup?: T_ClassGroup;
}
