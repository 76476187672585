import React from 'react';
import { useTranslation } from 'react-i18next';

import T_AncestryPart from '~/types/T_AncestryPart';

import { Section } from './section';

type Props = {
  nestedPart: T_AncestryPart;
  sections: any[];
};

export function Sections(props: Props) {
  const { t } = useTranslation();

  const { nestedPart, sections } = props;

  if (!sections || !sections.length) {
    return <div className="text-gray-700 text-sm py-2">{t('pages.booksShow.text.noSections')}</div>;
  }

  return (
    <div className="pl-4 space-y-1">
      {sections.map((section, index) => (
        <Section nestedPart={nestedPart} section={section} key={index} />
      ))}
    </div>
  );
}
