import { zodResolver } from '@hookform/resolvers/zod';
import { router } from '@inertiajs/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import ClassGroupsApi from '~/api/ClassGroupsApi';
import { Button } from '~/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import { T_Book } from '~/types/serializers';

const formSchema = z.object({
  name: z.string().min(2, {
    message: 'Naam moeten minstens 2 tekens bevatten.',
  }),
});

type Props = {
  book: T_Book;
  closeDialog: () => void;
};

export function NewGroupForm(props: Props) {
  const { t } = useTranslation();

  const { book, closeDialog } = props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('models.classGroup.name')}</FormLabel>
              <FormControl>
                <Input type="text" placeholder="ECO-MT 5" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button data-testid="create_class_group_button" type="submit">
          {t('common.buttons.add')}
        </Button>
      </form>
    </Form>
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.post(
      ClassGroupsApi.create.path({
        book_slug: book.slug,
      }),
      values,
      {
        replace: true,
        onError: (err) => {
          form.setError('name', {
            type: 'custom',
            message: `Naam ${err['name'][0]}`,
          });
        },
        onSuccess: () => {
          form.reset();
          closeDialog();
        },
      },
    );
  }
}
