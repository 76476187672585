import React, { useContext } from 'react';

import { T_NodeType } from '~/types/T_NodeTypes';

import { FillGaps } from './answerables/fill-gaps/FillGaps';
import { FillList } from './answerables/fill-list/FillList';
import { MarkImage } from './answerables/mark-image/MarkImage';
import { MarkOption } from './answerables/mark-option/MarkOption';
import { MultipleChoice } from './answerables/multiple-choice/MultipleChoice';
import { OpenAnswer } from './answerables/open-answer/OpenAnswer';
import { Oral } from './answerables/oral/Oral';
import { SubExerciseList } from './answerables/sub-exercises/SubExerciseList';

const components = {
  [T_NodeType.FILL_GAPS]: FillGaps,
  [T_NodeType.FILL_LIST]: FillList,
  [T_NodeType.MARK_OPTION]: MarkOption,
  [T_NodeType.MARK_IMAGE]: MarkImage,
  [T_NodeType.MULTIPLE_CHOICE]: MultipleChoice,
  [T_NodeType.OPEN_ANSWER]: OpenAnswer,
  [T_NodeType.ORAL]: Oral,
  [T_NodeType.SUB_EXERCISE_LIST]: SubExerciseList,
};

type Props = {
  answerable: any;
};

export function Answerable(props: Props) {
  const { answerable } = props;

  return renderAnswerable();

  function renderAnswerable() {
    if (!answerable) {
      return null;
    }

    const Component = components[answerable.type];

    return <Component answerable={answerable} />;
  }
}

const AnswerableNodeKeyContext = React.createContext<string>(null);

export const useAnswerableNodeKey = () => useContext(AnswerableNodeKeyContext);
