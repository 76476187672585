import React from 'react';

import { LayoutBook } from '~/components/ui-extended/layout-book';

import PageContent from './components/page-content';
import { useClassroomsShowPage } from './T_ClassroomsShow';

export default function Show() {
  const { access, book } = useClassroomsShowPage().props;

  return (
    <LayoutBook
      breadcrumbs={[
        {
          title: 'models.group.one',
          translateTitle: true,
          type: 'static',
        },
      ]}
      htmlHeadTitle={book.title}
      access={access}
      book={book}
      sidebarTitle={book.title}>
      <PageContent />
    </LayoutBook>
  );
}
