import { DownloadIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { T_File } from '~/types/node/T_File';
import { Element } from '~/views/book/components/Element';

import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_File['ViewNode'];
};

export function File(props: Props) {
  const { t } = useTranslation();

  const { node } = props;

  return (
    <Element node={node} title={t('common.nodes.file')}>
      <div className="px-3 py-2 rounded bg-white border">
        <a target="_blank" href={node.source.src} rel="noreferrer" className="flex items-center">
          <DownloadIcon className="h-4 w-4 mr-1.5" />

          <p className="text-sm font-medium text-gray-700">
            <NodeMap nodes={node.title.children} />
          </p>
        </a>
      </div>
    </Element>
  );
}
