import React from 'react';

import { LayoutBook } from '~/components/ui-extended/layout-book';

import { PageContent } from './components/page-content';
import { useAuthorsIndexPage } from './T_AuthorsIndex';

export default function Index() {
  const { access, book } = useAuthorsIndexPage().props;

  return (
    <LayoutBook
      breadcrumbs={[
        {
          title: 'common.breadcrumbs.collaborationsIndexPage',
          translateTitle: true,
          type: 'static',
        },
      ]}
      htmlHeadTitle={book.title}
      access={access}
      book={book}
      sidebarTitle={book.title}>
      <PageContent />
    </LayoutBook>
  );
}
