import clsx from 'clsx';
import React from 'react';
import { HandRaised as HandOutline } from 'styled-icons/heroicons-outline';
import { HandRaised as HandSolid } from 'styled-icons/heroicons-solid';

import { idFromNodeKey } from '~/lib/node-renderer/nodeHelpers';
import { T_AnswerableFillGapsGap } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGapsGap';
import { NodeMap } from '~/views/book/NodeMap';

import { useExerciseProps } from '../../context/ExercisePropsContext';

type Props = {
  node: T_AnswerableFillGapsGap['ViewNode'];
  possibleAnswers: Array<{ nodeKey: string; text: string }>;
  showAnswer: 'all' | 'none' | number;
  value: string;
  handleChange: (gapKey: string, answerKey: string) => void;
  activeGap: T_AnswerableFillGapsGap['ViewNode'] | null;
  setActiveGap: (gap: T_AnswerableFillGapsGap['ViewNode'] | null) => void;
  showSentInAnswers: boolean;
};

export function GapSelect(props: Props) {
  const {
    activeGap,
    handleChange,
    node,
    possibleAnswers,
    setActiveGap,
    showAnswer,
    showSentInAnswers,
    value,
  } = props;

  const { access } = useExerciseProps();

  const htmlId = idFromNodeKey(node.nodeKey);

  const showAnswerForCurrentGap =
    showAnswer === 'all' || (typeof showAnswer === 'number' && showAnswer >= node.number);

  const active = node.nodeKey === activeGap?.nodeKey;

  const answerIsCorrect = value === node?.nodeKey;

  return (
    <div className="inline-block mb-2">
      <div className="flex items-center">
        {showAnswerForCurrentGap && !answerIsCorrect && (
          <div
            data-testid="fill-gaps-correct-answer"
            className="bg-green-300 text-sm mx-1 rounded px-1">
            <NodeMap nodes={node.children} />
          </div>
        )}

        <div className="inline-flex rounded-md shadow-sm">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <select
              className={clsx(
                'focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md sm:text-sm border-gray-300 py-[0.04rem]',
                showSentInAnswers && !access.student && 'rounded-r-none',
                !!showAnswerForCurrentGap && !!answerIsCorrect && 'border-green-300',
                !!showAnswerForCurrentGap && !answerIsCorrect && 'border-red-300',
              )}
              data-testid="fill-gaps-gap-input"
              name={`fill-gaps-input-${node.number}`}
              value={value || 'default'}
              onChange={selectAnswer}
              id={htmlId}>
              <option value="default">...</option>

              {possibleAnswers
                .sort((a, b) => a.text.localeCompare(b.text))
                .map((answer, index) => {
                  return (
                    <option key={index} value={answer.nodeKey}>
                      {answer.text}
                    </option>
                  );
                })}
            </select>
          </div>

          {showSentInAnswers && !access.student && (
            <button
              className="-ml-px relative inline-flex items-center space-x-2 px-1 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 py-[0.04rem]"
              data-testid={`select-gap-button-${node.number}`}
              onClick={() => setActiveGap(node)}>
              {active ? (
                <HandSolid className="w-4 h-4 text-gray-600" />
              ) : (
                <HandOutline className="w-4 h-4 text-gray-400" />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  function selectAnswer(event) {
    const answerNodeKey = event.target.value;

    handleChange(node.nodeKey, answerNodeKey);
  }
}
