import { router } from '@inertiajs/react';
import { usePage } from '@inertiajs/react';
import { Cog } from '@styled-icons/open-iconic/Cog';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BooksApi from '~/api/BooksApi';
import { Button } from '~/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
export default function BookItem(props) {
  const { t } = useTranslation();

  const { user } = usePage<any>().props;
  const { book } = props;

  return (
    <li
      data-testid={`book_${book.slug}`}
      key={book.id}
      className="flex mx-1.5 my-2 flex-col text-center bg-white rounded-lg shadow w-40 relative">
      {user.role !== 'student' && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="secondary"
              size="icon"
              data-testid="book-options-button"
              className="absolute top-2 text-stone-700 right-2 p-0 h-6 w-6">
              <Cog className="w-4 h-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuLabel>{t('pages.booksIndex.menus.bookOptions.title')}</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem data-testid="archive-book-button" onClick={handleArchivation}>
              {t('pages.booksIndex.menus.bookOptions.buttons.archiveBook')}
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => router.patch(BooksApi.duplicate.path({ slug: book.slug }))}>
              {t('pages.booksIndex.menus.bookOptions.buttons.duplicateBook')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}

      <div
        className="flex flex-col flex-1 overflow-hidden rounded-lg cursor-pointer"
        onClick={navigateToBook}>
        <div className="h-56">
          {book.coverUrl ? (
            <img className="object-fill w-full h-full" src={book.coverUrl} />
          ) : (
            <div className="flex items-center justify-center h-56 p-2 text-6xl font-semibold text-white bg-gray-700">
              {book.title[0]}
            </div>
          )}
        </div>

        <div className="p-3 truncate">
          <h3 data-tip={book.title} className="text-sm font-medium text-gray-700 truncate ">
            {book.title}
          </h3>
        </div>
      </div>
    </li>
  );

  function handleArchivation() {
    if (confirm(t('pages.booksIndex.confirmations.archiveBook'))) {
      router.patch(BooksApi.archive.path({ slug: book.slug }));
    }
  }

  function navigateToBook() {
    router.visit(BooksApi.show.path({ slug: book.slug }));
  }
}
