import React from 'react';

import { Footer } from '../../components/footer';
import { HeaderEducation } from '../../components/header-education';

export default function Support() {
  return (
    <>
      <HeaderEducation />
      <div className="bg-white pt-10 px-6 pb-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Klaar voor een nieuwe uitdaging?
          </h1>
          <div className="mt-16 max-w-3xl">
            <h2 className="text-2xl font-bold tracking-tight text-gray-700">Wie zijn we?</h2>
            <p className="mt-4">
              Brightbook is een publicatieplatform voor educatief cursusmateriaal, bestaande uit een
              eigen editor en een interactieve leeromgeving. We bieden daarmee een antwoord op de
              vraag naar moderne onderwijsvormen in zowel scholen als universiteiten als bij
              learning & development professionals.
            </p>
            <p className="mt-4">
              Ons kantoor bevindt zich in het historische Brugge waar we met een klein maar
              toegewijd team ons best doen om zowel leerkrachten als studenten van betere educatieve
              tools te voorzien.
            </p>
            <p className="mt-4">
              Gezien we aan een gestaag tempo blijven groeien, zijn wij op zoek naar meerdere
              developers die ons team willen komen versterken. Groeien betekent immers nieuwe en
              interessante uitdagingen om te tackelen en de mogelijkheid om een betekenisvol
              verschil te maken voor talloze leerkrachten en studenten.
            </p>
          </div>
          <div className="mt-16 max-w-3xl">
            <h2 className="text-2xl font-bold tracking-tight text-gray-700">Wie zoeken we?</h2>
            <p className="mt-4">
              We zijn op zoek naar getalenteerde developers met ervaring in React en het moderne
              JavaScript ecosysteem (Typescript, Plate, Vite, …). Daarnaast is het belangrijk dat je
              ook niet vies bent van wat backend werk (Ruby/Rails in ons geval). Gezien we met een
              klein team werken draagt iedereen verschillende hoedjes en is kruisbestuiving
              noodzakelijk.
            </p>
            <p className="mt-4">
              We hechten wel meer waarde aan ingesteldheid en leergierigheid dan ervaring in een
              bepaalde stack. Als je dus vooral gewerkt hebt met andere web-technologieën, zoals
              Vue, Svelte, Django, Node, …, ben je zeker ook welkom voor een babbel!
            </p>
            <p className="mt-4">
              Het is ook belangrijk te vermelden dat we, gezien we actief zijn op de Belgische en
              Nederlandse markt, iemand zoeken die vloeiend Nederlands praat of op zijn minst de
              taal aan het leren is.
            </p>
          </div>
          <div className="mt-16 max-w-3xl">
            <h2 className="text-2xl font-bold tracking-tight text-gray-700">Wat bieden we aan?</h2>
            <p className="mt-4">
              Het spreekt voor zich dat je een marktconform loon mag verwachten met tal van
              extralegale voordelen. Daarnaast bieden we ook een mix aan van remote werk en werken
              vanuit ons kantoor in Brugge. We zorgen ook dat je voorzien wordt van het beste
              materiaal (Macbook Pro, software, …).
            </p>
            <p className="mt-4">
              Als je zelf met vragen of wensen zit, proberen we hier ook aan tegemoet te komen. Het
              belangrijkste voor ons is dat je je goed voelt op je plek en je werk met plezier en
              motivatie kan uitvoeren.
            </p>
            <p className="mt-4">
              Ben je geinteresseerd? Stuur ons een mailtje op{' '}
              <span>
                <a className="text-green-500 underline" href="mailto:info@brightbook.io">
                  info@brightbook.io
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

Support.layout = (page) => page;
