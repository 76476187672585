import React from 'react';

import { nodeIsEmpty } from '~/lib/node-renderer/nodeHelpers';
import { T_TextMultiLine } from '~/types/node/T_TextMultiLine';
import { NodeMap } from '~/views/print/NodeMap';

type Props = {
  node: T_TextMultiLine['ViewNode'];
};

export function Feedback(props: Props) {
  const { node } = props;

  const isEmpty = nodeIsEmpty(node);

  if (isEmpty) return null;

  return (
    <div className="text-gray-500 italic [&>p]:text-[rgba(107, 114, 128)]">
      <NodeMap nodes={node.children} />
    </div>
  );
}
