export function handleQuerySort(queryString) {
  const sortByValue = queryString.parse(location.search)['sort_by'] as string;

  if (!sortByValue) return [];

  return [formatSortBy(sortByValue)];
}

function formatSortBy(value) {
  const valueSplit = value.split('_');

  const id = valueSplit.slice(0, -1).join('_');
  const direction = valueSplit.slice(-1)[0];

  const desc = direction === 'DESC';

  return { id, desc };
}
