import React from 'react';
import { useTranslation } from 'react-i18next';

import { T_Definition } from '~/types/node/T_Definition';

import { Gutter } from '../../components/Gutter';
import { Concept } from './Concept';
import { Explanation } from './Explanation';

type Props = {
  node: T_Definition['ViewNode'];
};

export function Definition(props: Props) {
  const { t } = useTranslation();

  const { node } = props;

  return (
    <div
      data-testid={node.type}
      className="bg-yellow-50 box-decoration-clone break-inside-avoid p-4 relative mt-2 mb-4 rounded">
      <div className="text-xs text-gray-500">
        <Gutter content={t('common.nodes.definition')} />
      </div>

      <Concept node={node.concept} />
      <Explanation node={node.explanation} />
    </div>
  );
}
