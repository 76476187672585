import React from 'react';

import { useTheme } from '~/components/theme-provider';

import { Gutter } from '../components/Gutter';
import { Section } from './Section';

type Props = {
  partNested: any;
};

export function PartNested(props: Props) {
  const { partNested } = props;

  const { colors } = useTheme();

  const { sections } = partNested;

  return (
    <div className="part-content-page">
      <h1 style={{ color: colors.chapter }} className="text-2xl font-bold text-gray-700">
        <Gutter content={partNested.number} />
        {partNested.title}
      </h1>

      {sections.map((section, index) => (
        <Section section={section} key={index} />
      ))}
    </div>
  );
}
