// JsFromRoutes CacheKey be26eb92dfca727b74e2e72e0e2eca41
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/users/password/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/users/password/edit'),
  update: /* #__PURE__ */ definePathHelper('patch', '/users/password'),
  create: /* #__PURE__ */ definePathHelper('post', '/users/password'),
}
