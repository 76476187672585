import { router } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChatBubbleLeftRight } from 'styled-icons/heroicons-outline';

import DiscussionsApi from '~/api/DiscussionsApi';
import { Button } from '~/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';

import { useViewBookContext } from '../hooks/useViewBookContext';

type Props = {
  nodeKey: string;
};

export function AddDiscussionButton({ nodeKey }: Props) {
  const { t } = useTranslation();

  const { courseContent, studentClassGroup, teachingClassGroup } = useViewBookContext();

  const classGroup = studentClassGroup || teachingClassGroup;
  const disabled = !classGroup;

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={navigateToNewDiscussion}
            disabled={disabled}
            data-testid="create_node_discussion_button"
            size="sm"
            variant="ghost">
            <ChatBubbleLeftRight className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t('pages.courseContentsShow.text.addDiscussionTooltip')}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );

  function navigateToNewDiscussion() {
    router.get(DiscussionsApi.new.path({ course_content_slug: courseContent.slug }), { nodeKey });
  }
}
