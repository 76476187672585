import React from 'react';

import { T_ListOrdered } from '~/types/node/T_ListOrdered';
import { cn } from '~/utils';
import { NodeMap } from '~/views/print/NodeMap';

type Props = {
  nested?: boolean;
  node: T_ListOrdered['ViewNode'];
};

export function OrderedList(props: Props) {
  const { nested, node } = props;

  return (
    <ol
      data-testid={node.type}
      className={cn('pl-6 list-decimal break-inside-avoid', !nested && 'mb-1')}>
      <NodeMap nodes={node.children} />
    </ol>
  );
}
