import React from 'react';

import { Iframe } from '~/components/pages-shared/iframe';
import { T_Media } from '~/types/node/T_Media';

type Props = {
  node: T_Media['ViewNode'];
};

export function Source(props: Props) {
  const { node } = props;

  return <Iframe node={node} />;
}
