import React from 'react';
import { useTranslation } from 'react-i18next';

import { T_WordList } from '~/types/node/T_WordList';

import { Element } from '../../components/Element';
import { WordItem } from './WordItem';

type Props = {
  node: T_WordList['ViewNode'];
};

export function WordList(props: Props) {
  const { t } = useTranslation();

  const { node } = props;

  return (
    <Element node={node} title={t('common.nodes.wordList')}>
      <table data-testid={`${node.type} ${node.nodeKey}`} className="min-w-full p-1 mb-2">
        <tbody className="divide-y divide-gray-300">
          {node.items.map((item, index) => (
            <WordItem index={index} key={item.nodeKey} node={item} />
          ))}
        </tbody>
      </table>
    </Element>
  );
}
