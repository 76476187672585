import { usePage } from '@inertiajs/react';

import { T_Access, T_Book, T_Collaboration } from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';
import { T_PagyMetadata } from '~/types/T_PagyMetadata';

export const useAuthorsIndexPage = usePage<T_AuthorIndex>;

export interface T_AuthorIndex extends T_PageProps {
  access: T_Access;
  book: T_Book;
  collaborations: T_Collaboration[];
  pagyMetadata: T_PagyMetadata;
}
