import shuffle from 'lodash/shuffle';
import React from 'react';

type Props = {
  gaps: any[];
};

export function PossibleAnswers(props: Props) {
  const { gaps } = props;

  return (
    <div className="break-inside-avoid break-before-avoid flex flex-row flex-wrap">
      {renderPossibleAnswers()}
    </div>
  );

  function renderPossibleAnswers() {
    return gaps.map((gap, index) => {
      const { text } = gap.children[0];

      const possibleAnswers = shuffle([...gap.extraOptions, text]);

      return (
        <>
          <span className="text-[10pt] text-gray-500 italic mr-[3mm]" key={index}>
            <span className="text-gray-500">
              {gap.number}
              {') '}
            </span>

            {possibleAnswers.join(' - ')}
          </span>
        </>
      );
    });
  }
}
