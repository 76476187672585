import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '~/components/layout/page-container';
import { Card, CardHeader, CardTitle } from '~/components/ui/card';

import { ChangePasswordForm } from './change-password-form';

export default function Edit() {
  const { t } = useTranslation();

  return (
    <PageContainer htmlHeadTitle={t('pages.registrationsEdit.htmlHeadTitle')}>
      <div className="flex min-h-full flex-1 flex-col py-12 sm:px-6 lg:px-8">
        <Card className="my-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <CardHeader>
            <CardTitle className="text-xl">{t('pages.registrationsEdit.title')}</CardTitle>
          </CardHeader>
          <ChangePasswordForm />
        </Card>
      </div>
    </PageContainer>
  );
}
