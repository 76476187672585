import clsx from 'clsx';
import React from 'react';
import { HandRaised as HandOutline } from 'styled-icons/heroicons-outline';
import { HandRaised as HandSolid } from 'styled-icons/heroicons-solid';

import { T_AnswerableFillList } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillList';
import { T_AnswerableFillListItemAnswer } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillListItemAnswer';

import { useSentInAnswerProps } from '../../context/AnswerablePropsContext';
import { useExerciseProps } from '../../context/ExercisePropsContext';

type Props = {
  active: boolean;
  answerable: T_AnswerableFillList['ViewNode'];
  handleChange: (key: string, value: string) => void;
  itemNodeKey: string;
  node: T_AnswerableFillListItemAnswer['ViewNode'];
  number: number;
  setActiveItem: () => void;
  showAnswer: boolean;
  userAnswer: string;
};

export function AnswerTextInput(props: Props) {
  const { active, handleChange, itemNodeKey, node, number, setActiveItem, showAnswer, userAnswer } =
    props;

  const { access } = useExerciseProps();
  const { showSentInAnswers } = useSentInAnswerProps();

  return (
    <div className="w-full">
      <div className="mt-1 flex rounded-md shadow-sm">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <input
            className={clsx(
              'focus:ring-indigo-500 focus:border-indigo-500 block w-full text-blue-800 font-medium py-1 px-2 rounded-none rounded-md sm:text-sm border-gray-300',
              showSentInAnswers && !access.student && 'rounded-r-none',
            )}
            data-testid={`fill-list-item-text-input-${number}`}
            value={userAnswer ?? ''}
            onChange={(event) => handleChange(itemNodeKey, event.target.value)}
            type="text"
          />
        </div>

        {showSentInAnswers && !access.student && (
          <button
            className="-ml-px relative inline-flex items-center space-x-2 py-1 px-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            onClick={setActiveItem}
            data-tip="Toon leerling-antwoorden voor dit item"
            data-testid={`fill-list-text-active-button-${number}`}
            type="button">
            {active ? (
              <HandSolid className="w-4 h-4 text-gray-600" />
            ) : (
              <HandOutline className="w-4 h-4 text-gray-400" />
            )}
          </button>
        )}
      </div>

      {showAnswer && (
        <div data-testid="fill-list-correct-answer" className="text-green-600 text-sm pl-1 pt-1">
          {node.text}
        </div>
      )}
    </div>
  );
}
