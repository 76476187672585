import React from 'react';

import BusinessApi from '~/api/Website/BusinessApi';
import EducationApi from '~/api/Website/EducationApi';
import { buttonVariants } from '~/components/ui/button';

import { BrightbookLogo } from '../../components/brightbook-logo';

export default function Select() {
  return (
    <div className="w-full flex flex-col flex-1 h-full items-center justify-center">
      <div className="flex items-center space-x-1 mb-4">
        <BrightbookLogo className="w-10 h-10" />

        <h1 className="font-semibold text-xl">Brightbook</h1>
      </div>

      <p className="text-lg font-medium">Welcome!</p>
      <p>Please select your environment:</p>

      <div className="flex space-x-4 mt-4">
        <a
          data-testid="business-link"
          className={buttonVariants({ variant: 'default' })}
          href={routeHelper('business', BusinessApi.home.path())}>
          Brightbook for Business
        </a>
        <a
          data-testid="education-link"
          className={buttonVariants({ variant: 'default' })}
          href={routeHelper('education', EducationApi.home.path())}>
          Brightbook for Education
        </a>
      </div>
    </div>
  );

  function routeHelper(subdomain: string, path: string) {
    if (window.location.host.endsWith('3000')) {
      return 'http://' + subdomain + '.' + window.location.host + path;
    }

    return 'https://' + subdomain + '.' + window.location.host + path;
  }
}

Select.layout = (page) => page;
