import { Head } from '@inertiajs/react';
import React, { ReactNode, useCallback } from 'react';

type Props = {
  children: ReactNode;
  htmlHeadTitle: string;
  htmlHeadMeta?: Record<string, string>;
};

export function PageContainer(props: Props) {
  const { children, htmlHeadMeta, htmlHeadTitle } = props;

  const renderMeta = useCallback(() => {
    if (!htmlHeadMeta) return;

    return Object.entries(htmlHeadMeta).map(([name, content], index) => (
      <meta key={index} name={name} content={content} />
    ));
  }, []);

  return (
    <React.Fragment>
      <Head>
        <title>{htmlHeadTitle}</title>

        {renderMeta()}
      </Head>
      {children}
    </React.Fragment>
  );
}
