import { CheckIcon } from '@radix-ui/react-icons';
import { useTranslation } from 'react-i18next';

export function Features({ features }) {
  const { t } = useTranslation();

  return (
    <div className="bg-neutral-800 py-16 sm:py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-base font-semibold leading-7 text-green-400">
            {t('website.home.featureSubtitle')}
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            {t('website.home.featureTitle')}
          </p>
        </div>
        <dl className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-4 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
          {features.map((feature, index) => (
            <div key={index} className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <CheckIcon
                  className="absolute left-1 top-1 h-5 w-5 text-green-500"
                  aria-hidden="true"
                />
                {t('website.home.features.' + feature)}
              </dt>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
