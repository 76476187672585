import React from 'react';

import { T_LayoutImageText } from '~/types/node/T_LayoutImageText';

import { Content } from './Content';
import { Image } from './Image';

type Props = {
  node: T_LayoutImageText['ViewNode'];
};

export function LayoutImageText(props: Props) {
  const { node } = props;

  const { image_position } = node;

  return (
    <div data-testid={`${node.type} ${node.nodeKey}`} className="relative flex flex-1 items-start">
      {renderChildren()}
    </div>
  );

  function renderChildren() {
    const children = [
      <Content key="content" node={node.content} />,
      <Image key="image" node={node.image} />,
    ];

    return image_position === 'right' ? children : children.reverse();
  }
}
