import React, { useContext } from 'react';

import { T_OrganizationTemplate } from '~/types/serializers';

const defaultTheme = {
  colors: {
    action: 'black',
    buttonHover: 'rgb(231,229,228)',
    chapter: 'black',
    section: 'black',
    headingOne: 'black',
    headingTwo: 'black',
    headingThree: 'black',
    headingFour: 'black',
    print: {
      pageBackground: '#347dbd',
    },
  },
};

const ThemeContext = React.createContext<T_ThemeProps>(defaultTheme);

type T_ThemeProps = {
  colors: {
    action: string;
    buttonHover: string;
    chapter: string;
    section: string;
    headingOne: string;
    headingTwo: string;
    headingThree: string;
    headingFour: string;
    print: {
      pageBackground: string;
    };
  };
};

type Props = {
  children: React.ReactNode;
  template?: T_OrganizationTemplate;
};

export function ThemeProvider({ children, template }: Props) {
  const theme = template
    ? {
        colors: {
          action: template.color1,
          buttonHover: template.color1 + '20',
          chapter: template.color1,
          section: template.color2,
          headingOne: 'black',
          headingTwo: 'black',
          headingThree: 'black',
          headingFour: template.color1,
          print: {
            pageBackground: template.color1,
          },
        },
      }
    : defaultTheme;

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
}

export function useTheme() {
  return useContext(ThemeContext);
}
