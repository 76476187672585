import React from 'react';
import { useTranslation } from 'react-i18next';

import { TypographyLarge } from '~/components/typography/large';
import { T_StudentSelfAssessment } from '~/types/serializers';
import { T_AncestrySection } from '~/types/T_AncestryPart';

import { AssessmentBadge } from './assessment-badge';

type Props = {
  section: T_AncestrySection;
  assessment: T_StudentSelfAssessment | null;
  exerciseCount: number;
  answeredExerciseCount: number;
};

export default function SectionItem(props: Props) {
  const { t } = useTranslation();

  const { answeredExerciseCount, assessment, exerciseCount, section } = props;

  return (
    <div className="border-b py-2">
      <div className="">
        <div className="w-full pt-1 flex justify-between">
          <TypographyLarge>{section.title}</TypographyLarge>

          <AssessmentBadge assessment={assessment} />
        </div>
        <div className="w-full pt-1 flex justify-between">
          <p className="text-sm text-gray-600">
            <span className="font-semibold">{answeredExerciseCount}</span> {t('common.text.ofThe')}{' '}
            <span className="font-semibold">{exerciseCount}</span>{' '}
            {t('pages.partsShow.text.exercisesAnswered')}
          </p>
        </div>
      </div>
    </div>
  );
}
