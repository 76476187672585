import { router } from '@inertiajs/react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';

import { Button } from './button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './tooltip';

interface DataTablePaginationProps<TData> {
  pagyMetadata: any;
  table: Table<TData>;
}

export function DataTablePagination<TData>({ pagyMetadata }: DataTablePaginationProps<TData>) {
  return (
    <div className="flex items-center justify-end px-2 mt-2">
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex w-[100px] items-center justify-center text-sm font-medium">
          Page {pagyMetadata.page} of {pagyMetadata.pages}
        </div>

        <div className="flex items-center space-x-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  className="hidden h-8 w-8 p-0 lg:flex"
                  onClick={() => router.get(pagyMetadata.first_url)}
                  disabled={!pagyMetadata.prev}>
                  <span className="sr-only">Go to first page</span>
                  <DoubleArrowLeftIcon className="h-4 w-4" />
                </Button>
              </TooltipTrigger>

              <TooltipContent>Ga to the first page</TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  className="h-8 w-8 p-0"
                  onClick={() => router.get(pagyMetadata.prev_url)}
                  disabled={!pagyMetadata.prev}>
                  <span className="sr-only">Go to previous page</span>
                  <ChevronLeftIcon className="h-4 w-4" />
                </Button>
              </TooltipTrigger>

              <TooltipContent>Ga to the previous page</TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  className="h-8 w-8 p-0"
                  onClick={() => router.get(pagyMetadata.next_url)}
                  disabled={!pagyMetadata.next}>
                  <span className="sr-only">Go to next page</span>
                  <ChevronRightIcon className="h-4 w-4" />
                </Button>
              </TooltipTrigger>

              <TooltipContent>Ga to the next page</TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  className="hidden h-8 w-8 p-0 lg:flex"
                  onClick={() => router.get(pagyMetadata.last_url)}
                  disabled={pagyMetadata.page === pagyMetadata.pages}>
                  <span className="sr-only">Go to last page</span>
                  <DoubleArrowRightIcon className="h-4 w-4" />
                </Button>
              </TooltipTrigger>

              <TooltipContent>Ga to the last page</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
  );
}
