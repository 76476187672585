import clsx from 'clsx';
import shuffle from 'lodash/shuffle';
import React from 'react';
import { HandRaised as HandOutline } from 'styled-icons/heroicons-outline';
import { HandRaised as HandSolid } from 'styled-icons/heroicons-solid';

import { T_AnswerableMarkOptionGap } from '~/types/node/exercise/answerables/mark-option/T_AnswerableMarkOptionGap';
import { NodeMap } from '~/views/book/NodeMap';

import { useExerciseProps } from '../../context/ExercisePropsContext';

type Props = {
  node: T_AnswerableMarkOptionGap['ViewNode'];
  showAnswer: 'all' | 'none' | number;
  userAnswer: string;
  handleChange: (gapKey: string, answerKey: string) => void;
  activeGap: T_AnswerableMarkOptionGap['ViewNode'] | null;
  setActiveGap: (gap: T_AnswerableMarkOptionGap['ViewNode'] | null) => void;
  showSentInAnswers: boolean;
};

export function GapSelect(props: Props) {
  const { activeGap, handleChange, node, setActiveGap, showAnswer, showSentInAnswers, userAnswer } =
    props;

  const { access } = useExerciseProps();

  const possibleAnswers = formatPossibleAnswers();

  const showAnswerForCurrentGap =
    showAnswer === 'all' || (typeof showAnswer === 'number' && showAnswer >= node.number);

  const active = node.nodeKey === activeGap?.nodeKey;

  const answerIsCorrect = userAnswer === node?.nodeKey;

  return (
    <div className="inline-block mb-2">
      <div className="flex items-center">
        {showAnswerForCurrentGap && !answerIsCorrect && (
          <div
            data-testid="mark-option-correct-answer"
            className="bg-green-300 text-sm mx-1 rounded px-1">
            <NodeMap nodes={node.children} />
          </div>
        )}

        <div className="inline-flex rounded-md shadow-sm">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <select
              className={clsx(
                'focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md sm:text-sm border-gray-300',
                showSentInAnswers && !access.student && 'rounded-r-none',
                !!showAnswerForCurrentGap && !!answerIsCorrect && 'border-green-300',
                !!showAnswerForCurrentGap && !answerIsCorrect && 'border-red-300',
              )}
              data-testid="fill-gaps-gap-input"
              name={`fill-gaps-input-${node.number}`}
              value={userAnswer || 'default'}
              onChange={selectAnswer}>
              <option value="default">...</option>

              {possibleAnswers.map((answer, index) => {
                return (
                  <option key={index} value={answer.nodeKey}>
                    {answer.text}
                  </option>
                );
              })}
            </select>
          </div>

          {showSentInAnswers && !access.student && (
            <button
              className="-ml-px relative py-[0.04rem] inline-flex items-center space-x-2 px-1 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              data-testid={`select-gap-button-${node.number}`}
              onClick={() => setActiveGap(node)}>
              {active ? (
                <HandSolid className="w-4 h-4 text-gray-600" />
              ) : (
                <HandOutline className="w-4 h-4 text-gray-400" />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  function formatPossibleAnswers() {
    const { text } = node.children[0];

    const possibleAnswers = (node.extraOptions || []).map((extraOption) => ({
      text: extraOption,
      nodeKey: extraOption,
    }));

    return shuffle([...possibleAnswers, { text, nodeKey: node.nodeKey }]);
  }

  function selectAnswer(event) {
    const answerNodeKey = event.target.value;

    handleChange(node.nodeKey, answerNodeKey);
  }
}
