import { router } from '@inertiajs/react';
import React, { useEffect, useMemo, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'styled-icons/heroicons-outline';

import CourseContentsApi from '~/api/CourseContentsApi';
import { Button } from '~/components/ui/button';

import { useCourseContentShowPage } from '../T_CourseContentShowProps';

export function PresentationNavigation() {
  const { bookContent, courseContent, focus } = useCourseContentShowPage().props;

  const previousButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const key = event.code;

      console.log('KEY', key);

      if (
        previousButtonRef &&
        previousButtonRef.current &&
        (key === 'ArrowLeft' || key === 'ArrowLeft' || key === 'PageUp')
      ) {
        previousButtonRef.current.click();
      }

      if (
        nextButtonRef &&
        nextButtonRef.current &&
        (key === 'ArrowRight' || key === 'ArrowRight' || key === 'PageDown')
      ) {
        nextButtonRef.current.click();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const collection = useMemo(
    () => bookContent.children.map((child) => child.nodeKey),
    [focus.anchor],
  );

  const activeIndex = useMemo(() => collection.indexOf(focus.anchor), [focus.anchor]);

  return (
    <div className="fixed z-50 p-3 ml-5 left-[50%] translate-x-[-50%]  bottom-0 space-x-4">
      <Button ref={previousButtonRef} disabled={activeIndex === 0} onClick={navigateToPrevious}>
        <ChevronLeft className="w-6 h-6" />
      </Button>
      <Button
        ref={nextButtonRef}
        disabled={activeIndex === collection.length - 1}
        onClick={navigateToNext}>
        <ChevronRight className="w-6 h-6" />
      </Button>
    </div>
  );

  function navigateToPrevious() {
    const nodeKey = collection[activeIndex - 1];

    const href = CourseContentsApi.show.path({
      slug: courseContent.slug,
      query: { focus: nodeKey },
    });

    router.visit(href, { preserveState: true });
  }

  function navigateToNext() {
    const nodeKey = collection[activeIndex + 1];

    const href = CourseContentsApi.show.path({
      slug: courseContent.slug,
      query: { focus: nodeKey },
    });

    router.visit(href, { preserveState: true });
  }
}
