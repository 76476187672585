import React from 'react';

import { T_NodeType } from '~/types/T_NodeTypes';
import { T_Extract } from '~/types/serializers';

import { ContentDefinition } from './content-definition';
import { ContentMarkedImage } from './content-marked-image';
import { ContentTranslation } from './content-translation';

type Props = {
  keyword: string;
  keywordData: T_Extract;
};

export function KeywordMarkerContent(props: Props) {
  const { keyword, keywordData } = props;

  return <>{renderKeywordContent()}</>;

  function renderKeywordContent() {
    switch (keywordData.node.type) {
      case T_NodeType.WORD_LIST: {
        return <ContentTranslation keyword={keyword} extract={keywordData} />;
      }
      case T_NodeType.DEFINITION: {
        return <ContentDefinition keyword={keyword} extract={keywordData} />;
      }
      case T_NodeType.MARKED_IMAGE: {
        return <ContentMarkedImage keyword={keyword} extract={keywordData} />;
      }
    }
  }
}
