import React from 'react';
import { useTranslation } from 'react-i18next';
import { Star } from 'styled-icons/heroicons-solid';

import { T_ExerciseDifficultyLevel } from '~/types/node/exercise/T_Exercise';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';

type Props = {
  difficulty_level: T_ExerciseDifficultyLevel;
  withTooltip?: boolean;
};

const difficultyLevelMap: Record<Props['difficulty_level'], number> = {
  easy: 1,
  medium: 2,
  hard: 3,
};

export function ExerciseDifficultyLevel(props: Props) {
  const { t } = useTranslation();

  const { difficulty_level, withTooltip } = props;

  if (!difficulty_level) return null;

  if (withTooltip) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <>
              {[...Array(difficultyLevelMap[difficulty_level])].map((_, index) => (
                <Star className="w-3 h-3 -mt-1 text-gray-300" key={index} />
              ))}
            </>
          </TooltipTrigger>
          <TooltipContent>
            <p>
              {t('pages.courseContentsEditor.text.exerciseDifficultyLevel')}:{' '}
              {t(`pages.courseContentsEditor.text.exerciseDifficultyLevel_${difficulty_level}`)}
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return (
    <>
      {[...Array(difficultyLevelMap[difficulty_level])].map((_, index) => (
        <Star className="w-3 h-3 -mt-1 text-gray-300" key={index} />
      ))}
    </>
  );
}
