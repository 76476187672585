import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { router } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PartsApi from '~/api/PartsApi';
import { cn } from '~/utils';

import { NestedParts } from './nested-parts';
import { TPart } from './page-content';
import { StructureItem } from './structure-item';

type Props = {
  parts: TPart[];
  bookSlug: string;
  isInDragAction: boolean;
};

export function Parts(props: Props) {
  const { t } = useTranslation();

  const { isInDragAction, parts } = props;

  return (
    <>
      {parts.length ? (
        <SortableContext items={parts.map((p) => p.slug)} strategy={verticalListSortingStrategy}>
          <div className={cn('mt-4 structure', isInDragAction && 'pending-drag-action')}>
            {parts.map((part) => (
              <StructureItem
                sortable={{ id: part.slug, data: { type: 'part' } }}
                meta={{
                  type: 'part',
                  title: part.title,
                  id: part.slug,
                  number: part.number,
                  i18nKey: 'part',
                }}
                paths={{
                  update: PartsApi.update.path({ slug: part.slug }),
                  delete: PartsApi.destroy.path({ slug: part.slug }),
                }}
                createItem={() => createPart(part.position + 1)}
                key={part.slug}>
                <NestedParts
                  part={part}
                  nestedParts={part.children}
                  bookSlug={props.bookSlug}
                  isInDragAction={isInDragAction}
                />
              </StructureItem>
            ))}
          </div>
        </SortableContext>
      ) : (
        <div className="text-xs mt-2 mb-1 text-gray-600 font-light">
          {t('pages.booksEditStructure.text.noPartsYet')}

          <button
            type="button"
            data-testid="create_first_part_button"
            onClick={() => createPart(0)}
            className="text-black ml-1 font-bold">
            {t('pages.booksEditStructure.buttons.addFirstPart')}
          </button>
        </div>
      )}
    </>
  );

  function createPart(position) {
    router.post(
      PartsApi.create.path(),
      {
        title: 'New part',
        book_id: props.bookSlug,
        position,
      },
      {
        preserveScroll: true,
      },
    );
  }
}
