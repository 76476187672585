import React from 'react';
import { useTranslation } from 'react-i18next';

import { T_AnswerableOral } from '~/types/node/exercise/answerables/oral/T_AnswerableOral';

import { useRemake } from '../../hooks/useRemake';
import { AnswerableControls } from '../../shared/AnswerableControls';

type Props = {
  answerable: T_AnswerableOral['ViewNode'];
  subExercise?: boolean;
};

export function Oral(props: Props) {
  const { t } = useTranslation();

  const { answerable, subExercise } = props;

  const remakeOptions = useRemake(answerable);

  return (
    <>
      <div className="pb-3 text-sm text-gray-500">
        {t('pages.courseContentsShow.nodes.exercise.oralExerciseExplanation')}
      </div>

      <AnswerableControls<T_AnswerableOral['ViewNode']>
        {...remakeOptions}
        subExercise={!!subExercise}
        answerable={answerable}
      />
    </>
  );
}
