import React from 'react';

import { PartNested } from './PartNested';

type Props = {
  part: any;
};

export function Part(props: Props) {
  const { part } = props;

  const { children } = part;

  const nestedPartNodes = children.filter(({ type }) => type === 'part');

  return (
    <React.Fragment>
      {nestedPartNodes.map((partNested, index) => (
        <PartNested partNested={partNested} key={index} />
      ))}
    </React.Fragment>
  );
}
