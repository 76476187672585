import React from 'react';

import { T_ListItemContent } from '~/types/node/T_ListItemContent';

import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_ListItemContent['ViewNode'];
};

export function ListItemContent(props: Props) {
  const { node } = props;

  return (
    <span className="text-gray-700 font-light">
      <NodeMap nodes={node.children} />
    </span>
  );
}
