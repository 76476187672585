import React from 'react';

import { cn } from '~/utils';

import { SidebarMenu } from './sidebar-menu';

type Props = {
  children: React.ReactNode;
  title: string;
  open: boolean;
};

export function SidebarDesktop(props: Props) {
  const { children, open, title } = props;

  return (
    <aside
      className={cn(
        'hidden lg:block relative z-[2] w-64 min-w-[16rem] bg-stone-100 transition-all duration-500',
        !open && '-ml-64',
      )}>
      <SidebarMenu title={title}>{children}</SidebarMenu>
    </aside>
  );
}
