import startCase from 'lodash/startCase';
import { Element } from 'slate';

import { T_NodeType, T_NodeTypeValue } from '~/types/T_NodeTypes';

export function nodeIsEmpty(node) {
  return (
    !node ||
    (node?.children?.length === 1 &&
      node?.children[0].type === T_NodeType.PARAGRAPH &&
      node?.children[0].children[0].text === '')
  );
}

export function testEnvironment() {
  return process.env.NODE_ENV === 'test';
}

export function idFromNodeKey(nodeKey) {
  return `NODE-${nodeKey}`;
}

export function pascalCase(string: string) {
  return startCase(string).replace(/\s+/g, '');
}

export function findChild<T>(node, childType: T_NodeTypeValue): T {
  return node.children.find((child) => child.type === childType);
}

export function findChildren<T>(parentNode, childType: T_NodeTypeValue): T[] {
  const parentNodes = [].concat(parentNode);
  let nodeList = [];

  parentNodes.forEach((parentNode) => {
    const childNodes = parentNode.children.filter((child) => child.type === childType);

    nodeList = nodeList.concat(childNodes);
  });

  return nodeList;
}

export function findDescendant<T>(node, descendantTypes: T_NodeTypeValue[]): T {
  let child = findChild<Element>(node, descendantTypes[0]);

  for (let i = 1; i < descendantTypes.length; i++) {
    child = findChild<Element>(child, descendantTypes[i]);
  }

  return child as unknown as T;
}
