import React, { useRef } from 'react';

import { MarkerOverlay } from '~/components/ui-extended/marker-overlay';
import { T_Coordinatable } from '~/types/editor/T_Editor';

import { SourceButton } from './source-button';

type Props = {
  imageable: any;
  markers: T_Coordinatable[];
};

export function MarkableImageable(props: Props) {
  const { markers, imageable } = props;

  const imageRef = useRef<HTMLImageElement>(null);
  const widthRef = useRef<HTMLDivElement>(null);

  return (
    <div className="flex relative justify-center cursor-pointer bg-stone-900" ref={widthRef}>
      <div className="relative">
        <MarkerOverlay markers={markers} imageRef={imageRef} widthRef={widthRef} />

        <img src={imageable.large_src} ref={imageRef} className="max-w-full" />

        <SourceButton source={imageable.source} />
      </div>
    </div>
  );
}
