import React, { useEffect, useState } from 'react';

import { Column, Columns } from '~/components/ui/column-layout';
import { usePrevious } from '~/hooks/usePrevious';
import { T_AnswerableMarkImage } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImage';
import { T_AnswerableMarkImageMarker } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImageMarker';
import { cn } from '~/utils';
import { splitCollection } from '~/utils/splitCollection';

import { useSentInAnswerProps } from '../../context/AnswerablePropsContext';
import { useExerciseProps } from '../../context/ExercisePropsContext';
import { useFindUserAnswers } from '../../hooks/useFindUserAnswers';
import Marker from './Marker';

type Props = {
  activeItem: T_AnswerableMarkImageMarker['ViewNode'];
  answerable: T_AnswerableMarkImage['ViewNode'];
  markers: T_AnswerableMarkImageMarker['ViewNode'][];
  remakable: boolean;
  remakeMode: boolean;
  setActiveItem: (value: T_AnswerableMarkImageMarker['ViewNode']) => void;
};

export function MarkerList(props: Props) {
  const { activeItem, answerable, markers, remakeMode, remakable, setActiveItem } = props;

  const { submitExercise } = useExerciseProps();
  const { showSentInAnswers } = useSentInAnswerProps();
  const userAnswer = useFindUserAnswers(answerable.nodeKey);

  const previousShowStudentAnswers = usePrevious(showSentInAnswers);

  useEffect(() => {
    if (showSentInAnswers && !previousShowStudentAnswers) {
      setActiveItem(markers[0]);
    }
  });

  useEffect(() => {
    if (remakeMode) {
      setAnswer([]);
    } else if (userAnswer?.value) {
      setAnswer(userAnswer?.value?.answersByKey || []);
    }
  }, [remakeMode]);

  const [answer, setAnswer] = useState(
    userAnswer?.value && !remakeMode ? userAnswer.value.answersByKey : generateValues(),
  );

  const [column1, column2] = splitCollection(markers);

  return (
    <Columns className={cn('my-4', remakeMode && remakable && 'bg-green-50')}>
      <Column className="pr-4">{column1.map(renderMarkers)}</Column>
      <Column>{column2.map(renderMarkers)}</Column>
    </Columns>
  );

  function renderMarkers(marker) {
    return (
      <Marker
        activeItem={activeItem}
        answerable={answerable}
        handleChange={handleChange}
        key={marker.nodeKey}
        node={marker}
        number={marker.number}
        setActiveItem={() => setActiveItem(marker)}
        showSentInAnswers={showSentInAnswers}
        userAnswer={answer[marker.nodeKey] || ''}
      />
    );
  }

  function generateValues() {
    const values = {};

    markers.forEach((item) => {
      values[item.nodeKey] = '';
    });

    return values;
  }

  function handleChange(key: string, value: string) {
    const newAnswer = { ...answer, [key]: value };

    submitExercise({
      answerableNodeKey: answerable.nodeKey,
      answer: { value: { answersByKey: newAnswer } },
    });

    setAnswer(newAnswer);
  }
}
