import React from 'react';

import { T_AnswerableFillList } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillList';
import { useViewPrintContext } from '~/views/print/useViewPrintContext';

import { Item } from './Item';

type Props = {
  answerable: T_AnswerableFillList['ViewNode'];
};

export function ItemList(props: Props) {
  const { answerable } = props;

  const { exercise_layout, user_answers } = useViewPrintContext();

  const answers = answerable.items.map(({ nodeKey, answer }) => ({ nodeKey, ...answer }));

  return <div className="flex flex-row flex-wrap pb-[2mm]">{renderItemNodes()}</div>;

  function renderItemNodes() {
    return answerable.items.map((item, index) => {
      let answer: string;

      switch (exercise_layout) {
        case 'empty': {
          answer = null;
          break;
        }
        case 'standard_answers': {
          answer = item.answer.text;
          break;
        }
        case 'user_answers': {
          const userAnswer = user_answers.answers.find(
            (answer) => answer.answerable.node_key === answerable.nodeKey,
          );

          if (!userAnswer) return null;

          if (answerable.answer_by === 'text') {
            answer = userAnswer?.value.answersByKey[item.nodeKey];
          } else {
            const selectedNodeKey = userAnswer?.value.answersByKey[item.nodeKey];

            const selectedNode = answers.find((answer) => answer.nodeKey === selectedNodeKey);

            answer = selectedNode?.text as string;
          }

          break;
        }
      }

      return <Item item={item} answerable={answerable} answer={answer} key={index} />;
    });
  }
}
