import React, { useCallback } from 'react';
import temml from 'temml';

import { T_Formula } from '~/types/node/T_Formula';

type Props = {
  node: T_Formula['ViewNode'];
};

export function Formula(props: Props) {
  const { node } = props;

  const ref = useCallback((element: HTMLSpanElement) => {
    if (!element) return;

    temml.render(node.latex, element);
  }, []);

  return <span ref={ref} />;
}
