import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components/ui/button';

import { useCollapseAll } from './collapsable-context';

export function CollapseAllButton() {
  const { t } = useTranslation();

  const { toggleAllContent, allContentIsClosed } = useCollapseAll();

  return (
    <Button onClick={toggleAllContent} className="border" variant="ghost" size="sm">
      {allContentIsClosed
        ? t('pages.booksEditStructure.buttons.expandAll')
        : t('pages.booksEditStructure.buttons.collapseAll')}
    </Button>
  );
}
