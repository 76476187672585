import { useRemember } from '@inertiajs/react';
import React from 'react';

import { useCourseContentShowPage } from '../../T_CourseContentShowProps';
import Navigation from './Navigation';
import ToggleSidebarButton from './ToggleSidebarButton';

export function SidebarMenu() {
  const { bookContent, courseContent, enrichments, focus } = useCourseContentShowPage().props;

  const [sidebarState, setSidebarState] = useRemember({
    shown: false,
  });

  return (
    <>
      <ToggleSidebarButton
        showSidebar={sidebarState.shown}
        setShowSidebar={(value) => setSidebarState({ shown: value })}
      />

      {sidebarState.shown && (
        <Navigation
          bookContent={bookContent}
          courseContent={courseContent}
          enrichments={enrichments}
          focus={focus}
        />
      )}
    </>
  );
}
