import { useState } from 'react';

import { T_AnswerablesViewNodes } from '~/node-builder/nodes/exercise/answerables/types';
import { T_Exercise } from '~/node-builder/nodes/exercise/types';

import { useExerciseProps } from '../context/ExercisePropsContext';
import { useFindUserAnswers } from './useFindUserAnswers';

export function useRemake(answerable: T_AnswerablesViewNodes['answerable_value']) {
  const { access, node } = useExerciseProps();

  const userAnswerForAnswerable = useFindUserAnswers(answerable.nodeKey);

  const remakable = !!userAnswerForAnswerable && isRemakable(node) && access.student;

  const [remakeMode, setRemakeMode] = useState(remakable);

  return {
    remakable,
    remakeMode,
    setRemakeMode,
  };
}

function isRemakable(node: T_Exercise['ViewNode']) {
  return node.behavior === 'remakable';
}
