import { InfoCircledIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';

type Props = { source?: string };

export function SourceButton(props: Props) {
  const { t } = useTranslation();

  const { source } = props;

  if (!source) return null;

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            className="w-5 h-5 border absolute bottom-1 right-1 opacity-50"
            variant="secondary"
            size="icon">
            <InfoCircledIcon className="h-3.5 w-3.5" />
          </Button>
        </TooltipTrigger>
        <TooltipContent align="end" side="top" className="z-[99]">
          <p>
            {t('common.nouns.source')}: {source}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
