import clsx from 'clsx';
import queryString from 'query-string';
import React from 'react';
import Highlighter from 'react-highlight-words';

import { useHighlightContext } from '~/hooks/useHighlightContext';
import { T_BaseText } from '~/node-builder/types';
import { T_Link } from '~/types/node/T_Link';
import { T_SingleLineNode } from '~/types/node/T_SingleLineNode';

import { KeywordMarker } from '../../components-new/keyword-marker/keyword-marker';
import { useViewBookContext } from '../../hooks/useViewBookContext';
import { useIsExplanationChild } from '../definition/Explanation';

type Props = {
  node: T_BaseText | T_Link['ViewNode'] | [T_SingleLineNode];
};

export function Text(props: Props) {
  const { node } = props;

  const { showHighlight } = useHighlightContext();

  // When node comes from single-line-text (e.g. Definition Concept)
  if (Array.isArray(node)) {
    return (
      <>
        {node[0].children.map((node, index) => (
          <Span key={index} node={node as T_BaseText} />
        ))}
      </>
    );
  }

  return <Span node={node as T_BaseText} />;
}

function Span({ node }: { node: T_BaseText }) {
  const { text, bold, italic, underline, superscript } = node;

  const { book } = useViewBookContext();

  const { showHighlight } = useHighlightContext();
  const isExplanationChild = useIsExplanationChild();

  const searchQuery = (queryString.parse(location.search)['highlight'] as string) || '';

  const isIOS = iOS();

  const Tag = superscript ? 'sup' : 'span';

  return (
    <Tag
      className={clsx(
        bold && 'font-bold text-[#235F8C]',
        italic && 'italic',
        underline && 'underline',
      )}>
      {showHighlight && !isExplanationChild ? (
        <Highlighter
          searchWords={(book?.keywords || [])
            .concat([searchQuery])
            .map((k) => (isIOS ? k : new RegExp(`(?<![\\w\\d])${k}(?![\\w\\d])`)))}
          highlightClassName={{ [searchQuery]: 'bg-green-100' }}
          autoEscape={false} // we zoeken met regexen
          textToHighlight={text}
          highlightTag={({ children, className }) => {
            if (children === searchQuery) {
              return <mark className={className + ' px-1'}>{children}</mark>;
            } else {
              return <KeywordMarker keyword={children} />;
            }
          }}
        />
      ) : (
        text
      )}
    </Tag>
  );
}

function iOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}
