import { router } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import RegistrationsApi from '~/api/Users/RegistrationsApi';
import UsersApi from '~/api/UsersApi';
import OrganizationApi from '~/api/Website/OrganizationApi';
import { Button } from '~/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import { T_CurrentUser } from '~/types/serializers';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog';

type Props = {
  user: T_CurrentUser;
};

export function UserMenu(props: Props) {
  const { t } = useTranslation();

  const { user } = props;

  return (
    <Dialog>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button data-testid="user_menu_button" variant="outline" className="w-full bg-white">
            {user.fullName}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent side="top" className="w-56">
          <DropdownMenuLabel>{t('common.nouns.actions')}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem asChild>
              <a target="_blank" href={OrganizationApi.tutorials.path()} rel="noreferrer">
                {t('common.sidebar.userMenu.options.viewTutorials')}
              </a>
            </DropdownMenuItem>
            <DialogTrigger asChild>
              <DropdownMenuItem>{t('common.sidebar.userMenu.options.copyright')}</DropdownMenuItem>
            </DialogTrigger>
            <DropdownMenuItem onClick={navigateToEditPassword}>
              {t('common.sidebar.userMenu.options.changePassword')}
            </DropdownMenuItem>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                {t('common.sidebar.userMenu.options.language')}
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  <DropdownMenuRadioGroup value={user.language}>
                    <DropdownMenuRadioItem onSelect={setUserLanguage} data-value="en" value="en">
                      English
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem onSelect={setUserLanguage} data-value="nl" value="nl">
                      Nederlands
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem onSelect={setUserLanguage} data-value="fr" value="fr">
                      Français
                    </DropdownMenuRadioItem>
                  </DropdownMenuRadioGroup>
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem data-testid="log_out_button" onClick={handleSignOut}>
              {t('common.sidebar.userMenu.options.signOut')}
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('common.sidebar.userMenu.dialogs.copyright.title')}</DialogTitle>
          <DialogDescription className="space-y-2 pt-2 text-stone-600 text-justify">
            <p>{t('common.sidebar.userMenu.dialogs.copyright.firstParagraph')}</p>
            <p>{t('common.sidebar.userMenu.dialogs.copyright.secondParagraph')}</p>
            <p>{t('common.sidebar.userMenu.dialogs.copyright.thirdParagraph')}</p>
            <p>{t('common.sidebar.userMenu.dialogs.copyright.fourthParagraph')}</p>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter></DialogFooter>
      </DialogContent>
    </Dialog>
  );

  function setUserLanguage(e: Event) {
    e.preventDefault();

    const { value: language } = e.target.dataset;

    router.put(UsersApi.update.path({ id: user.id }), { language });
  }

  function handleSignOut() {
    router.delete('/users/sign_out');
  }

  function navigateToEditPassword() {
    router.visit(RegistrationsApi.edit.path());
  }
}
