import clsx from 'clsx';
import React from 'react';

import { idFromNodeKey } from '~/lib/node-renderer/nodeHelpers';
import { T_AnswerableMultipleChoice } from '~/types/node/exercise/answerables/multiple-choice/T_AnswerableMultipleChoice';
import { T_AnswerableMultipleChoiceItem } from '~/types/node/exercise/answerables/multiple-choice/T_AnswerableMultipleChoiceItem';

import { useStandardAnswerProps } from '../../context/AnswerablePropsContext';

type Props = {
  checked: boolean;
  index: number;
  onAnswerClick: () => void;
  option: T_AnswerableMultipleChoiceItem['ViewNode'];
  answerable: T_AnswerableMultipleChoice['ViewNode'];
};

export function Option(props: Props) {
  const { answerable, option, checked, onAnswerClick, index } = props;

  const { showAnswer } = useStandardAnswerProps();

  const htmlId = idFromNodeKey(option.nodeKey);

  return (
    <div
      data-testid={
        showAnswer === 'all' && option.correct
          ? 'multiple-choice-correct-answer'
          : 'multiple-choice-answer'
      }
      className={clsx(
        'flex items-center my-1.5 text-base font-light',
        showAnswer === 'none' && 'text-gray-700',
        checked && showAnswer === 'all' && 'text-green-600',
      )}
      key={option.nodeKey}>
      <input
        className={clsx(
          'h-4 w-4 border-gray-300 rounded',
          showAnswer === 'none' && 'text-indigo-600',
          showAnswer === 'all' && option.correct && 'text-green-600 border-green-600 border-2',
          showAnswer === 'all' &&
            !option.correct &&
            checked &&
            'text-red-400 border-red-400 border-2',
        )}
        name={answerable.nodeKey}
        checked={checked}
        data-testid={`multiple-choice-answer-option-${index}`}
        id={htmlId}
        onChange={onAnswerClick}
        type={answerable.single_answer ? 'radio' : 'checkbox'}
      />

      <label
        className={clsx(
          'ml-2 "__multiple_choice__option_"',
          showAnswer === 'none' && 'text-gray-700',
          showAnswer === 'all' && option.correct && 'text-green-600 ',
          showAnswer === 'all' && !option.correct && checked && 'text-red-400 ',
        )}
        htmlFor={htmlId}>
        {option.text}
      </label>
    </div>
  );
}
