import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { T_AnswerableFillGapsGap } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGapsGap';

import { useSentInAnswerProps } from '../../context/AnswerablePropsContext';

type Props = {
  activeGap: T_AnswerableFillGapsGap['ViewNode'] | null;
};

export function OpenTextView(props: Props) {
  const { t } = useTranslation();

  const { activeGap } = props;

  const { sentInAnswers, includeName } = useSentInAnswerProps<any>();

  const answers = useMemo(() => answersForActiveItem(sentInAnswers), [activeGap, sentInAnswers]);

  return (
    <div className="flex flex-wrap mt-4">
      {answers.map((answer, index) => {
        return (
          <div className="w-1/3" key={index}>
            {includeName && (
              <div className="text-gray-700 font-semibold px-4 text-sm">
                {answer.student.fullName}:
              </div>
            )}

            <div className="border-gray-100 bg-yellow-50 p-4 shadow flex-col flex-1 rounded-lg m-2 mt-1 mb-3">
              {answer.text}
            </div>
          </div>
        );
      })}
    </div>
  );

  function answersForActiveItem(sentInAnswers: any[]) {
    if (!activeGap) return [];

    return sentInAnswers.map((answer) => {
      return {
        text:
          answer.value.fillGaps[activeGap.nodeKey] ||
          t('pages.courseContentsShow.nodes.exercise.notFilledIn'),
        student: answer.user,
      };
    });
  }
}
