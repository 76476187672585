import React from 'react';
import { useTranslation } from 'react-i18next';
import { Facebook, Instagram, Linkedin } from 'styled-icons/bootstrap';

import { BrightbookLogo } from './brightbook-logo';
import { Container } from './container';

export function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="border-t border-gray-200">
      <Container>
        <div className="flex flex-col items-start justify-between gap-y-12 pb-6 pt-16 lg:flex-row lg:items-center lg:py-10">
          <div className="flex items-center text-gray-900">
            <BrightbookLogo className="h-10 w-10 flex-none" />

            <div className="ml-4">
              <p className="text-base font-semibold">Brightbook</p>
              <p className="mt-1 text-sm">{t('website.footer.subtitle')}</p>
            </div>
          </div>
          <div className="flex justify-center space-x-6 md:order-2">
            <a
              target="_blank"
              href="https://www.facebook.com/profile.php?id=61551417357357"
              className="text-gray-400 hover:text-gray-500"
              rel="noreferrer">
              <span className="sr-only">Facebook</span>
              <Facebook className="h-6 w-6" aria-hidden="true" />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/brightbook_for_learning/"
              className="text-gray-400 hover:text-gray-500"
              rel="noreferrer">
              <span className="sr-only">Instagram</span>
              <Instagram className="h-6 w-6" aria-hidden="true" />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/86933752/admin/feed/posts/"
              className="text-gray-400 hover:text-gray-500"
              rel="noreferrer">
              <span className="sr-only">Linkedin</span>
              <Linkedin className="h-6 w-6" aria-hidden="true" />
            </a>
          </div>
        </div>

        <div className="mx-auto border-t max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
          <div className="mt-8 md:order-1 md:mt-0">
            <p className="text-center text-xs leading-5 text-gray-500">
              &copy; Copyright {new Date().getFullYear()}. {t('website.footer.copyright')}
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
}
