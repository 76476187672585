import React from 'react';

import { Column, Columns } from '~/components/ui/column-layout';
import { T_AnswerableMarkImage } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImage';
import { T_AnswerableMarkImageMarker } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImageMarker';
import { splitCollection } from '~/utils/splitCollection';
import { useViewPrintContext } from '~/views/print/useViewPrintContext';

import { Marker } from './Marker';

type Props = {
  markers: T_AnswerableMarkImageMarker['ViewNode'][];
  answerable: T_AnswerableMarkImage['ViewNode'];
};

export function MarkerList(props: Props) {
  const { answerable, markers } = props;

  const { exercise_layout, user_answers } = useViewPrintContext();

  const [column1, column2] = splitCollection(markers);

  return (
    <Columns className="mt-[3mm]">
      <Column className="pr-2">
        {column1.map((marker) => {
          const answer = findAnswerForItem(marker);

          return <Marker answer={answer} key={marker.nodeKey} marker={marker} />;
        })}
      </Column>
      <Column>
        {column2.map((marker) => {
          const answer = findAnswerForItem(marker);

          return <Marker answer={answer} key={marker.nodeKey} marker={marker} />;
        })}
      </Column>
    </Columns>
  );

  function findAnswerForItem(marker: any) {
    const { nodeKey } = marker;

    let answer;

    switch (exercise_layout) {
      case 'empty': {
        answer = null;
        break;
      }
      case 'standard_answers': {
        answer = marker.text;
        break;
      }
      case 'user_answers': {
        const userAnswer = user_answers?.answers.find(
          (answer) => answer.answerable.node_key === answerable.nodeKey,
        );

        if (answerable.answer_by === 'text') {
          answer = userAnswer?.value.answersByKey[nodeKey];
        } else {
          const selectedNodeKey = userAnswer?.value.answersByKey[nodeKey];

          const selectedNode = markers.find((marker) => marker.nodeKey === selectedNodeKey);

          answer = selectedNode?.text;
        }
      }
    }

    return answer;
  }
}
