import clsx from 'clsx';
import React, { useMemo } from 'react';
import { HandRaised as HandOutline } from 'styled-icons/heroicons-outline';
import { HandRaised as HandSolid } from 'styled-icons/heroicons-solid';

import { T_AnswerableFillList } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillList';
import { T_AnswerableFillListItemAnswer } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillListItemAnswer';

import { useSentInAnswerProps } from '../../context/AnswerablePropsContext';
import { useExerciseProps } from '../../context/ExercisePropsContext';

type Props = {
  active: boolean;
  answerable: T_AnswerableFillList['ViewNode'];
  handleChange: (key: string, value: string) => void;
  itemNodeKey: string;
  node: T_AnswerableFillListItemAnswer['ViewNode'];
  number: number;
  setActiveItem: () => void;
  showAnswer: boolean;
  userAnswer: string;
};

export function AnswerSelectInput(props: Props) {
  const {
    active,
    answerable,
    handleChange,
    itemNodeKey,
    node,
    number,
    setActiveItem,
    showAnswer,
    userAnswer,
  } = props;

  const { access } = useExerciseProps();
  const { showSentInAnswers } = useSentInAnswerProps();

  const possibleAnswers = useMemo(() => formatPossibleAnswers(), []);

  const answerIsCorrect = userAnswer === itemNodeKey;

  return (
    <div className="w-full">
      <div className="mt-1 flex rounded-md shadow-sm">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <select
            className={clsx(
              '__answer_select_input__ focus:ring-indigo-500 focus:border-indigo-500 block w-full py-1 rounded-md sm:text-sm border-gray-300',
              showSentInAnswers && !access.student && 'rounded-r-none',
              showAnswer && answerIsCorrect && 'border-green-300',
              showAnswer && !answerIsCorrect && 'border-red-300',
            )}
            value={userAnswer ?? ''}
            onChange={(event) => handleChange(itemNodeKey, event.target.value)}
            name={node.nodeKey}>
            <option value="default">...</option>

            {possibleAnswers.map((answer, index) => {
              return (
                <option key={index} value={answer.nodeKey}>
                  {answer.text}
                </option>
              );
            })}
          </select>
        </div>

        {showSentInAnswers && !access.student && (
          <button
            className="-ml-px relative inline-flex items-center space-x-2 py-1 px-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            onClick={setActiveItem}
            data-testid={`fill-list-select-active-button-${number}`}
            data-tip="Toon leerling-antwoorden voor dit item"
            type="button">
            {active ? (
              <HandSolid className="w-4 h-4 text-gray-600" />
            ) : (
              <HandOutline className="w-4 h-4 text-gray-400" />
            )}
          </button>
        )}
      </div>

      {showAnswer && !answerIsCorrect && (
        <div className="flex">
          <div
            data-testid="fill-list-correct-answer"
            className="bg-green-300 text-sm mx-1 rounded px-1 mt-1">
            {node.text}
          </div>
        </div>
      )}
    </div>
  );

  function formatPossibleAnswers() {
    const answerOptions = answerable.items.map((item) => {
      return {
        nodeKey: item.nodeKey,
        text: (item.answer as T_AnswerableFillListItemAnswer['ViewNode']).text,
      };
    });

    return answerOptions.sort((a, b) => a.text.localeCompare(b.text));
  }
}
