import { router } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlass } from 'styled-icons/heroicons-outline';

import CourseContentsApi from '~/api/CourseContentsApi';
import { Button } from '~/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';

import { useViewBookContext } from '../hooks/useViewBookContext';

type Props = {
  nodeKey: string;
};

export function FocusNodeButton({ nodeKey }: Props) {
  const { t } = useTranslation();

  const { access, courseContent } = useViewBookContext();

  if (access.student) return null;

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={navigateToFocusedNode}
            data-testid={`focus-${nodeKey}-button`}
            size="sm"
            variant="ghost">
            <MagnifyingGlass className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t('pages.courseContentsShow.text.focusOnNode')}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );

  function navigateToFocusedNode() {
    router.visit(
      CourseContentsApi.show.path({ slug: courseContent.slug, query: { focus: nodeKey } }),
      {
        only: ['focus', 'teachingClassGroupStudentAnswers'],
        preserveState: true,
      },
    );
  }
}
