import React from 'react';
import { useTranslation } from 'react-i18next';

export function BrandingPage(props) {
  const { t } = useTranslation();

  const { metadata } = props;

  const { author_names } = metadata;

  return (
    <div className="branding-page">
      <div className="absolute flex-col items-center justify-center top-0 w-full">
        <h2 className="text-[14pt] font-normal">
          {author_names && author_names.map((name, index) => <p key={index}>{name}</p>)}
        </h2>
      </div>

      <div className="absolute flex-col items-center justify-center bottom-0 w-full">
        <div className="flex items-center justify-center mb-10">
          <h3 className="font-normal text-[#444f5a]">
            {t('pages.printTasksGenerate.text.madeWith')}{' '}
            <span className="font-serif text-[24pt] font-semibold">Brightbook.</span>
          </h3>
        </div>

        <p className="text-[#444f5a] text-[9pt]">
          {t('pages.printTasksGenerate.text.copyright', { author: author_names[0] })}
        </p>
      </div>
    </div>
  );
}
