import React from 'react';

import { T_AnswerableOpenQuestion } from '~/types/node/exercise/answerables/open-question/T_AnswerableOpenQuestion';
import { NodeMap } from '~/views/print/NodeMap';
import { useViewPrintContext } from '~/views/print/useViewPrintContext';

type Props = {
  answerable: T_AnswerableOpenQuestion['ViewNode'];
};

export function OpenAnswer(props: Props) {
  const { answerable } = props;

  const { exercise_layout, user_answers } = useViewPrintContext();

  if (exercise_layout === 'user_answers') {
    const userAnswer = user_answers.answers.find(
      (answer) => answer.answerable.node_key === answerable.nodeKey,
    );

    return (
      <div className="border-b-[1px] border-dotted border-b-gray-400">
        <p className="m-0 text-[#0066cc] text-[11pt]">{userAnswer?.value.openAnswer ?? ''}</p>
      </div>
    );
  }

  if (exercise_layout === 'standard_answers') {
    return (
      <div className="border-b-[1px] border-dotted border-b-gray-400">
        <NodeMap nodes={answerable.children} />
      </div>
    );
  }

  return <div className="break-inside-avoid">{renderAnswerLines(answerable.line_count)}</div>;
}

function renderAnswerLines(lineCount: number) {
  const lines = [];

  for (let i = 0; i < (lineCount || 2); i++) {
    lines.push(<div className="border-b-[1px] border-dotted border-b-gray-400 h-[18pt]" key={i} />);
  }

  return lines;
}
