import React from 'react';

export function LinkToAnchor({ id, children, className = '' }) {
  return (
    <a href={`#${id}`} className={className}>
      {children}
    </a>
  );
}

export function Anchor({ id }) {
  return <a id={id} className="pt-14 -mt-14" />;
}
