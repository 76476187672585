import React from 'react';
import { useTranslation } from 'react-i18next';
import { Emoji, EmojiSad, EmojiSadSlight } from 'styled-icons/fluentui-system-regular';
import { XCircle } from 'styled-icons/heroicons-outline';

import { Badge } from '~/components/ui/badge';
import { T_StudentSelfAssessment } from '~/types/serializers';

type Props = {
  assessment: T_StudentSelfAssessment | null;
};

export function AssessmentBadge(props: Props) {
  const { t } = useTranslation();

  const { assessment } = props;

  return <div className="flex-1 max-w-[10rem]">{renderAssessment()}</div>;

  function renderAssessment() {
    if (!assessment) {
      return (
        <Badge variant="secondary" className="py-1.5">
          <XCircle className="w-4 h-4 mr-1" />
          {t('pages.partsShow.text.assessmentUnfinished')}
        </Badge>
      );
    }

    switch (assessment.understanding) {
      case 'bad': {
        return (
          <Badge variant="secondary" className="bg-red-200 hover:bg-red-200 py-1.5">
            <EmojiSad className="w-4 h-4 mr-1" />

            {t('pages.partsShow.text.assessmentBad')}
          </Badge>
        );
      }
      case 'medium': {
        return (
          <Badge variant="secondary" className="bg-orange-200 hover:bg-orange-200 py-1.5">
            <EmojiSadSlight className="w-4 h-4 mr-1" />

            {t('pages.partsShow.text.assessmentMedium')}
          </Badge>
        );
      }
      case 'good': {
        return (
          <Badge variant="secondary" className="bg-green-200 hover:bg-green-200 py-1.5">
            <Emoji className="w-4 h-4 mr-1" />

            {t('pages.partsShow.text.assessmentGood')}
          </Badge>
        );
      }
    }
  }
}
