import { zodResolver } from '@hookform/resolvers/zod';
import { router } from '@inertiajs/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import ClassGroupsApi from '~/api/ClassGroupsApi';
import { Button } from '~/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import { T_Book } from '~/types/serializers';

const formSchema = z.object({
  code: z.string().min(1, { message: 'Code is verplicht' }),
});

type Props = {
  book: T_Book;
  closeDialog: () => void;
};

export function RegisterMemberForm(props: Props) {
  const { t } = useTranslation();

  const { book, closeDialog } = props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: '',
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="code"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('models.licenseGroup.code')}</FormLabel>
              <FormControl>
                <Input type="text" placeholder="ABC123" {...field} />
              </FormControl>
              <FormDescription className="space-y-3">
                <p>{t('pages.classroomsShow.forms.registerInGroup.descriptions.code')}</p>
                <p>
                  {t(
                    'pages.classroomsShow.forms.registerInGroup.descriptions.codeWarningNotReversible',
                  )}
                </p>
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button data-testid="create_class_group_button" type="submit">
          {t('pages.classroomsShow.forms.registerInGroup.buttons.register')}
        </Button>
      </form>
    </Form>
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.post(ClassGroupsApi.join.path({ book_slug: book.slug }), values, {
      replace: true,
      onError: (err) => {
        form.setError('code', {
          type: 'custom',
          message: `Klasgroep ${err['class_group'][0]}`,
        });
      },
      onSuccess: () => {
        form.reset();
        closeDialog();
      },
    });
  }
}
