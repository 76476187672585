import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { router } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PartsApi from '~/api/PartsApi';
import { Badge } from '~/components/ui/badge';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '~/components/ui/dialog';
import {
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
} from '~/components/ui/dropdown-menu';
import { Label } from '~/components/ui/label';
import { Switch } from '~/components/ui/switch';
import { T_Part } from '~/types/serializers';
import { cn } from '~/utils';

import { TNestedPart } from './page-content';
import { Sections } from './sections';
import { StructureItem } from './structure-item';

type Props = {
  nestedParts: TNestedPart[];
  bookSlug: string;
  isInDragAction: boolean;
  part: any;
};

export function NestedParts(props: Props) {
  const { t } = useTranslation();

  const { isInDragAction, nestedParts, part } = props;

  const { setNodeRef } = useDroppable({
    id: part.slug,
    data: {
      accepts: 'nestedPart',
    },
  });

  return (
    <div data-testid={`nested_part_droppable_${part.slug}`} className="pb-10" ref={setNodeRef}>
      <SortableContext
        items={nestedParts.map((p) => p.slug)}
        strategy={verticalListSortingStrategy}>
        {nestedParts.length ? (
          <div className={cn(isInDragAction && 'pending-drag-action')}>
            {nestedParts.map((nestedPart) => (
              <StructureItem
                sortable={{
                  id: nestedPart.slug,
                  data: { type: 'nestedPart', partSlug: part.slug },
                }}
                meta={{
                  type: 'nested_part',
                  title: nestedPart.title,
                  id: nestedPart.slug,
                  number: nestedPart.number,
                  paramType: 'part',
                  i18nKey: 'nestedPart',
                }}
                paths={{
                  update: PartsApi.update.path({ slug: nestedPart.slug }),
                  delete: PartsApi.destroy.path({ slug: nestedPart.slug }),
                }}
                createItem={() => createNestedPart(nestedPart.position + 1)}
                extraOptions={[
                  <>
                    <DropdownMenuLabel>{t('models.part.learningPath')}</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuRadioGroup value={nestedPart.learningPath}>
                      <DropdownMenuRadioItem
                        onSelect={(e) => setLearningPath(e, nestedPart)}
                        data-value="fixed"
                        data-testid="fixed-learning-path-option"
                        value="fixed">
                        {t('models.part.fixed')}
                      </DropdownMenuRadioItem>
                      <DropdownMenuRadioItem
                        onSelect={(e) => setLearningPath(e, nestedPart)}
                        data-value="open"
                        data-testid="open-learning-path-option"
                        value="open">
                        {t('models.part.open')}
                      </DropdownMenuRadioItem>
                    </DropdownMenuRadioGroup>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      key="publish"
                      onClick={(e) => handleSelectToggle(e, nestedPart)}>
                      <div className="flex items-center space-x-2">
                        <Switch
                          data-testid={
                            nestedPart.published
                              ? 'unpublish_nested_part_button'
                              : 'publish_nested_part_button'
                          }
                          checked={nestedPart.published}
                          id="publish-chapter"
                        />
                        <Label htmlFor="publish-chapter">
                          {t('pages.booksEditStructure.buttons.publishSection')}
                        </Label>
                      </div>
                    </DropdownMenuItem>
                  </>,
                ]}
                rightSideContent={
                  <div className="mr-2 flex items-center">
                    <div className="mr-1">
                      {nestedPart.published ? (
                        <Badge
                          className="bg-emerald-100 text-emerald-800 h-5 border-none"
                          variant="outline">
                          {t('models.section.published')}
                        </Badge>
                      ) : (
                        <Badge
                          className="bg-orange-100 text-orange-800 h-5 border-none"
                          variant="outline">
                          {t('models.section.unpublished')}
                        </Badge>
                      )}
                    </div>
                    <Dialog>
                      <DialogTrigger>
                        <Badge
                          data-testid="learning-path-badge"
                          className="bg-white h-5"
                          variant="outline">
                          {t(`models.part.${nestedPart.learningPath}LearningPath`)}
                        </Badge>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogHeader>
                          <DialogTitle>
                            {t('pages.booksEditStructure.dialogs.learnPathInfo.title')}
                          </DialogTitle>
                        </DialogHeader>
                        <div className="text-stone-700 mt-2">
                          <h2 className="font-semibold">{t('models.part.fixedLearningPath')}</h2>
                          <p className="font-light">
                            {t(
                              'pages.booksEditStructure.dialogs.learnPathInfo.fixedLearningPathDescription',
                            )}
                          </p>
                        </div>
                        <div>
                          <h2 className="font-semibold">{t('models.part.openLearningPath')}</h2>
                          <p className="font-light">
                            {t(
                              'pages.booksEditStructure.dialogs.learnPathInfo.openLearningPathDescription',
                            )}
                          </p>
                        </div>
                        <div className="mt-2 font-medium text-sm text-stone-600">
                          {t('pages.booksEditStructure.dialogs.learnPathInfo.changeLearningPath')}
                        </div>
                      </DialogContent>
                    </Dialog>
                  </div>
                }
                key={nestedPart.slug}>
                <Sections
                  sections={nestedPart.sections}
                  isInDragAction={isInDragAction}
                  nestedPart={nestedPart}
                  bookSlug={props.bookSlug}
                  part={part}
                />
              </StructureItem>
            ))}
          </div>
        ) : (
          <div className="text-xs mt-2 mb-1 text-gray-600 font-light">
            {t('pages.booksEditStructure.text.noChaptersYet')}
            <button onClick={() => createNestedPart(0)} className="text-black font-bold ml-1">
              {t('pages.booksEditStructure.buttons.addFirstChapter')}
            </button>
          </div>
        )}
      </SortableContext>
    </div>
  );

  function createNestedPart(position) {
    router.post(
      PartsApi.create.path(),
      {
        title: 'New chapter',
        book_id: props.bookSlug,
        part_id: part.slug,
        position,
      },
      {
        preserveScroll: true,
      },
    );
  }

  function setLearningPath(event: Event, nestedPart: T_Part) {
    event.preventDefault();

    const { value: learning_path } = event.target.dataset;

    router.patch(
      PartsApi.update.path({ slug: nestedPart.slug }),
      { learning_path },
      {
        preserveScroll: true,
        preserveState: true,
      },
    );
  }

  function handleSelectToggle(e, chapter) {
    e.preventDefault();

    router.patch(
      PartsApi.update.path({ slug: chapter.slug }),
      { published: !chapter.published },
      {
        preserveScroll: true,
        preserveState: true,
      },
    );
  }
}
