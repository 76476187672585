import React, { useRef } from 'react';

import { T_AnswerableMarkImageMarker } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImageMarker';

type Props = {
  markers: T_AnswerableMarkImageMarker['ViewNode'][];
  imageRef: React.MutableRefObject<HTMLImageElement>;
  width: number;
  onClick?: (point: [number, number]) => void;
};

export function MarkerOverlay(props: Props) {
  const { markers, imageRef, width, onClick } = props;

  const svgRef = useRef<SVGSVGElement>();

  if (!imageRef.current) return null;

  const svgNormalizeWidthFactor = calcSvgNormalizeWidthFactor();

  return (
    <svg
      className="absolute"
      onClick={(event) => handleClick(event)}
      ref={svgRef}
      width="100%"
      height="100%"
      viewBox="0 0 100 100">
      {renderMarkers()}
    </svg>
  );

  function renderMarkers() {
    return markers.map((marker, index) => {
      if (!marker || !marker.coordinates) return null;

      const [x, y] = marker.coordinates;

      return (
        <g key={index}>
          <circle
            cx={x}
            cy={y}
            r={calcFromPercentageOfWidth(2)}
            stroke="#374151"
            strokeWidth={calcFromPercentageOfWidth(0.1)}
            textAnchor="middle"
            alignmentBaseline="middle"
            fill="white"
          />
          <text
            x={x}
            y={y}
            dy="0.1em"
            fontSize={calcFromPercentageOfWidth(2)}
            fill="#374151"
            fontWeight={600}
            textAnchor="middle"
            dominantBaseline="middle">
            {marker.number}
          </text>
        </g>
      );
    });
  }

  function calcFromPercentageOfWidth(percentage: number) {
    return percentage * svgNormalizeWidthFactor;
  }

  // An svg uses a unit where 100 equals [width, height].min.
  //
  // This function returns a factor where 100 * factor == width
  function calcSvgNormalizeWidthFactor() {
    const { naturalHeight, naturalWidth, offsetWidth } = imageRef.current;

    if (naturalHeight == 0 || offsetWidth == 0) return 1.5;

    const containerWidth = width;

    const scaleWidthToContainerWidth = containerWidth / offsetWidth;

    if (naturalWidth < naturalHeight) {
      return scaleWidthToContainerWidth;
    } else {
      return (naturalWidth / naturalHeight) * scaleWidthToContainerWidth;
    }
  }

  function handleClick(event: React.MouseEvent<SVGSVGElement, MouseEvent>) {
    event.preventDefault();
    event.stopPropagation();

    const point = svgRef.current.createSVGPoint();

    point.x = event.clientX;
    point.y = event.clientY;

    const transformedPoint = point.matrixTransform(svgRef.current.getScreenCTM().inverse());

    onClick([transformedPoint.x, transformedPoint.y]);
  }
}
