import { DialogTrigger } from '@radix-ui/react-dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircle } from 'styled-icons/bootstrap';

import { Button } from '~/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '~/components/ui/dialog';

import { NewContainerForm } from './new-container-form';

type Props = {
  book: any;
};

export function NewContainerDialog(props: Props) {
  const { t } = useTranslation();

  const { book } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button data-testid="new-container-template-button" variant="outline">
          <PlusCircle className="w-4 h-4 mr-2" />
          {t('common.buttons.add')}
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('pages.booksEdit.dialogs.newContainerTemplate.title')}</DialogTitle>
        </DialogHeader>

        <NewContainerForm closeDialog={() => setOpen(false)} book={book} />
      </DialogContent>
    </Dialog>
  );
}
