import React from 'react';

import ClassGroupsApi from '~/api/ClassGroupsApi';
import { LayoutBook } from '~/components/ui-extended/layout-book';

import PageContent from './components/page-content';
import { useClassGroupMembersIndexPage } from './T_ClassGroupMembersIndex';

export default function Index() {
  const { access, book, classGroup } = useClassGroupMembersIndexPage().props;

  return (
    <LayoutBook
      breadcrumbs={[
        {
          title: 'models.group.many',
          translateTitle: true,
          type: 'link',
          href: ClassGroupsApi.index.path({ book_slug: book.slug }),
        },
        { title: classGroup.name, type: 'static' },
      ]}
      htmlHeadTitle={book.title}
      access={access}
      book={book}
      sidebarTitle={book.title}>
      <PageContent />
    </LayoutBook>
  );
}
