import React from 'react';
import styled from 'styled-components';

import { T_Link } from '~/types/node/T_Link';

import { Text } from '../text/Text';

type Props = {
  node: T_Link['ViewNode'];
};

export function Link(props: Props) {
  const { node } = props;

  return (
    <S.A
      target="_blank"
      rel="noreferrer"
      href={node.url}
      style={{ color: '#174489' }}
      className="underline text-blue-700">
      {node.children.map((textNode, index) => (
        <Text key={index} node={textNode} />
      ))}
    </S.A>
  );
}

const S = {
  A: styled.a`
    &::before {
      color: '#174489';
      content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" /></svg>');
      display: inline-block;
      width: 1rem;
      height: 1rem;
      margin-right: 0.125rem;
    }
  `,
};
