import React from 'react';

import { T_ListItem } from '~/types/node/T_ListItem';
import { T_NodeType } from '~/types/T_NodeTypes';

import { OrderedList } from '../ordered-list/OrderedList';
import { UnorderedList } from '../unordered-list/UnorderedList';
import { ListItemContent } from './ListItemContent';

type Props = {
  node: T_ListItem['ViewNode'];
};

export function ListItem(props: Props) {
  const { node } = props;

  return (
    <li className="text-gray-700 font-light">
      {node.children.map((contentNode, index) => {
        switch (contentNode.type) {
          case T_NodeType.LIST_ITEM_CONTENT: {
            return <ListItemContent key={index} node={contentNode} />;
          }
          case T_NodeType.LIST_ORDERED: {
            return <OrderedList key={index} node={contentNode} nested />;
          }
          case T_NodeType.LIST_UNORDERED: {
            return <UnorderedList key={index} node={contentNode} nested />;
          }
        }
      })}
    </li>
  );
}
