import React from 'react';

import { T_TextMultiLine } from '~/types/node/T_TextMultiLine';
import { NodeMap } from '~/views/print/NodeMap';

type Props = {
  node: T_TextMultiLine['ViewNode'];
};

export function Explanation(props: Props) {
  const { node } = props;

  return (
    <div className="text-gray-700">
      <div className="space-y-2 w-full relative my-1">
        <NodeMap nodes={node.children} />
      </div>
    </div>
  );
}
