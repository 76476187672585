import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExerciseDifficultyLevel } from '~/components/pages-shared/exercise-difficulty-level';
import { T_Exercise } from '~/types/node/exercise/T_Exercise';

import { Gutter } from '../../components/Gutter';
import { useViewPrintContext } from '../../useViewPrintContext';
import { Answerable } from './Answerable';
import { Feedback } from './Feedback';
import { Hint } from './Hint';
import { Question } from './Question';

type Props = {
  node: T_Exercise['ViewNode'];
};

export function Exercise(props: Props) {
  const { t } = useTranslation();

  const { node } = props;

  const { exercise_layout } = useViewPrintContext();

  return (
    <div data-testid={node.type} className="my-4 breakpoint" data-break-unless-height="65mm">
      <div className="text-xs text-gray-500">
        <Gutter
          content={
            <div>
              <h6>{`${generateGutterTitle()}`}</h6>
              <div className="flex items-center justify-end mt-2">
                <ExerciseDifficultyLevel difficulty_level={node.difficulty_level} />
              </div>
            </div>
          }
        />
      </div>

      <Question node={node.question} />
      <Hint />

      {exercise_layout === 'standard_answers' && <Feedback node={node.feedback} />}

      <Answerable answerable={node.answerable_value} />
    </div>
  );

  function generateGutterTitle() {
    const { number } = node;

    return node.behavior === 'static'
      ? t('common.nodes.question_n', { n: number })
      : t('common.nodes.exercise_n', { n: number });
  }
}
