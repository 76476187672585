import React from 'react';

import { T_AnswerableSubExerciseItem } from '~/types/node/exercise/answerables/sub-exercise-list/T_AnswerableSubExerciseItem';
import { Gutter } from '~/views/print/components/Gutter';

import { Answerable } from '../../Answerable';
import { Question } from '../../Question';

type Props = {
  subExercise: T_AnswerableSubExerciseItem['ViewNode'];
};

export function SubExerciseItem(props: Props) {
  const { subExercise } = props;

  return (
    <div className="break-inside-avoid-page mt-[5mm]">
      <div className="text-xs text-gray-500">
        <Gutter content={`${subExercise.number})`} />
      </div>

      <Question node={subExercise.question} />

      <Answerable answerable={subExercise.answerable_value} />
    </div>
  );
}
