import { zodResolver } from '@hookform/resolvers/zod';
import { router } from '@inertiajs/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import ContainerTemplatesApi from '~/api/ContainerTemplatesApi';
import { Button } from '~/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import { T_Book as T_Book, T_ContainerTemplate as T_ContainerTemplate } from '~/types/serializers';
import { E_TemplateIcon } from '~/types/T_TemplateIcon';

import { ColorPicker } from './color-picker';
import { IconPicker } from './icon-picker';

const formSchema = z.object({
  name: z.string().min(2, {
    message: 'Naam moeten minstens 2 tekens bevatten.',
  }),
  color: z.string(),
  icon: z
    .enum([null, ...Object.keys(E_TemplateIcon)])
    .optional()
    .nullable(),
});

type Props = {
  book: T_Book;
  closeDialog: () => void;
  containerTemplate: T_ContainerTemplate;
};

export function UpdateContainerForm(props: Props) {
  const { t } = useTranslation();

  const { book, closeDialog, containerTemplate } = props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: containerTemplate.name,
      color: containerTemplate.color,
      icon: containerTemplate.icon,
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('pages.booksEdit.forms.editContainerTemplate.labels.name')}</FormLabel>
              <FormControl>
                <Input type="text" placeholder="Experiment" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="color"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('pages.booksEdit.forms.editContainerTemplate.labels.color')}</FormLabel>
              <FormControl>
                <ColorPicker
                  selectedColor={field.value}
                  onSelect={(color) => form.setValue('color', color)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="icon"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('pages.booksEdit.forms.editContainerTemplate.labels.icon')}</FormLabel>
              <FormControl>
                <IconPicker
                  selectedIcon={field.value}
                  onSelect={(icon) => form.setValue('icon', icon)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button data-testid="update-container-template-button" type="submit">
          {t('common.buttons.save')}
        </Button>
      </form>
    </Form>
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.patch(
      ContainerTemplatesApi.update.path({ book_slug: book.slug, id: containerTemplate.id }),
      values,
      {
        replace: true,
        onSuccess: () => {
          form.reset();
          closeDialog();
        },
      },
    );
  }
}
