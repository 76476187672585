import React from 'react';
import { useTranslation } from 'react-i18next';
import { HandRaised } from 'styled-icons/heroicons-solid';

import { T_AnswerableFillList } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillList';
import { T_AnswerableFillListItem } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillListItem';

import { SentInAnswersWrapper } from '../../shared/SentInAnswersWrapper';
import { OpenTextView } from './OpenTextView';
import { PercentageView } from './PercentageView';

type Props = {
  activeItem: T_AnswerableFillListItem['ViewNode'] | null;
  answerable: T_AnswerableFillList['ViewNode'];
};

export function SentInAnswers(props: Props) {
  const { t } = useTranslation();

  const { activeItem, answerable } = props;

  const answers = getAnswerNodes();

  return (
    <SentInAnswersWrapper>
      <div className="flex flex-col">
        <div className="border-t mt-1">
          {activeItem ? (
            answerable.answer_by === 'select' ? (
              <PercentageView answerNodes={answers} activeItem={activeItem} />
            ) : (
              <OpenTextView activeItem={activeItem} />
            )
          ) : (
            <div className="mt-1 flex items-center text-gray-500">
              <button
                className="bg-gray-500 p-0.5 rounded mr-2"
                data-tip="Toon leerling-antwoorden voor dit item">
                <HandRaised className="h-4 w-4 text-white" />
              </button>
              {t('pages.courseContentsShow.nodes.exercise.selectItemToSeeSentInAnswers')}
            </div>
          )}
        </div>
      </div>
    </SentInAnswersWrapper>
  );

  function getAnswerNodes() {
    return answerable.items.map((item) => ({ ...item.answer, nodeKey: item.nodeKey })).flat();
  }
}
