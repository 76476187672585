import React, { useRef, useState } from 'react';

import { Dialog, DialogContent } from '~/components/ui/dialog';
import { useHover } from '~/hooks/useHover';
import { T_BaseText, T_BaseViewNode } from '~/node-builder/types';

import { Anchor } from './components/Anchor';
import { AddEnrichmentForm, EnrichmentsList } from './components/enrichments';
import { GutterContentContainer } from './components/Gutter';
import { ElementMenu } from './components-new/element-menu';

type Props = {
  children?: React.ReactElement;
  node: T_BaseViewNode | T_BaseText;
};

export function Wrapper(props: Props) {
  const { children, node } = props;

  const contentRef = useRef<HTMLDivElement>(null);
  const contentIsHovered = useHover(contentRef);

  const [showAddEnrichmentDialog, setShowAddEnrichmentDialog] = useState(false);

  if (!('type' in node)) return null;

  return (
    <Dialog open={showAddEnrichmentDialog} onOpenChange={setShowAddEnrichmentDialog}>
      <div
        ref={contentRef}
        className="w-full relative my-6 first:my-1 last:my-1"
        data-testid={`node-${node.nodeKey}`}
        data-node-key={node.nodeKey}
        data-node-type={node.type}>
        <Anchor id={node.nodeKey} />

        <ElementMenu show={contentIsHovered} node={node} />

        <GutterContentContainer>
          {children}
          <EnrichmentsList nodeKey={node.nodeKey} />
        </GutterContentContainer>
      </div>

      <DialogContent>
        <AddEnrichmentForm nodeKey={node.nodeKey} />
      </DialogContent>
    </Dialog>
  );
}
