import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  children: React.ReactNode;
  rightSideContent?: React.ReactNode;
  title: string;
  translateTitle?: boolean;
};

export function DataTableContainer(props: Props) {
  const { t } = useTranslation();

  const { children, rightSideContent, title, translateTitle } = props;

  return (
    <div className="mx-auto h-full py-2 lg:py-10 max-w-6xl px-4 sm:px-8 lg:px-8">
      <div className="flex items-end justify-between border-b pb-2">
        <h2 className="text-2xl font-bold">{translateTitle ? t(title) : title}</h2>
        {rightSideContent}
      </div>

      <div className="mt-4 h-full">{children}</div>
    </div>
  );
}
