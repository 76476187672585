import React from 'react';

import { NestedParts } from './nested-parts';

type Props = {
  access: any;
  part: any;
};

export function Part(props: Props) {
  const { access, part } = props;

  return (
    <div className="mt-8" data-testid={`part_${part.slug}`}>
      <h3 className="text-xl font-bold">{part.title}</h3>

      <NestedParts access={access} nestedParts={part.children} />
    </div>
  );
}
