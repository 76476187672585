import React from 'react';

import { T_AnswerableSubExerciseItem } from '~/types/node/exercise/answerables/sub-exercise-list/T_AnswerableSubExerciseItem';
import { GutterTitle } from '~/views/book/components/Gutter';

import { Answerable } from '../../Answerable';
import { Question } from '../../Question';

type Props = {
  node: T_AnswerableSubExerciseItem['ViewNode'];
  layout: 'list' | 'carousel';
};

export function SubExerciseItem(props: Props) {
  const { node, layout } = props;

  return (
    <>
      {layout === 'list' && (
        <GutterTitle>
          <div className="text-sm text-gray-500 font-medium flex justify-end">
            <div className="bg-gray-100 w-7 h-7 rounded-full flex items-center justify-center">
              {node.number}
            </div>
          </div>
        </GutterTitle>
      )}

      <Question subExercise node={node.question} />

      <Answerable answerable={node.answerable_value} subExercise />
    </>
  );
}
