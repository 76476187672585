import React from 'react';

import { T_LayoutImageText } from '~/types/node/T_LayoutImageText';

import { Image } from './Image';
import { Text } from './Text';

type Props = {
  children: React.ReactElement;
  node: T_LayoutImageText['ViewNode'];
};

export function LayoutImageText(props: Props) {
  const { node } = props;

  const { image_position } = node;

  return (
    <div
      data-testid={`${node.type} ${node.nodeKey}`}
      className="flex flex-row w-full my-[5mm] [&>p]:first-child:mt-0">
      {renderChildren()}
    </div>
  );

  function renderChildren() {
    const children = [
      <Text key="content" node={node.content} />,
      <Image key="image" node={node.image} />,
    ];

    return image_position === 'right' ? children : children.reverse();
  }
}
