import { usePage } from '@inertiajs/react';

import { T_Sect1Element } from '~/types/nodes';
import {
  T_Access,
  T_Ancestry,
  T_Book,
  T_ClassGroup,
  T_CourseContent,
  T_CourseContentable,
  T_Discussion,
} from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';

export const useDiscussionsShowPage = usePage<T_DiscussionsShowProps>;

export interface T_DiscussionsShowProps extends T_PageProps {
  access: T_Access;
  book: T_Book;
  bookContent: T_Sect1Element;
  courseContent: T_CourseContent;
  customSections: T_CourseContentable[];
  parts: T_Ancestry[];
  discussion: T_Discussion;
  studentClassGroup?: T_ClassGroup;
}
