import { Link, usePage } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BooksApi from '~/api/BooksApi';
import CourseContentsApi from '~/api/CourseContentsApi';
import { SidebarMenuWrapper } from '~/components/ui-extended/sidebar-menu-wrapper';
import { T_Book, T_CourseContent } from '~/types/serializers';
import T_AncestryPart from '~/types/T_AncestryPart';

import { TypographyMuted } from '../../../../components/typography/muted';
import { SidebarButton } from '../../../../components/ui-extended/sidebar-button';

type Props = {
  book: T_Book;
  chapter?: T_AncestryPart;
  courseContent?: T_CourseContent;
};

export function SidebarEditorMenu(props: Props) {
  const { t } = useTranslation();

  const { book, chapter, courseContent } = props;

  const { component } = usePage();

  return (
    <>
      <div className="flex justify-between items-center mt-4">
        <TypographyMuted>{t('common.nouns.content')}</TypographyMuted>

        <Link
          className="hover:underline text-xs font-medium"
          href={BooksApi.editStructure.path({ slug: book.slug })}>
          {t('common.sidebar.courseContentOptions.backToBook')}
        </Link>
      </div>

      <SidebarMenuWrapper className="border-t mt-1 pt-1 overflow-y-auto">
        {(chapter.sections ?? []).map((section) => {
          const active =
            component === 'course-contents/show' &&
            section.id === courseContent?.courseContentable.id;

          return (
            <SidebarButton
              key={section.id}
              data-testid={`section-link-${section.slug}`}
              active={active}
              href={CourseContentsApi.editor.path({ slug: section.slug })}
              title={section.numberedTitle}
            />
          );
        })}
      </SidebarMenuWrapper>
    </>
  );
}
