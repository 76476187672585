import { ArrowDownIcon } from '@radix-ui/react-icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '~/components/ui/card';
import { T_Flashcard } from '~/types/serializers';
import { T_NodeType } from '~/types/T_NodeTypes';

import { DefinitionBack, DefinitionFront } from './flashcard-item-definition';
import { MarkedImageBack, MarkedImageFront } from './flashcard-item-marked-image';
import { WordItemBack, WordItemFront } from './flashcard-item-word-item';

type Props = {
  flashcard: T_Flashcard;
};

export function Flashcard(props: Props) {
  const { t } = useTranslation();

  const { flashcard } = props;

  const [showBack, setShowBack] = useState(false);

  useEffect(() => {
    setShowBack(false);
  }, [flashcard]);

  return (
    <div className="min-w-[200px] w-[450px] mx-auto">
      <Card className="w-full  overflow-hidden p-0.5">
        <div className="p-2">
          <div
            data-testid="flashcard-front"
            className="min-h-[100px] flex items-center justify-center">
            {renderFrontSide()}
          </div>
          {showBack && (
            <div data-testid="flashcard-back" className="border-t p-2">
              {renderBackSide()}
            </div>
          )}
        </div>

        {!showBack && (
          <div
            data-testid="show-answer-button"
            onClick={() => setShowBack(true)}
            className="bg-stone-50 p-3 overflow-hidden  rounded-b-lg flex justify-center items-center hover:cursor-pointer">
            <div className="flex items-center">
              <ArrowDownIcon className="w-4 h-4 mr-1" />
              <p className="text-stone-600 text-sm font-medium">
                {t('pages.partsFlashcards.buttons.showAnswer')}
              </p>
            </div>
          </div>
        )}
      </Card>
    </div>
  );

  function renderFrontSide() {
    switch (flashcard.type) {
      case T_NodeType.DEFINITION:
        return <DefinitionFront flashcard={flashcard} />;
      case T_NodeType.WORD_ITEM:
        return <WordItemFront flashcard={flashcard} />;
      case T_NodeType.MARKED_IMAGE:
        return <MarkedImageFront flashcard={flashcard} />;
    }
  }

  function renderBackSide() {
    switch (flashcard.type) {
      case T_NodeType.DEFINITION:
        return <DefinitionBack flashcard={flashcard} />;
      case T_NodeType.WORD_ITEM:
        return <WordItemBack flashcard={flashcard} />;
      case T_NodeType.MARKED_IMAGE:
        return <MarkedImageBack flashcard={flashcard} />;
    }
  }
}
