import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { T_AnswerableFillListItem } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillListItem';
import { T_AnswerableFillListItemAnswer } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillListItemAnswer';

import { useSentInAnswerProps } from '../../context/AnswerablePropsContext';

type Props = {
  activeItem: T_AnswerableFillListItem['ViewNode'] | null;
  answerNodes: T_AnswerableFillListItemAnswer['ViewNode'][];
};

export function PercentageView(props: Props) {
  const { t } = useTranslation();

  const { activeItem, answerNodes } = props;

  const { sentInAnswers, includeName } = useSentInAnswerProps();

  console.log('ACTIVE ITEM', answerNodes);

  const selectedAnswersWithCount = useMemo(
    () => getAnswersWithUserCount(activeItem?.nodeKey, sentInAnswers, answerNodes),
    [activeItem, sentInAnswers],
  );

  return (
    <React.Fragment>
      {Object.entries(selectedAnswersWithCount).map(([key, value]) => {
        const percentage = ((value.count / sentInAnswers.length) * 100).toFixed(2);

        return (
          <div
            className="mb-4 p-3 bg-white rounded-md border my-2 text-gray-700 font-medium text-lg"
            key={key}>
            {key === 'notFilledIn' ? (
              <span>{t('pages.courseContentsShow.nodes.exercise.notFilledIn')}</span>
            ) : (
              value.answerNode.text
            )}
            <span> ({value.count})</span>

            <div className={clsx('bg-purple-400 rounded-xl mt-1 h-1.5', `w-[${percentage}%]`)} />

            {includeName && (
              <div className="flex mt-3 mb-6 flex-wrap">
                {value.sentInAnswers.map((sentInAnswer, index) => {
                  return (
                    <div
                      className="border text-sm font-normal shadow border-gray-100 text-gray-600 bg-yellow-50 rounded-lg px-2 py-0.5 mr-2"
                      key={index}>
                      {sentInAnswer.user.fullName}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </React.Fragment>
  );

  type AnswersWithUserCount = {
    [nodeKey: string]: {
      count: number;
      answerNode: T_AnswerableFillListItemAnswer['ViewNode'];
      sentInAnswers: any[];
    };
  };

  // TODO: Refactor dit samen met dezelfde functie bij fill gaps

  function getAnswersWithUserCount(
    activeNodeKey: string | null,
    sentInAnswers: any[],
    answerNodes: T_AnswerableFillListItemAnswer['ViewNode'][],
  ): AnswersWithUserCount {
    if (!activeNodeKey) return {};

    const answersWithUserCount: AnswersWithUserCount = {
      notFilledIn: { count: 0, answerNode: null, sentInAnswers: [] },
    };

    for (const sentInAnswer of sentInAnswers) {
      const sentInAnswerNodeKey = sentInAnswer.value.answersByKey[activeNodeKey];

      const selectedAnswer = answerNodes.find(
        (chosenAnswerNode) => chosenAnswerNode.nodeKey === sentInAnswerNodeKey,
      );

      if (!selectedAnswer) {
        answersWithUserCount.notFilledIn.count++;
        answersWithUserCount.notFilledIn.sentInAnswers.push(sentInAnswer);

        continue;
      }

      const answerWithUserCount = answersWithUserCount[selectedAnswer.nodeKey];

      if (answerWithUserCount) {
        answerWithUserCount.count++;
        answerWithUserCount.sentInAnswers = [...answerWithUserCount.sentInAnswers, sentInAnswer];
      } else {
        answersWithUserCount[selectedAnswer.nodeKey] = {
          count: 1,
          answerNode: selectedAnswer,
          sentInAnswers: [sentInAnswer],
        };
      }
    }

    if (!answersWithUserCount.notFilledIn.count) delete answersWithUserCount.notFilledIn;

    return answersWithUserCount;
  }
}
