import React from 'react';

import { T_AnswerableFillGaps } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGaps';
import { T_NodeType } from '~/types/T_NodeTypes';
import {
  findNodeByProperty,
  forEachNode,
} from '~/views/book/nodes/exercise/answerables/fill-gaps/helpers/gapIterators';
import { useViewPrintContext } from '~/views/print/useViewPrintContext';

import { AnswerInputFields } from './AnswerInputFields';
import { ParagraphList } from './ParagraphList';
import { PossibleAnswers } from './PossibleAnswers';

type Props = {
  children: React.ReactNode;
  answerable: T_AnswerableFillGaps['ViewNode'];
};

export function FillGaps(props: Props) {
  const { answerable } = props;

  const { exercise_layout, user_answers } = useViewPrintContext();

  const gapNodes = formatGaps();

  return (
    <>
      <ParagraphList answerable={answerable} />

      {answerable.show_possible_answers && <PossibleAnswers gaps={gapNodes} />}
      <AnswerInputFields gaps={gapNodes} />
    </>
  );

  function formatGaps() {
    const gaps: Record<string, any>[] = [];
    let gapCount = 0;

    forEachNode(answerable, T_NodeType.FILL_GAPS_GAP, (gap) => {
      gap.number = ++gapCount;

      let answer;

      switch (exercise_layout) {
        case 'empty': {
          answer = null;
          break;
        }
        case 'standard_answers': {
          answer = gap.children[0].text;
          break;
        }
        case 'user_answers': {
          const userAnswer = user_answers.answers.find(
            (answer) => answer.answerable.node_key === answerable.nodeKey,
          );

          if (!userAnswer) break;

          if (answerable.answer_by === 'text') {
            answer = userAnswer?.value.fillGaps[gap.nodeKey];
          } else {
            const selectedNodeKey = userAnswer?.value.fillGaps[gap.nodeKey];

            const selectedNode = findNodeByProperty(answerable, 'nodeKey', selectedNodeKey);

            answer = selectedNode?.children?.[0]?.text;
          }

          break;
        }
      }

      gap.answer = answer;

      gaps.push(gap);
    });

    return gaps;
  }
}
