import { router } from '@inertiajs/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DiscussionCommentsApi from '~/api/DiscussionCommentsApi';
import { Button } from '~/components/ui/button';

export function CommentForm(props) {
  const { t } = useTranslation();

  const { discussion, courseContent } = props;

  const [commentBody, setCommentBody] = useState('');

  return (
    <form onSubmit={handleSubmitComment}>
      <div className="mt-6 sm:col-span-6">
        <label htmlFor="commentBody" className="block text-sm font-medium text-gray-700">
          {t('pages.discussionsShow.buttons.addComment')}
        </label>

        <div className="mt-1">
          <textarea
            value={commentBody}
            onChange={(event) => setCommentBody(event.target.value)}
            id="comment-body"
            name="comment-body"
            rows={3}
            className="block w-full border border-gray-300 resize-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"></textarea>
        </div>
      </div>

      <div className="pt-2">
        <div className="flex justify-end">
          <Button disabled={!commentBody} type="submit">
            {t('common.buttons.add')}
          </Button>
        </div>
      </div>
    </form>
  );

  function handleSubmitComment(event) {
    event.preventDefault();

    router.post(
      DiscussionCommentsApi.create.path({
        course_content_slug: courseContent.slug,
        discussion_id: discussion.id,
      }),
      { body: commentBody },
      {
        preserveState: false,
        replace: true,
        only: ['discussion'],
      },
    );
  }
}
