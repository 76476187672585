import React from 'react';

import { T_TextSingleLine } from '~/types/node/T_TextSingleLine';

import { SourceButton } from '../../components-new/nodes-shared/source-button';
import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_TextSingleLine['ViewNode'];
  source?: string;
};

export function Title(props: Props) {
  const { node, source } = props;

  return (
    <div className="flex mt-1.5">
      <div className="flex text-sm font-medium text-gray-700">
        <NodeMap nodes={node.children} />
      </div>

      <SourceButton source={source} />
    </div>
  );
}
