import { Link, router } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import CourseContentsApi from '~/api/CourseContentsApi';
import CustomSectionsApi from '~/api/CustomSectionsApi';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '~/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import { cn } from '~/utils';

import { UpdateCustomSectionForm } from './update-custom-section-form';

type Props = {
  access: any;
  customSection: any;
  partSlug: string;
  number: any;
};

export function CustomSection(props: Props) {
  const { t } = useTranslation();

  const { access, customSection, partSlug, number } = props;

  const [open, setOpen] = React.useState(false);

  if (access.student) {
    return (
      <Link
        href={CourseContentsApi.show.path({ slug: customSection.courseContent.slug })}
        className="text-sm truncate hover:underline text-gray-700 font-medium cursor-pointer"
        data-testid={`custom_section_item_${customSection.slug}`}>
        {customSection.title}
      </Link>
    );
  }

  return (
    <div className="flex">
      <Dialog open={open} onOpenChange={setOpen}>
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger asChild>
            <StyledDiv
              beforeContent={`D${number}`}
              className={cn(
                'text-sm mb-1.5 truncate hover:underline text-gray-700 font-medium cursor-pointer underline',
                'before:inline-block before:w-8 before:text-gray-500',
              )}>
              {customSection.title}
            </StyledDiv>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuLabel>{customSection.title}</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              data-testid="custom_section_view_button"
              onClick={() =>
                router.visit(
                  CourseContentsApi.show.path({ slug: customSection.courseContent.slug }),
                )
              }>
              {t('pages.booksShow.menus.customSectionOptions.buttons.viewCustomSection')}
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              data-testid="custom_section_editor_button"
              onClick={() =>
                router.visit(
                  CourseContentsApi.editor.path({ slug: customSection.courseContent.slug }),
                )
              }>
              {t('pages.booksShow.menus.customSectionOptions.buttons.editCustomSection')}
            </DropdownMenuItem>
            <DropdownMenuItem data-testid="custom_section_edit_title_button">
              <DialogTrigger className="w-full text-left">
                {t('pages.booksShow.menus.customSectionOptions.buttons.changeTitle')}
              </DialogTrigger>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={removeCustomSection}
              data-testid="custom_section_remove_button">
              {t('pages.booksShow.menus.customSectionOptions.buttons.removeCustomSection')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

        <DialogContent>
          <DialogHeader>
            <DialogTitle> {t('pages.booksShow.dialogs.editCustomSection.title')}</DialogTitle>
          </DialogHeader>

          <UpdateCustomSectionForm
            customSection={customSection}
            closeDialog={() => setOpen(false)}
            partSlug={partSlug}
          />
        </DialogContent>
      </Dialog>
    </div>
  );

  function removeCustomSection() {
    if (confirm(t('pages.booksShow.confirmations.removeCustomSection'))) {
      router.delete(CustomSectionsApi.destroy.path({ part_slug: partSlug, id: customSection.id }), {
        preserveScroll: true,
        preserveState: true,
      });
    }
  }
}

const StyledDiv = styled.div<{ beforeContent: string }>((props) => {
  return `
    &::before {
      content: "${props.beforeContent}";
    }
    `;
});
