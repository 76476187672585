import { zodResolver } from '@hookform/resolvers/zod';
import { router } from '@inertiajs/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import BooksApi from '~/api/BooksApi';
import { Button } from '~/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '~/components/ui/form';
import { Switch } from '~/components/ui/switch';

const formSchema = z.object({
  printable: z.boolean(),
});

type Props = {
  book: any;
};

export function PagePrint(props: Props) {
  const { t } = useTranslation();

  const { book } = props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      printable: book.printable,
    },
  });

  return (
    <div className="space-y-6">
      <div className="border-b pb-6 mb-4">
        <h3 className="text-lg font-medium">{t('pages.booksEdit.text.pagePrintTitle')}</h3>
        <p className="text-sm text-muted-foreground">
          {t('pages.booksEdit.text.pagePrintDescription')}
        </p>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 lg:max-w-2xl">
          <FormField
            control={form.control}
            name="printable"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('pages.booksEdit.forms.print.labels.printSettings')}</FormLabel>
                <div className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
                  <div className="space-y-0.5">
                    <FormDescription>
                      {t('pages.booksEdit.forms.print.descriptions.allowPrinting')}.
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                </div>
              </FormItem>
            )}
          />

          <Button type="submit">{t('common.buttons.save')}</Button>
        </form>
      </Form>
    </div>
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.put(BooksApi.update.path({ slug: book.slug }), values, {
      replace: true,
    });
  }
}
