import React from 'react';
import { useTranslation } from 'react-i18next';

import { T_Media } from '~/types/node/T_Media';

import { Gutter } from '../../components/Gutter';
import { Source } from './Source';
import { Title } from './Title';

type Props = {
  node: T_Media['ViewNode'];
};

export function Media(props: Props) {
  const { t } = useTranslation();

  const { node } = props;

  return (
    <div data-testid={node.type} className="break-inside-avoid relative mb-1">
      <div className="text-xs text-gray-500">
        <Gutter content={t('common.nodes.media')} />
      </div>
      <Title node={node.title} />
      <Source />
    </div>
  );
}
