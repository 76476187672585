// JsFromRoutes CacheKey 8ffed10daf1d6c73cece47d67a419fab
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  sort: /* #__PURE__ */ definePathHelper('patch', '/parts/:part_slug/sections/:id/sort'),
  create: /* #__PURE__ */ definePathHelper('post', '/parts/:part_slug/sections'),
  update: /* #__PURE__ */ definePathHelper('patch', '/parts/:part_slug/sections/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/parts/:part_slug/sections/:id'),
}
