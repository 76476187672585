import React from 'react';

import { Switch } from '~/components/ui/switch';

import { useSentInAnswerProps } from '../context/AnswerablePropsContext';

export function SentInNamesButton() {
  const { includeName, setIncludeName } = useSentInAnswerProps();

  return (
    <div className="text-gray-700 font-medium text-left text-sm flex items-center">
      Toon Namen
      <Switch
        className="ml-2"
        checked={includeName}
        onCheckedChange={() => setIncludeName(!includeName)}
      />
    </div>
  );
}
