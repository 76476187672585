import React from 'react';

import { T_BaseViewNode } from '~/node-builder/types';
import { T_NodeType, T_NodeTypeValue } from '~/types/T_NodeTypes';

import { AddDiscussionButton } from './add-discussion-button';
import { AddEnrichmentButton } from './add-enrichment-button';
import { CopyElementUrlButton } from './copy-element-url-button';
import { FocusNodeButton } from './focus-node-button';

type Props = {
  node: T_BaseViewNode;
  show: boolean;
};

export function ElementMenu({ node, show }: Props) {
  if (!show || ([T_NodeType.SECT1, T_NodeType.MANUAL] as T_NodeTypeValue[]).includes(node.type)) {
    return null;
  }

  return (
    <div className="absolute flex items-center bg-white p-0.5 border border-gray-400 -right-2 -top-9 rounded-md shadow-md z-[5]">
      <CopyElementUrlButton nodeKey={node.nodeKey} />
      <AddEnrichmentButton />
      <AddDiscussionButton nodeKey={node.nodeKey} />
      <FocusNodeButton nodeKey={node.nodeKey} />
    </div>
  );
}
