import { DoubleArrowLeftIcon, DoubleArrowRightIcon } from '@radix-ui/react-icons';
import React from 'react';

import { Button } from '~/components/ui/button';

type Props = {
  currentPosition: number;
  flashcardCount: number;
  showNextFlashcard: () => void;
  showPreviousFlashcard: () => void;
};

export function Navigation(props: Props) {
  const { currentPosition, flashcardCount, showNextFlashcard, showPreviousFlashcard } = props;

  return (
    <div className="rounded-md absolute bottom-10 left-1/2 -translate-x-1/2 bg-stone-100 w-[400px] p-3 text-sm font-medium text-gray-700 flex items-center justify-between">
      <Button
        size="icon"
        variant="outline"
        className="w-7 h-7 bg-white"
        onClick={showPreviousFlashcard}>
        <DoubleArrowLeftIcon className="w-4 h-4" />
      </Button>

      <span className="mx-3">
        {currentPosition} / {flashcardCount}
      </span>

      <Button
        size="icon"
        variant="outline"
        className="w-7 h-7 bg-white"
        onClick={showNextFlashcard}>
        <DoubleArrowRightIcon className="w-4 h-4" />
      </Button>
    </div>
  );
}
