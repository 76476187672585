import React from 'react';

import { T_HeadingThree } from '~/types/node/T_HeadingThree';
import { NodeMap } from '~/views/print/NodeMap';

type Props = {
  node: T_HeadingThree['ViewNode'];
};

export function HeadingThree(props: Props) {
  const { node } = props;

  return (
    <h5 data-testid={node.type} className="text-[11pt] font-bold">
      <NodeMap nodes={node.children} />
    </h5>
  );
}
