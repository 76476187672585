// JsFromRoutes CacheKey 6e2fcfc38edac1efd980e1ecde862cc1
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  archive: /* #__PURE__ */ definePathHelper('post', '/b/:book_slug/class_groups/:class_group_id/archive'),
  provideAnswers: /* #__PURE__ */ definePathHelper('post', '/b/:book_slug/class_groups/:class_group_id/provide_answers'),
  join: /* #__PURE__ */ definePathHelper('post', '/b/:book_slug/class_groups/join'),
  index: /* #__PURE__ */ definePathHelper('get', '/b/:book_slug/class_groups'),
  create: /* #__PURE__ */ definePathHelper('post', '/b/:book_slug/class_groups'),
  show: /* #__PURE__ */ definePathHelper('get', '/b/:book_slug/class_groups/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/b/:book_slug/class_groups/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/b/:book_slug/class_groups/:id'),
}
