import React from 'react';

import { T_AnswerablesViewNodes } from '~/node-builder/nodes/exercise/answerables/types';

import { useExerciseProps } from '../context/ExercisePropsContext';
import { Feedback } from '../Feedback';
import { Hint } from '../Hint';
import { HintButton } from './HintButton';
import { RemakeButton } from './RemakeButton';
import { SentInAnswersButton } from './SentInAnswersButton';
import { StandardAnswerButtons } from './StandardAnswerButtons';

type Props<T> = {
  answerable: T;
  remakable: boolean;
  remakeMode: boolean;
  sentInAnswersRender?: (props: { answerable: T }) => JSX.Element;
  standardAnswerRender?: (props: { answerable: T; subExercise?: boolean }) => JSX.Element;
  setRemakeMode: (value: boolean) => void;
  subExercise: boolean;
};

export function AnswerableControls<T = T_AnswerablesViewNodes['answerable_value']>(
  props: Props<T>,
) {
  const {
    answerable,
    remakable,
    remakeMode,
    sentInAnswersRender,
    setRemakeMode,
    standardAnswerRender,
    subExercise,
  } = props;

  const { access, studentAnswerPresent } = useExerciseProps();

  return (
    <>
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-1">
          {!subExercise && <HintButton />}

          {access.student && !studentAnswerPresent ? null : (
            <StandardAnswerButtons access={access} />
          )}

          <SentInAnswersButton access={access} />
        </div>

        <RemakeButton remakable={remakable} remakeMode={remakeMode} setRemakeMode={setRemakeMode} />
      </div>

      {!subExercise && <Hint />}

      {standardAnswerRender && standardAnswerRender({ answerable, subExercise })}

      <Feedback />

      {sentInAnswersRender && sentInAnswersRender({ answerable })}
    </>
  );
}
