// JsFromRoutes CacheKey 1cdc29f751d1350717edd5a15efe871a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  home: /* #__PURE__ */ definePathHelper('get', '/home'),
  support: /* #__PURE__ */ definePathHelper('get', '/support'),
  pricing: /* #__PURE__ */ definePathHelper('get', '/pricing'),
  contact: /* #__PURE__ */ definePathHelper('get', '/contact'),
  courses: /* #__PURE__ */ definePathHelper('get', '/opleidingen'),
  tutorials: /* #__PURE__ */ definePathHelper('get', '/tutorials'),
  jobs: /* #__PURE__ */ definePathHelper('get', '/jobs'),
  subscribe: /* #__PURE__ */ definePathHelper('post', '/subscribe'),
  trainings: /* #__PURE__ */ definePathHelper('get', '/trainings'),
  training: /* #__PURE__ */ definePathHelper('get', '/training/:id'),
}
