import React from 'react';
import { useTranslation } from 'react-i18next';

import { nodeIsEmpty } from '~/lib/node-renderer/nodeHelpers';

import { NodeMap } from '../../NodeMap';
import { useStandardAnswerProps } from './context/AnswerablePropsContext';
import { useExerciseProps } from './context/ExercisePropsContext';

export function Feedback() {
  const { t } = useTranslation();

  const { showAnswer } = useStandardAnswerProps();

  const { node } = useExerciseProps();

  if (nodeIsEmpty(node.feedback) || showAnswer !== 'all') return null;

  return (
    <div
      className="rounded-md bg-green-50 border mb-2 p-3 mt-2 [&_p]:text-sm [&_p]:p-0 [&_p]:text-gray-600"
      data-testid="feedback">
      <span className="text-sm text-gray-600 font-semibold mr-2">
        {t('common.nodes.exerciseFeedback')}:{' '}
      </span>
      <NodeMap nodes={node.feedback.children} />
    </div>
  );
}
