import { usePage } from '@inertiajs/react';

import {
  T_Access,
  T_Ancestry,
  T_Book,
  T_CustomSection,
  T_StudentSelfAssessment,
} from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';

export const useBooksShowPage = usePage<T_BooksShowProps>;

export interface T_BooksShowProps extends T_PageProps {
  access: T_Access;
  book: T_Book;
  custom_sections: T_CustomSection[];
  parts: T_Ancestry[];
  studentSelfAssessments?: T_StudentSelfAssessment[];
}
