import { Link } from '@inertiajs/react';
import { useTranslation } from 'react-i18next';

import RegistrationsApi from '~/api/Users/RegistrationsApi';
import editorGif from '~/assets/images/editor.gif';
import onlineGif from '~/assets/images/online.gif';
import printGif from '~/assets/images/print.gif';
import { AspectRatio } from '~/components/ui/aspect-ratio';
import { buttonVariants } from '~/components/ui/button';
import { cn } from '~/utils';

import { Features } from '../../components/features';
import { Footer } from '../../components/footer';
import {
  GifSectionDark,
  GifSectionDarkCallout,
  GifSectionDarkContent,
  GifSectionDarkParagraph,
  GifSectionDarkTitle,
} from '../../components/gif-section-dark';
import {
  GifSectionLight,
  GifSectionLightCallout,
  GifSectionLightContent,
  GifSectionLightParagraph,
  GifSectionLightTitle,
} from '../../components/gif-section-light';
import { HeaderEducation } from '../../components/header-education';
import { Hero } from '../../components/hero';
import { NewsletterSubscription } from '../../components/newsletter-subscription';

const features = [
  'teacherSetsContent',
  'focusOnLearning',
  'userFriendlyLayout',
  'hybrid',
  'multiDevice',
  'lmsIntegration',
  'scienceSupported',
  'freeSupport',
];

export default function Home() {
  const { t } = useTranslation();

  return (
    <>
      <HeaderEducation />

      <Hero>
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-black sm:text-5xl">
            {t('website.home.heroTitle')}
          </h1>
          <p className="mt-6 text-lg leading-8 font-light text-gray-800">
            {t('website.home.education.heroDescription')}
          </p>

          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              href={RegistrationsApi.new.path()}
              className={cn(buttonVariants({ variant: 'default', size: 'lg' }), 'bg-green-600')}>
              {t('website.home.heroCallToAction')}
            </Link>
          </div>
        </div>

        <div className="mt-20">
          <AspectRatio className="flex items-center justify-center bg-black" ratio={16 / 9}>
            <iframe
              className="w-full h-full"
              src="https://www.youtube.com/embed/NRvLAPnNL4w?si=-ueQrUXE7at9kQzk"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen></iframe>
          </AspectRatio>
        </div>
      </Hero>

      <Features features={features} />

      <NewsletterSubscription />

      <GifSectionDark gifSrc={editorGif}>
        <GifSectionDarkCallout>{t('website.home.editorBlockSubtitle')}</GifSectionDarkCallout>
        <GifSectionDarkTitle>{t('website.home.editorBlockTitle')}</GifSectionDarkTitle>

        <GifSectionDarkContent>
          <GifSectionDarkParagraph>
            {t('website.home.editorBlockParagraph1')}
          </GifSectionDarkParagraph>

          <GifSectionDarkParagraph>
            {t('website.home.editorBlockParagraph2')}
          </GifSectionDarkParagraph>
        </GifSectionDarkContent>
      </GifSectionDark>

      <GifSectionLight gifSrc={onlineGif}>
        <GifSectionLightCallout>{t('website.home.bookBlockSubtitle')}</GifSectionLightCallout>
        <GifSectionLightTitle>{t('website.home.bookBlockTitle')}</GifSectionLightTitle>

        <GifSectionLightContent>
          <GifSectionLightParagraph>
            {t('website.home.bookBlockParagraph1')}
          </GifSectionLightParagraph>

          <GifSectionLightParagraph>
            {t('website.home.bookBlockParagraph2')}
          </GifSectionLightParagraph>
        </GifSectionLightContent>
      </GifSectionLight>

      <GifSectionDark gifSrc={printGif}>
        <GifSectionDarkCallout>{t('website.home.printBlockSubtitle')}</GifSectionDarkCallout>
        <GifSectionDarkTitle>{t('website.home.printBlockTitle')}</GifSectionDarkTitle>

        <GifSectionDarkContent>
          <GifSectionDarkParagraph>
            {t('website.home.printBlockParagraph1')}
          </GifSectionDarkParagraph>

          <GifSectionDarkParagraph>
            {t('website.home.printBlockParagraph2')}
          </GifSectionDarkParagraph>
        </GifSectionDarkContent>
      </GifSectionDark>

      <Footer />
    </>
  );
}

Home.layout = (page) => page;
