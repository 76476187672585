const ELEMENT_LINK = 'a';

export enum T_ElemenNodeType {
  AUDIO_FRAGMENT = 'audio-fragment',
  AUDIO_SOURCE = 'audio-source',
  BLOCK_QUOTE = 'block-quote',
  CONTAINER = 'container',
  DEFINITION = 'definition',
  EXERCISE = 'exercise',
  FEEDBACK = 'feedback',
  FILE = 'file',
  FILE_SOURCE = 'file-source',
  FILL_GAPS = 'fill-gaps',
  FILL_GAPS_GAP = 'fill-gaps-gap',
  FILL_LIST = 'fill-list',
  FILL_LIST_ITEM = 'fill-list-item',
  FILL_LIST_ITEM_ANSWER_IMAGE = 'fill-list-item-answer-image',
  FILL_LIST_ITEM_ANSWER_TEXT = 'fill-list-item-answer-text',
  FILL_LIST_ITEM_KEY_IMAGE = 'fill-list-item-key-image',
  FILL_LIST_ITEM_KEY_TEXT = 'fill-list-item-key-text',
  HEADING_FOUR = 'heading-four',
  HEADING_ONE = 'heading-one',
  HEADING_THREE = 'heading-three',
  HEADING_TWO = 'heading-two',
  HINT = 'hint',
  IMAGE = 'image',
  IMAGE_COLLECTION = 'image-collection',
  LAYOUT_IMAGE_TEXT = 'layout-image-text',
  LAYOUT_TEXT = 'layout-text',
  LIST_ITEM = 'list-item',
  LIST_ITEM_CONTENT = 'list-item--content',
  LIST_ORDERED = 'ordered-list',
  LIST_UNORDERED = 'unordered-list',
  MANUAL = 'manual',
  MARK_IMAGE = 'mark-image',
  MARK_OPTION = 'mark-option',
  MARK_OPTION_GAP = 'mark-option-gap',
  MARKED_IMAGE = 'marked-image',
  MARKED_IMAGE_MARKER = 'marked-image--marker',
  MARKED_IMAGE_SOURCE = 'marked-image--source',
  MEDIA = 'media',
  MEDIA_SOURCE = 'media-source',
  MULTIPLE_CHOICE = 'multiple-choice',
  MULTIPLE_CHOICE_ITEM = 'multiple-choice-item',
  OPEN_ANSWER = 'open-answer',
  ORAL = 'oral',
  PARAGRAPH = 'paragraph',
  QUESTION = 'question',
  SECT1 = 'sect1',
  SLASH_MENU_INPUT = 'slash-menu-input',
  SUB_EXERCISE = 'sub-exercise',
  SUB_EXERCISE_LIST = 'sub-exercise-list',
  TABLE = 'table',
  TABLE_CELL = 'table--cell',
  TABLE_ROW = 'table--row',
  TEXT_MULTI_LINE = 'text-multi-line',
  TEXT_SINGLE_LINE = 'text-single-line',
  WORD_LIST = 'word-list',
  WORD_ITEM = 'word-item',
  VIDEO = 'video',
}

export type T_AnswerableType =
  | T_ElemenNodeType.OPEN_ANSWER
  | T_ElemenNodeType.MULTIPLE_CHOICE
  | T_ElemenNodeType.FILL_LIST
  | T_ElemenNodeType.FILL_GAPS
  | T_ElemenNodeType.ORAL
  | T_ElemenNodeType.MARK_IMAGE
  | T_ElemenNodeType.MARK_OPTION
  | T_ElemenNodeType.SUB_EXERCISE_LIST;

export enum T_InlineNodeType {
  LINK = 'link',
  FORMULA = 'formula',
}

export const T_NodeType = { ...T_ElemenNodeType, ...T_InlineNodeType, ELEMENT_LINK };

export type T_NodeType = typeof T_NodeType;
export type T_NodeTypeValue = T_NodeType[keyof T_NodeType];
