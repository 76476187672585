import clsx from 'clsx';
import React from 'react';

import { T_BaseText } from '~/types/node/T_BaseText';

type Props = {
  node: T_BaseText;
};

export function Text(props: Props) {
  const { node } = props;

  const { text, ...styleProps } = node;

  if (!text) return null;

  const Tag = node.superscript ? 'sup' : 'span';

  return (
    <Tag
      className={clsx(
        styleProps?.bold && 'font-bold',
        styleProps?.italic && 'italic',
        styleProps?.underline && 'underline',
      )}>
      {text}
    </Tag>
  );
}
