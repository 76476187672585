import axios from 'axios';
import debounce from 'lodash/debounce';
import React, { createContext, useContext, useState } from 'react';

import AnswersApi from '~/api/AnswersApi';
import { T_Exercise } from '~/node-builder/nodes/exercise/types';
import { T_Answer } from '~/types/serializers';

type T_ExercisePropsContext = {
  access: T_Access;
  courseContent: any;
  node: T_Exercise['ViewNode'];
  setShowHint: React.Dispatch<React.SetStateAction<boolean>>;
  showHint: boolean;
  submitExercise: (options: SubmitExerciseProps) => void;
  userAnswers: T_Answer[];
  studentAnswerPresent: boolean;
};

const ExercisePropsContext = createContext<T_ExercisePropsContext>(null);

type Props = {
  access: T_Access;
  children: React.ReactNode;
  courseContent: any;
  node: T_Exercise['ViewNode'];
  userAnswers: T_Answer[];
};

export function ExercisePropsContextProvider(props: Props) {
  const { access, children, courseContent, node, userAnswers } = props;

  const [studentAnswerPresent, setStudentAnswerPresent] = useState(
    userAnswerIsPresent(userAnswers, node),
  );

  const submitExercise = generateSubmitExercise({
    exerciseNodeKey: node.nodeKey,
    courseContent,
    studentAnswerPresent,
    setStudentAnswerPresent,
  });

  const [showHint, setShowHint] = useState(false);

  return (
    <ExercisePropsContext.Provider
      value={{
        access,
        courseContent,
        node,
        setShowHint,
        showHint,
        submitExercise,
        userAnswers,
        studentAnswerPresent,
      }}>
      {children}
    </ExercisePropsContext.Provider>
  );
}

export const useExerciseProps = () => useContext<T_ExercisePropsContext>(ExercisePropsContext);

type SubmitExerciseProps = {
  answer: Record<string, any>;
  answerableNodeKey: string;
};

function userAnswerIsPresent(userAnswers, node) {
  if (!userAnswers) return false;

  const userAnswerNodeKeys = userAnswers.map((answer) => answer.nodeKey);

  return userAnswerNodeKeys.includes(node.answerable_value.nodeKey);
}

function generateSubmitExercise({
  exerciseNodeKey,
  courseContent,
  studentAnswerPresent,
  setStudentAnswerPresent,
}) {
  const answerRoute = generateAnswerRoute(courseContent);

  if (!answerRoute) return () => null;

  const submitExerciseFunc = async (options: SubmitExerciseProps) => {
    await axios
      .post(answerRoute, {
        exercise_node_key: exerciseNodeKey,
        answerable_node_key: options.answerableNodeKey,
        answer: options.answer,
      })
      .then((response) => {
        const responseStatus = response.status;
        if (responseStatus === 200 && !studentAnswerPresent) {
          setStudentAnswerPresent(true);
        }
      });
  };

  return process.env.NODE_ENV === 'test'
    ? debounce(submitExerciseFunc, 10)
    : debounce(submitExerciseFunc, 3000);
}

function generateAnswerRoute(courseContent) {
  if (!courseContent || !courseContent?.type || !courseContent?.slug) return;

  return AnswersApi.create.path({ course_content_slug: courseContent.slug });
}
