import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '~/components/theme-provider';

import { useViewPrintContext } from '../useViewPrintContext';

type Props = {
  partNodes: any[];
};

export function TableOfContents(props: Props) {
  const { t } = useTranslation();

  const { partNodes } = props;

  const { structure } = useViewPrintContext();

  return (
    <div className="toc-page">
      <h1 className="font-bold text-2xl">{t('pages.printTasksGenerate.text.tableOfContents')}</h1>
      {renderContent()}
    </div>
  );

  function renderContent() {
    if (structure === 'part_chapter_section') {
      return renderParts(partNodes);
    } else {
      const chapters = partNodes[0].children.filter(({ type }) => type === 'part');

      return renderChapters(chapters);
    }
  }

  function renderParts(partNodes) {
    return partNodes.map((part, index) => {
      const chapters = part.children.filter(({ type }) => type === 'part');

      return (
        <div key={index}>
          <h2 className="text-gray-700 font-bold text-lg mt-4">{part.title}</h2>
          <div>{renderChapters(chapters)}</div>
        </div>
      );
    });
  }

  function renderChapters(chapters) {
    const { colors } = useTheme();

    return chapters.map((chapter, index) => {
      const { sections } = chapter;

      return (
        <div key={index}>
          <h3 style={{ color: colors.chapter }} className="font-bold mt-2">
            {chapter.number} {chapter.title}
          </h3>
          <div>{renderSections(sections)}</div>
        </div>
      );
    });
  }

  function renderSections(sections) {
    return sections.map((section, index) => {
      return (
        <div key={index}>
          <h4 className="my-1 ml-6 text-gray-700 text-sm">
            {section.number} {section.title}
          </h4>
        </div>
      );
    });
  }
}
