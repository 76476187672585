import React from 'react';

import { LayoutBook } from '~/components/ui-extended/layout-book';

import { PageContent } from './components/page-content';
import { useLicenseGroupsIndexPage } from './T_LicenseGroupsIndex';

export default function Index() {
  const { access, book } = useLicenseGroupsIndexPage().props;

  return (
    <LayoutBook
      htmlHeadTitle={book.title}
      breadcrumbs={[{ title: 'models.licenseGroup.many', translateTitle: true, type: 'static' }]}
      access={access}
      book={book}
      sidebarTitle={book.title}>
      <PageContent />
    </LayoutBook>
  );
}
