import clsx from 'clsx';
import React, { CSSProperties } from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  node: any;
};

export function GutterTitle(props: Props) {
  const { children, className, style } = props;

  return (
    <span
      data-testid="gutter-content"
      style={style}
      className={clsx('md:absolute md:w-24 md:text-right md:-left-28 pr-2', className)}>
      {children}
    </span>
  );
}

export function GutterContentContainer({ children }) {
  return <div className="relative md:ml-28">{children}</div>;
}
