import { router } from '@inertiajs/react';
import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreHorizontal } from 'styled-icons/fluentui-system-regular';

import ClassGroupMembersApi from '~/api/ClassGroupMembersApi';
import ClassGroupsApi from '~/api/ClassGroupsApi';
import { AlertDialog, AlertDialogTrigger } from '~/components/ui/alert-dialog';
import { Button } from '~/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '~/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '~/components/ui/dropdown-menu';
import { DialogConfirmDestructiveAction } from '~/components/ui-extended/dialog-confirm-destructive-action';
import { T_Book, T_ClassGroup } from '~/types/serializers';

import { EditGroupForm } from './edit-group-form';

export type Props = {
  group: T_ClassGroup;
  book: T_Book;
};

export function GroupOptions(props: Props) {
  const { t } = useTranslation();

  const { book, group } = props;

  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <AlertDialog>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              data-testid="class_group_options_button"
              variant="ghost"
              className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{t('common.nouns.actions')}</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem data-testid="class_group_page_button" onClick={navigateToGroupPage}>
              {t('common.buttons.view')}
            </DropdownMenuItem>

            <DialogTrigger asChild>
              <DropdownMenuItem data-testid="class-group-change-name-button">
                {t('pages.classGroupsIndex.buttons.changeGroupName')}
              </DropdownMenuItem>
            </DialogTrigger>

            <AlertDialogTrigger asChild>
              <DropdownMenuItem data-testid="class-group-remove-group-button">
                {t('common.buttons.remove')}
              </DropdownMenuItem>
            </AlertDialogTrigger>
          </DropdownMenuContent>
        </DropdownMenu>

        <DialogConfirmDestructiveAction
          destructiveAction={removeGroup}
          message={t('pages.classGroupsIndex.dialogs.removeGroup.description')}
        />

        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('pages.classGroupsIndex.dialogs.editGroup.title')}</DialogTitle>
          </DialogHeader>
          <EditGroupForm group={group} closeDialog={() => setOpen(false)} book={book} />
        </DialogContent>
      </AlertDialog>
    </Dialog>
  );

  function navigateToGroupPage() {
    router.visit(
      ClassGroupMembersApi.index.path({ book_slug: book.slug, class_group_id: group.id }),
    );
  }

  function removeGroup() {
    router.delete(ClassGroupsApi.destroy.path({ book_slug: book.slug, id: group.id }));
  }
}
