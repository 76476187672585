import shuffle from 'lodash/shuffle';
import React, { useEffect } from 'react';

import { usePrevious } from '~/hooks/usePrevious';
import { T_AnswerableFillGaps } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGaps';
import { T_AnswerableFillGapsGap } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGapsGap';
import { T_AnswerableFillGapsParagraph } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGapsParagraph';

import { useSentInAnswerProps, useStandardAnswerProps } from '../../context/AnswerablePropsContext';
import { GapSelect } from './GapSelect';
import { GapText } from './GapText';
import { Text } from './Text';

type Props = {
  activeGap: T_AnswerableFillGapsGap['ViewNode'] | null;
  answer: any;
  answerable: T_AnswerableFillGaps['ViewNode'];
  handleChange: (gapKey: string, answerKey: string) => void;
  node: T_AnswerableFillGapsParagraph['ViewNode'];
  possibleAnswers: { nodeKey: string; number: number; text: string }[];
  setActiveGap: (gap: any | null) => void;
};

export function ParagraphWithGaps(props: Props) {
  const { activeGap, answer, answerable, handleChange, node, possibleAnswers, setActiveGap } =
    props;

  const { showSentInAnswers } = useSentInAnswerProps();
  const { showAnswer } = useStandardAnswerProps();

  const previousShowSentInAnswers = usePrevious(showSentInAnswers);

  useEffect(() => {
    if (showSentInAnswers && !previousShowSentInAnswers) {
      const firstGap = node.children.find(
        (child) => 'type' in child,
      ) as T_AnswerableFillGapsGap['ViewNode'];

      setActiveGap(firstGap);
    }
  }, [previousShowSentInAnswers, showSentInAnswers]);

  return (
    <div className="text-gray-600 mb-4">
      {node.children.map((child) => {
        if (!('type' in child)) return <Text text={child.text} />;

        switch (answerable.answer_by) {
          case 'select': {
            return (
              <GapSelect
                activeGap={activeGap}
                handleChange={handleChange}
                key={child.nodeKey}
                node={child}
                possibleAnswers={shuffle(possibleAnswers)}
                setActiveGap={setActiveGap}
                showAnswer={showAnswer}
                showSentInAnswers={showSentInAnswers}
                value={answer[child.nodeKey]}
              />
            );
          }
          case 'text': {
            return (
              <GapText
                key={child.nodeKey}
                value={answer[child.nodeKey] || ''}
                showAnswer={showAnswer}
                activeGap={activeGap}
                setActiveGap={setActiveGap}
                node={child}
                handleChange={handleChange}
                showSentInAnswers={showSentInAnswers}
              />
            );
          }
        }
      })}
    </div>
  );
}
