import { router } from '@inertiajs/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BookOpen, PresentationChartBar } from 'styled-icons/heroicons-outline';

import CourseContentsApi from '~/api/CourseContentsApi';
import { ToggleGroup, ToggleGroupItem } from '~/components/ui/toggle-group';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';

import { useCourseContentShowPage } from '../T_CourseContentShowProps';

export function FocusModeSwitch() {
  const { t } = useTranslation();

  const { access, bookContent, courseContent, focus } = useCourseContentShowPage().props;

  const [enabled, setEnabled] = useState(!!focus);

  useEffect(() => {
    setEnabled(!!focus);
  }, [focus]);

  if (access.student) return null;

  return (
    <ToggleGroup onValueChange={handleFocusToggle} value={enabled ? 'focus' : 'book'} type="single">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <ToggleGroupItem
              className="data-[state=on]:bg-green-400"
              data-testid="toggle-book-mode-button"
              variant="outline"
              value="book"
              aria-label="Toggle bold">
              <BookOpen className="h-4 w-4" />
            </ToggleGroupItem>
          </TooltipTrigger>
          <TooltipContent>{t('pages.courseContentsShow.text.toggleBookMode')}</TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <ToggleGroupItem
              className="data-[state=on]:bg-green-400"
              data-testid="toggle-focus-mode-button"
              variant="outline"
              value="focus"
              aria-label="Toggle italic">
              <PresentationChartBar className="h-4 w-4" />
            </ToggleGroupItem>
          </TooltipTrigger>
          <TooltipContent>
            {t('pages.courseContentsShow.text.togglePresentationMode')}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </ToggleGroup>
  );

  function handleFocusToggle(value) {
    if (value !== 'focus') {
      router.visit(
        CourseContentsApi.show.path({ slug: courseContent.slug, query: { anchor: focus.anchor } }),
        {
          only: ['focus'],
          preserveState: true,
        },
      );
    } else {
      console.log('focus', bookContent.children[0].nodeKey);

      const nodeKey = window.location.hash.substr(1) || bookContent.children[0].nodeKey;

      router.visit(
        CourseContentsApi.show.path({ slug: courseContent.slug, query: { focus: nodeKey } }),
        {
          only: ['focus', 'teachingClassGroupStudentAnswers'],
          preserveState: true,
        },
      );
    }
  }
}
