import React from 'react';
import { Bars3, XMark } from 'styled-icons/heroicons-outline';

type Props = {
  showSidebar: boolean;
  setShowSidebar: (value: boolean) => void;
};

export default function MenuButton(props: Props) {
  const { showSidebar, setShowSidebar } = props;

  const IconComponent = showSidebar ? XMark : Bars3;

  return (
    <button
      className="fixed z-50 p-3 ml-5 bg-accentt rounded-full bottom-5"
      data-testid="sidebar-toggle"
      onClick={() => setShowSidebar(!showSidebar)}>
      <IconComponent className="w-6 h-6 text-gray-50" />
    </button>
  );
}
