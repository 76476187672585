import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '~/components/ui/accordion';
import { AspectRatio } from '~/components/ui/aspect-ratio';
import { T_Tutorial } from '~/types/serializers';

import { Footer } from '../../components/footer';
import { HeaderBusiness } from '../../components/header-business';
import { useTutorialsIndexPage } from './T_TutorialsIndex';

export default function Tutorials() {
  const { t } = useTranslation();

  const { tutorials } = useTutorialsIndexPage().props;

  return (
    <>
      <HeaderBusiness />

      <div className="bg-white">
        <div className="mx-auto max-w-3xl px-6 py-10 lg:px-8">
          <div className="mx-auto max-w-4xl mb-6">
            <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900">
              {t('website.tutorials.title')}
            </h2>

            <div className="my-2 ">
              <a
                target="_blank"
                href="https://docs.google.com/document/d/16PJuRVUxDr08f4e1m4goXi00s-Qy-JOB/edit?usp=sharing&ouid=111355828512700193878&rtpof=true&sd=true"
                className="text-green-500 hover:underline"
                rel="noreferrer">
                {t('website.tutorials.callToAction')}
              </a>{' '}
              {t('website.tutorials.description')}
            </div>

            <Accordion type="single" collapsible className="pt-6">
              {tutorials.map((tutorial) => (
                <AccordionItem key={tutorial.id} value={`item-${tutorial.id}`}>
                  <AccordionTrigger className="text-lg">{tutorial.title}</AccordionTrigger>
                  <AccordionContent>
                    <TutorialContent tutorial={tutorial} />
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

function TutorialContent({ tutorial }: { tutorial: T_Tutorial }) {
  if (tutorial.contentType === 'url') {
    return (
      <AspectRatio ratio={16 / 9}>
        <iframe
          className="w-full h-full"
          src={tutorial.content}
          title="YouTube video player"
          allowFullScreen></iframe>
      </AspectRatio>
    );
  }

  return (
    <div>
      <p>{tutorial.content}</p>
    </div>
  );
}

Tutorials.layout = (page) => page;
