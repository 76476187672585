import React, { useCallback, useState } from 'react';

import { T_AnswerableMarkImageMarker } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImageMarker';

import { MarkerOverlay } from './MarkerOverlay';

type Props = {
  markers: T_AnswerableMarkImageMarker['ViewNode'][];
  src: string;
  handleSvgClick?: (point: [number, number]) => void;
};

export function Source(props: Props) {
  const { markers, src, handleSvgClick: onClick = () => null } = props;

  const [width, setWidth] = useState(null);
  const [imageRef, setImageRef] = useState({ current: null });

  const containerRef = useCallback((node) => {
    setWidth(node?.offsetWidth);
  }, []);

  const imgRef = useCallback((node) => {
    setImageRef({ current: node });
  }, []);

  return (
    <>
      <div className="flex justify-center w-[546.517px]" ref={containerRef}>
        <div className="relative">
          {imageRef.current && (
            <MarkerOverlay markers={markers} imageRef={imageRef} width={width} onClick={onClick} />
          )}

          <img className="max-w-full" src={src} ref={imgRef} />
        </div>
      </div>
    </>
  );
}
