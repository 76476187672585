import React from 'react';

import { Footer } from '../../components/footer';
import { HeaderEducation } from '../../components/header-education';

export default function Contact() {
  return (
    <>
      <HeaderEducation />
      <div className="flex justify-center bg-white mt-10">
        <iframe
          className="min-h-[516px] min-w-[312px] bg-white"
          src="https://calendly.com/simonbeausaert"
        />
      </div>
      <Footer />
    </>
  );
}

Contact.layout = (page) => page;
