import { usePage } from '@inertiajs/react';

import { T_Access, T_Ancestry, T_Book, T_Flashcard } from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';

export const useBooksFlashcardsPage = usePage<T_BooksFlashcardsProps>;

export interface T_BooksFlashcardsProps extends T_PageProps {
  access: T_Access;
  book: T_Book;
  dueFlashcards?: T_Flashcard[];
  allFlashcards?: T_Flashcard[];
  selectableParts: T_Ancestry[];
  flashcardChapterIds: number[];
}
