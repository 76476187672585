import { CellContext, ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Translation, useTranslation } from 'react-i18next';
import { Download } from 'styled-icons/bootstrap';

import { Badge } from '~/components/ui/badge';
import { buttonVariants } from '~/components/ui/button';
import { DataTableColumnHeader } from '~/components/ui/data-table-column-header';
import { cn } from '~/utils';

export type User = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
};

export const columns: ColumnDef<User>[] = [
  {
    accessorKey: 'createdAt',
    id: 'created_at',
    header: ({ column }) => (
      <Translation>
        {(t) => (
          <DataTableColumnHeader className="pl-4" column={column} title={t('common.nouns.date')} />
        )}
      </Translation>
    ),
    cell: ({ cell }: CellContext<User, string>) => (
      <div className="w-[100px] pl-4 font-medium">
        {dayjs(cell.getValue()).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    accessorKey: 'content',
    header: ({ column }) => (
      <Translation>
        {(t) => (
          <DataTableColumnHeader column={column} title={t('models.printTask.configuration')} />
        )}
      </Translation>
    ),
    cell: ({ row }: CellContext<User, string>) => (
      <Translation>
        {(t) => (
          <div className="flex space-x-2 max-w-[300px]">
            <div className="text-xs">
              <div className="flex items-center text-gray-700">
                <span className="font-medium mr-1">{t('common.nouns.content')}:</span>
                {renderContentText(row.original.content, row.original.selectedContainerTemplate)}
              </div>
              <div className="flex items-center text-gray-700">
                <span className="font-medium mr-1">{t('models.exercise.many')}: </span>
                {renderExerciseLayoutText(row.original.exerciseLayout, row.original.filledInByUser)}
              </div>
            </div>
          </div>
        )}
      </Translation>
    ),
    enableSorting: false,
  },
  {
    accessorKey: 'state',
    header: ({ column }) => (
      <Translation>
        {(t) => <DataTableColumnHeader column={column} title={t('models.printTask.status')} />}
      </Translation>
    ),
    cell: ({ cell }: CellContext<User, string>) => (
      <Translation>
        {(t) => (
          <div className="text-right text-gray-600 max-w-[40px]">
            <Badge variant="outline">{t(`models.printTask.state.${cell.getValue()}`)}</Badge>
          </div>
        )}
      </Translation>
    ),
    enableSorting: false,
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      return (
        <div className="text-center">
          {row.original.state === 'ready' && (
            <a
              className={cn(buttonVariants({ variant: 'secondary' }), 'text-sm h-6')}
              href={row.original.downloadUrl}
              target="_blank"
              rel="noreferrer">
              <Download className="h-4 w-4 mr-2" />
              Download
            </a>
          )}
        </div>
      );
    },
  },
];

function renderContentText(content, selectedContainerTemplate) {
  const { t } = useTranslation();

  let text = t('models.printTask.config.allContent');

  switch (content) {
    case 'exercises': {
      text = t('models.exercise.many');
      break;
    }
    case 'definitions': {
      text = t('models.definition.many');
      break;
    }
    case 'container': {
      text = `${t('models.printTask.config.allContainers')} ${selectedContainerTemplate}`;
      break;
    }
  }

  return <p>{text}</p>;
}

function renderExerciseLayoutText(exerciseLayout, filledInByUser) {
  const { t } = useTranslation();

  let text = t('models.printTask.config.toFillIn');

  switch (exerciseLayout) {
    case 'standard_answers': {
      text = t('models.printTask.config.standardAnswers');
      break;
    }
    case 'user_answers': {
      text = `${t('models.printTask.config.filledInByUser')} ${filledInByUser}`;
      break;
    }
  }

  return <p>{text}</p>;
}
