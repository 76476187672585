import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';

import { usePrevious } from '~/hooks/usePrevious';
import { T_AnswerableFillList } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillList';
import { T_AnswerableFillListItem } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillListItem';

import { useSentInAnswerProps } from '../../context/AnswerablePropsContext';
import { useExerciseProps } from '../../context/ExercisePropsContext';
import { useFindUserAnswers } from '../../hooks/useFindUserAnswers';
import { Item } from './Item';

type Props = {
  activeItem: T_AnswerableFillListItem['ViewNode'] | null;
  answerable: T_AnswerableFillList['ViewNode'];
  remakeMode: boolean;
  setActiveItem: (item: T_AnswerableFillListItem['ViewNode'] | null) => void;
};

export function ItemList(props: Props) {
  const { activeItem, answerable, remakeMode, setActiveItem } = props;

  const { submitExercise } = useExerciseProps();

  const { showSentInAnswers } = useSentInAnswerProps();

  const userAnswer = useFindUserAnswers(answerable.nodeKey);

  const [answer, setAnswer] = useState(
    userAnswer?.value && !remakeMode ? userAnswer.value.answersByKey : {},
  );

  useEffect(() => {
    if (remakeMode) {
      setAnswer({});
    } else if (userAnswer?.value) {
      setAnswer(userAnswer?.value?.answersByKey || {});
    }
  }, [remakeMode]);

  const previousShowSentInAnswers = usePrevious(showSentInAnswers);

  useEffect(() => {
    if (showSentInAnswers && !previousShowSentInAnswers) {
      setActiveItem(answerable.items[0]);
    }
  }, [previousShowSentInAnswers, showSentInAnswers]);

  useMemo(() => addNumberToItems(), []);

  return (
    <div className={clsx('flex flex-wrap mt-4 mb-1 p-1', !!false && 'bg-green-50')}>
      {answerable.items.map((item) => {
        return (
          <Item
            activeItem={activeItem}
            answerable={answerable}
            handleChange={handleChange}
            key={item.nodeKey}
            node={item}
            setActiveItem={setActiveItem}
            userAnswer={answer ? answer[item.nodeKey] : ''}
          />
        );
      })}
    </div>
  );

  function handleChange(itemNodeKey: string, value: string) {
    const newAnswer = { ...answer, [itemNodeKey]: value };

    submitExercise({
      answer: { value: { answersByKey: newAnswer } },
      answerableNodeKey: answerable.nodeKey,
    });

    setAnswer(newAnswer);
  }

  function addNumberToItems() {
    let itemNumber = 0;

    answerable.items.forEach((item) => (item.number = ++itemNumber));
  }
}
