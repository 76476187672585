import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { T_AnswerableMarkOptionGap } from '~/types/node/exercise/answerables/mark-option/T_AnswerableMarkOptionGap';

import { useSentInAnswerProps } from '../../context/AnswerablePropsContext';

type Props = {
  activeGap: T_AnswerableMarkOptionGap['ViewNode'] | null;
};

export function PercentageView(props: Props) {
  const { t } = useTranslation();

  const { activeGap } = props;

  const { sentInAnswers, includeName } = useSentInAnswerProps();

  const selectedAnswersWithCount = useMemo(
    () => getAnswersWithUserCount(activeGap, sentInAnswers),
    [activeGap, sentInAnswers],
  );

  return (
    <React.Fragment>
      {Object.entries(selectedAnswersWithCount).map(([key, value]) => {
        const percentage = ((value.count / sentInAnswers.length) * 100).toFixed(2);

        return (
          <div
            className="mb-4 p-3 bg-white rounded-md border my-2 text-gray-700 font-medium text-lg"
            key={key}>
            {key === 'notFilledIn' ? (
              <span>{t('pages.courseContentsShow.nodes.exercise.notFilledIn')}</span>
            ) : (
              <span>{value.answerNode.text}</span>
            )}
            <span> ({value.count})</span>

            <div className={clsx('bg-purple-400 mt-1 rounded-full h-2', `w-[${percentage}%]`)} />

            {includeName && (
              <div className="flex flex-wrap mt-2">
                {value.sentInAnswers.map((studenAnswer, index) => {
                  return (
                    <div
                      className="border text-sm font-normal shadow border-gray-100 text-gray-600 bg-yellow-50 rounded-lg px-2 py-0.5 mr-2"
                      key={index}>
                      {studenAnswer.user.fullName}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </React.Fragment>
  );
}

type AnswersWithUserCount = {
  [nodeKey: string]: {
    count: number;
    answerNode: { text: string; nodeKey: string };
    sentInAnswers: any[];
  };
};

function getAnswersWithUserCount(
  activeGap: T_AnswerableMarkOptionGap['ViewNode'] | null,
  sentInAnswers: any[],
): AnswersWithUserCount {
  if (!activeGap) return {};

  const answersWithUserCount: AnswersWithUserCount = {
    notFilledIn: { count: 0, answerNode: null, sentInAnswers: [] },
  };

  const { text } = activeGap.children[0];

  const extraAnswers = activeGap.extraOptions.map((extraOption) => ({
    text: extraOption,
    nodeKey: extraOption,
  }));

  const possibleAnswers = [...extraAnswers, { text, nodeKey: activeGap.nodeKey }];

  for (const sentInAnswer of sentInAnswers) {
    const sentInAnswerNodeKey = sentInAnswer.value.markOption[activeGap.nodeKey];

    const selectedAnswer = possibleAnswers.find((answer) => answer.nodeKey === sentInAnswerNodeKey);

    if (!selectedAnswer) {
      answersWithUserCount.notFilledIn.count++;
      answersWithUserCount.notFilledIn.sentInAnswers.push(sentInAnswer);

      continue;
    }

    const answerWithUserCount = answersWithUserCount[selectedAnswer.nodeKey];

    if (answerWithUserCount) {
      answerWithUserCount.count++;
      answerWithUserCount.sentInAnswers = [...answerWithUserCount.sentInAnswers, sentInAnswer];
    } else {
      answersWithUserCount[selectedAnswer.nodeKey] = {
        count: 1,
        answerNode: selectedAnswer,
        sentInAnswers: [sentInAnswer],
      };
    }
  }

  if (!answersWithUserCount.notFilledIn.count) delete answersWithUserCount.notFilledIn;

  return answersWithUserCount;
}
