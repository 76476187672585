import { usePage } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bell,
  BookOpen,
  ChatBubbleLeftRight,
  Cog6Tooth,
  DocumentMagnifyingGlass,
  Key,
  PencilSquare,
  Printer,
  RectangleStack,
  UserGroup,
  Users,
} from 'styled-icons/heroicons-outline';

import AuthorsApi from '~/api/AuthorsApi';
import BooksApi from '~/api/BooksApi';
import ClassGroupsApi from '~/api/ClassGroupsApi';
import ClassroomsApi from '~/api/ClassroomsApi';
import FlashcardsApi from '~/api/FlashcardsApi';
import LicenseGroupsApi from '~/api/LicenseGroupsApi';
import NotificationsApi from '~/api/NotificationsApi';
import PrintTasksApi from '~/api/PrintTasksApi';
import { T_Access, T_Book } from '~/types/serializers';

import { Separator } from '../ui/separator';
import { SidebarButton } from './sidebar-button';
import { SidebarMenuWrapper } from './sidebar-menu-wrapper';

type Props = {
  access: T_Access;
  book: T_Book;
};

export function SidebarBookMenu(props: Props) {
  const { t } = useTranslation();

  const { component } = usePage();

  const { access, book } = props;

  const { slug, printable } = book;

  return (
    <>
      <SidebarMenuWrapper>
        <SidebarButton
          data-testid="book_content_link"
          active={component === 'books/show'}
          href={BooksApi.show.path({ slug })}
          icon={BookOpen}
          title={t('common.sidebar.bookUserOptions.content')}
        />

        {!access.student ? (
          <SidebarButton
            className="groups"
            data-testid="book_class_groups_link"
            active={component === 'class-groups/index' || component === 'class-group-members/index'}
            href={ClassGroupsApi.index.path({ book_slug: slug })}
            icon={UserGroup}
            title={t('common.sidebar.bookUserOptions.groups')}
          />
        ) : (
          <SidebarButton
            data-testid="book_class_group_link"
            active={component === 'classGroup'}
            href={ClassroomsApi.show.path({ book_slug: slug })}
            icon={UserGroup}
            title={t('common.sidebar.bookUserOptions.group')}
          />
        )}

        <SidebarButton
          data-testid="book_notifications_link"
          active={component === 'notifications/index'}
          href={NotificationsApi.index.path({ book_slug: slug })}
          icon={Bell}
          title={t('common.sidebar.bookUserOptions.notifications')}
        />

        <SidebarButton
          data-testid="book_search_link"
          active={component === 'books/search'}
          href={BooksApi.search.path({ slug })}
          icon={DocumentMagnifyingGlass}
          title={t('common.sidebar.bookUserOptions.search')}
        />

        <SidebarButton
          data-testid="book_discussions_link"
          active={component === 'books/discussions'}
          href={BooksApi.discussions.path({ slug })}
          icon={ChatBubbleLeftRight}
          title={t('common.sidebar.bookUserOptions.discussions')}
        />

        <SidebarButton
          data-testid="book_flashcards_link"
          active={component === 'flashcards/index'}
          href={FlashcardsApi.index.path({ book_slug: slug })}
          icon={RectangleStack}
          title={t('common.sidebar.bookUserOptions.flashcards')}
        />

        {(access.owner || access.author) && (
          <SidebarButton
            className="print"
            data-testid="book_print_link"
            active={component === 'print-tasks/index'}
            href={PrintTasksApi.index.path({ book_slug: slug })}
            icon={Printer}
            title={t('common.sidebar.bookUserOptions.print')}
          />
        )}

        {(access.student || access.teacher) && printable && (
          <SidebarButton
            className="print"
            data-testid="book_print_link"
            active={component === 'print'}
            href={PrintTasksApi.index.path({ book_slug: slug })}
            icon={Printer}
            title={t('common.sidebar.bookUserOptions.print')}
          />
        )}
      </SidebarMenuWrapper>

      {(access.owner || access.author) && (
        <>
          <Separator decorative className="mb-1" />

          <SidebarMenuWrapper>
            <SidebarButton
              className="edit-structure"
              data-testid="book_editor_link"
              active={component === 'books/edit-structure'}
              href={BooksApi.editStructure.path({ slug })}
              icon={PencilSquare}
              title={t('common.sidebar.bookOwnerOptions.editContent')}
            />

            <SidebarButton
              className="licenses"
              data-testid="book_licenses_link"
              active={component === 'license-groups/index'}
              href={LicenseGroupsApi.index.path({ book_slug: slug })}
              icon={Key}
              title={t('common.sidebar.bookOwnerOptions.licenses')}
            />

            <SidebarButton
              className="authors"
              data-testid="book_collaborations_link"
              active={component === 'authors/index'}
              href={AuthorsApi.index.path({ book_slug: slug })}
              icon={Users}
              title={t('common.sidebar.bookOwnerOptions.authors')}
            />

            {(access.owner || access.author) && (
              <SidebarButton
                className="configuration"
                active={component === 'books/edit'}
                data-testid="book_configuration_link"
                href={BooksApi.edit.path({ slug })}
                icon={Cog6Tooth}
                title={t('common.sidebar.bookOwnerOptions.configuration')}
              />
            )}
          </SidebarMenuWrapper>
        </>
      )}
    </>
  );
}
