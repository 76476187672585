import { UniqueIdentifier } from '@dnd-kit/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Plus } from 'styled-icons/heroicons-solid';

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';
import { cn } from '~/utils';

type Props = {
  createItem: () => void;
  fromDragOverlay?: boolean;
  isDragging: boolean;
  meta: {
    type: string;
    title: string;
    id: UniqueIdentifier;
    i18nKey: 'nestedPart' | 'section' | 'part';
  };
};

export function StructureItemCreateButton(props: Props) {
  const { t } = useTranslation();

  const { createItem, fromDragOverlay, isDragging, meta } = props;

  return (
    <TooltipProvider delayDuration={500}>
      <Tooltip>
        <TooltipTrigger asChild>
          <button
            type="button"
            data-testid={`create_${meta.type}_button`}
            onClick={createItem}
            className="ml-2 mr-1 flex items-center justify-center hover:bg-gray-200 rounded">
            <Plus className="w-4 h-4 text-gray-700" />
          </button>
        </TooltipTrigger>

        <TooltipContent className={cn((isDragging || fromDragOverlay) && 'hidden')}>
          <p>
            {t('pages.booksEditStructure.text.addNewResource', {
              resource: t(`models.${meta.i18nKey}.one`),
            })}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
