import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import React from 'react';

import { T_AnswerablesViewNodes } from '~/node-builder/nodes/exercise/answerables/types';

import { AnswerablePropsContextProvider } from './context/AnswerablePropsContext';

type Props = {
  answerable: T_AnswerablesViewNodes['answerable_value'];
  subExercise?: boolean;
};

const answerables = import.meta.glob<
  Record<
    string,
    (props: {
      answerable: T_AnswerablesViewNodes['answerable_value'];
      subExercise?: boolean;
    }) => JSX.Element
  >
>('./answerables/*/*.tsx', {
  eager: true,
});

export function Answerable(props: Props) {
  const { answerable, subExercise, node } = props;

  return mapAnswerable();

  function mapAnswerable() {
    if (!answerable) {
      console.log('No answerable found', node);
      return null;
    }

    const { type } = answerable;

    const ComponentName = upperFirst(camelCase(type));

    const AnswerableComponent =
      answerables[`./answerables/${type}/${ComponentName}.tsx`][ComponentName];

    return (
      <AnswerablePropsContextProvider answerable={answerable}>
        <AnswerableComponent answerable={answerable} subExercise={!!subExercise} />
      </AnswerablePropsContextProvider>
    );
  }
}
