import React from 'react';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '~/components/ui/carousel';
import { T_ImageCollection } from '~/types/node/T_ImageCollection';

import { Imageable } from '../../components-new/nodes-shared/imageable';

type Props = {
  node: T_ImageCollection['ViewNode'];
};

export function ImageCollection(props: Props) {
  const { node } = props;

  if (node?.images.length === 1 && node?.images?.[0]) {
    return (
      <div
        data-testid={`${node.type} ${node.nodeKey}`}
        className="relative w-full flex justify-center">
        <Imageable
          className="block max-w-full max-h-full"
          node={node.images[0]}
          src={node.images[0].large_src}
        />
      </div>
    );
  }

  if (node?.online_layout === 'carousel') {
    return (
      <Carousel className="w-full">
        <CarouselContent className="items-start">
          {(node?.images ?? []).map((image, index) => (
            <CarouselItem key={index}>
              <div
                data-testid={`${node.type} ${node.nodeKey}`}
                className="relative w-full flex justify-center">
                <Imageable
                  src={image.large_src}
                  className="block max-w-full max-h-full"
                  node={image}
                />
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    );
  }

  return (
    <div className="flex space-x-2">
      {(node?.images ?? []).map((image, index) => (
        <div className="flex-1" key={index}>
          <Imageable src={image.large_src} node={image} />
        </div>
      ))}
    </div>
  );
}
