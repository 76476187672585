import React from 'react';

import { LayoutBook } from '~/components/ui-extended/layout-book';

import { PageContent } from './components/page-content';
import { useBooksFlashcardsPage } from './T_BooksFlashcards';

export default function Index() {
  const { access, book } = useBooksFlashcardsPage().props;

  return (
    <LayoutBook
      breadcrumbs={[
        {
          title: 'Flashcards',
          type: 'static',
        },
      ]}
      htmlHeadTitle={book.title}
      access={access}
      book={book}
      sidebarTitle={book.title}>
      <PageContent />
    </LayoutBook>
  );
}
