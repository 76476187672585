import React from 'react';

import { BrightbookLogo } from './brightbook-logo';

export const Logomark = BrightbookLogo;

export function Logo(props) {
  return (
    <div className="flex items-center justify-center" aria-hidden="true" {...props}>
      <BrightbookLogo />

      <h1 className="font-medium ml-2 text-gray-800">Brightbook</h1>
    </div>
  );
}
