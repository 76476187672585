import React, { createContext, useContext, useMemo, useState } from 'react';

import { T_AnswerablesViewNodes } from '~/node-builder/nodes/exercise/answerables/types';
import { T_ElemenNodeType, T_NodeType } from '~/types/T_NodeTypes';
import { T_SentInAnswer } from '~/types/views';

import { useSentInAnswersListener } from '../../../components/SentInAnswersContext';
import { forEachNode } from '../answerables/fill-gaps/helpers/gapIterators';

type T_SentInAnswerProps<T = T_SentInAnswer> = {
  includeName: boolean;
  sentInAnswers: T[];
  setIncludeName: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSentInAnswers: React.Dispatch<React.SetStateAction<boolean>>;
  showSentInAnswers: boolean;
};

type T_StandardAnswerProps = {
  answerCount: number;
  answerPresent: boolean;
  hideOneAnswer: () => void;
  showAnswer: number | 'all' | 'none';
  showOneAnswer: () => void;
  toggleFullAnswer: () => void;
};

type T_AnswerablePropsContext = {
  answerable: T_AnswerablesViewNodes['answerable_value'];
  sentInAnswerProps: T_SentInAnswerProps;
  standardAnswerProps: T_StandardAnswerProps;
};

const AnswerablePropsContext = createContext<T_AnswerablePropsContext>(null);

export function AnswerablePropsContextProvider(props: {
  answerable: T_AnswerablesViewNodes['answerable_value'];
  children: React.ReactNode;
}) {
  const { answerable, children } = props;

  /**
   * Sent-in answers
   * These are the answers that were sent in by members of the active teaching group.
   * They are provided to this context section-wide but are narrowed down to the current answerable.
   */

  const { sentInAnswers: sentInAnswersForSection } = useSentInAnswersListener();

  const sentInAnswers = useMemo(
    () => findSentInAnswers(sentInAnswersForSection, answerable),
    [sentInAnswersForSection, answerable],
  );

  const [includeName, setIncludeName] = useState(false);
  const [showSentInAnswers, setShowSentInAnswers] = useState(false);

  const [showAnswer, setShowAnswer] = useState<'all' | 'none' | number>('none');

  /**
   * Standard Answers
   * These are the answers provided by the author at the time of writing.
   */

  const answerCount = useMemo(() => determineAnswerCount(answerable), [answerable]);
  const answerPresent = useMemo(() => determineAnswerPresence(answerable), [answerable]);

  return (
    <AnswerablePropsContext.Provider
      value={{
        answerable,
        sentInAnswerProps: {
          includeName,
          sentInAnswers,
          setIncludeName,
          setShowSentInAnswers,
          showSentInAnswers,
        },
        standardAnswerProps: {
          answerCount,
          answerPresent,
          showAnswer,
          hideOneAnswer: hideOneAnswer([showAnswer, setShowAnswer], answerCount),
          showOneAnswer: showOneAnswer([showAnswer, setShowAnswer], answerCount),
          toggleFullAnswer: toggleFullAnswer([showAnswer, setShowAnswer]),
        },
      }}>
      {children}
    </AnswerablePropsContext.Provider>
  );
}

export const useStandardAnswerProps = () =>
  useContext<T_AnswerablePropsContext>(AnswerablePropsContext).standardAnswerProps;

export function useSentInAnswerProps<T = T_SentInAnswer>() {
  return useContext<T_AnswerablePropsContext>(AnswerablePropsContext)
    .sentInAnswerProps as T_SentInAnswerProps<T>;
}

/**
 * Sent-in answers helpers
 */

function findSentInAnswers(sentInAnswers, answerable: T_AnswerablesViewNodes['answerable_value']) {
  if (!sentInAnswers) return null;

  return sentInAnswers.filter((a) => a.nodeKey === answerable.nodeKey);
}

/**
 * Standars answers helpers
 */

function determineAnswerCount(answerable: T_AnswerablesViewNodes['answerable_value']) {
  switch (answerable.type) {
    case T_ElemenNodeType.FILL_LIST: {
      return answerable.items.length;
    }
    case T_ElemenNodeType.FILL_GAPS: {
      let gapNumber = 0;

      forEachNode(answerable, T_NodeType.FILL_GAPS_GAP, () => gapNumber++);

      return gapNumber;
    }
    case T_ElemenNodeType.MARK_OPTION: {
      let gapNumber = 0;

      forEachNode(answerable, T_NodeType.MARK_OPTION_GAP, () => gapNumber++);

      return gapNumber;
    }
    case T_ElemenNodeType.MARK_IMAGE: {
      return answerable.markers.length;
    }
    default: {
      return null;
    }
  }
}

function determineAnswerPresence(answerable: T_AnswerablesViewNodes['answerable_value']) {
  switch (answerable.type) {
    case T_ElemenNodeType.ORAL: {
      return false;
    }
    case T_ElemenNodeType.OPEN_ANSWER: {
      return !!answerable.children.length;
    }
    default: {
      return true;
    }
  }
}

function toggleFullAnswer([showAnswer, setShowAnswer]) {
  return () => (showAnswer !== 'none' ? setShowAnswer('none') : setShowAnswer('all'));
}

function showOneAnswer(
  [showAnswer, setShowAnswer]: [
    number | 'all' | 'none',
    React.Dispatch<React.SetStateAction<number | 'all' | 'none'>>,
  ],
  answerCount: number,
) {
  return () => {
    switch (showAnswer) {
      case 'all': {
        break;
      }
      case answerCount - 1: {
        setShowAnswer('all');
        break;
      }
      case 'none': {
        setShowAnswer(answerCount === 1 ? 'all' : 1);
        break;
      }
      default: {
        setShowAnswer(showAnswer + 1);
      }
    }
  };
}

function hideOneAnswer(
  [showAnswer, setShowAnswer]: [
    number | 'all' | 'none',
    React.Dispatch<React.SetStateAction<number | 'all' | 'none'>>,
  ],
  answerCount: number,
) {
  return () => {
    switch (showAnswer) {
      case 'none': {
        break;
      }
      case 'all': {
        setShowAnswer(answerCount === 1 ? 'none' : answerCount - 1);
        break;
      }
      case 1: {
        setShowAnswer('none');
        break;
      }
      default: {
        setShowAnswer(showAnswer - 1);
      }
    }
  };
}
