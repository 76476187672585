import React from 'react';

import { Column, Columns } from '~/components/ui/column-layout';
import { splitCollection } from '~/utils/splitCollection';

type Props = {
  gaps: any[];
};

export function AnswerInputFields(props: Props) {
  const { gaps } = props;

  const [column1, column2] = splitCollection(gaps);

  return (
    <Columns className="break-inside-avoid mt-[3mm]">
      <Column className="pr-2">{renderAnswerInputFields(column1)}</Column>
      <Column>{renderAnswerInputFields(column2)}</Column>
    </Columns>
  );

  function renderAnswerInputFields(gaps) {
    return gaps.map((gap, index) => {
      return (
        <div className="mb-[2mm] flex h-[18pt] flex-row items-end" key={index}>
          <span className="text-[11pt] text-[#4b5563]">{gap.number}. </span>
          <div className="border-b-[1px] border-dotted border-gray-400 w-full ml-[1.5mm] mr-[3mm]">
            {gap.answer ? <p className="text-[#0066cc] text-[10pt] m-0">{gap.answer}</p> : null}
          </div>
        </div>
      );
    });
  }
}
