import { router } from '@inertiajs/react';
import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'react-i18next';
import { MoreHorizontal } from 'styled-icons/fluentui-system-regular';

import ClassGroupMembersApi from '~/api/ClassGroupMembersApi';
import { AlertDialog, AlertDialogTrigger } from '~/components/ui/alert-dialog';
import { Button } from '~/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '~/components/ui/dropdown-menu';
import { DialogConfirmDestructiveAction } from '~/components/ui-extended/dialog-confirm-destructive-action';
import { T_Book, T_ClassGroup, T_User } from '~/types/serializers';

export type Props = {
  user: T_User;
  classGroup: T_ClassGroup;
  book: T_Book;
};

export function UserOptions(props: Props) {
  const { t } = useTranslation();

  const { book, classGroup, user } = props;

  return (
    <AlertDialog>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            data-testid="class_group_user_options_button"
            variant="ghost"
            className="h-8 w-8 p-0">
            <span className="sr-only">{t('common.screenReader.openMenu')}</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>{t('common.nouns.actions')}</DropdownMenuLabel>
          <DropdownMenuSeparator />

          <AlertDialogTrigger asChild>
            <DropdownMenuItem data-testid="remove_class_group_user_button">
              {t('common.buttons.remove')}
            </DropdownMenuItem>
          </AlertDialogTrigger>
        </DropdownMenuContent>
      </DropdownMenu>

      <DialogConfirmDestructiveAction
        destructiveAction={removeStudent}
        message={t('pages.classGroupMembersIndex.dialogs.removeMember.description')}
      />
    </AlertDialog>
  );

  function removeStudent() {
    console.log('removeStudent', user, book, classGroup);
    router.delete(
      ClassGroupMembersApi.destroy.path({
        book_slug: book.slug,
        class_group_id: classGroup.id,
        id: user.id,
      }),
    );
  }
}
