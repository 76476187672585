import React from 'react';

import { T_Image } from '~/types/node/T_Image';

type Props = {
  node: T_Image['ViewNode'];
};

export function Image(props: Props) {
  const { node } = props;

  if (!node.print_src) {
    return (
      <div>
        <p>Afbeelding Placeholder</p>
      </div>
    );
  }

  return (
    <div className="my-2.5 flex justify-center w-full">
      <img className="w-11/12 h-auto max-h-[230mm] border" src={node.print_src} />
    </div>
  );
}
