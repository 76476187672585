import React from 'react';

import { SourceButton } from './source-button';

type Props = {
  node: { large_src: string; source?: string };
} & React.ImgHTMLAttributes<HTMLImageElement>;

export function Imageable(props: Props) {
  const { node, ...imgProps } = props;

  return (
    <div className="relative">
      <img {...imgProps} />

      <SourceButton source={node.source} />
    </div>
  );
}
