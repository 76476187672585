import { usePage } from '@inertiajs/react';

import { T_Access, T_Book, T_InvoiceDetails, T_LicenseGroup } from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';
import { T_PagyMetadata } from '~/types/T_PagyMetadata';

export const useLicenseGroupsNewPage = usePage<T_LicenseGroupsNewProps>;

export interface T_LicenseGroupsNewProps extends T_PageProps {
  access: T_Access;
  book: T_Book;
  licenseGroups: T_LicenseGroup[];
  pagyMetadata: T_PagyMetadata;
  invoiceDetails?: T_InvoiceDetails;
}
