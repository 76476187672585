import React from 'react';
import { useTranslation } from 'react-i18next';

import CourseContentsApi from '~/api/CourseContentsApi';
import { T_Extract } from '~/types/serializers';

import { Explanation } from '../../nodes/definition/Explanation';

type Props = {
  keyword: string;
  extract: T_Extract;
};

export function ContentDefinition(props: Props) {
  const { t } = useTranslation();

  const { extract } = props;

  return (
    <>
      <Explanation node={extract.node.explanation} />

      <div className="pt-1 border-t">
        <a
          className="text-sm font-light text-gray-600 hover:underline hover:text-gray-800"
          target="_blank"
          rel="noreferrer"
          href={`${CourseContentsApi.show.path({
            slug: extract.courseContent.slug,
          })}#${extract.nodeKey}`}>
          {t('pages.courseContentsShow.buttons.keywordViewDefinition')}
        </a>
      </div>
    </>
  );
}
