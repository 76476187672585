import React from 'react';
import { useTranslation } from 'react-i18next';

import BooksApi from '~/api/BooksApi';
import { Card, CardHeader, CardTitle } from '~/components/ui/card';
import { Layout } from '~/components/ui-extended/layout';

import { NewBookForm } from './new-book-form';

export default function New() {
  const { t } = useTranslation();

  return (
    <Layout
      breadcrumbs={[
        {
          title: 'common.breadcrumbs.booksIndexPage',
          translateTitle: true,
          type: 'link',
          href: BooksApi.index.path(),
        },
        { title: 'common.breadcrumbs.booksNewPage', translateTitle: true, type: 'static' },
      ]}
      sidebarTitle="Brightbook"
      sidebarChildren={<></>}
      htmlHeadTitle={t('pages.booksNew.htmlHeadTitle')}>
      <div className="flex flex-1 flex-col mt-24 mx-auto max-w-4xl">
        <Card>
          <CardHeader>
            <CardTitle className="text-2xl">{t('pages.booksNew.title')}</CardTitle>
          </CardHeader>
          <NewBookForm />
        </Card>
      </div>
    </Layout>
  );
}
