import { Link, usePage } from '@inertiajs/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import PartsApi from '~/api/PartsApi';
import { AccordionContent, AccordionItem, AccordionTrigger } from '~/components/ui/accordion';
import { buttonVariants } from '~/components/ui/button';
import { cn } from '~/utils';

import { CustomSections } from './custom-sections';
import { NewCustomSectionDialog } from './new-custom-section-dialog';
import { Sections } from './sections';

type Props = {
  access: any;
  nestedPart: any;
};

export function NestedPart(props: Props) {
  const { t } = useTranslation();

  const { access, customSections } = usePage<any>().props;

  const { nestedPart } = props;

  const customSectionsForChapter = useMemo(() => {
    return (customSections || []).filter((customSection) => customSection.partId === nestedPart.id);
  }, [customSections]);

  return (
    <AccordionItem value={nestedPart.slug} data-testid={`nested_part_${nestedPart.slug}`}>
      <AccordionTrigger
        className={cn('text-black font-semibold', nestedPart.published ? '' : 'text-stone-400')}>
        <span>
          {nestedPart.number}. {nestedPart.title}{' '}
          {!nestedPart.published && <span>({t('models.part.unpublished')})</span>}
        </span>
      </AccordionTrigger>

      <AccordionContent>
        <Sections nestedPart={nestedPart} sections={nestedPart.sections} />

        <CustomSections
          access={access}
          partSlug={nestedPart.slug}
          customSections={customSectionsForChapter}
        />

        <div className="flex justify-end items-end">
          <Link
            href={PartsApi.show.path({ slug: nestedPart.slug })}
            className={cn(buttonVariants({ variant: 'outline', size: 'sm' }), 'ml-2')}>
            {access.student
              ? t('common.sidebar.courseContentOptions.studyProgress')
              : t('common.sidebar.courseContentOptions.groupAnalytics')}
          </Link>

          {!access.student && <NewCustomSectionDialog partSlug={nestedPart.slug} />}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
}
