import React from 'react';
import { useTranslation } from 'react-i18next';

export function ExerciseLayoutText(props) {
  const { t } = useTranslation();

  const { exercise_layout, user } = props;

  if (exercise_layout === 'empty') return null;
  if (exercise_layout === 'standard_answers') {
    return (
      <div className="bottom-[2cm] absolute [&>p]:text-[12pt] [&>p]:text-[#52616b]">
        <p>{t('pages.printTasksGenerate.text.filledInWithStandardAnswers')}</p>
      </div>
    );
  }

  return (
    <div className="exercise-layout">
      <p>
        {t('pages.printTasksGenerate.text.filledInBy', {
          user: `${user.first_name} ${user.last_name}`,
        })}
      </p>
    </div>
  );
}
