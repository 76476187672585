import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Link, router } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModeEdit } from 'styled-icons/material';

import CourseContentsApi from '~/api/CourseContentsApi';
import SectionsApi from '~/api/SectionsApi';
import { Badge } from '~/components/ui/badge';
import { DropdownMenuItem } from '~/components/ui/dropdown-menu';
import { Label } from '~/components/ui/label';
import { Switch } from '~/components/ui/switch';
import { cn } from '~/utils';

import { StructureItem } from './structure-item';

type Props = {
  nestedPart: any;
  bookSlug: string;
  isInDragAction: boolean;
  part: any;
  sections: any[];
};

export function Sections(props: Props) {
  const { t } = useTranslation();

  const { isInDragAction, sections, part, nestedPart } = props;

  const { setNodeRef } = useDroppable({
    id: nestedPart.slug,
    data: {
      accepts: 'section',
    },
  });

  return (
    <div data-testid={`section_droppable_${nestedPart.slug}`} className="pb-2" ref={setNodeRef}>
      <SortableContext items={sections.map((s) => s.id)} strategy={verticalListSortingStrategy}>
        {sections.length ? (
          <div className={cn(isInDragAction && 'pending-drag-action')}>
            {sections.map((section, index) => (
              <StructureItem
                menuClassName={index === 0 ? 'section-options' : undefined}
                sortable={{
                  id: section.id,
                  data: {
                    type: 'section',
                    partSlug: part.slug,
                    nestedPartSlug: nestedPart.slug,
                  },
                }}
                meta={{
                  type: 'section',
                  title: section.title,
                  id: section.courseContent.slug,
                  number: section.number,
                  i18nKey: 'section',
                }}
                paths={{
                  update: SectionsApi.update.path({
                    part_slug: nestedPart.slug,
                    id: section.id,
                  }),
                  delete: SectionsApi.destroy.path({
                    part_slug: nestedPart.slug,
                    id: section.id,
                  }),
                }}
                extraOptions={
                  nestedPart.published
                    ? [
                        <DropdownMenuItem
                          key="publish"
                          onClick={(e) => handleSelectToggle(e, section)}>
                          <div className="flex items-center space-x-2">
                            <Switch
                              data-testid={
                                section.courseContent.published
                                  ? 'unpublish_section_button'
                                  : 'publish_section_button'
                              }
                              checked={section.courseContent.published}
                              id="publish-section"
                            />
                            <Label htmlFor="publish-section">
                              {t('pages.booksEditStructure.buttons.publishSection')}
                            </Label>
                          </div>
                        </DropdownMenuItem>,
                      ]
                    : []
                }
                rightSideContent={
                  <div className="mr-2 flex items-center">
                    {nestedPart.published && (
                      <div>
                        {section.courseContent.published ? (
                          <Badge
                            className="bg-emerald-100 text-emerald-800 h-5 border-none"
                            variant="outline">
                            {t('models.section.published')}
                          </Badge>
                        ) : (
                          <Badge
                            className="bg-orange-100 text-orange-800 h-5 border-none"
                            variant="outline">
                            {t('models.section.unpublished')}
                          </Badge>
                        )}
                      </div>
                    )}

                    <Link
                      className={cn(
                        'ml-2 flex items-center text-sm bg-gray-200 rounded hover:bg-gray-100 px-2',
                        index === 0 && 'section-editor',
                      )}
                      href={CourseContentsApi.editor.path({ slug: section.courseContent.slug })}>
                      <ModeEdit className="w-3 h-3 mr-1" />
                      {t('pages.booksEditStructure.buttons.openSectionEditor')}
                    </Link>
                  </div>
                }
                createItem={() => createSection(section.position + 1)}
                key={section.slug}
              />
            ))}
          </div>
        ) : (
          <div className="text-xs mt-2 mb-1 text-gray-600 font-light">
            {t('pages.booksEditStructure.text.noSectionsYet')}
            <button
              type="button"
              data-testid="create_first_section_button"
              onClick={() => createSection(0)}
              className="text-black font-bold ml-1">
              {t('pages.booksEditStructure.buttons.addFirstSection')}
            </button>
          </div>
        )}
      </SortableContext>
    </div>
  );

  function createSection(position) {
    router.post(
      SectionsApi.create.path({ part_slug: nestedPart.slug }),
      {
        title: 'New section',
        position: position,
      },
      {
        preserveScroll: true,
      },
    );
  }

  function handleSelectToggle(e, section) {
    e.preventDefault();
    togglePublished(section);
  }

  function togglePublished(section) {
    router.post(
      CourseContentsApi.setPublished.path({ slug: section.courseContent.slug }),
      { published: !section.courseContent.published },
      {
        preserveScroll: true,
        preserveState: true,
      },
    );
  }
}
