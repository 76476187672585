import React from 'react';
import { useTranslation } from 'react-i18next';

import { T_AnswerableMarkImage } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImage';
import { T_AnswerableMarkImageMarker } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImageMarker';

import { SentInAnswersWrapper } from '../../shared/SentInAnswersWrapper';
import OpenTextView from './OpenTextView';
import { PercentageView } from './PercentageView';

type Props = {
  activeItem: T_AnswerableMarkImageMarker['ViewNode'] | null;
  answerable: T_AnswerableMarkImage['ViewNode'];
};

export function SentInAnswers(props: Props) {
  const { t } = useTranslation();

  const { answerable, activeItem } = props;

  return (
    <SentInAnswersWrapper>
      <div className="flex flex-col">
        <div className="border-t mt-1">
          {activeItem ? (
            !answerable.answer_by || answerable.answer_by === 'text' ? (
              <OpenTextView answerNodes={answerable.markers} activeItem={activeItem} />
            ) : (
              <PercentageView answerNodes={answerable.markers} activeItem={activeItem} />
            )
          ) : (
            <div className="mt-1 text-gray-500">
              {t('pages.courseContentsShow.nodes.exercise.selectItemToSeeSentInAnswers')}
            </div>
          )}
        </div>
      </div>
    </SentInAnswersWrapper>
  );
}
