import { zodResolver } from '@hookform/resolvers/zod';
import { router, usePage } from '@inertiajs/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import RegistrationsApi from '~/api/Users/RegistrationsApi';
import { Button } from '~/components/ui/button';
import { CardContent, CardFooter } from '~/components/ui/card';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import { T_PageProps } from '~/types/T_PageProps';

export function ChangePasswordForm() {
  const { t } = useTranslation();

  const {
    organization: { template },
  } = usePage<T_PageProps>().props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      current_password: '',
      new_password: '',
      confirm_new_password: '',
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <CardContent className="space-y-4">
          <FormField
            control={form.control}
            name="current_password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('pages.registrationsEdit.forms.changePassword.labels.currentPassword')}
                </FormLabel>
                <FormControl>
                  <Input type="password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="new_password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('pages.registrationsEdit.forms.changePassword.labels.newPassword')}
                </FormLabel>
                <FormControl>
                  <Input type="password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="confirm_new_password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('pages.registrationsEdit.forms.changePassword.labels.confirmNewPassword')}
                </FormLabel>
                <FormControl>
                  <Input type="password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </CardContent>

        <CardFooter className="border-t px-6 py-4">
          <Button style={{ backgroundColor: template.color1 }} type="submit">
            {t('common.buttons.save')}
          </Button>
        </CardFooter>
      </form>
    </Form>
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.patch(RegistrationsApi.update.path(), values, {
      preserveState: true,
      onError: (err) => {
        console.log(err);
      },
    });
  }
}

const formSchema = z
  .object({
    current_password: z.string().refine((data) => data !== '', {
      params: { i18n: 'customErrors.required' },
    }),
    new_password: z.string().min(6),
    confirm_new_password: z.string().min(6),
  })
  .refine((data) => data.new_password === data.confirm_new_password, {
    params: { i18n: 'customErrors.noPasswordMatch' },
    path: ['confirm_new_password'],
  });
