import React from 'react';
import { useTranslation } from 'react-i18next';

import { T_AudioFragment } from '~/types/node/T_AudioFragment';
import { Gutter } from '~/views/print/components/Gutter';

import { Title } from './Title';

type Props = {
  children: React.ReactNode;
  node: T_AudioFragment['ViewNode'];
};

export function AudioFragment(props: Props) {
  const { t } = useTranslation();

  const { node } = props;

  return (
    <div className="relative mb-[2.5mm]" data-testid={node.type}>
      <div className="text-xs text-gray-500">
        <Gutter content={t('common.nodes.audioFragment')} />
      </div>

      <Title node={node.title} />
    </div>
  );
}
