import { useTranslation } from 'react-i18next';

import { Separator } from '~/components/ui/separator';
import { T_ContainerTemplate } from '~/types/serializers';

import { NewContainerDialog } from './new-container-dialog';
import { UpdateContainerDialog } from './update-container-dialog';

type Props = {
  book: any;
  containerTemplates: T_ContainerTemplate[];
};

export function PageContainers(props: Props) {
  const { t } = useTranslation();

  const { book, containerTemplates } = props;

  return (
    <div className="space-y-6">
      <div className="flex items-end justify-between">
        <div>
          <h3 data-testid="book-edit-tab-title-containers" className="text-lg font-medium">
            {t('pages.booksEdit.text.pageContainerTitle')}
          </h3>
          <p className="text-sm text-muted-foreground">
            {t('pages.booksEdit.text.pageContainerDescription')}
          </p>
        </div>

        <NewContainerDialog book={book} />
      </div>

      <Separator />

      <div>
        <div className="space-y-2">
          {containerTemplates.map((template) => {
            return (
              <div
                data-testid={`container-template-${template.id}`}
                className="border bg-stone-50 px-2 py-1 rounded text-black font-medium text-sm flex items-center justify-between"
                key={template.id}>
                <div className="flex items-center">
                  <div
                    className="mr-2 w-6 h-4 border"
                    style={{ backgroundColor: template.color }}
                  />
                  {template.name}
                </div>

                <UpdateContainerDialog book={book} containerTemplate={template} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
