import React from 'react';

import { T_TextMultiLine } from '~/types/node/T_TextMultiLine';

import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_TextMultiLine['ViewNode'];
};

export function Content(props: Props) {
  const { node } = props;

  return (
    <div className="text-gray-700 relative w-2/3 pl-4 [&_p]:text-base [&_p]:first:mt-0">
      <NodeMap nodes={node.children} />
    </div>
  );
}
