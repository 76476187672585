import React from 'react';
import { Cog6Tooth } from 'styled-icons/heroicons-outline';

import { Button } from '~/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import { useCourseContentShowPage } from '~/pages/course-contents/show/T_CourseContentShowProps';

type Props = {
  children: React.ReactNode;
};

export function MoreOptionsDropdown(props: Props) {
  const { children } = props;

  const { access, focus } = useCourseContentShowPage().props;

  if (access.student || !!focus) return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button data-testid="course-content-options-button" variant="outline" size="icon">
          <Cog6Tooth className="w-4 h-4" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent data-testid="course-content-options-menu" className="w-56">
        {React.Children.map(children, (child, index) => (
          <DropdownMenuItem asChild key={index}>
            {child}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
