import { zodResolver } from '@hookform/resolvers/zod';
import { router } from '@inertiajs/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import CustomSectionsApi from '~/api/CustomSectionsApi';
import { Button } from '~/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';

const formSchema = z.object({
  title: z.string().min(3),
});

type Props = {
  partSlug: string;
  closeDialog: () => void;
};

export function NewCustomSectionForm(props: Props) {
  const { t } = useTranslation();

  const { partSlug, closeDialog } = props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: '',
    },
  });

  return (
    <Form {...form}>
      <form
        data-testid="new_custom_section_form"
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-8">
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('pages.booksShow.forms.newCustomSection.labels.title')}</FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder={t('pages.booksShow.forms.newCustomSection.placeholders.title')}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button data-testid="create_custom_section_button" type="submit">
          {t('pages.booksShow.forms.newCustomSection.buttons.createCustomSection')}
        </Button>
      </form>
    </Form>
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.post(CustomSectionsApi.create.path({ part_slug: partSlug }), values, {
      replace: true,
      onError: (err) => {
        console.log('ERR', err);
      },
      onSuccess: () => {
        form.reset();
        closeDialog();
      },
    });
  }
}
