import { usePage } from '@inertiajs/react';

import { T_Book, T_User } from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';

export const useBooksIndexPage = usePage<T_BooksIndexProps>;

export interface T_BooksIndexProps extends T_PageProps {
  user: T_User;
  activeBooks: T_Book[];
  archivedBooks: T_Book[];
}
