import React from 'react';

import BooksApi from '~/api/BooksApi';
import CourseContentsApi from '~/api/CourseContentsApi';
import { T_Access, T_Ancestry, T_Book, T_ClassGroup } from '~/types/serializers';
import T_AncestryPart from '~/types/T_AncestryPart';
import {
  T_Breadcrumb,
  T_BreadcrumbDropdownItem,
  T_BreadcrumbDropdownItemLink,
  T_BreadcrumbDropdownItemNestedMenu,
} from '~/types/T_Breadcrumb';

import { Layout } from './layout';
import { SidebarCourseContentMenu } from './sidebar-course-content-menu';

type Props = {
  access: T_Access;
  book: T_Book;
  breadcrumbs: T_Breadcrumb[];
  chapter?: T_AncestryPart;
  children: React.ReactNode;
  parts: T_Ancestry[];
  studentClassGroup?: T_ClassGroup;
};

export function LayoutChapter(props: Props) {
  const { access, book, breadcrumbs, children, chapter, parts, studentClassGroup } = props;

  return (
    <Layout
      language={book.language}
      htmlHeadTitle={chapter.title}
      sidebarChildren={
        <SidebarCourseContentMenu
          chapter={chapter}
          access={access}
          book={book}
          courseContent={null}
          studentClassGroup={studentClassGroup}
        />
      }
      sidebarTitle={`${chapter.number}. ${chapter.title}`}
      breadcrumbs={[
        {
          title: book.title,
          type: 'link',
          href: BooksApi.show.path({ slug: book.slug }),
        },
        {
          type: 'dropdown-collapsed',
          items: formatPartsAsBreadcrumbs(parts),
        },
        ...breadcrumbs,
      ]}>
      {children}
    </Layout>
  );

  function formatPartsAsBreadcrumbs(parts: T_Ancestry[]): T_BreadcrumbDropdownItem[] {
    return parts.map<T_BreadcrumbDropdownItemNestedMenu>((part) => ({
      title: part.title,
      type: 'dropdown-item-nested-menu',
      items: formatChaptersAsBreadcrumbs(part.children),
    }));
  }

  function formatChaptersAsBreadcrumbs(chapters: T_AncestryPart[]): T_BreadcrumbDropdownItem[] {
    return chapters.map<T_BreadcrumbDropdownItemLink>((child) => ({
      title: `${child.number} ${child.title}`,
      type: 'dropdown-item-link',
      disabled: !child.sections?.[0],
      href: CourseContentsApi.show.path({ slug: child.sections?.[0]?.slug }),
    }));
  }
}
