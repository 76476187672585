import React from 'react';

import { T_AnswerableFillGapsGap } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGapsGap';

type Props = {
  node: T_AnswerableFillGapsGap['ViewNode'];
};

export function Gap(props: Props) {
  const { node } = props;

  return <span className="px-0.5 inline-block font-bold text-gray-800">({node.number})</span>;
}
