import { usePage } from '@inertiajs/react';

import { T_Access, T_Book, T_ClassGroup, T_User } from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';
import { T_PagyMetadata } from '~/types/T_PagyMetadata';

export const useClassGroupMembersIndexPage = usePage<T_ClassGroupMembersIndex>;

export interface T_ClassGroupMembersIndex extends T_PageProps {
  access: T_Access;
  book: T_Book;
  classGroup: T_ClassGroup;
  pagyMetadata: T_PagyMetadata;
  users: T_User[];
}
