import { Link, usePage } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import RegistrationsApi from '~/api/Users/RegistrationsApi';
import { PageContainer } from '~/components/layout/page-container';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '~/components/ui/card';
import { BrightbookLogo } from '~/pages/website/components/brightbook-logo';
import { T_PageProps } from '~/types/T_PageProps';

import { ResetPasswordForm } from './reset-password-form';

export default function New() {
  const { t } = useTranslation();

  const { organization } = usePage<T_PageProps>().props;

  const { template } = organization;

  return (
    <PageContainer htmlHeadTitle={t('pages.passwordNew.htmlHeadTitle')}>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {template.logoUrl ? (
            <img className="mx-auto h-10 w-auto" src={template.logoUrl} alt={organization.name} />
          ) : (
            <BrightbookLogo className="mx-auto h-12 w-12" />
          )}
        </div>

        <Card className="my-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <CardHeader>
            <CardTitle className="text-xl">{t('pages.passwordNew.title')}</CardTitle>
          </CardHeader>
          <CardContent>
            <ResetPasswordForm />
          </CardContent>

          <CardFooter className="flex justify-center text-sm">
            <h2>
              {t('pages.passwordNew.text.noAccountyet')}{' '}
              <Link style={{ color: template.color1 }} href={RegistrationsApi.new.path()}>
                {t('pages.passwordNew.buttons.registerNewAccount')}
              </Link>
            </h2>
          </CardFooter>
        </Card>

        <div className="mx-auto text-gray-500">
          {t('pages.passwordNew.text.madeWith')}{' '}
          <a
            target="_blank"
            href="https://education.brightbook.io/home"
            className="underline font-serif"
            rel="noreferrer">
            Brightbook.
          </a>
        </div>
      </div>
    </PageContainer>
  );
}

New.layout = (page) => page;
