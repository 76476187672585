import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckAll } from 'styled-icons/bootstrap';

import { Button } from '~/components/ui/button';
import { cn } from '~/utils';

type Props = {
  onPressedChange: () => void;
  disabled: boolean;
  active: boolean;
  multipleAnswers: boolean;
};

export function ToggleAnswer(props: Props) {
  const { t } = useTranslation();

  const { active, disabled, onPressedChange, multipleAnswers } = props;

  return (
    <Button
      variant="outline"
      data-testid="toggle-all-answers-button"
      className={cn(active && 'bg-stone-100')}
      disabled={disabled}
      onClick={onPressedChange}
      size="sm">
      {active ? (
        <>
          <CheckAll className="mr-1 h-4 w-4" />
          {multipleAnswers
            ? t('pages.courseContentsShow.buttons.hideAllAnswers')
            : t('pages.courseContentsShow.buttons.hideAnswer')}
        </>
      ) : (
        <>
          <CheckAll className="mr-1 h-4 w-4" />
          {multipleAnswers
            ? t('pages.courseContentsShow.buttons.showAllAnswers')
            : t('pages.courseContentsShow.buttons.showAnswer')}
        </>
      )}
    </Button>
  );
}
