import React from 'react';

import { T_BlockQuote } from '~/types/node/T_BlockQuote';

import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_BlockQuote['ViewNode'];
};

export function BlockQuote(props: Props) {
  const { node } = props;

  return (
    <blockquote className="italic font-serif text-xl text-gray-600 border-yellow-400 border-l-4 pl-3 ">
      <NodeMap nodes={node.children} />
    </blockquote>
  );
}
