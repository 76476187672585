import { router } from '@inertiajs/react';
import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'react-i18next';
import { MoreHorizontal } from 'styled-icons/fluentui-system-regular';

import AuthorsApi from '~/api/AuthorsApi';
import { AlertDialog, AlertDialogTrigger } from '~/components/ui/alert-dialog';
import { Button } from '~/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '~/components/ui/dropdown-menu';
import { DialogConfirmDestructiveAction } from '~/components/ui-extended/dialog-confirm-destructive-action';
import { T_Collaboration } from '~/types/serializers';

import { useAuthorsIndexPage } from '../T_AuthorsIndex';

export type Props = {
  collaboration: T_Collaboration;
};

export function CollaborationOptions(props: Props) {
  const { t } = useTranslation();

  const { collaboration } = props;

  const { access, book } = useAuthorsIndexPage().props;

  if (!access.owner) return null;

  return (
    <AlertDialog>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Button
            data-testid="collaboration_options_button"
            variant="ghost"
            className="h-8 w-8 p-0">
            <span className="sr-only">{t('common.screenReader.openMenu')}</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>{t('common.nouns.actions')}</DropdownMenuLabel>
          <DropdownMenuSeparator />

          <AlertDialogTrigger asChild>
            <DropdownMenuItem data-testid="remove_collaboration_button">
              {t('common.buttons.remove')}
            </DropdownMenuItem>
          </AlertDialogTrigger>
        </DropdownMenuContent>
      </DropdownMenu>

      <DialogConfirmDestructiveAction
        destructiveAction={removeAuthor}
        message={t('pages.collaborationsIndex.dialogs.removeCollaboration.description')}
        title={t('common.dialogs.confirmAction.title')}
      />
    </AlertDialog>
  );

  function removeAuthor() {
    router.delete(AuthorsApi.destroy.path({ book_slug: book.slug, id: collaboration.id }), {
      preserveState: false,
    });
  }
}
