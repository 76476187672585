import React, { useEffect, useState } from 'react';

import { LayoutCourseContent } from '~/components/ui-extended/layout-course-content';
import { TopMenu } from '~/pages/course-contents/show/components/top-menu';
import { BookView } from '~/views/book/BookView';
import { SentInAnswersContextWrapper } from '~/views/book/components/SentInAnswersContext';
import { ViewBookContextProvider } from '~/views/book/hooks/useViewBookContext';

import { PresentationNavigation } from './components/presentation-navigation';
import { FocusedContent } from './components-old/FocusedContent';
import { SidebarMenu } from './components-old/sidebar';
import { useCourseContentShowPage } from './T_CourseContentShowProps';

/**
 * Belangrijk:
 *  FocusedContent MOET de eerste child zijn in de container die overflow beheert.
 *  En die mogen ook niet genest zijn!
 *
 *  Bewijs:
 *
 *  In onderstaande is het niet mogelijk om de div met border de geschaalde div
 *  netjes te laten wrappen.
 *
 *  https://play.tailwindcss.com/7RdybvYeWM
 */

export default function Show() {
  const pageProps = useCourseContentShowPage().props;

  const { access, courseContent, book, focus, parts, studentClassGroup, studentSelfAssessments } =
    pageProps;

  const [hideSidebar, setHideSidebar] = useState(false);

  useEffect(() => {
    setHideSidebar(!!focus);
  }, [focus]);

  return (
    <LayoutCourseContent
      access={access}
      book={book}
      hideSidebar={hideSidebar}
      breadcrumbs={[]}
      courseContent={courseContent}
      htmlHeadTitle={courseContent.courseContentable.title}
      parts={parts}
      studentSelfAssessments={studentSelfAssessments}
      rightSideContent={<TopMenu />}
      sidebarTitle={courseContent.courseContentable.title}
      studentClassGroup={studentClassGroup}>
      <SidebarMenu />
      {focus && <PresentationNavigation />}

      <ViewBookContextProvider value={pageProps}>
        <SentInAnswersContextWrapper>
          {!focus && <BookView />}
          {focus && <FocusedContent zoomFactor={1.5} />}
        </SentInAnswersContextWrapper>
      </ViewBookContextProvider>
    </LayoutCourseContent>
  );
}
