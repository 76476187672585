// JsFromRoutes CacheKey 68a77956edf94ce7b5c3dddfd07228d7
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/b/:book_slug/license_groups'),
  create: /* #__PURE__ */ definePathHelper('post', '/b/:book_slug/license_groups'),
  new: /* #__PURE__ */ definePathHelper('get', '/b/:book_slug/license_groups/new'),
}
