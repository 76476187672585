import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { T_CourseContent, T_Enrichment } from '~/types/serializers';
import T_Focus from '~/types/T_Focus';

import OutlineContent from './OutlineContent';
import OutlineEnrichments from './OutlineEnrichments';
import OutlineMedia from './OutlineMedia';

type Props = {
  bookContent: Record<string, any>;
  courseContent: T_CourseContent;
  enrichments: T_Enrichment[];
  focus: T_Focus;
};

export default function Navigation(props: Props) {
  const { t } = useTranslation();

  const { bookContent, courseContent, enrichments, focus } = props;

  const { courseContentable } = courseContent;

  const [filter, setFilter] = useState('content');

  return (
    <StyledDiv className="flex z-20 bg-white flex-col text-gray-600 fixed w-80 ml-6 rounded-lg top-[calc(var(--header-height)+40px+var(--breadcrumb-height))] bottom-[calc(var(--header-height)+20px)]">
      <div className="p-5 pb-2">
        <h2 className="text-gray-700 font-bold truncate">{courseContentable.numberedTitle}</h2>
      </div>

      <div className="px-5 pb-3">
        <button
          data-testid="content-outline-button"
          onClick={() => setFilter('content')}
          className={clsx(
            'text-gray-600 px-2 py-0.5 rounded mr-1.5 bg-gray-100 hover:bg-gray-300',
            { 'bg-gray-300': filter === 'content' },
          )}>
          {t('pages.courseContentsShow.buttons.content')}
        </button>
        <button
          data-testid="media-outline-button"
          onClick={() => setFilter('media')}
          className={clsx(
            'text-gray-600 px-2 mr-1.5 py-0.5 rounded bg-gray-100 hover:bg-gray-300',
            {
              'bg-gray-300': filter === 'media',
            },
          )}>
          {t('pages.courseContentsShow.buttons.media')}
        </button>
        <button
          data-testid="enrichments-outline-button"
          onClick={() => setFilter('enrichments')}
          className={clsx('text-gray-600 px-2 py-0.5 rounded bg-gray-100 hover:bg-gray-300', {
            'bg-gray-300': filter === 'enrichments',
          })}>
          {t('pages.courseContentsShow.buttons.enrichments')}
        </button>
      </div>

      {filter === 'content' && (
        <OutlineContent focus={focus} bookContent={bookContent} courseContent={courseContent} />
      )}
      {filter === 'media' && (
        <OutlineMedia focus={focus} bookContent={bookContent} courseContent={courseContent} />
      )}
      {filter === 'enrichments' && (
        <OutlineEnrichments focus={focus} enrichments={enrichments} courseContent={courseContent} />
      )}
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  box-shadow:
    0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
    0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
  border: 0.5px solid #9ca3af;
`;
