import { zodResolver } from '@hookform/resolvers/zod';
import { router } from '@inertiajs/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Button } from '~/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '~/components/ui/form';
import { Input } from '~/components/ui/input';

type Props = {
  closePopover: () => void;
  path: string;
  meta: any;
};

const formSchema = z.object({
  title: z.string().min(3, { message: 'Titel moet minstens uit 4 tekens bestaan.' }),
});

export function StructureItemTitleEditForm(props: Props) {
  const { t } = useTranslation();

  const { closePopover, path, meta } = props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: meta.title,
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem className="z-99">
              <FormControl>
                <Input
                  data-testid={`${meta.type}_title_input`}
                  type="text"
                  placeholder="De ondergang van Rome"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex">
          <Button className="mt-2" type="submit">
            {t('common.buttons.save')}
          </Button>
        </div>
      </form>
    </Form>
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.patch(
      path,
      { [meta.paramType ?? meta.type]: values },
      {
        replace: true,
        onSuccess: () => {
          form.reset();
          closePopover();
        },
      },
    );
  }
}
