import { usePage } from '@inertiajs/react';
import React from 'react';

import { DataTable } from '~/components/ui/data-table';
import { DataTableContainer } from '~/components/ui-extended/data-table-container';

import { columns } from '../columns';

export function PageContent() {
  const { book, discussions, pagyMetadata } = usePage<any>().props;

  return (
    <DataTableContainer translateTitle title="pages.booksDiscussions.title">
      <DataTable columns={columns} data={discussions} meta={{ book }} pagyMetadata={pagyMetadata} />
    </DataTableContainer>
  );
}
