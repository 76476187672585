import { T_AnswerableFillGaps } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGaps';
import { T_AnswerableFillGapsGap } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGapsGap';
import { T_NodeType } from '~/types/T_NodeTypes';

export function forEachGap(
  answerable: T_AnswerableFillGaps['ViewNode'],
  cb: (gap: T_AnswerableFillGapsGap['ViewNode']) => void,
) {
  for (const paragraph of answerable.children) {
    for (const childNode of paragraph.children) {
      if ('type' in childNode) cb(childNode);
    }
  }
}

// TODO: verenig dit met de helpers in mark option

export function getGapNodes(answerable: T_AnswerableFillGaps['ViewNode']) {
  return answerable.children
    .map((node) => {
      const gapNodes = node.children.filter(
        (node) => 'type' in node && node.type === T_NodeType.FILL_GAPS_GAP,
      );

      return gapNodes as T_AnswerableFillGapsGap['ViewNode'][];
    })
    .flat();
}

export function forEachNode(
  node: Record<string, any>,
  type: string,
  callback: (node: Record<string, any>) => void,
) {
  if (node.children.length === 0) return;

  for (const childNode of node.children) {
    if ('type' in childNode && childNode.type === type) {
      callback(childNode);
    }

    if ('children' in childNode) {
      forEachNode(childNode, type, callback);
    }
  }
}

export function collectNodes(node: Record<string, any>, type: string) {
  const collectedNodes: any[] = [];

  if (node.children.length === 0) return collectedNodes;

  for (const childNode of node.children) {
    if ('type' in childNode && childNode.type === type) {
      collectedNodes.push(childNode);
    }

    if ('children' in childNode) {
      collectedNodes.push(...collectNodes(childNode, type));
    }
  }

  return collectedNodes.flat();
}

export function findNodeByProperty(node: Record<string, any>, property: string, value: string) {
  if (node[property] === value) return node;

  if (node.children.length === 0) return undefined;

  for (const childNode of node.children) {
    if (childNode[property] === value) return childNode;

    if ('children' in childNode) {
      const result = findNodeByProperty(childNode, property, value);

      if (result) return result;
    }
  }
}
