import { usePage } from '@inertiajs/react';

import {
  T_Access,
  T_Ancestry,
  T_Book,
  T_ClassGroup,
  T_CourseContent,
  T_CourseContentable,
  T_Discussion,
} from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';
import { T_PagyMetadata } from '~/types/T_PagyMetadata';

export const useDiscussionsIndexPage = usePage<T_DiscussionsIndexProps>;

export interface T_DiscussionsIndexProps extends T_PageProps {
  access: T_Access;
  book: T_Book;
  courseContent: T_CourseContent;
  customSections: T_CourseContentable[];
  discussions: T_Discussion[];
  pagyMetadata: T_PagyMetadata;
  parts: T_Ancestry[];
  studentClassGroup?: T_ClassGroup;
}
