import React from 'react';
import { useTranslation } from 'react-i18next';

import { DiscussionItem } from './DiscussionItem';

export function DiscussionList(props) {
  const { t } = useTranslation();

  const { access, discussions, courseContent } = props;

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
            {t('models.discussion.title')}
          </th>
          <th
            scope="col"
            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
            {t('models.discussion.author')}
          </th>
          {access.teacher && (
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              {t('models.discussion.group')}
            </th>
          )}
          <th
            scope="col"
            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
            {t('common.nouns.date')}
          </th>
          <th
            scope="col"
            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
            {t('models.discussionComment.many')}
          </th>
          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
            <span className="sr-only"> {t('common.buttons.edit')}</span>
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {discussions.map((discussion) => (
          <DiscussionItem
            discussion={discussion}
            key={discussion.id}
            access={access}
            courseContent={courseContent}
          />
        ))}
      </tbody>
    </table>
  );
}
