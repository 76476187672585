import { router } from '@inertiajs/react';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar, PlusSmall, User } from 'styled-icons/heroicons-outline';

import EnrichmentsApi from '~/api/EnrichmentsApi';
import { AspectRatioIframe } from '~/components/ui-extended/aspect-ratio-iframe';

import { useViewBookContext } from '../../hooks/useViewBookContext';

type Props = {
  nodeKey: string;
};

export function SharedEnrichmentsList(props: Props) {
  const { courseContent, sharedEnrichments } = useViewBookContext();

  const { nodeKey } = props;

  const sharedEnrichmentsForNode = useMemo(() => {
    if (!sharedEnrichments) return [];

    return sharedEnrichments.filter((enrichment) => {
      return enrichment.nodeKey == nodeKey;
    });
  }, [nodeKey]);

  return (
    <div data-testid="shared_enrichments_list" className="flex flex-col flex-1">
      {sharedEnrichmentsForNode.map((enrichment, index) => {
        return (
          <Enrichment
            duplicateEnrichment={duplicateEnrichment}
            enrichment={enrichment}
            key={index}
          />
        );
      })}
    </div>
  );

  function duplicateEnrichment(enrichmentId, pushed_to_students) {
    router.post(
      EnrichmentsApi.duplicate.path({ course_content_slug: courseContent.slug, id: enrichmentId }),
      { pushed_to_students },
      { preserveState: false },
    );
  }
}

function Enrichment(props: {
  duplicateEnrichment: (id: number, pushToStudents: boolean) => void;
  enrichment: any;
}) {
  const { t } = useTranslation();

  const { duplicateEnrichment, enrichment } = props;

  const [pushToStudents, setPushToStudents] = useState(false);

  return (
    <div className="border rounded-xl shadow p-4 my-4">
      {enrichment.title && <p className="text-gray-700 mb-2">{enrichment.title}</p>}

      {renderExtraContent()}

      <div className="text-gray-500 mt-4 text-sm flex items-center">
        <User className="w-4 h-4 mr-1" />
        <h4>{enrichment.author.fullName}</h4>
        <div className="mx-2">•</div>
        <Calendar className="w-4 h-4 mr-1" />
        <h4>{dayjs(enrichment.createdAt).format('DD/MM/YYYY')}</h4>
      </div>

      <div className="flex items-center mt-4">
        <input
          id={`pushed_to_students_${enrichment.id}`}
          checked={pushToStudents}
          name={`pushed_to_students_${enrichment.id}`}
          type="checkbox"
          onChange={() => setPushToStudents(!pushToStudents)}
          className="focus:ring-green-400 h-4 w-4 text-green-400 border-gray-300"
        />
        <label
          htmlFor={`pushed_to_students_${enrichment.id}`}
          className="ml-1.5 font-semibold block text-sm text-gray-500">
          {t('pages.courseContentsShow.buttons.shareWithStudents')}
        </label>
      </div>

      <button
        onClick={handleEnrichmentDuplication}
        className="border mt-2 px-2 py-0.5 flex text-sm items-center rounded border-green-500 text-green-500">
        <PlusSmall className="w-4 h-4 mr-1" />
        {t('common.buttons.add')}
      </button>
    </div>
  );

  function handleEnrichmentDuplication() {
    duplicateEnrichment(enrichment.id, pushToStudents);
  }

  function renderExtraContent() {
    switch (enrichment.kind) {
      case 'image': {
        return (
          <div>
            <img src={enrichment.imageUrl} />
          </div>
        );
      }
      case 'youtube': {
        return <AspectRatioIframe url={enrichment.url} />;
      }
    }
  }
}
