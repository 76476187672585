import React from 'react';
import { useTranslation } from 'react-i18next';
import { EllipsisHorizontal } from 'styled-icons/heroicons-outline';

import { TypographyH3 } from '~/components/typography/heading-three';
import { Button } from '~/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '~/components/ui/sheet';

type Props = {
  children: React.ReactNode;
};

export function TopMenuMobile(props: Props) {
  const { t } = useTranslation();

  const { children } = props;

  return (
    <div className="sm:hidden">
      <Sheet>
        <SheetTrigger asChild>
          <Button size="icon" variant="outline">
            <EllipsisHorizontal className="w-4 h-4" />
          </Button>
        </SheetTrigger>

        <SheetContent className="h-2/3 bg-stone-100" side="bottom">
          <div className="border-b pb-1">
            <TypographyH3>{t('common.nouns.actions')}</TypographyH3>
          </div>

          {React.Children.map(children, (child, index) => (
            <div className="py-3 border-b" key={index}>
              {child}
            </div>
          ))}
        </SheetContent>
      </Sheet>
    </div>
  );
}
