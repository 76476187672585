import React, { useState } from 'react';

import { TNestedPart, TPart, TSection } from '../components/page-content';
import { DndContext } from './DndContext';
import { DndOverlay } from './DndOverlay';

type Props = {
  parts: TPart[];
  bookSlug: string;
  children: React.ReactNode;
  setIsInDragAction: (isInDragAction: boolean) => void;
  setParts: (parts: TPart[]) => void;
};

export function DndWrapper(props: Props) {
  const { children, parts, setIsInDragAction, setParts } = props;

  const [draggedItem, setDraggedItem] = useState<TPart | TNestedPart | TSection>(null);

  return (
    <DndContext
      parts={parts}
      setDraggedItem={setDraggedItem}
      setIsInDragAction={setIsInDragAction}
      setParts={setParts}>
      {children}

      <DndOverlay draggedItem={draggedItem} />
    </DndContext>
  );
}
