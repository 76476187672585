import React from 'react';

import { useTheme } from '../theme-provider';
import { Button, ButtonProps } from '../ui/button';

type Props = ButtonProps & React.RefAttributes<HTMLButtonElement>;

export function ActionButton(props: Props) {
  const { colors } = useTheme();

  return <Button style={{ backgroundColor: colors.action }} {...props} variant="default" />;
}
