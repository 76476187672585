import React from 'react';

import { useTheme } from '~/components/theme-provider';
import { HightlightContextProvider } from '~/hooks/useHighlightContext';
import { T_HeadingTwo } from '~/types/node/T_HeadingTwo';

import { GutterTitle } from '../../components/Gutter';
import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_HeadingTwo['ViewNode'];
};

export function HeadingTwo(props: Props) {
  const { node } = props;

  const { colors } = useTheme();

  const numArr = node.number.toString().split('.');

  const num = numArr[numArr.length - 1];

  const alpha = String.fromCharCode(parseInt(num, 10) + 64);

  return (
    <h3
      style={{ color: colors.headingTwo }}
      data-testid={`${node.type} ${node.nodeKey}`}
      className="text-xl leading-relaxed font-bold text-cyan-600">
      <GutterTitle style={{ color: colors.headingTwo }} className="font-bold text-cyan-600">
        {alpha}
      </GutterTitle>
      <HightlightContextProvider showHighlight={false}>
        <NodeMap nodes={node.children} />
      </HightlightContextProvider>
    </h3>
  );
}
