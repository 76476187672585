import { zodResolver } from '@hookform/resolvers/zod';
import { router, usePage } from '@inertiajs/react';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import StaticApi from '~/api/StaticApi';
import RegistrationsApi from '~/api/Users/RegistrationsApi';
import { Button } from '~/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '~/components/ui/popover';
import { RadioGroup, RadioGroupItem } from '~/components/ui/radio-group';
import { T_PageProps } from '~/types/T_PageProps';

const formSchema = z.object({
  email: z.string(),
  password: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  role: z.enum(['student', 'teacher_or_author']),
  language: z.string(),
});

export function SignUpForm() {
  const { t, i18n } = useTranslation();

  const {
    organization: { template },
  } = usePage<T_PageProps>().props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      role: 'student',
      language: i18n.language,
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('pages.registrationsNew.forms.newAccount.labels.firstName')}
                </FormLabel>
                <FormControl>
                  <Input autoComplete="given-name" type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('pages.registrationsNew.forms.newAccount.labels.lastName')}
                </FormLabel>
                <FormControl>
                  <Input autoComplete="last-name" type="text" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>{t('pages.registrationsNew.forms.newAccount.labels.email')}</FormLabel>
                <FormControl>
                  <Input type="email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="col-span-2">
                <FormLabel>
                  {t('pages.registrationsNew.forms.newAccount.labels.password')}
                </FormLabel>
                <FormControl>
                  <Input type="password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="role"
            render={({ field }) => (
              <FormItem className="col-span-2 mt-1">
                <FormLabel className="flex mb-1">
                  {t('pages.registrationsNew.forms.newAccount.labels.role')}

                  <Popover>
                    <PopoverTrigger>
                      <QuestionMarkCircledIcon className="ml-1" />
                    </PopoverTrigger>
                    <PopoverContent side="top" className="w-80 text-sm space-y-4">
                      <div>
                        <h4 className="font-bold mt-2">
                          {t('pages.registrationsNew.forms.newAccount.labels.roleStudent')}
                        </h4>
                        <p>
                          {t('pages.registrationsNew.forms.newAccount.descriptions.roleStudent')}
                        </p>
                      </div>

                      <div>
                        <h4 className="font-bold mt-2">
                          {t('pages.registrationsNew.forms.newAccount.labels.roleTeacherOrAuthor')}
                        </h4>
                        <p>
                          {t(
                            'pages.registrationsNew.forms.newAccount.descriptions.roleTeacherOrAuthor',
                          )}
                        </p>
                      </div>
                    </PopoverContent>
                  </Popover>
                </FormLabel>
                <FormControl>
                  <RadioGroup
                    orientation="horizontal"
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className="flex items-center w-full">
                    <FormItem
                      data-testid="role_student"
                      className="flex flex-1 items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="student" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        {t('pages.registrationsNew.forms.newAccount.labels.roleStudent')}
                      </FormLabel>
                    </FormItem>
                    <FormItem
                      data-testid="role_teacher_or_author"
                      className="flex flex-1 items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="teacher_or_author" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        {t('pages.registrationsNew.forms.newAccount.labels.roleTeacherOrAuthor')}
                      </FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex text-sm text-gray-600 col-span-2 my-2">
            <div>
              <p>
                {t('pages.registrationsNew.text.youAgreeTo')}{' '}
                <a
                  className="underline"
                  target="_blank"
                  href={StaticApi.termsOfService.path()}
                  rel="noreferrer">
                  {t('pages.registrationsNew.text.privacyAgreement')}{' '}
                </a>{' '}
                {t('pages.registrationsNew.text.andThe')}{' '}
                <span>
                  <Popover>
                    <PopoverTrigger className="underline">
                      {t('pages.registrationsNew.text.ageAgreement')}.
                    </PopoverTrigger>
                    <PopoverContent side="top" className="w-80 text-sm p-4">
                      {t('pages.registrationsNew.text.ageAgreementDescription')}
                    </PopoverContent>
                  </Popover>
                </span>
              </p>
            </div>
          </div>
        </div>

        <div>
          <Button
            style={{ backgroundColor: template.color1 }}
            type="submit"
            className="w-full font-bold">
            {t('pages.registrationsNew.buttons.register')}
          </Button>
        </div>
      </form>
    </Form>
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    const data = {
      ...values,
      password_confirmation: values.password,
      tos_agreement: true,
      age_agreement: true,
    };

    router.post(RegistrationsApi.create.path(), data, {
      onError: (errors) => {
        Object.entries(errors).forEach(([key, value]) => {
          const message = value[0];

          form.setError(key as any, { message });
        });
      },
    });
  }
}
