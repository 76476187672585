import React from 'react';

import { T_Paragraph } from '~/types/node/T_Paragraph';
import { cn } from '~/utils';
import { NodeMap } from '~/views/print/NodeMap';

import { useViewPrintContext } from '../../useViewPrintContext';

type Props = {
  node: T_Paragraph['ViewNode'];
};

export function Paragraph(props: Props) {
  const { node } = props;

  const { justify_text } = useViewPrintContext();

  return (
    <p
      data-testid={node.type}
      className={cn('text-sm text-gray-700 mb-3 paragraph', justify_text && ' text-justify')}>
      <NodeMap nodes={node.children} />
    </p>
  );
}
