import React from 'react';
import { useTranslation } from 'react-i18next';

import { T_Manual } from '~/types/node/T_Manual';
import { NodeMap } from '~/views/print/NodeMap';

import { Gutter } from '../../components/Gutter';

type Props = {
  node: T_Manual['ViewNode'];
};

export function Manual(props: Props) {
  const { t } = useTranslation();

  const { node } = props;

  return (
    <div
      className="relative bg-[#fef3c7] rounded my-[3mm] p-[5mm] leading-[1.4] [&>p]:m-0"
      data-testid={node.type}>
      <Gutter content={t('common.nodes.manual')} />
      <div className="text-gray-700">
        {node.content.children.map((child, index) => {
          return (
            <div className="my-2 w-full relative first:my-1 last:my-1" key={index}>
              <NodeMap nodes={[child]} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
