import React from 'react';

import { T_AnswerableMarkOptionGap } from '~/types/node/exercise/answerables/mark-option/T_AnswerableMarkOptionGap';

type Props = {
  node: T_AnswerableMarkOptionGap['ViewNode'];
};

export function Gap(props: Props) {
  const { node } = props;

  return <span className="px-0.5 inline-block font-bold text-gray-800">({node.number})</span>;
}
