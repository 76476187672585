import React from 'react';

import { Part } from './part';

type Props = {
  parts: any[];
  access: any;
};

export function Parts(props: Props) {
  const { access, parts } = props;

  return (
    <>
      {parts.map((part) => (
        <Part access={access} part={part} key={part.id} />
      ))}
    </>
  );
}
