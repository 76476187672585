import React from 'react';

import { T_AnswerableFillListItemKeyImage } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillListItemKeyImage';

type Props = {
  itemKey: T_AnswerableFillListItemKeyImage['ViewNode'];
};

export function KeyImage(props: Props) {
  const { itemKey } = props;

  if (!itemKey.print_src) {
    return (
      <div className="flex flex-col items-center justify-center w-20 h-20 mr-2 text-xs text-gray-700 border border-gray-300">
        <p>Afbeelding</p>
        <p>toe</p>
        <p>te</p>
        <p>voegen</p>
      </div>
    );
  }

  return <img className="w-[70%] mb-[7mm] mt-[2mm] px-[5mm]" src={itemKey.print_src} />;
}
