import { Head } from '@inertiajs/react';
import React, { useCallback } from 'react';

type Props = {
  htmlHeadTitle: string;
  htmlHeadMeta?: Record<string, string>;
};

export function HtmlHead(props: Props) {
  const { htmlHeadMeta, htmlHeadTitle } = props;

  const renderMeta = useCallback(() => {
    if (!htmlHeadMeta) return;

    return Object.entries(htmlHeadMeta).map(([name, content], index) => (
      <meta key={index} name={name} content={content} />
    ));
  }, []);

  return (
    <Head>
      <title>{htmlHeadTitle}</title>

      {renderMeta()}
    </Head>
  );
}
