import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TypographyMuted } from '~/components/typography/muted';

import { Flashcard } from './flashcard-item';
import { Navigation } from './navigation';
import ShuffleButton from './ShuffleButton';
import { usePartFlashcardsPage } from './T_PartFlashCardsProps';

export default function FlashcardDisplay() {
  const { t } = useTranslation();

  const { flashcards } = usePartFlashcardsPage().props;

  const flashcardCount = flashcards.length;

  const [deck, setDeck] = useState(flashcards);

  const [currentPosition, setCurrentPosition] = useState(1);
  const [currentFlashcard, setCurrentFlashcard] = useState(getNextFlashcard());

  useEffect(() => {
    if (currentPosition < 1) {
      setCurrentPosition(flashcardCount);
    } else if (currentPosition > flashcardCount) {
      setCurrentPosition(1);
    } else {
      setCurrentFlashcard(getNextFlashcard());
    }
  }, [currentPosition]);

  if (!flashcards.length) {
    return (
      <div className="mt-5">
        <TypographyMuted>{t('pages.partsFlashcards.text.noFlashcards')}</TypographyMuted>
      </div>
    );
  }

  return (
    <div className="h-full relative">
      <div className="-mt-3">
        <ShuffleButton shuffleFlashcards={shuffleFlashcards} />
      </div>

      <Flashcard flashcard={currentFlashcard} />

      <Navigation
        currentPosition={currentPosition}
        flashcardCount={flashcardCount}
        showNextFlashcard={showNextFlashcard}
        showPreviousFlashcard={showPreviousFlashcard}
      />
    </div>
  );

  function shuffleFlashcards() {
    shuffle(flashcards);
    setDeck(flashcards);

    setCurrentPosition(10000);
  }

  function showNextFlashcard() {
    setCurrentPosition(currentPosition + 1);
  }

  function showPreviousFlashcard() {
    setCurrentPosition(currentPosition - 1);
  }

  function getNextFlashcard() {
    if (flashcardCount > 0) {
      return deck[currentPosition - 1];
    } else {
      return null;
    }
  }
}

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}
