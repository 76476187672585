import { T_Access, T_StudentSelfAssessment } from '~/types/serializers';
import T_AncestryPart, { T_AncestrySection } from '~/types/T_AncestryPart';

export function userHasAccessToSection(
  chapter: T_AncestryPart,
  section: T_AncestrySection,
  access: T_Access,
  studentSelfAssessments: T_StudentSelfAssessment[],
) {
  if (chapter.learningPath === 'open' || !access.student) return true;

  if (chapter.sections?.[0].id === section.id) return true;

  const currentSectionIndex = chapter.sections?.findIndex((s) => s.id === section.id);

  const previousSection = chapter.sections?.[currentSectionIndex - 1];

  if (!previousSection) return false;

  return (studentSelfAssessments || []).some(
    (assessment) => assessment.courseContent.id === previousSection.courseContent.id,
  );
}
