import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Translation } from 'react-i18next';

import { DataTableColumnHeader } from '~/components/ui/data-table-column-header';
import { T_User } from '~/types/serializers';

import { UserOptions } from './components/user-options';

export const columns: ColumnDef<T_User>[] = [
  {
    accessorKey: 'firstName',
    id: 'first_name',
    header: ({ column }) => (
      <Translation>
        {(t) => (
          <DataTableColumnHeader
            className="pl-4"
            column={column}
            title={t('models.user.firstName')}
          />
        )}
      </Translation>
    ),
    cell: ({ cell }: CellContext<T_User, string>) => (
      <div className="w-[80px] pl-4">{cell.getValue()}</div>
    ),
  },
  {
    accessorKey: 'lastName',
    header: ({ column }) => (
      <Translation>
        {(t) => <DataTableColumnHeader column={column} title={t('models.user.lastName')} />}
      </Translation>
    ),
    cell: ({ cell }: CellContext<T_User, string>) => (
      <div className="flex space-x-2">
        <span className="max-w-[300px] truncate font-medium">{cell.getValue()}</span>
      </div>
    ),
  },
  {
    accessorKey: 'email',
    header: ({ column }) => (
      <Translation>
        {(t) => <DataTableColumnHeader column={column} title={t('models.user.email')} />}
      </Translation>
    ),
    cell: ({ cell }: CellContext<T_User, string>) => (
      <div className="text-right text-gray-600 max-w-[40px]">{cell.getValue()}</div>
    ),
  },
  {
    id: 'actions',
    cell: ({ row, table }) => {
      return (
        <UserOptions
          user={row.original}
          classGroup={table.options.meta.classGroup}
          book={table.options.meta.book}
        />
      );
    },
  },
];
