// JsFromRoutes CacheKey a198ef4d4963366e76277a57bd4798fb
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  flashcards: /* #__PURE__ */ definePathHelper('get', '/parts/:slug/flashcards'),
  sort: /* #__PURE__ */ definePathHelper('patch', '/parts/:slug/sort'),
  create: /* #__PURE__ */ definePathHelper('post', '/parts'),
  show: /* #__PURE__ */ definePathHelper('get', '/parts/:slug'),
  update: /* #__PURE__ */ definePathHelper('patch', '/parts/:slug'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/parts/:slug'),
}
