import React from 'react';

import { HightlightContextProvider } from '~/hooks/useHighlightContext';
import { T_HeadingThree } from '~/types/node/T_HeadingThree';

import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_HeadingThree['ViewNode'];
};

export function HeadingThree(props: Props) {
  const { node } = props;

  return (
    <h3
      data-testid={`${node.type} ${node.nodeKey}`}
      className="text-lg font-semibold leading-relaxed text-gray-800">
      <HightlightContextProvider showHighlight={false}>
        <NodeMap nodes={node.children} />
      </HightlightContextProvider>
    </h3>
  );
}
