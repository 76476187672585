import { useEffect } from 'react';

import consumer from '~/channels/consumer';
import { isJSTestEnv } from '~/utils/isJSTestEnv';

type SubscriptionOptions = {
  channel: string;
  room: string;
  stream: string;
  trigger?: boolean;
  onConnected?: (subscription) => void;
  onDisconnected?: (subscription) => void;
  props?: Record<string, any>;
};

export function useSubscription<Response = any>(
  callback: (response: Response) => void,
  options: SubscriptionOptions,
) {
  const { channel, room, onConnected, props, onDisconnected, stream, trigger } = options;

  useEffect(() => {
    if (trigger === false || isJSTestEnv) return;

    const subscription = consumer.subscriptions.create(
      { channel, room, stream, ...props },
      {
        received(response: Response) {
          callback(response);
        },
        connected() {
          onConnected && onConnected(this);
        },

        disconnected() {
          onDisconnected && onDisconnected(this);
        },
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [trigger]);
}
