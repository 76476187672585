import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClipboardDocument } from 'styled-icons/heroicons-outline';

import { Button } from '~/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';
import { toast } from '~/components/ui/use-toast';
import { copyToClipboard } from '~/utils/copyToClipboard';

type Props = {
  nodeKey: string;
};

export function CopyElementUrlButton({ nodeKey }: Props) {
  const { t } = useTranslation();

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button onClick={copyElementLinkToClipboard} size="sm" variant="ghost">
            <ClipboardDocument className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t('pages.courseContentsShow.text.copyElementLinkTooltip')}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );

  async function copyElementLinkToClipboard() {
    const url = document.URL.replace(/#.*$/, '');

    const result = await copyToClipboard(`${url}#${nodeKey}`);

    if (result) {
      toast({
        description: (
          <p className="font-medium">
            <span className="text-cyan-600">Url</span> was copied to clipboard.
          </p>
        ),
        duration: 1500,
      });
    }
  }
}
