import { DialogTrigger } from '@radix-ui/react-dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '~/components/ui/dialog';
import { T_Book } from '~/types/serializers';

import { RegisterMemberForm } from './register-member-form';

type Props = {
  book: T_Book;
};

export function RegisterMemberDialog(props: Props) {
  const { t } = useTranslation();

  const { book } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <p className="mb-4">{t('pages.classroomsShow.text.notYetRegistered')}</p>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger>
          <Button data-testid="register_member_button" variant="outline">
            {t('pages.classroomsShow.buttons.registerInGroup')}
          </Button>
        </DialogTrigger>

        <DialogContent>
          <DialogHeader>
            <DialogTitle> {t('pages.classroomsShow.dialogs.registerInGroup.title')}</DialogTitle>
          </DialogHeader>

          <RegisterMemberForm closeDialog={() => setOpen(false)} book={book} />
        </DialogContent>
      </Dialog>
    </>
  );
}
