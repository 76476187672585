import { Link, router } from '@inertiajs/react';
import { SlashIcon } from '@radix-ui/react-icons';
import React, { useMemo } from 'react';

import BooksApi from '~/api/BooksApi';
import CourseContentsApi from '~/api/CourseContentsApi';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '~/components/ui/breadcrumb';
import { T_Ancestry, T_Book, T_CourseContent, T_CourseContentable } from '~/types/serializers';
import T_AncestryPart, { T_AncestrySection } from '~/types/T_AncestryPart';

import { BreadcrumbDropdown } from './breadcrumb-dropdown';
import { BreadcrumbTitle } from './breadcrumb-title';
import { useBreadcrumbContent } from './useBreadcrumbContent';

type Props = {
  book: T_Book;
  courseContent: T_CourseContent;
  customSections: T_CourseContentable[];
  parts: T_Ancestry[];
};

export function BreadcrumbsBook(props: Props) {
  const { book, courseContent, customSections, parts } = props;

  const { parentPart, rootPart, partOptions, nestedPartOptions, courseContentOptions } =
    useBreadcrumbContent({
      courseContent,
      parts,
    });

  const customSectionsForPart = useMemo(() => {
    return (customSections || [])
      .filter((customSection) => customSection.partId === parentPart.id)
      .map((customSection) => ({
        ...customSection,
        title: customSection.title,
      }));
  }, []);

  return (
    <Breadcrumb>
      <BreadcrumbList className="text-gray-800 gap-0.5 sm:gap-0.5">
        <BreadcrumbItem>
          <Link href={BooksApi.show.path({ slug: book.slug })}>
            <BreadcrumbTitle title={book.title} />
          </Link>
        </BreadcrumbItem>

        <BreadcrumbSeparator>
          <SlashIcon />
        </BreadcrumbSeparator>

        <BreadcrumbDropdown
          onSelect={handlePartSelect}
          options={partOptions}
          title={rootPart.title}
        />

        <BreadcrumbSeparator>
          <SlashIcon />
        </BreadcrumbSeparator>

        <BreadcrumbDropdown
          onSelect={handleNestedPartSelect}
          options={nestedPartOptions}
          title={parentPart.title}
        />

        <BreadcrumbSeparator>
          <SlashIcon />
        </BreadcrumbSeparator>

        <BreadcrumbDropdown
          onSelect={handleCourseContentSelect}
          options={[...courseContentOptions, ...customSectionsForPart]}
          title={courseContent.courseContentable.numberedTitle}
        />
      </BreadcrumbList>
    </Breadcrumb>
  );

  function handlePartSelect(part: T_Ancestry) {
    if (!part.children?.[0].sections?.[0]) return;

    const {
      courseContent: { slug },
    } = part.children[0].sections[0];

    navigateToCourseContent(slug);
  }

  function handleNestedPartSelect(part: T_AncestryPart) {
    if (!part.sections?.[0]) return;

    const {
      courseContent: { slug },
    } = part.sections[0];

    navigateToCourseContent(slug);
  }

  function handleCourseContentSelect(courseContentable: T_AncestrySection) {
    navigateToCourseContent(courseContentable.slug);
  }

  function navigateToCourseContent(slug) {
    router.visit(CourseContentsApi.show.path({ slug }), {});
  }
}
