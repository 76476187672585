import { usePage } from '@inertiajs/react';

import { T_Sect1Element } from '~/types/nodes';
import {
  T_Access,
  T_Ancestry,
  T_Answer,
  T_Book,
  T_ClassGroup,
  T_ClassGroupCourseContent,
  T_ContainerTemplate,
  T_CourseContent,
  T_CourseContentable,
  T_CourseContentableNavigation,
  T_CurrentUserBook,
  T_CustomQuestion,
  T_CustomQuestionAnswer,
  T_Enrichment,
  T_StudentSelfAssessment,
  T_User,
} from '~/types/serializers';
import T_AncestryPart from '~/types/T_AncestryPart';
import T_Focus from '~/types/T_Focus';
import { T_PageProps } from '~/types/T_PageProps';

export const useCourseContentShowPage = usePage<T_CourseContentShowProps>;

export interface T_CourseContentShowProps extends T_PageProps {
  access: T_Access;
  answers: T_Answer[];
  book: T_Book;
  bookContent: T_Sect1Element;
  classGroupCourseContent?: T_ClassGroupCourseContent;
  courseContent: T_CourseContent;
  courseNavigation: T_CourseContentableNavigation;
  currentUserBook: T_CurrentUserBook;
  customQuestion: T_CustomQuestion;
  customQuestionAnswers: T_CustomQuestionAnswer[];
  customSections: T_CourseContentable[];
  enrichments: T_Enrichment[];
  chapter: T_AncestryPart;
  focus: T_Focus;
  parts: T_Ancestry[];
  recentCustomQuestions: T_CustomQuestion[];
  studentClassGroup?: T_ClassGroup;
  studentSelfAssessment: T_StudentSelfAssessment;
  studentSelfAssessments: T_StudentSelfAssessment[];
  teachingClassGroup: T_ClassGroup;
  teachingClassGroups: T_ClassGroup[];
  teachingClassGroupStudentAnswers: T_Answer[];
  teachingClassGroupStudents: T_User[];
  templateConfig: T_ContainerTemplate[];
}
