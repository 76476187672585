import { Link, router } from '@inertiajs/react';
import styled from 'styled-components';

import { buttonVariants } from '~/components/ui/button';
import { Menu } from '~/environment-editor/components/menu/menu';
import { MoreOptionsDropdown } from '~/pages/course-contents/show/components/more-options-dropdown';
import { cn } from '~/utils';

import { useTheme } from '../theme-provider';
import { TypographySmall } from '../typography/small';

export type Props = {
  active: boolean;
  disabled: boolean;
  href: string;
  icon: React.ElementType;
  linkToEditor?: boolean;
  editorHref?: string;
  editorIcon?: React.ElementType;
  title: string;
  'data-testid'?: string;
  className?: string;
};

export function SidebarButton(props: any) {
  const {
    className,
    active,
    disabled,
    href,
    icon,
    title,
    linkToEditor,
    editorHref,
    editorIcon,
    ...rest
  } = props;

  const { colors } = useTheme();

  return (
    <>
      <StyledLink
        {...rest}
        active={active}
        bgColor={colors.buttonHover}
        disabled={disabled}
        onClick={() => router.visit(href, {})}
        className={cn(
          buttonVariants({ variant: active ? 'secondary' : 'ghost' }),
          'w-full justify-start',
          active && 'cursor-default',
          disabled && 'text-stone-400',
          ` ${className}`,
        )}>
        {icon && <props.icon className="h-5 w-5 mr-3 text-stone-500" aria-hidden="true" />}
        <TypographySmall>{title}</TypographySmall>
        {active && linkToEditor && (
          <Link
            onClick={editorHref}
            href={editorHref}
            className={cn(buttonVariants({ variant: 'ghost', size: 'sm' }), 'w-6 h-6 p-0 ml-auto')}>
            {editorIcon && (
              <props.editorIcon className="h-5 w-5 text-stone-500" aria-hidden="false" />
            )}
          </Link>
        )}
      </StyledLink>
    </>
  );
}

const StyledLink = styled.button<{ bgColor: string; active: boolean }>`
  background-color: ${({ active, bgColor }) => (active ? bgColor : 'none')};

  &:hover {
    background-color: ${({ bgColor, disabled }) => {
      if (disabled) {
        return 'none';
      }

      return bgColor ? bgColor : 'rgb(231 229 228 / var(--tw-bg-opacity))';
    }};
  }
`;
