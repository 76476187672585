import React from 'react';

import { DataTableContainer } from '~/components/ui-extended/data-table-container';

import { useClassroomsShowPage } from '../T_ClassroomsShow';
import { ClassGroupInfo } from './class-group-info';
import { RegisterMemberDialog } from './register-member-dialog';

export default function Show() {
  const { book, classGroup } = useClassroomsShowPage().props;

  return (
    <DataTableContainer title="models.group.one" translateTitle>
      {classGroup ? (
        <ClassGroupInfo classGroup={classGroup} />
      ) : (
        <RegisterMemberDialog book={book} />
      )}
    </DataTableContainer>
  );
}
