import { Link, usePage } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { buttonVariants } from '~/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '~/components/ui/card';
import { BrightbookLogo } from '~/pages/website/components/brightbook-logo';
import { T_PageProps } from '~/types/T_PageProps';
import { cn } from '~/utils';

export default function New() {
  const { t } = useTranslation();

  const { organization } = usePage<T_PageProps>().props;

  const { template } = organization;

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {template.logoUrl ? (
          <img className="mx-auto h-10 w-auto" src={template.logoUrl} alt={organization.name} />
        ) : (
          <BrightbookLogo className="mx-auto h-12 w-12" />
        )}
      </div>

      <Card className="my-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <CardHeader>
          <CardTitle className="text-xl">{t('pages.confirmationsNew.title')}</CardTitle>
        </CardHeader>

        <CardContent>
          <p className="mb-4 text-sm">{t('pages.confirmationsNew.text.nextStepDescription')}</p>

          <p className="mb-4 text-sm text-gray-500">
            {t('pages.confirmationsNew.text.spamWarning')}
          </p>

          <Link
            style={{ backgroundColor: template.color1 }}
            className={cn(buttonVariants({}), 'w-full')}
            href="/">
            {t('pages.confirmationsNew.buttons.goToHomepage')}
          </Link>
        </CardContent>

        <CardFooter className="text-sm flex justify-center"></CardFooter>
      </Card>

      <div className="mx-auto text-gray-500">
        {t('pages.confirmationsNew.text.madeWith')}{' '}
        <a
          target="_blank"
          href="https://education.brightbook.io/home"
          className="underline font-serif"
          rel="noreferrer">
          Brightbook.
        </a>
      </div>
    </div>
  );
}

New.layout = (page) => page;
