import * as Sentry from '@sentry/react';
import React from 'react';

export class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

function ErrorPage() {
  return (
    <div className="flex items-center justify-center flex-col flex-1">
      <h1 className="font-bold text-lg">Er lijkt iets fout gegaan te zijn.</h1>
      <h3>Ons team is op de hoogte gebracht en zal dit euvel zo snel mogelijk oplossen.</h3>
      <h4>Onze excuses voor het ongemak.</h4>
    </div>
  );
}
