import clsx from 'clsx';
import React, { CSSProperties } from 'react';

import { T_BaseViewNode } from '~/node-builder/types';

import { GutterTitle } from './Gutter';

type Props = {
  children: React.ReactNode;
  node?: T_BaseViewNode;
  className?: string;
  title: string;
  style?: CSSProperties;
  gutterElement?: React.ReactNode;
};

export function Element(props: Props) {
  const { children, gutterElement, title, node, className, ...otherProps } = props;

  return (
    <div
      data-testid={`book_view_element ${node.type} ${node.nodeKey}`}
      className={clsx('relative', className)}
      {...otherProps}>
      <GutterTitle node={node}>
        {gutterElement ? gutterElement : <h6 className="text-sm text-gray-500">{title}</h6>}
      </GutterTitle>

      {children}
    </div>
  );
}
