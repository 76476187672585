import clsx from 'clsx';
import React from 'react';
import { HandRaised as HandOutline } from 'styled-icons/heroicons-outline';
import { HandRaised as HandSolid } from 'styled-icons/heroicons-solid';

import { T_AnswerableMarkImage } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImage';
import { T_AnswerableMarkImageMarker } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImageMarker';

import { useExerciseProps } from '../../context/ExercisePropsContext';

type Props = {
  activeItem: T_AnswerableMarkImageMarker['ViewNode'] | null;
  answerable: T_AnswerableMarkImage['ViewNode'];
  handleChange: (key: string, value: string) => void;
  node: T_AnswerableMarkImageMarker['ViewNode'];
  number: number;
  setActiveItem: () => void;
  showSentInAnswers: boolean;
  showStandardAnswer: boolean;
  userAnswer: string;
};

export function MarkerSelect(props: Props) {
  const { access } = useExerciseProps();

  const {
    activeItem,
    answerable,
    handleChange,
    node,
    number,
    setActiveItem,
    showSentInAnswers,
    showStandardAnswer,
    userAnswer,
  } = props;

  const active = node.nodeKey === activeItem?.nodeKey;

  const possibleAnswers = generateAnswerOptions();

  const answerIsCorrect = node?.nodeKey === userAnswer;

  return (
    <div className="w-full">
      <div className="mt-1 flex rounded-md shadow-sm">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-base font-bold text-gray-700">{number}.</span>
          </div>
          <select
            className={clsx(
              '__answer_select_input__ focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 py-1 rounded-md sm:text-sm border-gray-300',
              showSentInAnswers && !access.student && 'rounded-r-none',
              showStandardAnswer && answerIsCorrect && 'border-green-300',
              showStandardAnswer && answerIsCorrect && 'border-red-300',
            )}
            value={userAnswer}
            onChange={(event) => handleChange(node.nodeKey, event.target.value)}
            name={node.nodeKey}>
            <option value="" disabled hidden>
              ...
            </option>

            {possibleAnswers.map((answer, index) => {
              return (
                <option key={index} value={answer.nodeKey}>
                  {answer.text}
                </option>
              );
            })}
          </select>
        </div>

        {showSentInAnswers && !access.student && (
          <button
            className="-ml-px relative inline-flex items-center space-x-2 py-1 px-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            onClick={setActiveItem}
            data-testid={`fill-list-select-active-button-${number}`}
            data-tip="Toon leerling-antwoorden voor dit item"
            type="button">
            {active ? (
              <HandSolid className="w-4 h-4 text-gray-600" />
            ) : (
              <HandOutline className="w-4 h-4 text-gray-400" />
            )}
          </button>
        )}
      </div>
    </div>
  );

  function generateAnswerOptions() {
    const answerOptions = answerable.markers.map((node) => {
      return {
        nodeKey: node.nodeKey,
        text: node.text,
      };
    });

    return answerOptions.sort((a, b) => a.text.localeCompare(b.text));
  }
}
