import { Link } from '@inertiajs/react';
import React, { useCallback } from 'react';

import CourseContentsApi from '~/api/CourseContentsApi';
import { T_Enrichment } from '~/types/serializers';

export default function OutlineEnrichments(props) {
  const { courseContent, enrichments, focus } = props;

  const render = useCallback(renderEnrichments, []);

  return (
    <ul className="p-5 space-y-2 overflow-y-auto">
      <div className="space-y-3">{render(enrichments, focus)}</div>
    </ul>
  );

  function renderEnrichments(enrichments: T_Enrichment[], focus) {
    const outlineEnrichments = [];

    for (const enrichment of enrichments) {
      const href = focus
        ? CourseContentsApi.show.path({
            slug: courseContent.slug,
            query: { focus: enrichment.nodeKey },
          })
        : `#${enrichment.nodeKey}`;

      let Component = null;

      switch (enrichment.kind) {
        case 'image': {
          Component = (
            <Link
              data-testid={`outline_image_enrichment_${enrichment.nodeKey}`}
              href={href}
              preserveState>
              <OutlineImage imageUrl={enrichment?.imageUrl} />
            </Link>
          );
          break;
        }
        case 'youtube': {
          Component = (
            <Link
              data-testid={`outline_youtube_enrichment_${enrichment.nodeKey}`}
              href={href}
              preserveState>
              <OutlineVideo title={enrichment.title} />
            </Link>
          );
          break;
        }
        case 'text': {
          Component = (
            <Link
              data-testid={`outline_text_enrichment_${enrichment.nodeKey}`}
              href={href}
              preserveState>
              <OutlineText title={enrichment.title} />
            </Link>
          );
          break;
        }
      }

      if (Component) {
        outlineEnrichments.push(<li key={enrichment.id}>{Component}</li>);
      }
    }

    return outlineEnrichments;
  }
}

function OutlineImage({ imageUrl }) {
  if (!imageUrl) return null;

  return (
    <div className="flex shadow rounded border h-24 justify-center overflow-hidden">
      <img className="h-24" src={imageUrl} />
    </div>
  );
}

function OutlineText({ title }) {
  if (!title) return null;

  return (
    <div className="flex shadow rounded border py-1 px-2 overflow-hidden">
      <p className="truncate">{title}</p>
    </div>
  );
}

function OutlineVideo({ title }) {
  if (!title) return null;

  return (
    <div className="flex shadow rounded border py-1 px-2 overflow-hidden">
      <p className="truncate">{title}</p>
    </div>
  );
}
