import { usePage } from '@inertiajs/react';
import React from 'react';

import PrintTasksApi from '~/api/PrintTasksApi';
import { LayoutBook } from '~/components/ui-extended/layout-book';

import PageContent from './components/page-content';

export default function Print() {
  const { access, book } = usePage<any>().props;

  return (
    <LayoutBook
      breadcrumbs={[
        {
          title: 'models.printTask.many',
          translateTitle: true,
          type: 'link',
          href: PrintTasksApi.index.path({ book_slug: book.slug }),
        },
        {
          title: 'common.breadcrumbs.printTasksNew',
          translateTitle: true,
          type: 'static',
        },
      ]}
      htmlHeadTitle={book.title}
      access={access}
      book={book}
      sidebarTitle={book.title}>
      <PageContent />
    </LayoutBook>
  );
}
