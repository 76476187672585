import { Link } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from 'styled-icons/material';

import RegistrationsApi from '~/api/Users/RegistrationsApi';
import SessionsApi from '~/api/Users/SessionsApi';
import { Button, buttonVariants } from '~/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '~/components/ui/popover';
import { cn } from '~/utils';

import { BrightbookLogo } from './brightbook-logo';
import { LanguageSelector } from './language-selector';
import { NavLinks } from './nav-links';

type Props = {
  navLinks: { label: string; href: string; external?: boolean }[];
  subtitle: string;
};

export function NavBar({ navLinks }: Props) {
  const { t } = useTranslation();

  return (
    <header>
      <nav>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative z-50 flex justify-between py-6">
          <div className="relative z-10 flex items-center gap-16">
            <Link href="/" aria-label="Home">
              <div className="flex items-center justify-center" aria-hidden="true">
                <BrightbookLogo className="w-10 h-10" />

                <h1 className="font-bold ml-1 text-lg">Brightbook</h1>
              </div>
            </Link>

            <NavLinks navLinks={navLinks} />
          </div>
          <div className="flex items-center">
            <div className="lg:hidden">
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline">
                    <Menu className="h-6 w-6" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-64">
                  <div className="flex flex-col space-y-2">
                    {navLinks.map(({ label, href, external, ...props }) => {
                      if (external) {
                        return (
                          <a
                            className={cn(
                              buttonVariants({ variant: 'ghost', size: 'lg' }),
                              'justify-start px-4',
                            )}
                            {...props}
                            key={label}
                            href={href}
                            target="_blank"
                            rel="noopener noreferrer">
                            {label}
                          </a>
                        );
                      }

                      return (
                        <Link
                          className={cn(
                            buttonVariants({ variant: 'ghost', size: 'lg' }),
                            'justify-start px-4',
                          )}
                          key={label}
                          href={href}>
                          {label}
                        </Link>
                      );
                    })}

                    <Link
                      href={SessionsApi.new.path()}
                      className={buttonVariants({ variant: 'default' })}>
                      {t('website.navbar.logIn')}
                    </Link>
                    <Link
                      href={RegistrationsApi.new.path()}
                      className={buttonVariants({ variant: 'outline' })}>
                      {t('website.navbar.register')}
                    </Link>
                  </div>
                </PopoverContent>
              </Popover>
            </div>

            <div className="hidden lg:block space-x-4">
              <Link
                href={SessionsApi.new.path()}
                className={cn(buttonVariants({ variant: 'default' }))}>
                {t('website.navbar.logIn')}
              </Link>

              <Link
                href={RegistrationsApi.new.path()}
                className={cn(buttonVariants({ variant: 'outline' }))}>
                {t('website.navbar.register')}
              </Link>

              <LanguageSelector />
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
