import { ChevronDownIcon } from '@radix-ui/react-icons';
import React from 'react';

import { BreadcrumbItem } from '~/components/ui/breadcrumb';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';

import { BreadcrumbTitle } from './breadcrumb-title';

export function BreadcrumbDropdown({ title, options, onSelect }) {
  return (
    <BreadcrumbItem>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button data-testid="breadcrumb-selectable-button" className="flex items-center gap-1">
            <BreadcrumbTitle title={title} />
            <ChevronDownIcon />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          {options.map((option: { title: string }) => (
            <DropdownMenuItem asChild key={title}>
              <button className="w-full" onClick={() => onSelect(option)}>
                {option.title}
              </button>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </BreadcrumbItem>
  );
}
