import { router } from '@inertiajs/react';
import { usePage } from '@inertiajs/react';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XMark } from 'styled-icons/heroicons-outline';

import CustomQuestionsApi from '~/api/CustomQuestionsApi';
import { Button } from '~/components/ui/button';

type Props = {
  customQuestion: any;
  customQuestionAnswers: any;
  isBeamingCustomQuestion: boolean;
  setCustomQuestionAnswers: (value: any) => void;
};

export function DynamicQuestionAskForm(props: Props) {
  const { t } = useTranslation();

  const { courseContent, recentCustomQuestions } = usePage<any>().props;

  const {
    isBeamingCustomQuestion,
    customQuestion,
    customQuestionAnswers,
    setCustomQuestionAnswers,
  } = props;

  const [title, setTitle] = useState('');
  const [options, setOptions] = useState({ 0: '', 1: '' });

  return (
    <>
      {isBeamingCustomQuestion ? (
        <div className="h-full">
          {customQuestion.title && (
            <div className="mb-6 text-3xl text-gray-700">{customQuestion.title}</div>
          )}

          {customQuestion.kind === 'multiple_choice' && (
            <div className="px-4">
              {customQuestion.options &&
                Object.entries(customQuestion.options).map(([key, value], index) => {
                  const answerCount = customQuestionAnswers.filter(
                    ({ option }) => option === parseInt(key),
                  ).length;

                  const percentage = (answerCount / customQuestionAnswers.length) * 100;

                  return (
                    <div
                      data-testid={`selected-option-${index}`}
                      className="my-3 text-4xl font-semibold text-gray-600"
                      key={key}>
                      {value}
                      <div className="flex items-center mt-2">
                        <div className={clsx(`h-3 bg-green-500 rounded-xl w-[${percentage}%]`)} />
                        <div
                          data-testid={`selected-option-${index}-answer-count`}
                          className="px-2 ml-1 text-lg border border-gray-300 rounded">
                          {answerCount}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}

          {customQuestion.kind === 'open_question' && (
            <div className="flex flex-wrap gap-4">
              {customQuestionAnswers.map((answer, index) => {
                return (
                  <div
                    className="w-1/4 p-4 text-gray-700 border-gray-100 rounded-lg shadow bg-yellow-50"
                    key={index}>
                    {answer.value}
                  </div>
                );
              })}
            </div>
          )}
          <div className="flex justify-end pt-3 mt-6 border-t">
            {isBeamingCustomQuestion && (
              <Button onClick={cancelCustomQuestion}>
                {t('pages.courseContentsShow.forms.askDynamicQuestion.buttons.cancelQuestion')}
              </Button>
            )}
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="flex flex-col flex-1 mt-6">
          <input
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            name="title"
            id="title"
            autoFocus
            className="w-full px-3 py-2 mb-6 text-2xl text-gray-600 border rounded"
          />

          <div className="flex flex-col">
            <h4 className="mb-2 text-lg text-gray-600">
              {t('pages.courseContentsShow.forms.askDynamicQuestion.labels.multipleChoice')}
            </h4>

            {Object.entries(options).map(([key, value]) => {
              return (
                <div className="flex items-center mb-3" key={key}>
                  <input
                    data-testid={`custom-question-multiple-choice-option-${key}`}
                    value={value}
                    onChange={(event) => setOptions({ ...options, [key]: event.target.value })}
                    name={`custom_question_option_${key}`}
                    id={`custom_question_option_${key}`}
                    className="w-1/2 px-3 py-2 text-2xl text-gray-600 border rounded"
                  />
                  <div>
                    <XMark
                      onClick={() => removeOption(key)}
                      className="w-6 h-6 ml-2 text-gray-500 cursor-pointer"
                    />
                  </div>
                </div>
              );
            })}
            <div>
              <button
                onClick={addExtraOption}
                className="border px-2 py-0.5 rounded shadow text-sm text-gray-600">
                {t('pages.courseContentsShow.forms.askDynamicQuestion.buttons.addOption')}
              </button>

              <div className="mt-4">
                <h4 className="mb-2 text-lg font-semibold text-gray-600">
                  {t('pages.courseContentsShow.forms.askDynamicQuestion.labels.quickQuestions')}
                </h4>
                <button
                  onClick={addYesNoQuestion}
                  className="border font-semibold border-yellow-500 px-2 py-0.5 mr-2 rounded shadow text-gray-600">
                  {t('pages.courseContentsShow.forms.askDynamicQuestion.buttons.yesNoQuestion')}
                </button>
                <button
                  onClick={addRightWrongQuestion}
                  className="border font-semibold border-yellow-500 px-2 py-0.5 mx-2 rounded shadow text-gray-600">
                  {t(
                    'pages.courseContentsShow.forms.askDynamicQuestion.buttons.rightWrongQuestion',
                  )}
                </button>
                <button
                  onClick={addABCDQuestion}
                  className="border font-semibold border-yellow-500 px-2 py-0.5 mx-2 rounded shadow text-gray-600">
                  {t('pages.courseContentsShow.forms.askDynamicQuestion.buttons.abcdQuestion')}
                </button>
              </div>

              <div className="mt-4">
                <h4 className="mb-2 text-lg font-semibold text-gray-600">
                  {t('pages.courseContentsShow.forms.askDynamicQuestion.labels.recentQuestions')}
                </h4>
                <div className="flex flex-wrap">
                  {(recentCustomQuestions || []).map((question, index) => {
                    return (
                      <div className="w-1/5 px-4 first:pl-0 last:pr-0" key={index}>
                        <button
                          onClick={(event) => duplicateCustomQuestion(event, question)}
                          className="w-full p-2 text-left text-gray-600 truncate border rounded shadow">
                          {question.title}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-end justify-end flex-1">
            <Button type="submit">{t('common.buttons.share')}</Button>
          </div>
        </form>
      )}
    </>
  );

  function addYesNoQuestion(event) {
    event.preventDefault();

    router.post(
      CustomQuestionsApi.create.path({ course_content_slug: courseContent.slug }),
      {
        title,
        kind: 'multiple_choice',
        options: {
          0: t('pages.courseContentsShow.forms.askDynamicQuestion.labels.yes'),
          1: t('pages.courseContentsShow.forms.askDynamicQuestion.labels.no'),
        },
      },
      { replace: true, preserveState: true },
    );
  }

  function addRightWrongQuestion(event) {
    event.preventDefault();

    router.post(
      CustomQuestionsApi.create.path({ course_content_slug: courseContent.slug }),
      {
        title,
        kind: 'multiple_choice',
        options: {
          0: t('pages.courseContentsShow.forms.askDynamicQuestion.labels.right'),
          1: t('pages.courseContentsShow.forms.askDynamicQuestion.labels.wrong'),
        },
      },
      { replace: true, preserveState: true },
    );
  }

  function addABCDQuestion(event) {
    event.preventDefault();

    router.post(
      CustomQuestionsApi.create.path({ course_content_slug: courseContent.slug }),
      {
        title,
        kind: 'multiple_choice',
        options: { 0: 'A', 1: 'B', 2: 'C', 3: 'D' },
      },
      { replace: true, preserveState: true },
    );
  }

  function duplicateCustomQuestion(event, question) {
    event.preventDefault();

    const { title, kind, options } = question;

    router.post(
      CustomQuestionsApi.create.path({ course_content_slug: courseContent.slug }),
      {
        title,
        kind,
        options,
      },
      { replace: true, preserveState: true },
    );
  }

  function handleSubmit(event) {
    event.preventDefault();

    const isMultipleChoice = Object.entries(options).some(([, value]) => !!value);

    router.post(CustomQuestionsApi.create.path({ course_content_slug: courseContent.slug }), {
      title,
      kind: isMultipleChoice ? 'multiple_choice' : 'open_question',
      options: isMultipleChoice ? options : null,
    });
  }

  function cancelCustomQuestion() {
    router.delete(
      CustomQuestionsApi.destroy.path({
        course_content_slug: courseContent.slug,
        id: customQuestion.id,
      }),
      {
        replace: true,
        preserveState: true,
      },
    );

    setTitle('');
    setOptions({ 0: '', 1: '' });
    setCustomQuestionAnswers([]);
  }

  function addExtraOption(event) {
    event.preventDefault();

    setOptions({ ...options, [Object.entries(options).length]: '' });
  }

  function removeOption(keyToRemove) {
    const newOptions = {};

    let index = 0;

    for (const [key, value] of Object.entries(options)) {
      if (key === keyToRemove) {
        continue;
      }

      newOptions[index] = value;
      index++;
    }

    setOptions(newOptions);
  }
}
