import React from 'react';

import { T_AnswerableOral } from '~/types/node/exercise/answerables/oral/T_AnswerableOral';

type Props = {
  answerable: T_AnswerableOral['ViewNode'];
};

export function Oral(_props: Props) {
  return <div />;
}
