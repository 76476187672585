import { usePage } from '@inertiajs/react';
import { Library } from '@styled-icons/ionicons-outline';
import React from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Bell, Search } from 'styled-icons/boxicons-regular';

import BooksApi from '~/api/BooksApi';
import { LanguageProvider } from '~/i18n/provider';
import { T_PageProps } from '~/types/T_PageProps';

import { useTheme } from '../theme-provider';
import { TypographyH4 } from '../typography/heading-four';
import { SidebarButton } from './sidebar-button';
import { UserMenu } from './user-menu';

type Props = {
  children: React.ReactNode;
  title: string;
};

export function SidebarMenu(props: Props) {
  const { children, title } = props;

  const { component } = usePage();
  const { colors } = useTheme();

  const {
    auth: { user },
    organization,
  } = usePage<T_PageProps>().props;

  return (
    <nav className="relative h-full p-4">
      <div className="h-full">
        <div className="flex flex-col h-[calc(100%-11.3rem)]">
          <div style={{ color: colors.action }} className="border-b pb-2 mb-4">
            <TypographyH4>{title}</TypographyH4>
          </div>

          <div className="flex flex-col overflow-y-auto">{children}</div>
        </div>
        <LanguageProvider
          context={organization?.kind}
          lng={user?.language ?? organization?.language}>
          <Translation>
            {(t) => (
              <div>
                <ul className="space-y-1 mb-4 border-t py-1 border-b">
                  <li>
                    <SidebarButton
                      data-testid="books-index-link"
                      active={component === 'books/index'}
                      href={BooksApi.index.path()}
                      icon={Library}
                      title={t('common.sidebar.generalOptions.myBooks')}
                    />
                  </li>
                  <li>
                    <SidebarButton
                      disabled
                      data-testid="notifications-link"
                      // active={activeLink === 'classGroup'}
                      href={BooksApi.index.path()}
                      icon={Bell}
                      title={t('common.sidebar.generalOptions.notifications')}
                    />
                  </li>
                  <li>
                    <SidebarButton
                      disabled
                      data-testid="search-link"
                      // active={activeLink === 'classGroup'}
                      href={BooksApi.index.path()}
                      icon={Search}
                      title={t('common.sidebar.generalOptions.search')}
                    />
                  </li>
                </ul>

                <UserMenu user={user} />
              </div>
            )}
          </Translation>
        </LanguageProvider>
      </div>
    </nav>
  );
}
