import React from 'react';

import { templateIconMap } from '~/components/pages-shared/template-icon-map';
import { T_Container } from '~/types/node/T_Container';
import { useViewPrintContext } from '~/views/print/useViewPrintContext';

import { Gutter } from '../../components/Gutter';
import Content from './Content';
import Title from './Title';

type Props = {
  node: T_Container['ViewNode'];
};

export function Container(props: Props) {
  const { node } = props;

  const { template_config } = useViewPrintContext();

  const template = (template_config || []).find((template) => template.name === node.name);

  const Icon = templateIconMap[template?.icon];

  return (
    <div
      data-testid={node.type}
      style={{ backgroundColor: node.color }}
      className="rounded box-decoration-clone break-inside-avoid relative my-[2.5mm] p-[5mm] pb-[1.5mm]">
      <div className="text-xs p-1 text-gray-500">
        <Gutter icon={template?.icon ? <Icon className="w-4 h-4" /> : null} content={node.name} />
      </div>
      <Title node={node.title} />
      <Content node={node.content} />
    </div>
  );
}
