import React from 'react';

import { T_AnswerableMarkImageMarker } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImageMarker';

type Props = {
  marker: T_AnswerableMarkImageMarker['ViewNode'];
  answer: string;
};

export function Marker(props: Props) {
  const { answer, marker } = props;

  if (answer) {
    return (
      <div className="flex flex-row mb-[3mm] items-end">
        <span className="pr-[2mm] text-[#374151] text-[11pt]">{marker.number}.</span>
        <div className="relative flex flex-1 flex-row border-b border-dotted border-gray-400 ml-[1.5mm] mr-[5mm]">
          {answer ? <p className="text-[#0066cc] text-[10pt] m-0">{answer}</p> : null}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row mb-[3mm] items-end">
      <span className="pr-[2mm] text-[#374151] text-[11pt]">{marker.number}.</span>
      <div className="border-b-[1px] w-full border-dotted border-b-gray-400" />
    </div>
  );
}
