import { useTranslation } from 'react-i18next';

type Props = {
  possibleAnswers: {
    amount: number;
    nodeKey: string;
    number: number;
    text: string;
  }[];
};

export function PossibleAnswersList(props: Props) {
  const { t } = useTranslation();

  const { possibleAnswers } = props;

  return (
    <>
      <div className="text-sm font-medium mb-1 text-gray-800">
        {t('pages.courseContentsShow.nodes.exercise.toFillIn')}
      </div>

      <div className="text-sm text-gray-500 italic">
        {possibleAnswers
          .sort((a, b) => a.text.localeCompare(b.text))
          .map((option) => {
            let string = option.text;

            if (option.amount > 1) string += ` (${option.amount}x)`;

            return string;
          })
          .join(', ')}
      </div>
    </>
  );
}
