import React from 'react';

import { HightlightContextProvider } from '~/hooks/useHighlightContext';
import { nodeIsEmpty } from '~/lib/node-renderer/nodeHelpers';
import { T_Container } from '~/types/node/T_Container';

import { Element } from '../../components/Element';
import { useViewBookContext } from '../../hooks/useViewBookContext';
import { Content } from './Content';
import { GutterIcon } from './GutterIcon';
import { Title } from './Title';

type Props = {
  node: T_Container['ViewNode'];
};

export function Container(props: Props) {
  const { node } = props;

  const { templateConfig } = useViewBookContext();

  const template = (templateConfig || []).find((template) => template.name === node.name);

  const backgroundColor = template ? template.color : '#F7C4A5';

  return (
    <Element
      node={node}
      className="relative rounded-md p-4"
      gutterElement={
        template?.icon ? <GutterIcon name={template?.name} iconName={template?.icon} /> : null
      }
      style={{ backgroundColor }}
      title={node.name}>
      <HightlightContextProvider showHighlight={false}>
        {!nodeIsEmpty(node.title) && <Title node={node.title} />}
        <Content node={node.content} />
      </HightlightContextProvider>
    </Element>
  );
}
