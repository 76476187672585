import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '~/components/ui/dialog';

export function AiDialog() {
  const { t } = useTranslation();

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost">{t('pages.booksEditStructure.buttons.aiHelpButton')}</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('pages.booksEditStructure.dialogs.aiHelp.title')}</DialogTitle>
        </DialogHeader>
        <div className=" py-4">{t('pages.booksEditStructure.dialogs.aiHelp.description')}</div>
        <DialogFooter>
          <Button variant="ghost">
            {t('pages.booksEditStructure.dialogs.aiHelp.goToChatGPTButton')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
