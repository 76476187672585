export enum E_TemplateIcon {
  bell = 'bell',
  bookmark = 'bookmark',
  camera = 'camera',
  chatBubble = 'chatBubble',
  crosshair = 'crosshair',
  clipboard = 'clipboard',
  envelopeClosed = 'envelopeClosed',
  eyeOpen = 'eyeOpen',
  file = 'file',
  globe = 'globe',
  hand = 'hand',
  heart = 'heart',
  lightning = 'lightning',
  paperPlane = 'paperPlane',
  pencil = 'pencil',
  person = 'person',
  pin = 'pin',
  rocket = 'rocket',
  ruler = 'ruler',
  star = 'star',
  stopwatch = 'stopwatch',
  sun = 'sun',
  warning = 'warning',
  academicCap = 'academicCap',
  conversation = 'conversation',
  beaker = 'beaker',
  bookOpen = 'bookOpen',
  cake = 'cake',
  calculator = 'calculator',
  library = 'library',
  cube = 'cube',
  smile = 'smile',
  film = 'film',
  frown = 'frown',
  fire = 'fire',
  gift = 'gift',
  key = 'key',
  megaphone = 'megaphone',
  puzzlePiece = 'puzzlePiece',
  sparkle = 'sparkle',
  users = 'users',
  variable = 'variable',
  chatFilled = 'chatFilled',
  group = 'group',
}

type T_TemplateIcon = keyof typeof E_TemplateIcon;
export type T_TemplateIconValue = T_TemplateIcon[keyof T_TemplateIcon];

export default T_TemplateIcon;
