import React from 'react';

import { LanguageProvider } from '~/i18n/provider';

import { Book } from './structure/Book';
import { Part } from './structure/PartRoot';
import { ViewPrintContext } from './useViewPrintContext';

type Props = {
  json: any;
};

export function PrintView(props: Props) {
  const {
    document,
    book_lng,
    context,
    template_config,
    source_list,
    exercise_layout,
    structure,
    user_answers,
    justify_text,
  } = props.json;

  return (
    <LanguageProvider lng={book_lng} context={context}>
      <ViewPrintContext.Provider
        value={{ exercise_layout, template_config, structure, user_answers, justify_text }}>
        {document.type === 'book' ? (
          <Book book={{ ...document, exercise_layout, user_answers, source_list }} />
        ) : (
          <Part part={{ ...document, exercise_layout, user_answers }} />
        )}
      </ViewPrintContext.Provider>
    </LanguageProvider>
  );
}
