import React from 'react';

import { ExerciseLayoutText } from './ExerciseLayoutText';

type Props = {
  title: string;
  metadata: any;
};

export function PartRootTitlePage(props: Props) {
  const { title, metadata } = props;

  const { user_answers, exercise_layout } = metadata;

  return (
    <div className="part-title-page">
      <h1 className="border-b-4 font-semibold text-lg border-b-white mb-4 pb-2">{title}</h1>

      {exercise_layout && (
        <ExerciseLayoutText exercise_layout={exercise_layout} user={user_answers?.user} />
      )}
    </div>
  );
}
