import React from 'react';

import { T_MarkedImage } from '~/types/node/T_MarkedImage';

import { MarkerList } from './MarkerList';
import { Source } from './Source';

type Props = {
  node: T_MarkedImage['ViewNode'];
};

export function MarkedImage(props: Props) {
  const { node } = props;

  return (
    <div className="break-inside-avoid relative my-[5mm]" data-testid={node.type}>
      <Source src={node.source.print_src} markers={node.markers} />
      <MarkerList markers={node.markers} />
    </div>
  );
}
