import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'styled-icons/heroicons-outline';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '~/components/ui/accordion';
import { T_Enrichment } from '~/types/serializers';

import { EnrichmentOptionsButton } from '../../components-new/enrichment-options-button';
import { Anchor } from '../Anchor';
import { TypeIcon } from './TypeIcon';

type Props = {
  enrichment: T_Enrichment;
  currentUserIsOwner: boolean;
};

export function Enrichment(props: Props) {
  const { currentUserIsOwner, enrichment } = props;

  return (
    <div
      data-testid="enrichment_item"
      style={
        currentUserIsOwner
          ? {}
          : { borderLeftColor: 'MediumSeaGreen', borderLeft: '4px solid MediumSeaGreen' }
      }
      className="border rounded-md overflow-hidden">
      <Anchor id={`enrichment_${enrichment.id}`} />

      {!isMedia() ? (
        <TextEnrichment enrichment={enrichment} currentUserIsOwner={currentUserIsOwner} />
      ) : (
        <MediaEnrichment enrichment={enrichment} currentUserIsOwner={currentUserIsOwner} />
      )}
    </div>
  );

  function isMedia() {
    return enrichment.kind !== 'text';
  }
}

function MediaEnrichment({ enrichment, currentUserIsOwner }) {
  const { t } = useTranslation();

  const [value, setValue] = useState(['']);

  return (
    <Accordion onValueChange={setValue} type="multiple" className="p-2">
      <AccordionItem value="enrichment" className="border-none">
        <AccordionTrigger className="hover:no-underline">
          <div className="flex items-center justify-between w-full text-left font-light text-sm px-2">
            <div className="flex flex-1">
              <TypeIcon kind={enrichment.kind} />
              {value.includes('enrichment') ? enrichment.title : truncateTitle()}
            </div>

            {currentUserIsOwner && (
              <div>
                {(enrichment.pushedToStudents || enrichment.sharedWithTeachers) && (
                  <User
                    data-tip={`${t('pages.courseContentsShow.text.sharedWith')} ${enrichment.pushedToStudents ? t('pages.courseContentsShow.text.students') : ''} ${
                      enrichment.pushedToStudents && enrichment.sharedWithTeachers
                        ? t('common.text.and')
                        : ''
                    } ${enrichment.sharedWithTeachers ? t('pages.courseContentsShow.text.teachers') : ''}`}
                    className="w-4 h-4 mr-1 text-gray-400"
                  />
                )}
                <EnrichmentOptionsButton enrichment={enrichment} />
              </div>
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <div className="w-full bg-gray-800 flex justify-center">
            {enrichment.kind == 'image' && <img src={enrichment.imageUrl} className="max-h-80" />}

            {enrichment.kind == 'youtube' && (
              <iframe src={`//www.youtube.com/embed/${enrichment.url}`} className="w-full h-108" />
            )}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );

  function truncateTitle() {
    if (enrichment.title.length < 65) return enrichment.title;

    return `${enrichment.title.slice(0, 65)}...`;
  }
}

function TextEnrichment({ enrichment, currentUserIsOwner }) {
  const { t } = useTranslation();

  const [truncate, setTruncate] = useState(true);

  return (
    <div className="flex justify-between items-start w-full text-left font-light text-sm p-2 pt-3">
      <div
        className="flex flex-1 items-start hover:cursor-pointer"
        onClick={() => setTruncate(!truncate)}>
        <TypeIcon kind={enrichment.kind} />
        {needsTruncation && truncate ? truncateTitle() : enrichment.title}
      </div>

      {currentUserIsOwner && (
        <div>
          {(enrichment.pushedToStudents || enrichment.sharedWithTeachers) && (
            <User
              data-tip={`${t('pages.courseContentsShow.text.sharedWith')} ${enrichment.pushedToStudents ? t('pages.courseContentsShow.text.students') : ''} ${
                enrichment.pushedToStudents && enrichment.sharedWithTeachers
                  ? t('common.text.and')
                  : ''
              } ${enrichment.sharedWithTeachers ? t('pages.courseContentsShow.text.teachers') : ''}`}
              className="w-4 h-4 mr-1 text-gray-400"
            />
          )}
          <EnrichmentOptionsButton enrichment={enrichment} />
        </div>
      )}
    </div>
  );

  function truncateTitle() {
    if (enrichment.title.length < 65) return enrichment.title;

    return `${enrichment.title.slice(0, 65)}...`;
  }

  function needsTruncation() {
    return enrichment.title.length > 65;
  }
}
