import React from 'react';

import { Footer } from '../../components/footer';
import { HeaderBusiness } from '../../components/header-business';
import { Hero } from '../../components/hero';

export default function Support() {
  return (
    <>
      <HeaderBusiness />

      <Hero className="flex flex-col flex-1">
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-black sm:text-5xl">
            We are always by your side
          </h1>
          <p className="mt-6 text-lg leading-8 font-light text-gray-800">
            If you get stuck or you have questions, we are only an email away. The Brightbook team
            stands for quick response time and strives for high customer satisfaction. We are here
            to help you, at every step of the way.
          </p>
          <p className="mt-6 text-lg leading-8 font-light text-gray-800">
            Don’t hesitate to send us an email at{' '}
            <a className="text-cyan-500 underline" href="mailto:info@brightbook.io">
              info@brightbook.io
            </a>
          </p>
        </div>
      </Hero>

      <Footer />
    </>
  );
}

Support.layout = (page) => page;
