import { router, usePage } from '@inertiajs/react';
import { t } from 'i18next';
import { set } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import LicenseGroupsApi from '~/api/LicenseGroupsApi';
import { Button } from '~/components/ui/button';
import { Card, CardContent, CardTitle } from '~/components/ui/card';
import { DataTableContainer } from '~/components/ui-extended/data-table-container';

import { NewLicenseGroupForm } from './new-license-group-form';

const data = {
  student: {
    title: 'pages.licenseGroupsIndex.forms.newLicenseGroup.tabs.studentLicenses',
    license: 'student',
    cost: 8,
    list: {
      cost: 'pages.licenseGroupsIndex.forms.newLicenseGroup.cards.studentLicenseCost',
      access: 'pages.licenseGroupsIndex.forms.newLicenseGroup.cards.studentLicenseAccess',
      interaction: 'pages.licenseGroupsIndex.forms.newLicenseGroup.cards.studentLicenseInteraction',
      duration: 'pages.licenseGroupsIndex.forms.newLicenseGroup.cards.studentLicenseDuration',
    },
    formTitle: 'pages.licenseGroupsIndex.forms.newLicenseGroup.text.purchaseStudent',
    formText: 'pages.licenseGroupsIndex.forms.newLicenseGroup.text.studentLicenseCost',
    invoiceDetails: 'pages.licenseGroupsIndex.forms.newLicenseGroup.text.invoiceDetailsExplanation',
  },
  teacher: {
    title: 'pages.licenseGroupsIndex.forms.newLicenseGroup.tabs.teacherLicenses',
    license: 'teacher',
    cost: 0,
    list: {
      cost: 'pages.licenseGroupsIndex.forms.newLicenseGroup.cards.licenseFree',
      access: 'pages.licenseGroupsIndex.forms.newLicenseGroup.cards.teacherClassGroups',
      interaction: 'pages.licenseGroupsIndex.forms.newLicenseGroup.cards.teacherNoAuthorRights',
      authorRights: 'pages.licenseGroupsIndex.forms.newLicenseGroup.cards.teacherAuthorRights',
      duration: 'pages.licenseGroupsIndex.forms.newLicenseGroup.cards.teacherLicenseDuration',
    },
    formTitle: 'pages.licenseGroupsIndex.forms.newLicenseGroup.text.purchaseTeacher',
    formText: 'pages.licenseGroupsIndex.forms.newLicenseGroup.text.teacherLicenseFree',
  },
  studentTrial: {
    title: 'pages.licenseGroupsIndex.forms.newLicenseGroup.tabs.studentTrialLicenses',
    license: 'trial',
    cost: 0,
    list: {
      cost: 'pages.licenseGroupsIndex.forms.newLicenseGroup.cards.licenseFree',
      access: 'pages.licenseGroupsIndex.forms.newLicenseGroup.cards.studentLicenseAccess',
      interaction: 'pages.licenseGroupsIndex.forms.newLicenseGroup.cards.studentLicenseInteraction',
      duration: 'pages.licenseGroupsIndex.forms.newLicenseGroup.cards.studentTrialLicenseDuration',
    },
    formTitle: 'pages.licenseGroupsIndex.forms.newLicenseGroup.tabs.studentTrialLicenses',
    formText: 'pages.licenseGroupsIndex.forms.newLicenseGroup.text.testLicenseExplanation',
  },
};

export function PageContent() {
  const { book } = usePage<any>().props;
  const { t } = useTranslation();
  const [role, setRole] = useState('');

  function handleUnselect() {
    setRole('');
  }

  return (
    <DataTableContainer
      title="pages.licenseGroupsIndex.forms.newLicenseGroup.title"
      translateTitle
      rightSideContent={
        <Button
          onClick={() => router.get(LicenseGroupsApi.index.path({ book_slug: book.slug }))}
          variant="outline">
          {t('common.buttons.back')}
        </Button>
      }>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 pb-40">
        {Object.keys(data).map((key) => {
          const element = data[key];

          const notSelected = role !== element.license && role !== '';
          const selected = role === element.license;

          const array = [
            element.list.cost,
            element.list.access,
            element.list.interaction,
            element.list.duration,
          ];

          function handleSelectLicense() {
            setRole(element.license);
            return role;
          }

          return (
            <>
              <Card
                className={
                  notSelected
                    ? 'text-gray-400 place-self-start'
                    : 'flex flex-col justify-between pt-6'
                }>
                <CardTitle className="pt-6 pt-2 pl-6">{t(element.title)}</CardTitle>
                <CardContent>
                  <ul>
                    {array.map((arr) => (
                      <li className="pt-3 pb-3" key="">
                        {t(arr)}
                      </li>
                    ))}
                  </ul>
                  {/* {element.list.authorRights ? (
                    <p className="text-sm pb-3">*{t(element.list.authorRights)}</p>
                  ) : null} */}

                  <Button
                    data-testid={element.license == 'student' ? 'select_student_licenses' : ''}
                    className={selected ? 'invisible' : ''}
                    onClick={handleSelectLicense}>
                    {t('common.nouns.select')}{' '}
                  </Button>

                  {role == element.license && (
                    <>
                      <h3 className="font-semibold text-stone-700 border-b pb-2 pt-2 mb-2" key="">
                        {t(element.formTitle)}
                      </h3>
                      <p className="text-sm pt-1 pb-1">{t(element.formText)}</p>
                      {element.license == 'student' && (
                        <p className="text-sm pt-1 pb-1">{t(element.invoiceDetails)}</p>
                      )}
                      <NewLicenseGroupForm
                        licenseForm={element.license}
                        handleUnselect={handleUnselect}
                        book={book}
                      />
                    </>
                  )}
                </CardContent>
              </Card>
            </>
          );
        })}
      </div>
    </DataTableContainer>
  );
}
