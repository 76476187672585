import { Link } from '@inertiajs/react';
import React, { useCallback } from 'react';
import { Embed } from 'styled-icons/icomoon';
import { Audiotrack } from 'styled-icons/material';

import CourseContentsApi from '~/api/CourseContentsApi';
import { T_BaseElement } from '~/types/nodes';
import { T_ElemenNodeType } from '~/types/T_NodeTypes';

export default function OutlineMedia(props) {
  const { courseContent, bookContent, focus } = props;

  const render = useCallback(renderChildren, []);

  return (
    <ul className="p-5 space-y-2 overflow-y-auto">
      <div className="space-y-3">{render(bookContent.children, courseContent.slug, focus)}</div>
    </ul>
  );
}

function renderChildren(nodes: T_BaseElement[], slug: string, focus) {
  const outlineElements = [];

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];

    let Component = null;

    const href = focus
      ? CourseContentsApi.show.path({ slug, query: { focus: node.nodeKey } })
      : `#${node.nodeKey}`;

    switch (node.type) {
      case T_ElemenNodeType.IMAGE_COLLECTION: {
        Component = (
          <Link data-testid={`outline_image-collection_${node.nodeKey}`} href={href} preserveState>
            <OutlineImage image={node.images?.[0]} />
          </Link>
        );
        break;
      }
      case T_ElemenNodeType.LAYOUT_IMAGE_TEXT: {
        Component = (
          <Link data-testid={`outline_layout-image-text_${node.nodeKey}`} href={href} preserveState>
            <OutlineImage node={node} image={node?.image} />
          </Link>
        );
        break;
      }
      case T_ElemenNodeType.MARKED_IMAGE: {
        Component = (
          <Link data-testid={`outline_marked-image_${node.nodeKey}`} href={href} preserveState>
            <OutlineImage node={node} image={node?.source} />
          </Link>
        );
        break;
      }
      case T_ElemenNodeType.MEDIA: {
        Component = (
          <Link
            data-testid={`outline_media_${node.nodeKey}`}
            className="flex items-center border rounded truncate text-sm px-2 py-1 hover:bg-gray-100"
            href={href}
            preserveState>
            <Embed className="h-4 w-4 mr-1" />
            <span className="truncate flex-1">
              <span className="font-semibold">Extern:</span>{' '}
              {node.title.children?.[0]?.children?.[0]?.text}{' '}
            </span>
          </Link>
        );
        break;
      }
      case T_ElemenNodeType.AUDIO_FRAGMENT: {
        Component = (
          <Link
            data-testid={`outline_audio-fragment_${node.nodeKey}`}
            className="flex items-center border rounded truncate text-sm px-2 py-1 hover:bg-gray-100"
            href={href}
            preserveState>
            <Audiotrack className="h-4 w-4 mr-1" />
            <span className="truncate flex-1">
              <span className="font-semibold">Audio:</span>{' '}
              {node.title.children?.[0]?.children?.[0]?.text}{' '}
            </span>
          </Link>
        );
        break;
      }
    }

    if (Component) {
      outlineElements.push(<li key={i}>{Component}</li>);
    }
  }

  return outlineElements;
}

function OutlineImage({ image }) {
  if (!image) return null;

  return (
    <div className="flex shadow rounded border h-24 justify-center overflow-hidden">
      <img className="h-24" src={image.medium_src} />
    </div>
  );
}
