import React from 'react';

import { T_TextMultiLine } from '~/types/node/T_TextMultiLine';
import { NodeMap } from '~/views/print/NodeMap';

type Props = {
  node: T_TextMultiLine['ViewNode'];
};

export default function Content(props: Props) {
  const { node } = props;

  return (
    <div className="text-gray-700">
      {node.children.map((child, index) => {
        return (
          <div className="my-2 w-full relative first:my-1 last:my-1" key={index}>
            <NodeMap nodes={[child]} />
          </div>
        );
      })}
    </div>
  );
}
