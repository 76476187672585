import React from 'react';

import { T_ListUnordered } from '~/types/node/T_ListUnordered';
import { cn } from '~/utils';

import { ListItem } from './ListItem';

type Props = {
  nested?: boolean;
  node: T_ListUnordered['ViewNode'];
};

export function UnorderedList(props: Props) {
  const { nested, node } = props;

  return (
    <ul
      data-testid={node.type}
      className={cn('pl-6 list-disc break-inside-avoid', !nested && 'mb-1')}>
      {node.children.map((itemNode) => (
        <ListItem key={itemNode.nodeKey} node={itemNode} />
      ))}
    </ul>
  );
}
