import React, { useContext } from 'react';

const HightlightContext = React.createContext({ showHighlight: true });

type Props = {
  children: React.ReactNode;
  showHighlight?: boolean;
};

export function HightlightContextProvider(props: Props) {
  const { children, showHighlight } = props;

  return (
    <HightlightContext.Provider value={{ showHighlight }}>{children}</HightlightContext.Provider>
  );
}

export const useHighlightContext = () => useContext(HightlightContext);
