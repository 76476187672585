import { zodResolver } from '@hookform/resolvers/zod';
import { Link, router, usePage } from '@inertiajs/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import PasswordsApi from '~/api/Users/PasswordsApi';
import SessionsApi from '~/api/Users/SessionsApi';
import { Button } from '~/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import { T_PageProps } from '~/types/T_PageProps';

const formSchema = z.object({
  email: z.string().email(),
  password: z.string(),
});

export function SignInForm() {
  const { t } = useTranslation();

  const {
    organization: { template },
  } = usePage<T_PageProps>().props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('pages.sessionsNew.forms.signIn.labels.email')}</FormLabel>
              <FormControl>
                <Input type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('pages.sessionsNew.forms.signIn.labels.password')}</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex items-center justify-between">
          <div className="text-sm leading-6">
            <Link
              style={{ color: template.color1 }}
              href={PasswordsApi.new.path()}
              className="font-semibold hover:text-gray-800">
              {t('pages.sessionsNew.buttons.forgotPassword')}
            </Link>
          </div>
        </div>

        <div>
          <Button
            style={{ backgroundColor: template.color1 }}
            type="submit"
            className="w-full font-bold">
            {t('pages.sessionsNew.forms.signIn.buttons.signIn')}
          </Button>
        </div>
      </form>
    </Form>
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.post(
      SessionsApi.create.path(),
      { user: values },
      {
        onError: (err) => {
          console.log(err);
        },
      },
    );
  }
}
