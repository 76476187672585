import React, { useContext } from 'react';

import { T_TextMultiLine } from '~/types/node/T_TextMultiLine';

import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_TextMultiLine['ViewNode'];
};

const Context = React.createContext(false);

export function Explanation(props: Props) {
  const { node } = props;

  return (
    <div className="text-gray-700">
      <Context.Provider value={true}>
        <NodeMap nodes={node.children} />
      </Context.Provider>
    </div>
  );
}

export function useIsExplanationChild() {
  return !!useContext(Context);
}
