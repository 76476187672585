import { router } from '@inertiajs/react';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomQuestionAnswersApi from '~/api/CustomQuestionAnswersApi';
import { Button } from '~/components/ui/button';
import { useCourseContentShowPage } from '~/pages/course-contents/show/T_CourseContentShowProps';

type Props = {
  question: any;
  userHasAnswered: boolean;
};

export function DynamicQuestionAnswerForm(props: Props) {
  const { t } = useTranslation();

  const { courseContent } = useCourseContentShowPage().props;

  const { question, userHasAnswered } = props;

  const [value, setValue] = useState('');
  const [option, setOption] = useState(null);

  return (
    <>
      {userHasAnswered && (
        <div className="flex items-center justify-center h-full py-4 font-bold text-gray-700 text-1xl">
          {t('pages.courseContentsShow.forms.answerDynamicQuestion.text.sentSuccessfully')}
        </div>
      )}

      {!userHasAnswered && question && question.kind === 'open_question' && (
        <form className="flex flex-col items-center justify-center h-2/3" onSubmit={handleSubmit}>
          {!!question.title && (
            <div className="mb-2 text-2xl font-semibold text-gray-700">{question.title}</div>
          )}

          <div className="flex">
            <input
              value={value}
              id="answer-input"
              name="answer-input"
              onChange={(event) => setValue(event.target.value)}
              type="text"
              className="w-full mb-3 border rounded"
            />
          </div>
          <div className="self-end mt-2">
            <Button onClick={handleSubmit}>{t('common.buttons.send')}</Button>
          </div>
        </form>
      )}

      {!userHasAnswered && question && question.kind === 'multiple_choice' && (
        <div className="flex flex-col items-center justify-center h-2/3">
          {!!question.title && (
            <div className="mb-2 text-2xl font-semibold text-gray-700">{question.title}</div>
          )}
          <div className="flex flex-wrap w-full">
            {Object.entries(question.options).map(([key, value]) => {
              return (
                <div className="w-1/2 px-4 mb-6" key={key}>
                  <button
                    className={clsx(
                      'border cursor-pointer border-blue-500 w-full  text-xl font-semibold p-4 flex justify-center rounded shadow text-blue-500',
                      option === key && 'bg-blue-500 text-white',
                    )}
                    onClick={() => setOption(key)}>
                    {value}
                  </button>
                </div>
              );
            })}
          </div>
          <div className="self-end mt-2">
            <button
              onClick={handleSubmit}
              className="inline-flex self-end justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-green-600 border border-transparent shadow-sm rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              {t('common.buttons.send')}
            </button>
          </div>
        </div>
      )}
    </>
  );

  function handleSubmit(event) {
    event.preventDefault();

    const data = question.kind === 'open_question' ? { value } : { option };

    router.post(
      CustomQuestionAnswersApi.create.path({ course_content_slug: courseContent.slug }),
      data,
      {
        replace: true,
        preserveState: true,
      },
    );
  }
}
