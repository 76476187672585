import React from 'react';

import { T_AnswerableMultipleChoice } from '~/types/node/exercise/answerables/multiple-choice/T_AnswerableMultipleChoice';

import { OptionList } from './OptionList';

type Props = {
  answerable: T_AnswerableMultipleChoice['ViewNode'];
};

export function MultipleChoice(props: Props) {
  const { answerable } = props;

  return <OptionList answerable={answerable} />;
}
