import React from 'react';

import { HightlightContextProvider } from '~/hooks/useHighlightContext';
import { T_HeadingOne } from '~/types/node/T_HeadingOne';

import { GutterTitle } from '../../components/Gutter';
import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_HeadingOne['ViewNode'];
};

export function HeadingOne(props: Props) {
  const { node } = props;

  return (
    <h2
      data-testid={`${node.type} ${node.nodeKey}`}
      className="text-2xl font-semibold mb-2 text-gray-800">
      <GutterTitle>{node.number}</GutterTitle>
      <HightlightContextProvider showHighlight={false}>
        <NodeMap nodes={node.children} />
      </HightlightContextProvider>
    </h2>
  );
}
