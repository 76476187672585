import { ColumnDef } from '@tanstack/react-table';
import { Translation } from 'react-i18next';

import { DataTableColumnHeader } from '~/components/ui/data-table-column-header';
import { ClassGroup as T_ClassGroup } from '~/types/serializers';

import { GroupOptions } from './components/group-options';

export const columns: ColumnDef<T_ClassGroup>[] = [
  {
    accessorKey: 'code',
    header: ({ column }) => (
      <Translation>
        {(t) => (
          <DataTableColumnHeader
            className="pl-4"
            column={column}
            title={t('models.classGroup.code')}
          />
        )}
      </Translation>
    ),
    cell: ({ row }) => <div className="w-[80px] pl-4">{row.getValue('code')}</div>,
  },
  {
    accessorKey: 'name',
    header: ({ column }) => (
      <Translation>
        {(t) => <DataTableColumnHeader column={column} title={t('models.classGroup.name')} />}
      </Translation>
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[300px] truncate font-medium">{row.getValue('name')}</span>
        </div>
      );
    },
  },
  {
    accessorKey: 'member_count',
    header: ({ column }) => (
      <Translation>
        {(t) => <DataTableColumnHeader column={column} title={t('models.classGroup.members')} />}
      </Translation>
    ),
    cell: ({ row }) => {
      return <div className="text-right max-w-[40px]">{row.getValue('member_count')}</div>;
    },
    enableSorting: false,
  },
  {
    id: 'actions',
    cell: ({ row, table }) => {
      return <GroupOptions book={table.options.meta.book} group={row.original} />;
    },
  },
];
