import React from 'react';

import { DataTableContainer } from '~/components/ui-extended/data-table-container';
import { LayoutChapter } from '~/components/ui-extended/layout-chapter';

import FlashcardList from './flashcard-list';
import { usePartFlashcardsPage } from './T_PartFlashCardsProps';

export default function Flashcards() {
  const { access, book, chapter, parts, studentClassGroup } = usePartFlashcardsPage().props;

  return (
    <LayoutChapter
      access={access}
      book={book}
      breadcrumbs={[
        {
          type: 'static',
          title: 'Flashcards',
        },
      ]}
      chapter={chapter}
      parts={parts}
      studentClassGroup={studentClassGroup}>
      <DataTableContainer title="Flashcards" translateTitle>
        <FlashcardList />
      </DataTableContainer>
    </LayoutChapter>
  );
}
