import clsx from 'clsx';
import React from 'react';

import { T_ListOrdered } from '~/types/node/T_ListOrdered';

import { ListItem } from '../shared/ListItem';

type Props = {
  node: T_ListOrdered['ViewNode'];
  nested?: boolean;
};

export function OrderedList(props: Props) {
  const { node, nested } = props;

  return (
    <ol
      data-testid={`${node.type} ${node.nodeKey}`}
      className={clsx('list-decimal pl-5', nested && 'mb-5 text-green-400 mt-3')}>
      {node.children.map((itemNode) => (
        <ListItem key={itemNode.nodeKey} node={itemNode} />
      ))}
    </ol>
  );
}
