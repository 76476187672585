import { usePage } from '@inertiajs/react';

import { T_Training } from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';

export const useTrainingsIndexPage = usePage<T_TrainingsIndexProps>;

export interface T_TrainingsIndexProps extends T_PageProps {
  trainings: T_Training[];
}
