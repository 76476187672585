import React from 'react';

import { T_TextSingleLine } from '~/types/node/T_TextSingleLine';

import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_TextSingleLine['ViewNode'];
};

export function Concept(props: Props) {
  const { node } = props;

  return (
    <h4 className="text-gray-700 font-bold mb-2">
      <NodeMap nodes={node.children} />
    </h4>
  );
}
