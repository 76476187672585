import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components/ui/button';
import { cn } from '~/utils';

import { useBooksFlashcardsPage } from '../T_BooksFlashcards';
import { ChapterSelect } from './chapter-select';
import { FlashcardsAllList } from './flashcards-all-list';
import { FlashcardsDueList } from './flashcards-due-list';

export function PageContent() {
  const { t } = useTranslation();

  const { book, dueFlashcards, allFlashcards } = useBooksFlashcardsPage().props;

  const [tab, setTab] = useState('due');

  return (
    <div className="w-full p-10 h-full relative">
      <div className="border-b pb-2 flex justify-between items-center">
        <div className="space-x-2">
          <ChapterSelect />

          <Button
            onClick={() => setTab('due')}
            className={cn('border border-transparent', tab === 'due' && 'border-stone-300')}
            variant={tab === 'due' ? 'secondary' : 'ghost'}>
            {t('pages.flashcardsIndex.buttons.dueToday')}{' '}
            <span className="ml-1 bg-stone-700 text-white px-2 py-1 rounded-full text-xs">
              {dueFlashcards.length}
            </span>
          </Button>

          <Button
            onClick={() => setTab('all')}
            className={cn('border border-transparent', tab === 'all' && 'border-stone-300')}
            variant={tab === 'all' ? 'secondary' : 'ghost'}>
            {t('pages.flashcardsIndex.buttons.allCards')}
          </Button>
        </div>
        <div></div>
      </div>

      {tab === 'due' ? (
        <FlashcardsDueList book={book} flashcards={dueFlashcards} />
      ) : (
        <FlashcardsAllList flashcards={allFlashcards} />
      )}
    </div>
  );
}
