import { router } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FlashcardsApi from '~/api/FlashcardsApi';
import { Button } from '~/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';

import { useBooksFlashcardsPage } from '../T_BooksFlashcards';

export function ChapterSelect() {
  const { t } = useTranslation();

  const { book, selectableParts, flashcardChapterIds } = useBooksFlashcardsPage().props;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          {t('pages.flashcardsIndex.buttons.selectChapters')}{' '}
          <span className="text-xs ml-1 font-normal">
            ({flashcardChapterIds.length} {t('pages.flashcardsIndex.text.chaptersSelected')})
          </span>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-56">
        {selectableParts.map((part) => {
          return (
            <React.Fragment key={part.id}>
              <DropdownMenuLabel>{part.title}</DropdownMenuLabel>
              <DropdownMenuSeparator />
              {part.children.map((chapter) => {
                return (
                  <DropdownMenuCheckboxItem
                    checked={flashcardChapterIds.includes(chapter.id)}
                    onSelect={(e) => toggleChapter(e, chapter.id)}
                    key={chapter.id}>
                    {chapter.title}
                  </DropdownMenuCheckboxItem>
                );
              })}
            </React.Fragment>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );

  function toggleChapter(e: Event, id: number) {
    e.preventDefault();

    let updatedFlashcardIds;

    if (flashcardChapterIds.includes(id)) {
      updatedFlashcardIds = flashcardChapterIds.filter((flashcardId) => flashcardId !== id);
    } else {
      updatedFlashcardIds = [...flashcardChapterIds, id];
    }

    router.post(FlashcardsApi.updateFlashcardChapters.path({ book_slug: book.slug }), {
      flashcard_chapter_ids: updatedFlashcardIds,
    });
  }
}
