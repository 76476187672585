import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import EducationApi from '~/api/Website/EducationApi';
import { cn } from '~/utils';

type Props = {
  dark?: boolean;
};

export function NewsletterSubscription({ dark }: Props) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const [sentStatus, setSentStatus] = useState('');

  return (
    <div className={cn(dark ? 'bg-neutral-800' : 'bg-white', 'py-16 sm:py-24')}>
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
        <div
          className={cn(
            dark ? 'text-white' : 'text-gray-900',
            'max-w-xl text-3xl font-bold tracking-tight sm:text-4xl lg:col-span-7',
          )}>
          <h2 className="inline sm:block lg:inline xl:block">
            {t('website.home.newsletterTitle1')}
          </h2>{' '}
          <p className="inline sm:block lg:inline xl:block">{t('website.home.newsletterTitle2')}</p>
        </div>
        <form onSubmit={subscribeToNewsLetter} className="w-full max-w-md lg:col-span-5 lg:pt-2">
          <div className="flex gap-x-4">
            <label htmlFor="email-address" className="sr-only">
              Email
            </label>
            <input
              id="email-address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              type="email"
              autoComplete="email"
              required
              className="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={t('website.home.newsletterPlaceholder')}
            />
            <button
              type="submit"
              className="flex-none rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              {t('website.home.newsletterButton')}
            </button>
          </div>

          {sentStatus === 'success' && (
            <p className={cn(dark ? 'text-white' : 'text-gray-900', 'mt-4 text-sm leading-6')}>
              {t('website.home.newsletterSuccess')}
            </p>
          )}
        </form>
      </div>
    </div>
  );

  async function subscribeToNewsLetter(event) {
    event.preventDefault();

    const result = await axios.post(EducationApi.subscribe.path(), { email });

    if (result.status === 200) {
      setSentStatus('success');
    } else {
      setSentStatus('error');
    }

    setEmail('');
  }
}
