import { usePage } from '@inertiajs/react';

import { T_Access, T_Book, T_ClassGroup } from '~/types/serializers';
import { T_PageProps } from '~/types/T_PageProps';
import { T_PagyMetadata } from '~/types/T_PagyMetadata';

export const useClassGroupsIndexPage = usePage<T_ClassGroupsIndexProps>;

export interface T_ClassGroupsIndexProps extends T_PageProps {
  access: T_Access;
  book: T_Book;
  classGroups: T_ClassGroup[];
  pagyMetadata: T_PagyMetadata;
}
