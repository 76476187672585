import React from 'react';

import { T_TableRow } from '~/types/node/T_TableRow';

import { Cell } from './Cell';

type Props = {
  node: T_TableRow['ViewNode'];
};

export function Row(props: Props) {
  const { node } = props;

  return (
    <tr className="break-inside-avoid">
      {node.children.map((node, index) => {
        return <Cell key={index} node={node} />;
      })}
    </tr>
  );
}
