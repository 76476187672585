// JsFromRoutes CacheKey b52cca9f336d2462ea88198810c13c70
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/b/:book_slug/authors'),
  create: /* #__PURE__ */ definePathHelper('post', '/b/:book_slug/authors'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/b/:book_slug/authors/:id'),
}
