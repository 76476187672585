import { HamburgerMenuIcon } from '@radix-ui/react-icons';

import { Button } from '~/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '~/components/ui/sheet';

import { SidebarMenu } from './sidebar-menu';

type Props = {
  children: React.ReactNode;
  title: string;
};

export function SidebarMobile(props: Props) {
  const { children, title } = props;

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button
          data-testid="toggle-sidebar-button"
          className="block lg:hidden mr-3"
          variant="secondary"
          size="sm">
          <HamburgerMenuIcon />
        </Button>
      </SheetTrigger>

      <SheetContent side="left" className="w-64 bg-stone-100 p-0">
        <SidebarMenu title={title}>{children}</SidebarMenu>
      </SheetContent>
    </Sheet>
  );
}
