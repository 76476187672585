import React from 'react';

import { cn } from '~/utils';

type Props = {
  onSelect: (color: string) => void;
  selectedColor: string;
};

export function ColorPicker(props: Props) {
  const { onSelect, selectedColor } = props;

  return (
    <div className="bg-white grid grid-cols-8 gap-2">
      {colors.map((color) => {
        return (
          <button
            type="button"
            data-testid={`book-edit-color-${color}-button`}
            onClick={() => onSelect(color)}
            key={color}
            style={{ backgroundColor: color }}
            className={cn(
              'p-4 rounded hover:border-gray-400 border border-transparent',
              selectedColor === color && 'border-black border',
            )}
          />
        );
      })}
    </div>
  );
}

export const colors = [
  '#f2d7d5',
  '#fadbd8',
  '#ebdef0',
  '#e8daef',
  '#d4e6f1',
  '#d6eaf8',
  '#d1f2eb',
  '#d0ece7',
  '#d4efdf',
  '#d5f5e3',
  '#fcf3cf',
  '#fdebd0',
  '#fae5d3',
  '#f6ddcc',
  '#eaeded',
  '#d6dbdf',
];
