import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExerciseDifficultyLevel } from '~/components/pages-shared/exercise-difficulty-level';
import { HightlightContextProvider } from '~/hooks/useHighlightContext';
import { T_Exercise } from '~/types/node/exercise/T_Exercise';

import { Element } from '../../components/Element';
import { useViewBookContext } from '../../hooks/useViewBookContext';
import { Answerable } from './Answerable';
import { ExercisePropsContextProvider } from './context/ExercisePropsContext';
import { Question } from './Question';

type Props = {
  node: T_Exercise['ViewNode'];
};

export function Exercise(props: Props) {
  const { t } = useTranslation();

  const { node } = props;

  const { access, answers, courseContent } = useViewBookContext();

  return (
    <ExercisePropsContextProvider
      access={access}
      courseContent={courseContent}
      node={node}
      userAnswers={answers}>
      <Element
        node={node}
        gutterElement={
          <div>
            <h6 className="text-sm text-gray-500">{generateGutterTitle()}</h6>
            <ExerciseDifficultyLevel withTooltip difficulty_level={node.difficulty_level} />
          </div>
        }
        title={generateGutterTitle()}>
        <HightlightContextProvider showHighlight={false}>
          <Question node={node.question} />
        </HightlightContextProvider>

        <Answerable answerable={node.answerable_value} />
      </Element>
    </ExercisePropsContextProvider>
  );

  function generateGutterTitle() {
    const { number } = node;

    return node.behavior === 'static'
      ? t('common.nodes.question_n', { n: number })
      : t('common.nodes.exercise_n', { n: number });
  }
}
