import { zodResolver } from '@hookform/resolvers/zod';
import { router } from '@inertiajs/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import LicenseGroupsApi from '~/api/LicenseGroupsApi';
import { Button } from '~/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import { T_Book, T_InvoiceDetails } from '~/types/serializers';

type Props = {
  book: T_Book;
  userRole?: string;
  handleUnselect: () => void;
  licenseForm: string;
  studentLicenses?: boolean;
  trialLicenses?: boolean;
  teacherLicenses?: boolean;
  invoiceDetails?: T_InvoiceDetails;
};

export function NewLicenseGroupForm(props: Props) {
  const { t } = useTranslation();

  const { book, licenseForm, handleUnselect, invoiceDetails } = props;

  const studentLicenses = licenseForm == 'student';

  const formSchema = z.object({
    quantity: z.string().min(1).max(1000),
    role: z.enum(['teacher', 'student']),
    notes: z.string().optional(),
    test_group: z.boolean().optional(),
    ...(studentLicenses
      ? {
          invoice_name: z.string(),
          invoice_email: z.string(),
          invoice_address: z.string(),
          invoice_vat_number: z.string(),
        }
      : {}),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      quantity: '1',
      role: licenseForm == 'teacher' ? 'teacher' : 'student',
      notes: '',
      test_group: !studentLicenses,
      ...(studentLicenses
        ? {
            invoice_name: invoiceDetails?.name,
            invoice_email: invoiceDetails?.email,
            invoice_address: invoiceDetails?.address,
            invoice_vat_number: invoiceDetails?.vat,
          }
        : {}),
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5 overflow-y-auto">
        <FormField
          control={form.control}
          name="quantity"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('pages.licenseGroupsIndex.forms.newLicenseGroup.labels.amount')}
              </FormLabel>
              <FormControl>
                <Input min={1} max={licenseForm == 'trial' ? 30 : 1000} type="number" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('models.licenseGroup.notes')}{' '}
                <span className="text-gray-400 font-light">({t('common.nouns.optional')})</span>
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder={t(
                    'pages.licenseGroupsIndex.forms.newLicenseGroup.placeholders.notes',
                  )}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {licenseForm == 'student' && (
          <div className="space-y-1">
            <h3 className="font-semibold text-stone-700 border-b pb-2 mb-2">
              {t('pages.licenseGroupsIndex.forms.newLicenseGroup.labels.invoiceDetails')}
            </h3>
            <FormField
              control={form.control}
              name="invoice_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="w-1/4">
                    {t('pages.licenseGroupsIndex.forms.newLicenseGroup.labels.invoiceName')}
                  </FormLabel>
                  <FormControl>
                    <Input type="text" placeholder="" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="invoice_email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="w-1/4">
                    {t('pages.licenseGroupsIndex.forms.newLicenseGroup.labels.invoiceEmail')}
                  </FormLabel>
                  <FormControl>
                    <Input type="email" placeholder="" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="invoice_address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="w-1/4">
                    {t('pages.licenseGroupsIndex.forms.newLicenseGroup.labels.invoiceAddress')}
                  </FormLabel>
                  <FormControl>
                    <Input type="text" placeholder="" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="invoice_vat_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="w-1/4">
                    {t('pages.licenseGroupsIndex.forms.newLicenseGroup.labels.invoiceVATNumber')}
                  </FormLabel>
                  <FormControl>
                    <Input type="text" placeholder="" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        )}
        <div className="flex justify-between">
          <Button data-testid="create_license_group_button" type="submit">
            {t('common.buttons.add')}
          </Button>
          <Button data-testid="close_license_form" variant="outline" onClick={handleUnselect}>
            {t('common.buttons.cancel')}
          </Button>
        </div>
      </form>
    </Form>
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.post(LicenseGroupsApi.create.path({ book_slug: book.slug }), values, {
      replace: true,
      onSuccess: () => {
        form.reset();
        console.log('success', book.slug);
      },
    });
  }
}
