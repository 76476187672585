import { UniqueIdentifier } from '@dnd-kit/core';
import { usePage } from '@inertiajs/react';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { DataTableContainer } from '~/components/ui-extended/data-table-container';
import T_LearningPath from '~/types/T_LearningPath';

import { DndWrapper } from '../dnd/DndWrapper';
import { AiDialog } from './ai-dialog';
import { CollapsableContextProvider } from './collapsable-context';
import { CollapseAllButton } from './collapse-all-button';
import { NestedParts } from './nested-parts';
import { Parts } from './parts';

export type TPart = {
  children: TNestedPart[];
  id: number;
  sections: TSection[];
  slug: UniqueIdentifier;
  title: string;
  position: number;
  learningPath: T_LearningPath;
  published: boolean;
};

export type TNestedPart = Omit<TPart, 'children'> & { number: number };

export type TSection = {
  id: number;
  number: string;
  numberedTitle: string;
  slug: UniqueIdentifier;
  title: string;
};

export function PageContent() {
  const { book, parts: unformattedParts } = usePage<any>().props;

  const [parts, setParts] = useState<TPart[]>(format(unformattedParts));
  const [isInDragAction, setIsInDragAction] = useState(false);

  useEffect(() => {
    setParts(format(unformattedParts));
  }, [unformattedParts]);

  return (
    <CollapsableContextProvider>
      <DataTableContainer
        rightSideContent={<AiDialog />}
        title="pages.booksEditStructure.title"
        translateTitle>
        <CollapseAllButton />

        <DndProvider backend={HTML5Backend}>
          <DndWrapper
            bookSlug={book.slug}
            parts={parts}
            setParts={setParts}
            setIsInDragAction={setIsInDragAction}>
            {book.structure === 'part_chapter_section' ? (
              <Parts bookSlug={book.slug} parts={parts} isInDragAction={isInDragAction} />
            ) : (
              <NestedParts
                part={parts[0]}
                nestedParts={parts[0].children}
                bookSlug={book.slug}
                isInDragAction={isInDragAction}
              />
            )}
          </DndWrapper>
        </DndProvider>
      </DataTableContainer>
    </CollapsableContextProvider>
  );

  function format(parts) {
    return parts.map((part) => {
      return {
        ...part,
        children: (part.children ?? []).map((c) => ({ ...c, sections: c.sections ?? [] })),
      };
    });
  }
}
