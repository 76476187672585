import React from 'react';

import { templateIconMap } from '~/components/pages-shared/template-icon-map';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';

type Props = {
  iconName: string;
  name: string;
};

export function GutterIcon(props: Props) {
  const { iconName, name } = props;

  const Icon = templateIconMap[iconName];

  return (
    <div className="flex justify-end">
      <TooltipProvider>
        <Tooltip delayDuration={350}>
          <TooltipTrigger className="cursor-default">
            <div
              data-testid={`container-gutter-icon-${iconName}`}
              className="p-1.5 border border-gray-500 rounded flex items-center justify-center">
              <Icon className="h-5 w-5" />
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p>{name}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}
