import React, { useRef } from 'react';

import { T_AnswerableMarkImageMarker } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImageMarker';
import { T_MarkedImageMarker } from '~/types/node/T_MarkedImageMarker';

import { MarkerOverlay } from './MarkerOverlay';

type Props = {
  markers: T_MarkedImageMarker['ViewNode'][] | T_AnswerableMarkImageMarker['ViewNode'][];
  src: string;
  handleSvgClick?: (point: [number, number]) => void;
};

export function ImageWithMarkerOverlay(props: Props) {
  const { markers, src, handleSvgClick = null } = props;

  const imageRef = useRef<HTMLImageElement>(null);
  const widthRef = useRef<HTMLDivElement>(null);

  return (
    <div className="flex justify-center cursor-pointer bg-black" ref={widthRef}>
      <div className="relative">
        <MarkerOverlay
          markers={markers}
          imageRef={imageRef}
          widthRef={widthRef}
          onClick={handleSvgClick}
        />
        <img src={src} ref={imageRef} className="max-w-full" />
      </div>
    </div>
  );
}
