import React from 'react';

import { useTheme } from '~/components/theme-provider';
import { T_HeadingFour } from '~/types/node/T_HeadingFour';
import { NodeMap } from '~/views/print/NodeMap';

type Props = {
  node: T_HeadingFour['ViewNode'];
};

export function HeadingFour(props: Props) {
  const { node } = props;

  const { colors } = useTheme();

  return (
    <div className="break-after-avoid relative mb-[1.5mm] mt-[2.5mm]">
      <h4
        style={{ color: colors.headingFour }}
        data-testid={node.type}
        className="text-green-500 text-[11pt] m-0">
        <NodeMap nodes={node.children} />
      </h4>
    </div>
  );
}
