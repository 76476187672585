import { HeaderEducation } from '../../components/header-education';

export default function Home() {
  return (
    <>
      <HeaderEducation />

      <div className="w-full flex items-center justify-center">
        <iframe
          width="800"
          height="600"
          frameborder="0"
          allow="clipboard-write;camera;geolocation;fullscreen"
          src="https://lichtvisforms.budibase.app/embed/opleidingen-brightbook"></iframe>
      </div>
    </>
  );
}

Home.layout = (page) => page;
