import { t } from 'i18next';
import React from 'react';

import { T_File } from '~/types/node/T_File';

import { Gutter } from '../../components/Gutter';
import { Title } from './Title';

type Props = {
  node: T_File['ViewNode'];
};

export function File(props: Props) {
  const { node } = props;

  return (
    <div className="relative mb-[2.5mm]" data-testid={node.type}>
      <div className="text-xs text-gray-500">
        <Gutter content={t('common.nodes.file')} />
      </div>

      <Title node={node.title} />
    </div>
  );
}
