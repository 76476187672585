import React from 'react';

import { T_AnswerableFillListItemKeyText } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillListItemKeyText';

type Props = {
  node: T_AnswerableFillListItemKeyText['ViewNode'];
};

export function KeyText(props: Props) {
  const { node } = props;

  return (
    <div className="w-full flex-1">
      <h4 className="font-light text-base mr-2 text-gray-600">{node.text}</h4>
    </div>
  );
}
