import React from 'react';
import { useTranslation } from 'react-i18next';

import { T_WordList } from '~/types/node/T_WordList';

import { Gutter } from '../../components/Gutter';
import { WordItem } from './WordItem';

type Props = {
  node: T_WordList['ViewNode'];
};

export function WordList(props: Props) {
  const { t } = useTranslation();

  const { node } = props;

  return (
    <div className="break-inside-avoid mt-2 mb-4">
      <div className="text-xs text-gray-500">
        <Gutter content={t('common.nodes.wordList')} />
      </div>

      <table className="min-w-full p-1 mb-2 text-sm">
        <tbody className="divide-y divide-gray-300">
          {node.items.map((item) => (
            <WordItem key={item.nodeKey} node={item} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
