import React from 'react';

import { cn } from '~/utils';

type Props = {
  node: {
    url: string;
    height?: number | null;
  };
};

export function Iframe(props: Props) {
  const { node } = props;

  if (node.height) {
    return (
      <div className="mt-4">
        <iframe
          allowFullScreen
          className="w-full bg-black relative"
          style={{ height: node.height }}
          src={node.url}
        />
      </div>
    );
  }

  const isH5PContent = node.url?.includes('h5p');

  return (
    <div className="mt-4">
      <div className={cn('relative w-full', !isH5PContent && 'overflow-hidden pt-[56.25%]')}>
        <iframe
          allowFullScreen
          className={cn('top-0 right-0 left-0 bottom-0 w-full h-full', !isH5PContent && 'absolute')}
          src={node.url}
        />
      </div>
    </div>
  );
}
