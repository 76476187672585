import clsx from 'clsx';
import React from 'react';
import { HandRaised as HandOutline } from 'styled-icons/heroicons-outline';
import { HandRaised as HandSolid } from 'styled-icons/heroicons-solid';

import { T_AnswerableMarkImageMarker } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImageMarker';

import { useExerciseProps } from '../../context/ExercisePropsContext';

type Props = {
  node: T_AnswerableMarkImageMarker['ViewNode'];
  userAnswer: string;
  number: number;
  handleChange: (key: string, value: string) => void;
  activeItem: T_AnswerableMarkImageMarker['ViewNode'] | null;
  setActiveItem: () => void;
  showSentInAnswers: boolean;
};

export function MarkerText(props: Props) {
  const { activeItem, handleChange, node, number, setActiveItem, showSentInAnswers, userAnswer } =
    props;

  const { access } = useExerciseProps();

  const active = node.nodeKey === activeItem?.nodeKey;

  return (
    <div className="mt-1 flex rounded-md shadow-sm">
      <div className="relative flex items-stretch flex-grow focus-within:z-50">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-base font-bold text-gray-700">{number}.</span>
        </div>
        <input
          className={clsx(
            'focus:ring-indigo-500 focus:border-indigo-500 text-blue-800 font-medium block w-full rounded-md pl-8 sm:text-sm border-gray-300',
            !!showSentInAnswers && !access.student && 'rounded-r-none',
          )}
          data-testid={`marked-image-marker-text-input-${node.nodeKey}`}
          value={userAnswer}
          onChange={(event) => handleChange(node.nodeKey, event.target.value)}
          type="text"
        />
      </div>

      {showSentInAnswers && !access.student && (
        <button
          className="-ml-px relative inline-flex items-center space-x-2 px-2 py-1 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          onClick={setActiveItem}
          data-testid={`marker-active-button-${node.nodeKey}`}
          data-tip="Toon leerling-antwoorden voor dit item"
          type="button">
          {active ? (
            <HandSolid className="w-4 h-4 text-gray-600" />
          ) : (
            <HandOutline className="w-4 h-4 text-gray-400" />
          )}
        </button>
      )}
    </div>
  );
}
