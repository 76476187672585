import React from 'react';
import { useTranslation } from 'react-i18next';

import BooksApi from '~/api/BooksApi';
import { Layout } from '~/components/ui-extended/layout';

import { PageContent } from './components/page-content';

export default function Index() {
  const { t } = useTranslation();

  return (
    <Layout
      breadcrumbs={[
        {
          title: 'common.breadcrumbs.booksIndexPage',
          translateTitle: true,
          type: 'link',
          href: BooksApi.index.path(),
        },
      ]}
      htmlHeadTitle={t('pages.booksIndex.htmlHeadTitle')}
      sidebarTitle={'Brightbook'}
      tour={{ steps }}
      sidebarChildren={<></>}>
      <PageContent />
    </Layout>
  );
}

const steps = [
  {
    selector: '.books',
    contentI18nKey: 'pages.booksIndex.tour.firstStep',
  },
  {
    selector: '.first-step',
    contentI18nKey: 'pages.booksIndex.tour.secondStep',
  },
  {
    selector: '.second-step',
    contentI18nKey: 'pages.booksIndex.tour.thirdStep',
  },
];
