import { Link } from '@inertiajs/react';
import { ChevronDownIcon, SlashIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  T_Breadcrumb,
  T_BreadcrumbDropdown,
  T_BreadcrumbDropdownCollapsed,
  T_BreadcrumbDropdownItem,
  T_BreadcrumbLink,
  T_BreadcrumbStatic,
} from '~/types/T_Breadcrumb';

import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '../ui/breadcrumb';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';

type Props = {
  breadcrumbs: T_Breadcrumb[];
};

export function Breadcrumbs(props: Props) {
  const { breadcrumbs } = props;

  return (
    <Breadcrumb>
      <BreadcrumbList className="gap-1 sm:gap-1 hidden sm:flex">
        {breadcrumbs.map((breadcrumb, index) => {
          let BreadcrumbContent: React.ReactNode;

          switch (breadcrumb.type) {
            case 'dropdown': {
              BreadcrumbContent = renderBreadcrumbDropdown(breadcrumb);
              break;
            }
            case 'dropdown-collapsed': {
              BreadcrumbContent = renderBreadcrumbDropdownCollapsed(breadcrumb);
              break;
            }
            case 'link': {
              BreadcrumbContent = renderBreadcrumbLink(breadcrumb);
              break;
            }
            case 'static': {
              BreadcrumbContent = renderBreadcrumbStatic(breadcrumb);
              break;
            }
          }

          return (
            <React.Fragment key={index}>
              <BreadcrumbItem>{BreadcrumbContent}</BreadcrumbItem>

              {index !== breadcrumbs.length - 1 && (
                <BreadcrumbSeparator>
                  <SlashIcon />
                </BreadcrumbSeparator>
              )}
            </React.Fragment>
          );
        })}
      </BreadcrumbList>
    </Breadcrumb>
  );
}

function renderBreadcrumbStatic(breadcrumb: T_BreadcrumbStatic) {
  const { t } = useTranslation();

  const title = breadcrumb.translateTitle ? t(breadcrumb.title) : breadcrumb.title;

  return <BreadcrumbPage>{title}</BreadcrumbPage>;
}

function renderBreadcrumbLink(breadcrumb: T_BreadcrumbLink) {
  const { t } = useTranslation();

  const title = breadcrumb.translateTitle ? t(breadcrumb.title) : breadcrumb.title;

  return (
    <BreadcrumbLink data-testid="breadcrumb-item" asChild>
      <Link
        as="button"
        disabled={breadcrumb.disabled}
        className="disabled:text-green-500"
        href={breadcrumb.href}>
        {title}
      </Link>
    </BreadcrumbLink>
  );
}

function renderBreadcrumbDropdown(breadcrumb: T_BreadcrumbDropdown) {
  const { t } = useTranslation();

  const title = breadcrumb.translateTitle ? t(breadcrumb.title) : breadcrumb.title;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex items-center gap-1">
        <DropdownMenuTrigger
          data-testid={breadcrumb.dataTestId ?? 'breadcrumb-dropdown'}
          className="flex items-center gap-1">
          {title}
          <ChevronDownIcon />
        </DropdownMenuTrigger>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {breadcrumb.items.map(renderdropdownItem)}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function renderBreadcrumbDropdownCollapsed(breadcrumb: T_BreadcrumbDropdownCollapsed) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        data-testid={breadcrumb.dataTestId ?? 'breadcrumb-collapsed'}
        className="flex items-center gap-1">
        <BreadcrumbEllipsis className="h-4 w-4" />
        <span className="sr-only">Toggle menu</span>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {breadcrumb.items.map(renderdropdownItem)}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function renderdropdownItem(item: T_BreadcrumbDropdownItem) {
  const { t } = useTranslation();

  switch (item.type) {
    case 'dropdown-item-separator': {
      return <DropdownMenuSeparator />;
    }

    case 'dropdown-item-title': {
      const title = item.translateTitle ? t(item.title) : item.title;

      return <DropdownMenuLabel>{title}</DropdownMenuLabel>;
    }

    case 'dropdown-item-link': {
      const title = item.translateTitle ? t(item.title) : item.title;

      return (
        <DropdownMenuItem asChild>
          <Link
            data-testid={item.dataTestId ?? 'breadcrumb-item'}
            as="button"
            disabled={item.disabled}
            href={item.href}
            className="disabled:text-stone-500">
            {title}
          </Link>
        </DropdownMenuItem>
      );
    }

    case 'dropdown-item-nested-menu': {
      const title: string = item.translateTitle ? t(item.title) : item.title;

      return (
        <DropdownMenuSub>
          <DropdownMenuSubTrigger data-testid={item.dataTestId ?? 'breadcrumb-item'}>
            {title}
          </DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent>{item.items.map(renderdropdownItem)}</DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
      );
    }
  }
}
