import React from 'react';

import { T_Image } from '~/types/node/T_Image';

import { Imageable } from '../../components-new/nodes-shared/imageable';

type Props = {
  node: T_Image['ViewNode'];
};

export function Image(props: Props) {
  const { node } = props;

  if (!node.medium_src) {
    return (
      <div
        data-testid="image-placeholder"
        className="w-32 h-32 flex items-center flex-col justify-start rounded shadow border-gray-700 border"></div>
    );
  }

  return (
    <div className="relative flex flex-1 pt-1">
      <div className="relative max-w-full bg-white">
        <Imageable
          className="block h-full w-full object-contain"
          node={node}
          src={node.medium_src}
        />
      </div>
    </div>
  );
}
