import React from 'react';

import { T_ImageCollection } from '~/types/node/T_ImageCollection';

import { Image } from './Image';

type Props = {
  node: T_ImageCollection['ViewNode'];
};

export function ImageCollection(props: Props) {
  const { node } = props;

  return (
    <div className="flex flex-wrap" data-testid={node.type}>
      {renderImagesInCollection()}
    </div>
  );

  function renderImagesInCollection() {
    return (node?.images ?? []).map((node, index) => {
      return <Image node={node} key={index} />;
    });
  }
}
