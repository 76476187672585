import React from 'react';

import { T_HeadingTwo } from '~/types/node/T_HeadingTwo';
import { NodeMap } from '~/views/print/NodeMap';

import { Gutter } from '../../components/Gutter';

type Props = {
  node: T_HeadingTwo['ViewNode'];
};

export function HeadingTwo(props: Props) {
  const { node } = props;

  const numArr = node.number.toString().split('.');

  const num = numArr[numArr.length - 1];

  const alpha = String.fromCharCode(parseInt(num, 10) + 64);

  return (
    <div className="breakpoint" data-break-unless-height="65mm">
      <Gutter content={alpha} />
      <h4 data-testid={node.type} className="text-gray-700 font-semibold mb-0.5">
        <NodeMap nodes={node.children} />
      </h4>
    </div>
  );
}
