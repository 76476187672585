import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion } from '~/components/ui/accordion';

import { NestedPart } from './nested-part';

type Props = {
  access: any;
  nestedParts: any[];
};

export function NestedParts(props: Props) {
  const { t } = useTranslation();

  const { access, nestedParts } = props;

  if (!nestedParts) {
    return <div className="text-gray-700 text-sm py-2">{t('pages.booksShow.text.noChapters')}</div>;
  }

  return (
    <Accordion type="multiple" className="max-w-3xl pl-4">
      {nestedParts.map((nestedPart, index) => (
        <NestedPart access={access} nestedPart={nestedPart} key={index} />
      ))}
    </Accordion>
  );
}
