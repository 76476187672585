import { Link } from '@inertiajs/react';
import React from 'react';
import { ChevronLeft, ChevronRight } from 'styled-icons/heroicons-solid';

import CourseContentsApi from '~/api/CourseContentsApi';
import { userHasAccessToSection } from '~/utils/userHasAccessToSection';

import { useViewBookContext } from '../hooks/useViewBookContext';

export function Navigation() {
  const { access, chapter, courseNavigation, studentSelfAssessment, studentSelfAssessments } =
    useViewBookContext();

  if (!courseNavigation) {
    return null;
  }

  return (
    <div className="flex flex-col items-center border-t mt-16 pt-1">
      <div className="flex justify-between w-full">
        {renderPreviousButton()}
        {renderNextButton()}
      </div>
    </div>
  );

  function renderNextButton() {
    let path = null;
    let title = null;

    if (chapter.learningPath === 'fixed' && access.student && !studentSelfAssessment) {
      return null;
    }

    switch (true) {
      case !!courseNavigation.lowerItem && courseNavigation.lowerItem.published: {
        const { lowerItem } = courseNavigation;
        path = CourseContentsApi.show.path({ slug: lowerItem.slug });
        title = lowerItem.numberedTitle;
        break;
      }
      case !!courseNavigation.part.lowerItem: {
        const { lowerItem } = courseNavigation.part;

        const section = lowerItem.sections?.[0];

        if (section) {
          path = CourseContentsApi.show.path({ slug: section.slug });
          title = section.numberedTitle;
        }
        break;
      }
    }

    if (!path || !title) {
      return null;
    }

    return (
      <Link
        data-testid="next-section-button"
        disabled={!path}
        className="font-medium text-sm hover:text-gray-500 text-gray-700 flex items-center"
        href={path}>
        <span className="underline">{title}</span>
        <span>
          <ChevronRight className="h-4 w-4 mt-0.5" />
        </span>
      </Link>
    );
  }

  function renderPreviousButton() {
    let path = null;
    let title = null;

    switch (true) {
      case !!courseNavigation.higherItem: {
        const { higherItem } = courseNavigation;

        path = CourseContentsApi.show.path({ slug: higherItem.slug });
        title = higherItem.numberedTitle;

        break;
      }
      case !!courseNavigation.part.higherItem: {
        const { higherItem } = courseNavigation.part;

        const firstAccessibleSection = higherItem?.sections?.find((section) =>
          userHasAccessToSection(higherItem, section, access, studentSelfAssessments),
        );

        if (firstAccessibleSection) {
          path = CourseContentsApi.show.path({ slug: firstAccessibleSection.slug });
          title = firstAccessibleSection.numberedTitle;
        }
        break;
      }
    }

    if (!path || !title) {
      return <div></div>;
    }

    return (
      <Link
        disabled={!path}
        data-testid="previous-section-button"
        className="font-medium text-sm hover:text-gray-500 text-gray-700 flex items-center"
        href={path}>
        <span>
          <ChevronLeft className="h-4 w-4 mt-0.5" />
        </span>
        <span className="underline">{title}</span>
      </Link>
    );
  }
}
