import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { T_AnswerableMarkImageMarker } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImageMarker';

import { useSentInAnswerProps } from '../../context/AnswerablePropsContext';

type Props = {
  activeItem: T_AnswerableMarkImageMarker['ViewNode'] | null;
  answerNodes: T_AnswerableMarkImageMarker['ViewNode'][];
};

export function PercentageView(props: Props) {
  const { t } = useTranslation();

  const { activeItem, answerNodes } = props;

  const { sentInAnswers, includeName } = useSentInAnswerProps();

  const selectedAnswersWithCount = useMemo(
    () => getAnswersWithUserCount(activeItem, sentInAnswers, answerNodes),
    [activeItem, sentInAnswers, answerNodes],
  );

  return (
    <React.Fragment>
      {Object.entries(selectedAnswersWithCount).map(([key, value]) => {
        const percentage = ((value.count / sentInAnswers.length) * 100).toFixed(2);

        return (
          <div
            className="mb-4 p-3 bg-white rounded-md border my-2 text-gray-700 font-medium text-lg"
            key={key}>
            {key === 'notFilledIn' ? (
              <span>{t('pages.courseContentsShow.nodes.exercise.notFilledIn')}</span>
            ) : (
              value.answerNode.text
            )}
            <span> ({value.count})</span>

            <div className={clsx('bg-purple-400 mt-1 rounded-full h-2', `w-[${percentage}%]`)} />

            {includeName && (
              <div className="flex flex-wrap mt-2">
                {value.sentInAnswers.map((sentInAnswer, index) => {
                  return (
                    <div
                      className="border text-sm font-normal shadow border-gray-100 text-gray-600 bg-yellow-50 rounded-lg px-2 py-0.5 mr-2"
                      key={index}>
                      {sentInAnswer.user.fullName}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </React.Fragment>
  );
}

type AnswersWithUserCount = {
  [nodeKey: string]: {
    count: number;
    answerNode: T_AnswerableMarkImageMarker['ViewNode'];
    sentInAnswers: any[];
  };
};

function getAnswersWithUserCount(
  activeItem: T_AnswerableMarkImageMarker['ViewNode'] | null,
  sentInAnswers: any[],
  gapNodes: T_AnswerableMarkImageMarker['ViewNode'][],
): AnswersWithUserCount {
  if (!activeItem) return {};

  const answersWithUserCount: AnswersWithUserCount = {
    notFilledIn: { count: 0, answerNode: null, sentInAnswers: [] },
  };

  for (const sentInAnswer of sentInAnswers) {
    const sentInAnswerNodeKey = sentInAnswer.value.answersByKey[activeItem.nodeKey];

    const selectedAnswer = gapNodes.find(
      (chosenAnswerNode) => chosenAnswerNode.nodeKey === sentInAnswerNodeKey,
    );

    if (!selectedAnswer) {
      answersWithUserCount.notFilledIn.count++;
      answersWithUserCount.notFilledIn.sentInAnswers.push(sentInAnswer);

      continue;
    }

    const answerWithUserCount = answersWithUserCount[selectedAnswer.nodeKey];

    if (answerWithUserCount) {
      answerWithUserCount.count++;
      answerWithUserCount.sentInAnswers = [...answerWithUserCount.sentInAnswers, sentInAnswer];
    } else {
      answersWithUserCount[selectedAnswer.nodeKey] = {
        count: 1,
        answerNode: selectedAnswer,
        sentInAnswers: [sentInAnswer],
      };
    }
  }

  if (!answersWithUserCount.notFilledIn.count) delete answersWithUserCount.notFilledIn;

  return answersWithUserCount;
}
