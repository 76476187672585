import { router } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ClassGroupsApi from '~/api/ClassGroupsApi';
import { Label } from '~/components/ui/label';
import { Switch } from '~/components/ui/switch';

import { useCourseContentShowPage } from '../T_CourseContentShowProps';

export function ProvideClassGroupAnswersToggle() {
  const { t } = useTranslation();

  const { access, book, classGroupCourseContent, teachingClassGroup, courseContent } =
    useCourseContentShowPage().props;

  if (!teachingClassGroup || access.student) return null;

  const provideAnswers = !!classGroupCourseContent?.provideAnswers;

  return (
    <>
      <div className="flex items-center space-x-2 p-2">
        <Switch
          checked={provideAnswers}
          onCheckedChange={toggleProvideAnswers}
          id="provide-answers-toggle"
          data-testid="provide-answers-toggle"
        />
        <Label className="font-normal flex flex-wrap" htmlFor="provide-answers-toggle">
          <div className="mr-1">
            {t('pages.courseContentsShow.buttons.provideAnswersToClassGroup')}
          </div>
        </Label>
      </div>
    </>
  );

  function toggleProvideAnswers(value: boolean) {
    router.post(
      ClassGroupsApi.provideAnswers.path({
        book_slug: book.slug,
        class_group_id: teachingClassGroup.id,
      }),
      {
        updates: [{ course_content_id: courseContent.id, show: value }],
      },
      {
        replace: true,
        preserveState: true,
      },
    );
  }
}
