import React from 'react';
import styled from 'styled-components';

import { T_TextMultiLine } from '~/types/node/T_TextMultiLine';
import { NodeMap } from '~/views/book/NodeMap';

type Props = {
  node: T_TextMultiLine['ViewNode'];
};

export function Content(props: Props) {
  const { node } = props;

  return (
    <StyledDiv className="text-gray-700 text-base py-3">
      <NodeMap nodes={node.children} />
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  > * {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
