import React from 'react';

import { T_WordItem } from '~/types/node/T_WordItem';

type Props = {
  node: T_WordItem['ViewNode'];
};

export function WordItem(props: Props) {
  const { node } = props;

  return (
    <tr className="flex first:pt-1">
      <td className="flex-1 p-1 pl-0">{node.term}</td>
      <td className="flex-1 p-1">{node.translation}</td>
    </tr>
  );
}
