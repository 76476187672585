import React from 'react';
import { useTranslation } from 'react-i18next';

import { T_ClassGroup } from '~/types/serializers';

type Props = {
  classGroup: T_ClassGroup;
};

export function ClassGroupInfo({ classGroup }: Props) {
  const { t } = useTranslation();

  const { name } = classGroup;

  return (
    <>
      <p>
        {t('pages.classroomsShow.text.currentlyRegistered')}{' '}
        <span className="font-semibold">{name}</span>.
      </p>

      <p className="mt-2 text-sm text-gray-600 font-light">
        {t('pages.classroomsShow.text.changeGroupHelp')}
      </p>
    </>
  );
}
