import { router } from '@inertiajs/react';
import { Link } from '@inertiajs/react';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EllipsisHorizontal } from 'styled-icons/heroicons-solid';

import DiscussionsApi from '~/api/DiscussionsApi';
import { Button } from '~/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';

export function DiscussionItem(props) {
  const { t } = useTranslation();

  const { access, discussion, courseContent } = props;

  return (
    <tr data-testid="discussion_item" key={discussion.id}>
      <td className="whitespace-nowrap  py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 w-64 max-w-[16rem] lg:w-64 lg:max-w-[20rem]">
        <div className="truncate hover:underline">
          <Link
            href={DiscussionsApi.show.path({
              course_content_slug: courseContent.slug,
              id: discussion.id,
            })}>
            {discussion.title}
          </Link>
        </div>

        <dl className="font-normal lg:hidden">
          <dt className="sr-only"> {t('models.discussion.author')}</dt>
          <dd className="mt-1 truncate text-xs text-gray-500">{discussion.user.fullName} </dd>

          {access.teacher && (
            <>
              <dt className="sr-only sm:hidden"></dt>
              <dd className="mt-1 truncate text-xs text-gray-500 sm:hidden">
                {discussion.classGroup.name} - {dayjs(discussion.createdAt).format('DD/MM/YY')}
              </dd>
            </>
          )}
          <dt className="sr-only sm:hidden">{t('pages.discussionsIndex.text.comments')}</dt>
          <dd className="mt-1 truncate text-xs text-gray-500 sm:hidden">
            {discussion.commentCount} {t('models.discussionComment.many')}
          </dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
        {discussion.user.fullName}
      </td>

      {access.teacher && (
        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
          {discussion.classGroup.name}
        </td>
      )}

      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {dayjs(discussion.createdAt).format('DD/MM/YY')}
      </td>

      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {discussion.commentCount}
      </td>
      {access.teacher && (
        <td className="z-50 whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-1">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                data-testid={`discussion_menu_trigger ${discussion.id}`}
                size="icon"
                className="w-7 h-7"
                variant="ghost">
                <EllipsisHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuGroup>
                <DropdownMenuItem
                  data-testid="remove-discussion-button"
                  onClick={() => removeDiscussion(discussion)}>
                  {t('common.buttons.remove')}
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </td>
      )}
    </tr>
  );

  function removeDiscussion(discussion) {
    if (confirm(t('pages.discussionsIndex.text.confirmDiscussionRemoval'))) {
      router.delete(
        DiscussionsApi.destroy.path({
          course_content_slug: courseContent.slug,
          id: discussion.id,
        }),
      );
    }
  }
}
