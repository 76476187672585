import { router } from '@inertiajs/react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  SortingState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import get from 'lodash/get';
import queryString from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '~/components/ui/table';
import { T_PagyMetadata } from '~/types/T_PagyMetadata';
import { handleQuerySort } from '~/utils/handleQuerySort';

import { DataTablePagination } from './data-table-pagination';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  pagyMetadata: T_PagyMetadata;
  meta?: Record<string, any>;
  testidConfig: { name: string; id: string };
}

export function DataTable<TData, TValue>({
  columns,
  data,
  pagyMetadata,
  meta,
  testidConfig,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,

    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    enableSortingRemoval: true,
    autoResetPageIndex: false,
    enableMultiSort: false,
    meta,
    pageCount: pagyMetadata.pages,
    enableSorting: true,
    state: {
      sorting: handleQuerySort(queryString),
    },
    onSortingChange: handleSort,
  });

  const { t } = useTranslation();

  return (
    <>
      <div className="border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  data-testid={
                    testidConfig ? `${testidConfig.name}_${get(row.original, testidConfig.id)}` : ''
                  }
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  {t('common.text.noItems')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination pagyMetadata={pagyMetadata} table={table} />
    </>
  );

  function handleSort(updater: Updater<SortingState>) {
    let sort_by = '';

    if (updater instanceof Function) {
      const sortBy = updater(null);

      if (sortBy.length) {
        const { id, desc } = sortBy[0];

        sort_by = `${id}_${desc ? 'DESC' : 'ASC'}`;
      }

      router.reload({
        data: {
          sort_by,
        },
      });
    }
  }
}
