import React from 'react';
import { useTranslation } from 'react-i18next';

import { useViewBookContext } from '~/views/book/hooks/useViewBookContext';

import { useSentInAnswerProps } from '../context/AnswerablePropsContext';
import { SentInNamesButton } from './SentInNamesButton';

type Props = {
  children: React.ReactNode;
};

export function SentInAnswersWrapper(props: Props) {
  const { t } = useTranslation();

  const { children } = props;

  const { teachingClassGroup } = useViewBookContext();

  const { showSentInAnswers, sentInAnswers } = useSentInAnswerProps();

  if (!showSentInAnswers) return null;

  return (
    <div className="rounded-md bg-purple-50 border mb-2 p-3 mt-2 [&_p]:text-sm [&_p]:p-0 [&_p]:m-0 [&_p]:text-gray-600">
      <div className="flex items-start justify-between mb-3">
        <h4 className="text-sm text-gray-600 font-semibold mr-2">Antwoorden: </h4>
        <SentInNamesButton />
      </div>
      {children}

      <div className="text-xs font-medium mt-1">
        {sentInAnswers.length} {t('pages.courseContentsShow.text.of')}{' '}
        {teachingClassGroup.memberCount} {t('pages.courseContentsShow.text.studentsHaveAnswered')}
      </div>
    </div>
  );
}
