import React from 'react';

export default function CookiesWarning() {
  return (
    <div className="flex w-full items-center mt-20">
      <div className="text-gray-700 font-medium space-y-2 border p-4">
        <h4 className="pb-2 font-semibold text-red-500">Opgelet!</h4>
        <p>Het lijkt erop dat cookies voor externe plugins niet toegelaten zijn in deze browser.</p>
        <p>
          Om plugins zoals Brightbook (en bv. Panopto) te kunnen gebruiken, dien je in de settings
          de cookie-instellingen aan te passen.
        </p>

        <p>
          Dit is gelukkig erg gemakkelijk te doen en{' '}
          <a
            className="underline text-cyan-500"
            href="https://support.panopto.com/s/article/How-to-Enable-Third-Party-Cookies-in-Supported-Browsers">
            Panopto heeft hier een goede gids voor geschreven.
          </a>
        </p>

        <p>
          Indien u nog steeds moeilijkheden heeft met het gebruik van Brightbook binnen Canvas,
          contacteer dan onze support via{' '}
          <a className="underline text-cyan-500" href="mailto:info@brightbook.io">
            info@brightbook.io
          </a>
          .
        </p>
      </div>
    </div>
  );
}

CookiesWarning.layout = (page) => page;
