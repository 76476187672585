import React from 'react';

import { LayoutCourseContent } from '~/components/ui-extended/layout-course-content';

import { PageContent } from './components/page-content';
import { useDiscussionsShowPage } from './T_DiscussionsNewProps';

export default function Show() {
  const { access, book, parts, courseContent, studentClassGroup } = useDiscussionsShowPage().props;

  return (
    <LayoutCourseContent
      access={access}
      book={book}
      breadcrumbs={[
        {
          type: 'static',
          title: 'models.discussion.one',
          translateTitle: true,
        },
      ]}
      courseContent={courseContent}
      htmlHeadTitle={courseContent.courseContentable.title}
      parts={parts}
      sidebarTitle={courseContent.courseContentable.title}
      studentClassGroup={studentClassGroup}>
      <PageContent />
    </LayoutCourseContent>
  );
}
