import { Button } from '~/components/ui/button';
import { cn } from '~/utils';

type Props = {
  activePage: string;
  pages: Record<string, { component: (props) => JSX.Element; title: string }>;
  setActivePage: (page: string) => void;
};

export function SideBar(props: Props) {
  const { pages, activePage, setActivePage } = props;

  return (
    <nav className={cn('flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1')}>
      {Object.entries(pages).map(([key, { title }]) => {
        return (
          <Button
            key={key}
            data-testid={`book-edit-${key}-tab-button`}
            onClick={() => setActivePage(key)}
            variant="ghost"
            className={cn(
              activePage === key
                ? 'bg-muted hover:bg-muted'
                : 'hover:bg-transparent hover:underline',
              'justify-start',
            )}>
            {title}
          </Button>
        );
      })}
    </nav>
  );
}
