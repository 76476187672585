import React from 'react';
import { useTranslation } from 'react-i18next';

import { nodeIsEmpty } from '~/lib/node-renderer/nodeHelpers';

import { NodeMap } from '../../NodeMap';
import { useExerciseProps } from './context/ExercisePropsContext';

export function Hint() {
  const { t } = useTranslation();

  const { node, showHint } = useExerciseProps();

  if (!showHint || nodeIsEmpty(node.hint)) return null;

  return (
    <div
      data-testid="hint"
      className="rounded-md bg-yellow-50 border mb-2 p-3 mt-2 [&_p]:text-sm [&_p]:p-0 [&_p]:text-gray-600">
      <span className="text-sm text-gray-600 font-semibold">
        {t('common.nodes.exerciseHint')}:{' '}
      </span>
      <NodeMap nodes={node.hint.children} />
    </div>
  );
}
