import React from 'react';

import { T_AnswerableFillListItemKeyText } from '~/types/node/exercise/answerables/fill-list/T_AnswerableFillListItemKeyText';

type Props = {
  itemKey: T_AnswerableFillListItemKeyText['ViewNode'];
};

export function KeyText(props: Props) {
  const { itemKey } = props;

  return <p className="text-[11pt] m-0 text-[#374151]">{itemKey.text}</p>;
}
