import React from 'react';

import { cn } from '~/utils';

import { AspectRatio } from '../ui/aspect-ratio';

type Props = {
  url: string;
  className?: string;
};

export function AspectRatioIframe(props: Props) {
  const { className, url } = props;

  return (
    <AspectRatio ratio={16 / 9} className={cn('relative w-full', className)}>
      <iframe className={'absolute top-0 left-0 right-0 bottom-0 h-full w-full'} src={url} />
    </AspectRatio>
  );
}
