import { router } from '@inertiajs/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Emoji, EmojiSad, EmojiSadSlight } from 'styled-icons/fluentui-system-regular';

import StudentSelfAssessmentsApi from '~/api/StudentSelfAssessmentsApi';

import { useViewBookContext } from '../hooks/useViewBookContext';

export function StudentSelfAssessment() {
  const { t } = useTranslation();

  const { courseContent, studentSelfAssessment } = useViewBookContext();

  return (
    <div className="flex flex-col items-center p-3" data-testid="student-self-assessment-options">
      <h5 className="font-medium text-gray-700">
        {t('pages.courseContentsShow.text.doIKnowThisContent')}
      </h5>

      <div className="flex mt-3 space-x-4">
        <button
          data-checked={(studentSelfAssessment?.understanding === 'bad').toString()}
          data-testid="student-self-assessment-bad-button"
          onClick={() => submitStudentSelfAssessment('bad')}>
          <EmojiSad
            className={`h-8 w-8  ${
              studentSelfAssessment?.understanding === 'bad'
                ? 'text-red-500'
                : 'text-gray-700 hover:text-gray-500'
            }`}
          />
        </button>
        <button
          data-checked={(studentSelfAssessment?.understanding === 'medium').toString()}
          data-testid="student-self-assessment-medium-button"
          onClick={() => submitStudentSelfAssessment('medium')}>
          <EmojiSadSlight
            className={`h-8 w-8  ${
              studentSelfAssessment?.understanding === 'medium'
                ? 'text-yellow-500'
                : 'text-gray-700 hover:text-gray-500'
            }`}
          />
        </button>
        <button
          data-checked={(studentSelfAssessment?.understanding === 'good').toString()}
          data-testid="student-self-assessment-good-button"
          onClick={() => submitStudentSelfAssessment('good')}>
          <Emoji
            className={`h-8 w-8  ${
              studentSelfAssessment?.understanding === 'good'
                ? 'text-green-500'
                : 'text-gray-700 hover:text-gray-500'
            }`}
          />
        </button>
      </div>
    </div>
  );

  function submitStudentSelfAssessment(understanding) {
    if (studentSelfAssessment && studentSelfAssessment.id) {
      router.put(
        StudentSelfAssessmentsApi.update.path({
          course_content_slug: courseContent.slug,
          id: studentSelfAssessment.id,
        }),
        { understanding },
      );
    } else {
      router.post(
        StudentSelfAssessmentsApi.create.path({ course_content_slug: courseContent.slug }),
        {
          understanding,
        },
      );
    }
  }
}
