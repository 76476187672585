import React, { useMemo, useState } from 'react';

import { T_AnswerableFillGaps } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGaps';
import { T_AnswerableFillGapsGap } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGapsGap';
import { T_NodeType } from '~/types/T_NodeTypes';

import { useRemake } from '../../hooks/useRemake';
import { AnswerableControls } from '../../shared/AnswerableControls';
import { forEachNode } from './helpers/gapIterators';
import { ParagraphList } from './ParagraphList';
import { SentInAnswers } from './SentInAnswers';

type Props = {
  answerable: T_AnswerableFillGaps['ViewNode'];
  subExercise?: boolean;
};

export function FillGaps(props: Props) {
  const { answerable, subExercise } = props;

  const remakeOptions = useRemake(answerable);

  const [activeGap, setActiveGap] = useState<T_AnswerableFillGapsGap['ViewNode'] | null>(null);

  const itemCount = useMemo(() => countGaps(), []);

  return (
    <>
      <ParagraphList
        {...remakeOptions}
        activeGap={activeGap}
        answerable={answerable}
        itemCount={itemCount}
        setActiveGap={setActiveGap}
      />

      <AnswerableControls<T_AnswerableFillGaps['ViewNode']>
        {...remakeOptions}
        subExercise={!!subExercise}
        answerable={answerable}
        sentInAnswersRender={(props) => <SentInAnswers {...props} activeGap={activeGap} />}
      />
    </>
  );

  function countGaps() {
    let gapNumber = 0;

    forEachNode(answerable, T_NodeType.FILL_GAPS_GAP, () => gapNumber++);

    return gapNumber;
  }
}
