import React from 'react';

import BusinessApi from '~/api/Website/BusinessApi';
import OrganizationApi from '~/api/Website/OrganizationApi';

import { NavBar } from './nav-bar';

const navLinks = [
  { i18nKey: 'home', href: '/' },
  { i18nKey: 'pricing', href: BusinessApi.pricing.path() },
  { i18nKey: 'contact', href: BusinessApi.contact.path() },
  { i18nKey: 'tutorials', href: OrganizationApi.tutorials.path() },
  { i18nKey: 'trainings', href: BusinessApi.trainings.path() },
];

export function HeaderBusiness() {
  return <NavBar subtitle="for Business" navLinks={navLinks} />;
}
