import React from 'react';

import { T_HeadingOne } from '~/types/node/T_HeadingOne';
import { NodeMap } from '~/views/print/NodeMap';

import { Gutter } from '../../components/Gutter';

type Props = {
  node: T_HeadingOne['ViewNode'];
};

export function HeadingOne(props: Props) {
  const { node } = props;

  return (
    <div className="breakpoint" data-break-unless-height="65mm">
      <h3 data-testid={node.type} className="text-lg font-bold mb-0.5">
        <Gutter content={node.number} />
        <NodeMap nodes={node.children} />
      </h3>
    </div>
  );
}
