import React, { Children, HTMLAttributes } from 'react';

import { cn } from '~/utils';

type Props = HTMLAttributes<HTMLUListElement> & {
  children: React.ReactNode;
};

export function SidebarMenuWrapper(props: Props) {
  const { children, className, ...listProps } = props;

  return (
    <ul role="list" className={cn('space-y-1', className)} {...listProps}>
      {Children.map(children, (child, index) => (
        <li key={index}>{child}</li>
      ))}
    </ul>
  );
}
