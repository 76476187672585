import { router } from '@inertiajs/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import BroadcastClassGroupsApi from '~/api/BroadcastClassGroupsApi';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '~/components/ui/select';
import { useCourseContentShowPage } from '~/pages/course-contents/show/T_CourseContentShowProps';

export function ClassGroupSelect() {
  const { t } = useTranslation();

  const { access, book, focus, teachingClassGroups, teachingClassGroup } =
    useCourseContentShowPage().props;

  const [loading, setLoading] = useState(false);

  if (access.student || !!focus) return null;

  return (
    <Select
      value={teachingClassGroup?.id?.toString()}
      onValueChange={handleClassGroupSelect}
      disabled={loading}
      data-testid="class-group-select"
      name="class_group">
      <SelectTrigger data-testid="class-group-select" className="w-[180px] bg-white">
        <SelectValue
          placeholder={t(
            'pages.courseContentsShow.forms.selectClassGroup.placeholder.selectClassGroup',
          )}
        />
      </SelectTrigger>

      <SelectContent>
        <SelectGroup>
          <SelectLabel>{t('models.classGroup.many')}</SelectLabel>
          {teachingClassGroups.map((classGroup) => (
            <SelectItem
              data-testid={`class-group-select-item-${classGroup.id}`}
              key={classGroup.id}
              value={classGroup.id.toString()}>
              {classGroup.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );

  function handleClassGroupSelect(class_group_id: string) {
    setLoading(true);

    router.patch(
      BroadcastClassGroupsApi.update.path({ book_slug: book.slug }),
      { class_group_id },
      {
        onFinish: () => setLoading(false),
        preserveScroll: true,
        preserveState: true,
        replace: true,
      },
    );
  }
}
