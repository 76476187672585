import React from 'react';

import { T_TextMultiLine } from '~/types/node/T_TextMultiLine';
import { NodeMap } from '~/views/print/NodeMap';

type Props = {
  node: T_TextMultiLine['ViewNode'];
};

export function Question(props: Props) {
  const { node } = props;

  return (
    <div className="breakpoint" data-break-unless-height="25mm">
      <NodeMap nodes={node.children} />
    </div>
  );
}
