import React from 'react';

import BooksApi from '~/api/BooksApi';
import { T_Access, T_Book } from '~/types/serializers';
import { T_Breadcrumb } from '~/types/T_Breadcrumb';
import T_OrganizationKind from '~/types/T_OrganizationKind';

import { Layout } from './layout';
import { SidebarBookMenu } from './sidebar-book-menu';

type Props = {
  access: T_Access;
  book: T_Book;
  breadcrumbs: T_Breadcrumb[];
  children: React.ReactNode;
  htmlHeadMeta?: Record<string, string>;
  htmlHeadTitle: string;
  sidebarTitle: string;
  tour?: {
    steps: any[];
  };
};

export function LayoutBook(props: Props) {
  const { access, book, breadcrumbs, children, htmlHeadTitle, tour, htmlHeadMeta, sidebarTitle } =
    props;

  return (
    <Layout
      language={book.language}
      tour={access.student ? undefined : tour}
      htmlHeadMeta={htmlHeadMeta}
      htmlHeadTitle={htmlHeadTitle}
      sidebarChildren={<SidebarBookMenu access={access} book={book} />}
      sidebarTitle={sidebarTitle}
      breadcrumbs={[
        {
          title: book.title,
          type: 'link',
          href: BooksApi.show.path({ slug: book.slug }),
        },

        ...breadcrumbs,
      ]}>
      {children}
    </Layout>
  );
}
