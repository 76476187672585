import { Crosshair2Icon, DrawingPinIcon, RulerHorizontalIcon } from '@radix-ui/react-icons';
import { IconProps } from '@radix-ui/react-icons/dist/types';
import { StyledIcon } from '@styled-icons/styled-icon';
import { ForwardRefExoticComponent, RefAttributes } from 'react';
import {
  AcademicCap,
  Beaker,
  Bell,
  Bolt,
  Bookmark,
  BookOpen,
  BuildingLibrary,
  Cake,
  Calculator,
  Camera,
  ChatBubbleBottomCenterText,
  ChatBubbleLeft,
  ChatBubbleLeftRight,
  Clipboard,
  Clock,
  Cube,
  Document,
  Envelope,
  ExclamationTriangle,
  Eye,
  FaceFrown,
  FaceSmile,
  Film,
  Fire,
  Gift,
  GlobeAlt,
  HandRaised,
  Heart,
  Key,
  Megaphone,
  PaperAirplane,
  Pencil,
  PuzzlePiece,
  RocketLaunch,
  Sparkles,
  Star,
  Sun,
  User,
  UserGroup,
  Users,
  Variable,
} from 'styled-icons/heroicons-outline';

import T_TemplateIcon from '~/types/T_TemplateIcon';

export const templateIconMap: Record<
  T_TemplateIcon,
  ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>> | StyledIcon
> = {
  bell: Bell,
  bookmark: Bookmark,
  camera: Camera,
  chatBubble: ChatBubbleLeft,
  crosshair: Crosshair2Icon,
  clipboard: Clipboard,
  envelopeClosed: Envelope,
  eyeOpen: Eye,
  file: Document,
  globe: GlobeAlt,
  hand: HandRaised,
  heart: Heart,
  lightning: Bolt,
  paperPlane: PaperAirplane,
  pencil: Pencil,
  person: User,
  pin: DrawingPinIcon,
  rocket: RocketLaunch,
  ruler: RulerHorizontalIcon,
  star: Star,
  stopwatch: Clock,
  sun: Sun,
  warning: ExclamationTriangle,
  academicCap: AcademicCap,
  conversation: ChatBubbleLeftRight,
  beaker: Beaker,
  bookOpen: BookOpen,
  cake: Cake,
  calculator: Calculator,
  library: BuildingLibrary,
  cube: Cube,
  smile: FaceSmile,
  film: Film,
  frown: FaceFrown,
  fire: Fire,
  gift: Gift,
  key: Key,
  megaphone: Megaphone,
  puzzlePiece: PuzzlePiece,
  sparkle: Sparkles,
  users: Users,
  variable: Variable,
  chatFilled: ChatBubbleBottomCenterText,
  group: UserGroup,
};
