import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from 'styled-icons/bootstrap';

import { Button } from '~/components/ui/button';

type Props = {
  onClick: () => void;
  disabled: boolean;
};

export function ShowOneAnswerButton(props: Props) {
  const { t } = useTranslation();

  const { disabled, onClick } = props;

  return (
    <Button
      variant="outline"
      data-testid="show-1-answer-button"
      disabled={disabled}
      onClick={onClick}
      size="sm">
      <Check className="mr-1 h-4 w-4" />
      {t('pages.courseContentsShow.buttons.showOneAnswer')}
    </Button>
  );
}
