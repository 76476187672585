import React, { useMemo, useState } from 'react';

import { T_AnswerableMarkOption } from '~/types/node/exercise/answerables/mark-option/T_AnswerableMarkOption';
import { T_NodeType } from '~/types/T_NodeTypes';

import { useRemake } from '../../hooks/useRemake';
import { AnswerableControls } from '../../shared/AnswerableControls';
import { forEachNode } from '../fill-gaps/helpers/gapIterators';
import { ParagraphList } from './ParagraphList';
import { SentInAnswers } from './SentInAnswers';

type Props = {
  answerable: T_AnswerableMarkOption['ViewNode'];
  subExercise?: boolean;
};

export function MarkOption(props: Props) {
  const { answerable, subExercise } = props;

  const remakeOptions = useRemake(answerable);

  const [activeGap, setActiveGap] = useState(null);

  const itemCount = useMemo(() => countGaps(), []);

  return (
    <>
      <ParagraphList
        {...remakeOptions}
        activeGap={activeGap}
        answerable={answerable}
        itemCount={itemCount}
        setActiveGap={setActiveGap}
      />

      <AnswerableControls<T_AnswerableMarkOption['ViewNode']>
        {...remakeOptions}
        subExercise={!!subExercise}
        answerable={answerable}
        sentInAnswersRender={(props) => <SentInAnswers {...props} activeGap={activeGap} />}
      />
    </>
  );

  function countGaps() {
    let gapNumber = 0;

    forEachNode(answerable, T_NodeType.MARK_OPTION_GAP, () => gapNumber++);

    return gapNumber;
  }
}
