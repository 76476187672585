import React from 'react';

import { T_WordItem } from '~/types/node/T_WordItem';

type Props = {
  index: number;
  node: T_WordItem['ViewNode'];
};

export function WordItem(props: Props) {
  const { index, node } = props;

  return (
    <tr data-testid={`word_item ${index}`} className="flex first:pt-1">
      <td data-testid="word_item_term" className="flex-1 p-1 pl-0">
        {node.term}
      </td>
      <td data-testid="word_item_translation" className="flex-1 p-1">
        {node.translation}
      </td>
    </tr>
  );
}
