import { zodResolver } from '@hookform/resolvers/zod';
import { router } from '@inertiajs/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import BooksApi from '~/api/BooksApi';
import { Button } from '~/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form';
import { Input } from '~/components/ui/input';
import { RadioGroup, RadioGroupItem } from '~/components/ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/components/ui/select';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';
import { T_Book } from '~/types/serializers';
import { cn } from '~/utils';

const MAX_UPLOAD_SIZE = 1024 * 1024 * 7; // 7MB

const formSchema = z.object({
  title: z.string().min(5, { message: 'Vul een geldige titel in.' }),
  cover: z
    .any()
    .optional()
    .refine((file) => !file || file?.size < MAX_UPLOAD_SIZE, {
      params: { i18n: 'customErrors.maxFileSize7' },
    }),
  language: z.string(),
  structure: z.string(),
});

type Props = {
  book: T_Book;
  rootPartCount: number;
};

export function PageGeneral(props: Props) {
  const { t } = useTranslation();

  const { book, rootPartCount } = props;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: book.title,
      cover: null,
      language: book.language,
      structure: book.structure,
    },
  });

  useEffect(() => {
    const coverFile = form.getValues('cover');

    if (coverFile && !form.formState.errors.cover) {
      setImagePreview(URL.createObjectURL(coverFile));
    }
  }, [form.getValues('cover')]);

  const [imagePreview, setImagePreview] = useState<string | null>(book.coverUrl);

  return (
    <div className="space-y-6 mb-10">
      <div className="border-b pb-6 mb-4">
        <h3 className="text-lg font-medium">{t('pages.booksEdit.text.pageGeneralTitle')}</h3>
        <p className="text-sm text-muted-foreground">
          {t('pages.booksEdit.text.pageGeneralDescription')}
        </p>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 lg:max-w-2xl">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('pages.booksEdit.forms.general.labels.title')}</FormLabel>
                <FormControl>
                  <Input placeholder="The Middle Ages" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="language"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('pages.booksEdit.forms.general.labels.language')}</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="en">English</SelectItem>
                    <SelectItem value="nl">Nederlands</SelectItem>
                    <SelectItem value="fr">Français</SelectItem>
                  </SelectContent>
                </Select>
                <FormDescription>
                  {t('pages.booksEdit.forms.general.descriptions.language')}
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="structure"
            render={({ field }) => (
              <FormItem className="space-y-1">
                <FormLabel>{t('pages.booksEdit.forms.general.labels.structure')}</FormLabel>
                <FormDescription>
                  {t('pages.booksEdit.forms.general.descriptions.structure')}
                </FormDescription>
                <FormMessage />
                <RadioGroup
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="grid max-w-md grid-cols-2 gap-8 pt-2">
                  <FormItem>
                    <FormLabel
                      data-testid="button_structure_part_chapter_section"
                      className="[&:has([data-state=checked])>div]:border-primary">
                      <FormControl>
                        <RadioGroupItem
                          data-testid="structure_part_chapter_section"
                          value="part_chapter_section"
                          className="sr-only"
                        />
                      </FormControl>
                      <div className="items-center rounded-md border-2 border-muted p-1 hover:border-accent">
                        <div className="space-y-2 text-xs rounded-sm h-[7.5rem] bg-[#ecedef] p-2">
                          <div className="space-y-2 text-stone-700 rounded-md bg-white p-2 shadow-sm">
                            <div className="bg-stone-200 p-1 px-2 rounded">
                              {t('pages.booksEdit.forms.general.descriptions.structurePart')}
                            </div>
                            <div className="ml-4 bg-stone-200 p-1 px-2 rounded">
                              {t('pages.booksEdit.forms.general.descriptions.structureChapter')}
                            </div>
                            <div className="ml-8 bg-stone-200 p-1 px-2 rounded">
                              {t('pages.booksEdit.forms.general.descriptions.structureSection')}
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="block w-full p-2 text-center font-normal">
                        {t('pages.booksEdit.forms.general.buttons.structureThreeLevels')}
                      </span>
                    </FormLabel>
                  </FormItem>
                  <FormItem>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <FormLabel
                            data-testid="button_structure_chapter_section"
                            className={cn('[&:has([data-state=checked])>div]:border-primary', {
                              'opacity-50': rootPartCount > 1,
                            })}>
                            <FormControl>
                              <RadioGroupItem
                                data-testid="structure_chapter_section"
                                disabled={rootPartCount > 1}
                                value="chapter_section"
                                className="sr-only"
                              />
                            </FormControl>

                            <div className="items-center rounded-md border-2 border-muted p-1 hover:border-accent">
                              <div className="space-y-2 rounded-sm h-[7.5rem] bg-[#ecedef] p-2">
                                <div className="space-y-2 text-xs text-stone-700 rounded-md bg-white p-2 shadow-sm">
                                  <div className="bg-stone-200 p-1 px-2 rounded">
                                    {t(
                                      'pages.booksEdit.forms.general.descriptions.structureChapter',
                                    )}
                                  </div>
                                  <div className="ml-4 bg-stone-200 p-1 px-2 rounded">
                                    {t(
                                      'pages.booksEdit.forms.general.descriptions.structureSection',
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <span className="block w-full p-2 text-center font-normal">
                              {t('pages.booksEdit.forms.general.buttons.structureTwoLevels')}{' '}
                            </span>
                          </FormLabel>
                        </TooltipTrigger>
                        {rootPartCount > 1 && (
                          <TooltipContent>
                            {t(
                              'pages.booksEdit.forms.general.descriptions.structureTwoLevelsWarning',
                            )}
                          </TooltipContent>
                        )}
                      </Tooltip>
                    </TooltipProvider>
                  </FormItem>
                </RadioGroup>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="cover"
            render={({ field: { value, ...field } }) => (
              <FormItem>
                <FormLabel>Cover</FormLabel>
                <FormControl className="w-64">
                  <Input
                    {...field}
                    onChange={handleImageSelection}
                    value={value?.fileName}
                    accept=".tif,.tiff,.jpg,.jpeg,.png"
                    type="file"
                  />
                </FormControl>
                <FormMessage />
                <FormDescription>
                  <p className="font-medium">
                    {t('pages.booksEdit.forms.general.descriptions.coverRatio')}
                  </p>
                  <p>{t('pages.booksEdit.forms.general.descriptions.coverMaxSize')}</p>
                  <p>{t('pages.booksEdit.forms.general.descriptions.coverExtensions')}</p>
                </FormDescription>

                {imagePreview && (
                  <div>
                    <img src={imagePreview} className="w-40 h-56" />
                  </div>
                )}
              </FormItem>
            )}
          />

          <Button type="submit">{t('common.buttons.save')}</Button>
        </form>
      </Form>
    </div>
  );

  function handleImageSelection(event: React.ChangeEvent<HTMLInputElement>) {
    form.setValue('cover', event.target.files[0], { shouldValidate: true, shouldTouch: true });
  }

  function onSubmit(values: z.infer<typeof formSchema>) {
    router.put(BooksApi.update.path({ slug: book.slug }), values, {
      replace: true,
    });
  }
}
