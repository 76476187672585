import React from 'react';

import { T_AnswerableMarkImage } from '~/types/node/exercise/answerables/mark-image/T_AnswerableMarkImage';

import { MarkerList } from './MarkerList';
import { Source } from './Source';

type Props = {
  answerable: T_AnswerableMarkImage['ViewNode'];
};

export function MarkImage(props: Props) {
  const { answerable } = props;

  return (
    <>
      <Source markers={answerable.markers} src={answerable.source.print_src} />
      <MarkerList answerable={answerable} markers={answerable.markers} />
    </>
  );
}
