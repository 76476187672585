import React from 'react';

import { HightlightContextProvider } from '~/hooks/useHighlightContext';
import { T_Media } from '~/types/node/T_Media';

import { Element } from '../../components/Element';
import { Source } from './Source';
import { Title } from './Title';

type Props = {
  node: T_Media['ViewNode'];
};

export function Media(props: Props) {
  const { node } = props;

  return (
    <Element node={node} className="relative p-0" title="Media">
      <HightlightContextProvider showHighlight={false}>
        <Source node={node} />
        <Title node={node.title} />
      </HightlightContextProvider>
    </Element>
  );
}
