import clsx from 'clsx';
import React, { useMemo } from 'react';

import { T_AnswerableMultipleChoice } from '~/types/node/exercise/answerables/multiple-choice/T_AnswerableMultipleChoice';
import { T_AnswerableMultipleChoiceItem } from '~/types/node/exercise/answerables/multiple-choice/T_AnswerableMultipleChoiceItem';

import { useSentInAnswerProps } from '../../context/AnswerablePropsContext';
import { SentInAnswersWrapper } from '../../shared/SentInAnswersWrapper';

type Props = {
  answerable: T_AnswerableMultipleChoice['ViewNode'];
};

export function SentInAnswers(props: Props) {
  const { answerable } = props;

  const { sentInAnswers, includeName } = useSentInAnswerProps();

  const optionsWithSentInAnswers = useMemo(() => linkOptionsWithSentInAnswers(), [sentInAnswers]);

  return (
    <SentInAnswersWrapper>
      {optionsWithSentInAnswers.map(({ option, sentInAnswersForOption }) => {
        const percentage = ((sentInAnswersForOption.length / sentInAnswers.length) * 100).toFixed(
          2,
        );

        return (
          <div className="mb-4 p-3 bg-white rounded-md border" key={option.nodeKey}>
            <div className="font-medium text-gray-600 mb-2 flex">
              {option.text}

              <span className="pl-0.5"> ({sentInAnswersForOption.length})</span>
            </div>

            <div className="bg-gray-200 mt-1 rounded-full relative h-2">
              <div
                className={clsx('absolute bg-purple-400 rounded-full h-2')}
                style={{ width: `${percentage}%` }}
              />
            </div>

            {includeName && (
              <div className="flex flex-wrap mt-2">
                {sentInAnswersForOption.map((sentInAnswer, index) => {
                  return (
                    <div
                      className="border shadow text-sm border-gray-100 text-gray-600 bg-yellow-50 rounded-lg px-2 py-0.5 mr-2"
                      key={index}>
                      {sentInAnswer.user.fullName}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </SentInAnswersWrapper>
  );

  function linkOptionsWithSentInAnswers() {
    const optionsWithSentInAnswers: Array<{
      option: T_AnswerableMultipleChoiceItem['ViewNode'];
      sentInAnswersForOption: any[];
    }> = [];

    if (!sentInAnswers) return optionsWithSentInAnswers;

    for (const option of answerable.options) {
      const sentInAnswersForOption = sentInAnswers.filter((classAnswer) =>
        classAnswer.value?.checkedAnswers?.includes(option.nodeKey),
      );

      optionsWithSentInAnswers.push({
        option,
        sentInAnswersForOption,
      });
    }

    return optionsWithSentInAnswers;
  }
}
