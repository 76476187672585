import React from 'react';

import { T_AnswerableFillGapsParagraph } from '~/types/node/exercise/answerables/fill-gaps/T_AnswerableFillGapsParagraph';

import { Gap } from './Gap';

type Props = {
  node: T_AnswerableFillGapsParagraph['ViewNode'];
};

export function ParagraphWithGaps(props: Props) {
  const { node } = props;

  const { children } = node;

  return (
    <p className="text-gray-700 text-sm leading-5 mb-2">
      {children.map((child, index) => {
        if (!child.type) return <span key={index}>{child.text}</span>;

        return <Gap key={index} node={child} />;
      })}
    </p>
  );
}
