import React, { useMemo } from 'react';

import { useSentInAnswerProps } from '../../context/AnswerablePropsContext';
import { SentInAnswersWrapper } from '../../shared/SentInAnswersWrapper';

export function SentInAnswers() {
  const { sentInAnswers, includeName } = useSentInAnswerProps();

  const answers = useMemo(() => formatAnswers(sentInAnswers), [sentInAnswers]);

  return (
    <SentInAnswersWrapper>
      <div className="columns-3">{renderClassAnswers(answers)}</div>
    </SentInAnswersWrapper>
  );

  function renderClassAnswers(answerData) {
    return answerData.map((answer, index) => {
      return (
        <div className="inline-block w-full mb-2" key={index}>
          {includeName && (
            <div className="text-gray-700 font-semibold px-1 pb-1 text-sm">
              {answer.user.fullName}:
            </div>
          )}
          <div className="border-gray-100 text-sm bg-yellow-50 shadow flex-col flex-1 rounded-lg">
            <div className="p-4">{answer.value}</div>
          </div>
        </div>
      );
    });
  }

  function formatAnswers(rawAnswerData) {
    const answers = (rawAnswerData || []).map(({ value, user }) => {
      return { value: value.openAnswer, user };
    });

    return answers;
  }
}
