import { DragOverlay as DndDragOverlay } from '@dnd-kit/core';

type Props = {
  draggedItem: any;
};

export function DndOverlay(props: Props) {
  if (!props.draggedItem) return null;

  return (
    <DndDragOverlay>
      <DragOverlayComponent item={props.draggedItem} />
    </DndDragOverlay>
  );
}

function DragOverlayComponent({ item }) {
  return (
    <div>
      <div className="flex flex-1 items-center py-2.5 pr-2 bg-stone-50 border">
        <div className="flex flex-1 justify-between items-center mr-2">
          <div className="flex items-center">
            <p className="text-xs text-black font-light pr-2 border-r mr-2 ml-4">
              {typeTitleMap[item.type]} {item.number}
            </p>
            <h4 className="flex items-center font-medium text-sm text-gray-700">{item.title}</h4>
          </div>
        </div>
      </div>
      {item.type !== 'section' && (
        <>
          <div className="my-2 ml-8 h-9 bg-stone-100" />
          <div className="my-2 ml-8 h-9 from-stone-100 bg-gradient-to-b" />
        </>
      )}
    </div>
  );
}

const typeTitleMap = {
  nestedPart: 'Hoofdstuk',
  part: 'Deel',
  section: 'Sectie',
};
