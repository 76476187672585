import React from 'react';

export function Gutter(props) {
  const { icon } = props;

  if (icon) {
    return (
      <div className="absolute -left-8 w-6 h-6 rounded border border-gray-500 flex justify-center items-center text-black">
        {icon}
      </div>
    );
  }

  return <span className="absolute -left-32 w-24 text-right">{props.content}</span>;
}
