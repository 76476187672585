import React from 'react';

import { useCourseContentShowPage } from '~/pages/course-contents/show/T_CourseContentShowProps';

import { DynamicQuestionAnswerButton } from './dynamic-question-answer-button';
import { DynamicQuestionAskButton } from './dynamic-question-ask-button';

export function DynamicQuestionButton() {
  const { access, focus } = useCourseContentShowPage().props;

  if (focus) return null;

  return <>{access.student ? <DynamicQuestionAnswerButton /> : <DynamicQuestionAskButton />}</>;
}
