import React from 'react';

import { T_SingleLineNode } from '~/types/node/T_SingleLineNode';

import { NodeMap } from '../../NodeMap';

type Props = {
  node: T_SingleLineNode['ViewNode'];
};

export function SingleLineNode(props: Props) {
  const { node } = props;

  return <NodeMap nodes={node.children} />;
}
