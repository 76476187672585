import React from 'react';
import { useTranslation } from 'react-i18next';
import { RectangleStack } from 'styled-icons/heroicons-outline';

type Props = {
  shuffleFlashcards: () => void;
};

export default function ShuffleButton(props: Props) {
  const { t } = useTranslation();

  const { shuffleFlashcards } = props;

  return (
    <button
      className="text-gray-600 font-semibold py-1 my-1 text-sm rounded inline-flex items-center"
      onClick={shuffleFlashcards}
      type="button">
      <RectangleStack className="h-4 w-4 mr-1" />
      <span>{t('pages.partsFlashcards.buttons.shuffle')}</span>
    </button>
  );
}
