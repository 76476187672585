import React from 'react';

import { T_AnswerableSubExerciseList } from '~/types/node/exercise/answerables/sub-exercise-list/T_AnswerableSubExerciseList';

import { SubExerciseItem } from './SubExerciseItem';

type Props = {
  answerable: T_AnswerableSubExerciseList['ViewNode'];
};

export function SubExerciseList(props: Props) {
  const { answerable } = props;

  return (
    <>
      {answerable.items.map((subExercise, index) => (
        <SubExerciseItem key={index} subExercise={subExercise} />
      ))}
    </>
  );
}
