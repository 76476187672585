import { DialogTrigger } from '@radix-ui/react-dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogContent, DialogHeader, DialogTitle } from '~/components/ui/dialog';
import { T_Book, T_ContainerTemplate } from '~/types/serializers';

import { UpdateContainerForm } from './update-container-form';

type Props = {
  book: T_Book;
  containerTemplate: T_ContainerTemplate;
};

export function UpdateContainerDialog(props: Props) {
  const { t } = useTranslation();

  const { book, containerTemplate } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <button data-testid="edit-container-template-button">{t('common.buttons.edit')}</button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t('pages.booksEdit.dialogs.editContainerTemplate.title', {
              templateName: containerTemplate.name,
            })}
          </DialogTitle>
        </DialogHeader>

        <UpdateContainerForm
          containerTemplate={containerTemplate}
          closeDialog={() => setOpen(false)}
          book={book}
        />
      </DialogContent>
    </Dialog>
  );
}
