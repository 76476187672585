import { Link, router, usePage } from '@inertiajs/react';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlass } from 'styled-icons/heroicons-outline';

import BooksApi from '~/api/BooksApi';
import CourseContentsApi from '~/api/CourseContentsApi';
import { DataTableContainer } from '~/components/ui-extended/data-table-container';

export function PageContent() {
  const { t } = useTranslation();

  const { book, results } = usePage<any>().props;

  const searchQuery = (queryString.parse(location.search)['query'] as string) || '';

  const [searchString, setSearchString] = useState(searchQuery);

  return (
    <DataTableContainer title="pages.booksSearch.title" translateTitle>
      <div>
        <label htmlFor="search_books" className="block text-sm font-medium text-gray-700">
          {t('pages.booksSearch.forms.search.labels.searchInBook', { bookTitle: book.title })}
        </label>
        <form onSubmit={handleSearch}>
          <div className="flex mt-1 rounded-md shadow-sm">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <input
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                type="text"
                name="search_books"
                id="search_books"
                className="block w-full border-gray-300 rounded-none focus:ring-indigo-500 focus:border-indigo-500 rounded-l-md sm:text-sm"
                placeholder={t('pages.booksSearch.forms.search.placeholders.searchInput')}
              />
            </div>
            <button
              type="submit"
              className="relative inline-flex items-center px-3 py-2 -ml-px text-sm font-medium text-gray-700 border border-gray-300 space-x-1 rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
              <MagnifyingGlass className="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span>{t('common.buttons.search')}</span>
            </button>
          </div>
        </form>
      </div>

      {searchQuery && (
        <div className="mt-4">
          <div className="flex items-end mb-4">
            <h3 className="text-sm font-medium text-gray-700">
              {t('pages.booksSearch.text.searchResultsFor')}{' '}
              <span className="italic">&rsquo;{searchQuery}&rsquo;</span>.
            </h3>
            <Link
              className="items-center inline-block ml-1 rounded hover:underline"
              href={BooksApi.search.path({ slug: book.slug })}>
              <h3 className="text-sm font-normal text-gray-500">
                {t('pages.booksSearch.forms.search.buttons.cancelSearch')}
              </h3>
            </Link>
          </div>

          <div>
            {!results.length ? (
              <p>{t('pages.booksSearch.text.noResults')}</p>
            ) : (
              <ul className="space-y-2">
                {results.map((result, index) => (
                  <li key={index}>
                    <button onClick={() => navigateWithQuery(result.slug)}>
                      <h4 className="font-medium text-gray-700">{result.numberedTitle}</h4>
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </DataTableContainer>
  );

  function navigateWithQuery(slug: string) {
    router.get(CourseContentsApi.show.path({ slug }), { highlight: searchString });
  }

  function handleSearch(event) {
    event.preventDefault();

    router.get(BooksApi.search.path({ slug: book.slug }), { query: searchString });
  }
}
