// JsFromRoutes CacheKey 3d79f6db055261f3237de238028d32f8
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  create: /* #__PURE__ */ definePathHelper('post', '/parts/:part_slug/custom_sections'),
  update: /* #__PURE__ */ definePathHelper('patch', '/parts/:part_slug/custom_sections/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/parts/:part_slug/custom_sections/:id'),
}
