import React from 'react';

import { LayoutBook } from '~/components/ui-extended/layout-book';

import { PageContent } from './components/page-content';
import { useBooksEditPage } from './T_BooksEdit';

export default function Edit() {
  const { access, book } = useBooksEditPage().props;

  return (
    <LayoutBook
      breadcrumbs={[
        { title: 'common.breadcrumbs.booksEdit', type: 'static', translateTitle: true },
      ]}
      sidebarTitle={book.title}
      htmlHeadTitle={book.title}
      access={access}
      book={book}>
      <PageContent />
    </LayoutBook>
  );
}
