import React from 'react';

import { ExerciseLayoutText } from './ExerciseLayoutText';

type Props = {
  metadata: any;
  book: any;
};

export function Cover(props: Props) {
  const { book, metadata } = props;

  const { title, exercise_layout, user_answers } = metadata;

  if (book.cover_url) {
    return (
      <div className="cover-image-page border-3">
        <img className="w-full h-full" src={book.cover_url} alt={title} />
      </div>
    );
  }

  return (
    <div className="cover-page">
      <h1 className="border-b-4 font-bold mb-4 pb-4 text-[28pt] border-b-white">{title}</h1>

      <ExerciseLayoutText exercise_layout={exercise_layout} user={user_answers?.user} />
    </div>
  );
}
